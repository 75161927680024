import React, { useRef } from "react";
import Overlay from "@components/Overlay";
import { Button } from "@components/ui/button";

export const DeleteLocationModal: React.FC<{
	locationName: string;
	buttonText?: string;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	showModal: boolean;
	isLoading: boolean;
	buttonAction: () => void;
	DeleteContent: (data?: any) => React.ReactNode;
}> = ({
	locationName,
	buttonText,
	showModal,
	setShowModal,
	isLoading,
	buttonAction,
	DeleteContent,
}) => {
	const modalRef = useRef(null);

	return (
		<>
			<Overlay
				show={showModal}
				setShow={setShowModal}
				modalRef={modalRef}
				isPersistent={isLoading}
			>
				<div
					className="relative z-50 w-full max-w-[363px] rounded-[10px] border border-[#E5E5E7] bg-white p-5 text-main-1"
					ref={modalRef}
				>
					<div className="flex items-start justify-between space-x-2">
						<div className="flex flex-1 flex-col">
							<h1 className="text-left text-[22px] font-medium leading-[30px] -tracking-[1%] text-main-1">
								Are you sure?
							</h1>
							<DeleteContent displayString={locationName} />
							<div className="mt-6 flex space-x-4 self-end">
								<Button
									className="font-medium text-[#09090B]"
									variant={"outline"}
									onClick={() => setShowModal(false)}
								>
									Cancel
								</Button>
								<Button
									className="font-medium"
									variant={"destructive"}
									onClick={buttonAction}
									disabled={isLoading}
								>
									{buttonText
										? buttonText
										: "Yes, delete location"}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Overlay>
		</>
	);
};
