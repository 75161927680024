import React from "react";

interface StepperProps {
	step: number;
	steplist: string[];
}

const Stepper: React.FC<StepperProps> = ({ step, steplist }) => {
	return (
		<div className="flex">
			{steplist.map((item, index) => (
				<div
					key={index}
					className={`flex ${index !== steplist.length - 1 && "flex-1"} cursor-pointer items-center py-3 text-sm font-medium ${
						step >= index ? "text-primary" : ""
					}`}
				>
					{step === index ? (
						<i className="mgc_check_circle_line mr-1 text-[32px] text-primary"></i>
					) : step > index ? (
						<i className="mgc_check_circle_fill mr-1 text-[32px] text-primary"></i>
					) : (
						<div className="mr-2 flex h-8 w-8 items-center justify-center rounded-full border-2">
							{index + 1}
						</div>
					)}

					{item}
					{index !== steplist.length - 1 && (
						<div className="mx-2 flex-1 border-t border-dashed border-primary"></div>
					)}
				</div>
			))}
		</div>
	);
};

export default Stepper;
