import { ScrollArea } from "@src/components/ui/scroll-area";
import React from "react";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";

const EveryOtherTimeOscarIntegration: React.FC = () => {
	const [step, setStep] = React.useState(2);

	return (
		<section className="flex h-[80dvh] flex-1 flex-col ">
			<h2 className="text-[22px] font-semibold leading-[24px] tracking-[-1%] text-[#323539]">
				EMR Integrations
			</h2>
			<ScrollArea className="flex-1">
				<div className="flex h-full flex-1 flex-col">
					<div className="mb-[30px] mt-[42px] flex items-center space-x-[27px]">
						<figure className="size-16 overflow-hidden rounded-xl border border-[#DEDEDE] drop-shadow-[0_0_20px_0_rgba(0,0,0,0.10)]">
							<img
								src="/assets/images/integration/oscar/oscar.png"
								alt="Oscar Pro Image"
							/>
						</figure>
						<p className="text-[22px] font-semibold leading-[24px] tracking-[-1%] text-[#323539]">
							OSCAR Pro
						</p>
					</div>
					<hr />
					{/* Every Other time */}

					{step === 1 && <StepOne step={step} setStep={setStep} />}
					{step === 2 && <StepTwo step={step} setStep={setStep} />}
					{step === 3 && <StepThree step={step} setStep={setStep} />}
				</div>
			</ScrollArea>
		</section>
	);
};

export default EveryOtherTimeOscarIntegration;
