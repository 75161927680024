import { z } from "zod";

export interface Location {
	id: number;
	name: string;
	address: string;
	country: string;
	state: string;
	city: string;
	admin_id: number;
	business_id: number;
	approximate_waiting_time: string;
	created_at: Date;
	updated_at: Date;
	deleted_at: null;
	admin: Admin;
	schedule_block_in_min: string;
	stations: Station[];
}

interface Admin {
	id: number;
	name: string;
	email: string;
	role: string;
	job_title: null;
	stripe_customer_id: null;
	created_at: Date;
	updated_at: Date;
	deleted_at: null;
	stripe_id: string;
	pm_type: string;
	pm_last_four: string;
	trial_ends_at: null;
	business_id: number;
	is_email_verified: number;
}

interface Station {
	id: number;
	name: string;
	location_id: number;
	admin_id: number;
	created_at: Date;
	updated_at: Date;
	deleted_at: null;
	url_code: string;
	queue_url: string;
	is_queue_active: boolean;
	is_active: boolean;
}

interface LocationsAdmin {
	id: number;
	name: string;
	email: string;
	role: string;
	job_title: string | null;
	stripe_customer_id: string | null;
	created_at: string;
	updated_at: string;
	stripe_id: string;
	pm_type: string | null;
	pm_last_four: string | null;
	trial_ends_at: string | null;
	business_id: number;
	is_email_verified: number;
	is_active: number;
	company_id: string | null;
	phone_number: string | null;
}

interface StationSettings {
	time_zone: string;
	is_queue_active: number;
	manual_wait_time: string;
	auto_flow_enabled: number;
	average_wait_time: string;
	auto_approve_enabled: number;
	schedule_block_in_min: number;
	total_waitlists_count: number;
	use_average_wait_time: number;
	priority_order_enabled: number;
}

interface Settings {
	id: number;
	settingable_type: string;
	settingable_id: number;
	created_at: string;
	updated_at: string;
	settings: StationSettings;
}

export interface StationsProps {
	id: number;
	name: string;
	location_id: number;
	admin_id: number;
	created_at: string;
	updated_at: string;
	is_active: number;
	business_id: number;
	schedule_before: string | null;
	average_waiting_time: string | null;
	use_average_wait_time: number | null;
	url_code: string;
	schedule_url: string;
	queue_url: string;
	is_queue_active: boolean;
	is_authorized_to_user: boolean;
	settings: Settings;
}

export type StationsArrayProps = StationsProps[];

export interface LocationsIProps {
	id: number;
	image?: string;
	name: string;
	address: string;
	country: string;
	state: string;
	city: string;
	admin_id: number;
	business_id: number;
	approximate_waiting_time: string;
	created_at: string;
	updated_at: string;
	is_active: number;
	available_slots_per_day: string | null;
	schedule_block_in_min: number;
	time_zone: string;
	average_waiting_time: string | null;
	use_average_wait_time: number;
	auto_clearing_minutes: number;
	is_auto_clearing_on: number;
	settings: string | null;
	admin: LocationsAdmin;
	stations: StationsProps[];
}

export type LocationArray = LocationsIProps[];

export interface AddLocationInfoType {
	id: number;
}

export interface LocationResponse {
	id: number;
	location_image?: string;
	name: string;
	address: string;
	country: string;
	state: string;
	city: string;
	admin_id: number;
	business_id: number;
	approximate_waiting_time: string;
	created_at: string;
	updated_at: string;
	is_active: number;
	available_slots_per_day: number | null;
	schedule_block_in_min: number;
	time_zone: string;
	average_waiting_time: string | null;
	use_average_wait_time: number;
	auto_clearing_minutes: number;
	is_auto_clearing_on: number;
	settings: any | null;
	stations: Station[];
}

export interface AddBusinessLocationResponseType {
	status: boolean;
	message: string;
	location: LocationResponse;
}

const timeSlotSchema = z.object({
	start_time: z.string(),
	end_time: z.string(),
});

const dayDataSchema = z.object({
	is_active: z.boolean(),
	time_slots: z.array(timeSlotSchema),
});

export const AddLocationSchema = z.object({
	name: z.string().min(3, { message: "Location name is required" }),
	address: z.string(),
	image: z.any(),
	country: z.string(),
	state: z.string(),
	city: z.string(),
	approximate_waiting_time: z.string().refine(
		(time) => {
			return +time >= 5;
		},
		{
			message: "Minimum wait time: 5 mins",
		}
	),
	schedule_block_in_min: z
		.number()
		.min(5, "Minimum schedule block time: 5 mins"),
	time_zone: z.string().refine((value) => !!value, {
		message: "Kindly select time zone",
	}),
	day_time_slots: z.array(
		z.object({
			day: z.string(),
			day_value: z.number(),
			is_active: z.number(),
			time_slots: z.array(
				z.object({
					start_time: z.string(),
					end_time: z.string(),
				})
			),
		})
	),
});

export const AddLocationSchemaV2 = z.object({
	name: z.string().min(3, { message: "Location name is required" }),
	address: z.string(),
	image: z.any(),
	country: z.string(),
	state: z.string(),
	city: z.string(),
	approximate_waiting_time: z.string().refine(
		(time) => {
			return +time >= 5;
		},
		{
			message: "Minimum wait time: 5 mins",
		}
	),
	schedule_block_in_min: z
		.number()
		.min(5, "Minimum schedule block time: 5 mins"),
	time_zone: z.string().refine((value) => !!value, {
		message: "Kindly select time zone",
	}),
	day_time_slots: z.array(
		z.object({
			day: z.string(),
			day_value: z.number(),
			is_active: z.number(),
			time_slots: z.array(
				z.object({
					start_time: z.string(),
					end_time: z.string(),
				})
			),
		})
	),
	child_update_strategy: z.enum([
		"specific",
		"ignore",
		"default",
		"non_default",
		"override",
	]),
	child_ids: z.array(z.number()),
	waitlist_schedule_option: z.enum(["all", "schedule", "waitlist"]),
	data: z.record(dayDataSchema),
	is_waitlist: z.boolean(),
	is_schedule: z.boolean(),
});

export const WorkingHoursSchema = z.object({
	child_update_strategy: z.enum([
		"specific",
		"ignore",
		"default",
		"non_default",
		"override",
	]),
	child_ids: z.array(z.number()),
	waitlist_schedule_option: z.enum(["all", "schedule", "waitlist"]),
	data: z.record(dayDataSchema),
	is_waitlist: z.boolean(),
	is_schedule: z.boolean(),
});

export const EditLocationInfoSchema = z.object({
	name: z
		.string()
		.min(3, { message: "Location name is required" })
		.optional(),
	address: z.string().optional(),
	country: z.string().optional(),
	state: z.string().optional(),
	city: z.string().optional(),
	is_active: z.number().optional(),
	approximate_waiting_time: z
		.string()
		.refine(
			(time) => {
				return +time >= 5;
			},
			{
				message: "Minimum wait time: 5 mins",
			}
		)
		.optional(),
	schedule_block_in_min: z
		.number()
		.min(5, "Minimum schedule block time: 5 mins")
		.optional(),
	time_zone: z
		.string()
		.refine((value) => !!value, {
			message: "Kindly select time zone",
		})
		.optional(),
	day_time_slots: z
		.array(
			z.object({
				day: z.string(),
				day_value: z.number(),
				is_active: z.number(),
				time_slots: z.array(
					z.object({
						start_time: z.string(),
						end_time: z.string(),
					})
				),
			})
		)
		.optional(),
});

export type AddLocationData = z.infer<typeof AddLocationSchema>;
export type AddLocationDataV2 = z.infer<typeof AddLocationSchemaV2>;
export type EditLocationInfoData = z.infer<typeof EditLocationInfoSchema>;
export type WorkingHoursProps = z.infer<typeof WorkingHoursSchema>;
