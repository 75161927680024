import AppointmentCard from "./AppointmentCard";

interface CurrentAppointmentCardProps {
	online: boolean;
}
const CurrentAppointmentCard = ({ online }: CurrentAppointmentCardProps) => {
	return <AppointmentCard status={"confirmed"} online={online} />;
};

export default CurrentAppointmentCard;
