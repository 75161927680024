import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Stations from "../components/Stations";
import IntakeFields from "../components/IntakeFields";
import Members from "../components/Members";
import { FormProvider, useForm } from "react-hook-form";
import LocationCard from "../components/LocationCard";
import {
	AddBusinessLocationSlice,
	GetLocationDetailsSlice,
	UpdateBusinessLocationSlice,
} from "@src/store/slices/locations/locationSlice";
import CustomCheckbox2 from "@components/form/CustomCheckbox2";
import useCustomToast from "@components/CustomToast";
import { CustomImage } from "@components/common/CustomImage";
import RatingComponent from "@components/common/RatingComponent";
import { LuMapPin, LuTrash2, LuChevronRight, LuInfo } from "react-icons/lu";
import { IoMdStopwatch } from "react-icons/io";
import { PiGear, PiPencilSimple } from "react-icons/pi";
import { Card } from "@components/ui/card";
import { Button } from "@components/ui/button";
import { Badge } from "@components/ui/badge";
import {
	LocationsIProps,
	LocationArray,
	AddBusinessLocationResponseType,
} from "@src/types/Location";
import { convertTimeFormat } from "@src/utils/functions";
import { DeleteLocationModal, LocationInfoModal } from "../components";
import {
	DeleteLocationSlice,
	GetLocationStationsSlice,
} from "@src/store/slices/locations/locationSlice";

const DeleteLocationContent = ({
	displayString,
}: {
	displayString: string;
}) => {
	return (
		<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
			Are you sure you want to delete{" "}
			<span className="font-semibold">&quot;{displayString}&quot;</span>
			{". "}This action cannot be undone.{" "}
			<span className="font-semibold">&quot;{displayString}&quot;</span>{" "}
			will no longer be visible to the system.
		</p>
	);
};

const LocationDetails: React.FC = () => {
	const navigate = useNavigate();
	const customToast = useCustomToast();
	const [tab, setTab] = useState("Station");
	const [showLocationInfoModal, setShowLocationInfoModal] = useState(false);
	const [selectedLocation, setSelectedLocation] =
		useState<LocationsIProps | null>(null);
	const [showDeleteLocationModal, setShowDeleteLocationModal] =
		useState(false);
	const [searchParams] = useSearchParams();
	const locationId = searchParams.get("locationId") || "";
	const isEditMode = Boolean(locationId);

	const { data, error, isLoading } = isEditMode
		? GetLocationDetailsSlice(Number(parseInt(locationId)))
		: { data: null, error: null, isLoading: false };

	const {
		data: stationData,
		error: stationError,
		isLoading: stationLoading,
	} = GetLocationStationsSlice(parseInt(locationId));

	// console.log("🚀 ~ stationData:", stationData);
	const { isLoading: deleteLoading, mutate: deleteMutation } =
		DeleteLocationSlice(selectedLocation?.id || 0, () =>
			setShowDeleteLocationModal(false)
		);

	const methods = useForm<any>({
		// resolver: zodResolver(
		// 	currentStep === 0 ? AddLocationSchema : AddLocationSchema
		// ),
		// defaultValues: {
		// 	name: "",
		// 	state: "",
		// 	address: "",
		// 	city: "",
		// 	country: "",
		// 	approximate_waiting_time: "15",
		// 	schedule_block_in_min: 15,
		// 	time_zone: moment.tz.guess(),
		// 	day_time_slots: slots,
		// },
	});

	const handleDeleteLocationModal = (location: any) => {
		setSelectedLocation(location);
		setShowDeleteLocationModal(true);
	};

	const {
		handleSubmit,
		formState: { errors, isValid },
	} = methods;

	const RenderTab = (activeStep: string) => {
		switch (activeStep) {
			case "Station":
				return <Stations />;
			case "Intake Fields":
				return <IntakeFields />;
			case "Members":
				return <Members />;
			// case 2:
			// 	return <LocationMember />;
			default:
				return "";
		}
	};

	useEffect(() => {
		if (!locationId) navigate("/dashboard/locations");
	}, [navigate, locationId]);

	const handleShowLocationInfoModal = (location: any) => {
		setSelectedLocation(location);
		setShowLocationInfoModal(true);
	};

	const handleDeleteLocation = () => {
		customToast("Deleting location", {
			id: "delete-location",
			type: "loading",
		});
		setShowDeleteLocationModal(false);
		if (selectedLocation) {
			deleteMutation({ id: selectedLocation.id });
		}
	};

	return (
		<div className="flex-1 space-y-4">
			<Card className="flex items-center justify-between space-x-3 bg-slate-50 p-4">
				<div className="flex flex-1 items-center space-x-3">
					<CustomImage
						src={data?.data?.location_image}
						alt="location"
						imageClass="aspect-square w-[64px] overflow-hidden rounded-lg border"
					/>
					<div className="flex-1 space-y-2">
						<div className="flex w-full items-center space-x-3">
							<p className="line-clamp-1 font-semibold">
								{data?.data?.name}
							</p>
							<div className="">
								<RatingComponent rating={3} readOnly />
							</div>
						</div>
						<div className="flex items-center space-x-1 text-gray-400">
							<LuMapPin size={12} />
							<p className="line-clamp-1 text-xs">
								{data?.data?.address || "N/A"}
							</p>
						</div>
					</div>
				</div>
				<div className="flex flex-1 items-center justify-between space-x-2">
					<div className="flex-2 space-x-3">
						<Badge
							className="bg-slate-200 text-sm"
							variant={"secondary"}
						>
							<i className="mgc_shop_line before-text-gray-4 mr-2 text-[18px]" />
							<span className=" mr-1 font-normal">Stations</span>{" "}
							{data?.data?.stations.length}
						</Badge>
						<Badge
							className="bg-slate-200 text-sm"
							variant={"secondary"}
						>
							<IoMdStopwatch className="mr-2" size={14} />
							<span className="mr-1 font-normal">
								Avg. Wait Time
							</span>{" "}
							{convertTimeFormat(
								data?.data?.approximate_waiting_time || ""
							)}
						</Badge>
					</div>

					<div className="flex flex-1 items-center justify-end space-x-4">
						<Button
							variant="secondary"
							size="icon-sm"
							type="button"
							onClick={() =>
								handleShowLocationInfoModal(data?.data)
							}
						>
							<LuInfo size={18} className=" text-black" />
						</Button>
						<Button
							variant="secondary"
							size="icon-sm"
							type="button"
							onClick={() => {
								navigate(
									`/dashboard/locations/edit-location?locationId=${data?.data?.id}`
								);
							}}
						>
							<PiPencilSimple size={18} className=" text-black" />
						</Button>
						<Button
							variant="secondary"
							size="icon-sm"
							type="button"
							onClick={() =>
								handleDeleteLocationModal(data?.data)
							}
						>
							<LuTrash2 size={18} className=" text-black" />
						</Button>
					</div>
				</div>
			</Card>
			<div className="flex justify-evenly border-b text-gray-400">
				{["Station", "Operating Hours", "Intake Fields", "Members"].map(
					(item, index) => (
						<p
							key={index}
							onClick={() => setTab(item)}
							className={`border-b-2 py-3 ${tab === item ? "border-primary" : ""}`}
						>
							{item}
						</p>
					)
				)}
			</div>

			{RenderTab(tab)}
			<DeleteLocationModal
				locationName={selectedLocation?.name || ""}
				showModal={showDeleteLocationModal}
				setShowModal={setShowDeleteLocationModal}
				isLoading={deleteLoading}
				buttonAction={handleDeleteLocation}
				DeleteContent={DeleteLocationContent}
			/>
			<LocationInfoModal
				location={selectedLocation}
				showModal={showLocationInfoModal}
				setShowModal={setShowLocationInfoModal}
				isLoading={deleteLoading}
				buttonAction={handleDeleteLocation}
			/>
		</div>
	);
};

export default LocationDetails;
