import CustomSelect from "@components/common/CustomSelect";
import CustomCheckbox2 from "@components/form/CustomCheckbox2";
import { Button } from "@components/ui/button";
import { Card } from "@components/ui/card";
import { Input } from "@components/ui/input";
import { Switch } from "@components/ui/switch";
import { LuMapPin, LuTrash2, LuChevronRight, LuInfo } from "react-icons/lu";
import { Badge } from "@components/ui/badge";
import { IoMdStopwatch } from "react-icons/io";
import { PiGear, PiQrCode } from "react-icons/pi";
import RatingComponent from "@components/common/RatingComponent";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {
	GetLocationsSlice,
	DeleteLocationSlice,
} from "@src/store/slices/locations/locationSlice";
import useLocationsStore from "@src/store/useLocationsStore";
import RequestIsLoading from "@components/RequestIsLoading";
import { convertTimeFormat } from "@src/utils/functions";
import { DeleteLocationModal, LocationInfoModal } from "./components";
// import LocationInfoModal from "./components/LocationInfoModal";
import useCustomToast from "@components/CustomToast";
import {
	LocationsIProps,
	LocationArray,
	AddBusinessLocationResponseType,
} from "@src/types/Location";
import { CustomImage } from "@components/common/CustomImage";
import {
	useForm,
	FormProvider,
	useFieldArray,
	Controller,
	useWatch,
} from "react-hook-form";
import { UpdateBusinessLocationSlice } from "@src/store/slices/locations/locationSlice";
import { useQueryClient } from "react-query";

interface FormData {
	locations: LocationArray;
}

const DeleteContent = ({ displayString }: { displayString: string }) => {
	return (
		<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
			Are you sure you want to delete{" "}
			<span className="font-semibold">&quot;{displayString}&quot;</span>
			{". "}This action cannot be undone.{" "}
			<span className="font-semibold">&quot;{displayString}&quot;</span>{" "}
			will no longer be visible to the system.
		</p>
	);
};

const Locations: React.FC = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { isLoading, isFetching } = GetLocationsSlice();
	const customToast = useCustomToast();

	const locationsData = useLocationsStore((s) => s.locations);
	const [locationIds, setLocationIds] = useState<number[]>([]);
	const [showDeleteLocationModal, setShowDeleteLocationModal] =
		useState(false);
	const [showLocationInfoModal, setShowLocationInfoModal] = useState(false);
	const [selectedLocation, setSelectedLocation] =
		useState<LocationsIProps | null>(null);
	const { isLoading: deleteLoading, mutate: deleteMutation } =
		DeleteLocationSlice(selectedLocation?.id || 0, () =>
			setShowDeleteLocationModal(false)
		);

	const updateBusinessLocationMutation = UpdateBusinessLocationSlice(
		(data) => {
			queryClient.setQueryData<AddBusinessLocationResponseType[]>(
				"locationDetails",
				(oldData) =>
					oldData?.map((location) =>
						location.location.id === data.location.id
							? {
									...location,
									is_active: data.location.is_active,
								}
							: location
					) ?? []
			);
		},
		(error) => console.log("🚀 ~ LocationTabList ~ error:", error)
	);

	const handleDeleteLocationModal = (location: any) => {
		setSelectedLocation(location);
		setShowDeleteLocationModal(true);
	};

	const handleShowLocationInfoModal = (location: any) => {
		setSelectedLocation(location);
		setShowLocationInfoModal(true);
	};

	const handleDeleteLocation = () => {
		customToast("Deleting location", {
			id: "delete-location",
			type: "loading",
		});
		setShowDeleteLocationModal(false);
		if (selectedLocation) {
			deleteMutation({ id: selectedLocation.id });
		}
	};

	const methods = useForm<FormData>({
		defaultValues: {
			locations: [],
		},
	});

	const { control, setValue } = methods;
	const { fields } = useFieldArray<FormData>({
		control,
		name: "locations",
		keyName: "_id" as "id",
	});

	const watchLocations = useWatch({
		control,
		name: "locations",
	});

	const handleSwitchChange = useCallback(
		async (locationId: number, value: boolean, index: number) => {
			const isActive = value ? 1 : 0;

			try {
				customToast("Updating location", {
					id: "update-location",
					type: "loading",
				});

				setValue(`locations.${index}.is_active`, isActive);

				const result = await updateBusinessLocationMutation.mutateAsync(
					{
						id: locationId,
						data: { is_active: isActive },
					}
				);

				const serverIsActive = result.location.is_active ? 1 : 0;
				setValue(`locations.${index}.is_active`, serverIsActive);

				customToast("Location updated successfully", {
					id: "update-location",
					type: "success",
				});
			} catch (error) {
				setValue(`locations.${index}.is_active`, !isActive ? 1 : 0);
			}
		},
		[updateBusinessLocationMutation, setValue, customToast]
	);

	useEffect(() => {
		if (locationsData) {
			methods.reset({ locations: locationsData });
		}
	}, [locationsData, methods]);

	return (
		<>
			<RequestIsLoading isLoading={isLoading} />
			<section className="flex flex-1 flex-col space-y-4">
				<h2>Locations newest</h2>
				<div className="flex space-x-4">
					<div className="flex-1">
						<Input placeholder="Quick search..." />
					</div>
					<div>
						<CustomSelect
							className="max-w-[186px]"
							options={[]}
							placeholder="Sort By"
							onChange={() => {
								//
							}}
						/>
					</div>
					<Button
						className="max-w-[140px] flex-1 self-center rounded-md"
						type="submit"
						onClick={() => {
							navigate("/dashboard/locations/add-location");
						}}
					>
						Add Location
					</Button>
				</div>

				<div className="flex items-center justify-between">
					<div className="flex items-center space-x-2">
						<CustomCheckbox2
							isChecked
							handleCheckboxChange={() => {}}
							className="checked:border-[#195388] checked:bg-[#195388]"
							id={""}
						/>
						<label className="font-medium">Select all</label>
					</div>
					<div className="flex items-center space-x-2.5">
						<label className="font-regular text-[#7D7E80]">
							Deactivate Selected
						</label>
						<Switch
							defaultChecked={true}
							id="queue-on-off"
							className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
						/>
						<p>No</p>
						<Button
							variant="secondary"
							size="icon-sm"
							// onClick={() => setShowClearQueueModal(true)}
						>
							<LuTrash2 size={18} className=" text-black" />
						</Button>
					</div>
				</div>
				<div>
					<FormProvider {...methods}>
						<form>
							<div className="flex flex-col space-y-4">
								{!isLoading &&
									fields.map(
										(location: any, index: number) => (
											<Card
												key={location.id}
												className="flex items-center justify-between space-x-3 bg-slate-50 p-4"
											>
												<div className="flex flex-1 items-center space-x-3">
													<CustomCheckbox2
														isChecked={locationIds.includes(
															location.id
														)}
														handleCheckboxChange={() => {
															setLocationIds(
																(prev) => {
																	if (
																		prev.includes(
																			location.id
																		)
																	) {
																		return prev.filter(
																			(
																				id
																			) =>
																				id !==
																				location.id
																		);
																	} else {
																		return [
																			...prev,
																			location.id,
																		];
																	}
																}
															);
														}}
														id={
															"location-" +
															location.id
														}
														className="checked:border-[#195388] checked:bg-[#195388]"
													/>
													<CustomImage
														src={location?.image}
														alt="location"
														imageClass="aspect-square w-[64px] overflow-hidden rounded-lg border"
													/>
													<div className="flex-1 space-y-2">
														<div className="flex w-full items-center space-x-3">
															<p className="line-clamp-1 font-semibold">
																{location.name}
															</p>
															<Button
																variant="secondary"
																size="xs"
																onClick={() =>
																	handleShowLocationInfoModal(
																		location
																	)
																}
															>
																<LuInfo
																	size={12}
																	className=" text-black"
																/>
															</Button>
														</div>
														<div className="flex items-center space-x-1 text-gray-400">
															<LuMapPin
																size={12}
															/>
															<p className="line-clamp-1 text-xs">
																{location.address ||
																	"N/A"}
															</p>
														</div>
														<div className="">
															<RatingComponent
																rating={3}
																readOnly
															/>
														</div>
													</div>
												</div>
												<div className="flex-1 space-x-3">
													<Badge
														className="bg-slate-200 text-sm"
														variant={"secondary"}
													>
														<i className="mgc_shop_line before-text-gray-4 mr-2 text-[18px]" />
														<span className=" mr-1 font-normal">
															Stations
														</span>{" "}
														{
															location.stations
																.length
														}
													</Badge>
													<Badge
														className="bg-slate-200 text-sm"
														variant={"secondary"}
													>
														<IoMdStopwatch
															className="mr-2"
															size={14}
														/>
														<span className="mr-1 font-normal">
															Avg. Wait Time
														</span>{" "}
														{convertTimeFormat(
															location.approximate_waiting_time
														)}
													</Badge>
												</div>

												<div className="flex flex-1 items-center justify-end space-x-4">
													<div className="flex items-center space-x-2">
														<p>Location Active</p>
														<Controller
															name={`locations.${location.is_active}`}
															control={control}
															render={({
																field,
															}) => (
																<Switch
																	ref={
																		field.ref
																	}
																	checked={Boolean(
																		watchLocations[
																			index
																		]
																			?.is_active
																	)}
																	className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
																	onCheckedChange={(
																		value
																	) =>
																		handleSwitchChange(
																			location.id,
																			value,
																			index
																		)
																	}
																/>
															)}
														/>
														<span>
															{location.is_active ===
															1
																? "Yes"
																: "No"}
														</span>
													</div>

													<Button
														variant="secondary"
														size="icon-sm"
														type="button"
														onClick={() => {
															navigate(
																`/dashboard/locations/edit-location?locationId=${location.id}`
															);
														}}
													>
														<PiGear
															size={18}
															className=" text-black"
														/>
													</Button>
													<Button
														variant="secondary"
														size="icon-sm"
														type="button"
														onClick={() => {
															handleShowLocationInfoModal(
																location
															);
														}}
													>
														<PiQrCode
															size={18}
															className=" text-black"
														/>
													</Button>
													<Button
														variant="secondary"
														size="icon-sm"
														type="button"
														onClick={() =>
															handleDeleteLocationModal(
																location
															)
														}
													>
														<LuTrash2
															size={18}
															className=" text-black"
														/>
													</Button>
													<Button
														variant="ghost"
														size="icon-sm"
														type="button"
														onClick={() =>
															navigate(
																`/dashboard/locations/details?locationId=${location.id}`
															)
														}
													>
														<LuChevronRight
															size={18}
															className=" text-black"
														/>
													</Button>
												</div>
											</Card>
										)
									)}
							</div>
						</form>
					</FormProvider>
				</div>
				<DeleteLocationModal
					locationName={selectedLocation?.name || ""}
					showModal={showDeleteLocationModal}
					setShowModal={setShowDeleteLocationModal}
					isLoading={deleteLoading}
					buttonAction={handleDeleteLocation}
					DeleteContent={DeleteContent}
				/>
				<LocationInfoModal
					location={selectedLocation}
					showModal={showLocationInfoModal}
					setShowModal={setShowLocationInfoModal}
					isLoading={deleteLoading}
					buttonAction={handleDeleteLocation}
				/>
			</section>
		</>
	);
};

export default Locations;
