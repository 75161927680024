import React, { useState } from "react";
import { Switch } from "../ui/switch";

export interface CustomSwitchAltProps extends React.PropsWithChildren {
	textBefore?: string;
	textAfter?: string;
	checked?: boolean;
	onCheckedChange?: (checked: boolean) => void;
	className?: string;
}

const CustomSwitchAlt: React.FC<CustomSwitchAltProps> = ({
	textBefore,
	checked = false,
	onCheckedChange,
	className,
	textAfter,
}) => {
	const [isActive, setIsActive] = useState(checked);

	const handleSwitchChange = (checked: boolean) => {
		setIsActive(checked);
		if (onCheckedChange) {
			onCheckedChange(checked);
		}
	};

	return (
		<div className="flex items-center space-x-0">
			{textBefore && <p>{textBefore}</p>}
			<Switch
				checked={isActive}
				onCheckedChange={handleSwitchChange}
				className={`scale-[0.80] ${className ?? ""} data-[state=checked]:bg-[#2EBF43]`}
			/>
			<span>{isActive ? "On" : "Off"}</span>
			{textAfter && <p>{textAfter}</p>}
		</div>
	);
};

export default CustomSwitchAlt;
