import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useForgotPassword, useLoginUser } from "../hooks/useAuthData";
import { ForgotParams, ForgotSchema } from "../types/Auth";
import Loader from "./Loader/Loader";
import { LoaderButton } from "./form/LoaderButton";
import FormInput from "./form/FormInput";
import { Navigate, useNavigate } from "react-router";

const ForgotPasswordCard: React.FC = () => {
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<ForgotSchema>({
		resolver: zodResolver(ForgotParams),
	});
	const navigate = useNavigate();

	const forgetPasswordMutation = useForgotPassword(
		() =>
			setTimeout(() => {
				navigate("/sign-in");
			}, 10000),
		undefined
	);

	const onSubmit: SubmitHandler<ForgotSchema> = async (data) => {
		try {
			forgetPasswordMutation.mutate(data);
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	return (
		<form
			className="z-10 flex w-full max-w-[488px] flex-col space-y-6 rounded-[10px] bg-white pt-6 shadow-[0_20px_25px_-5px_rgba(16,24,40,0.1),_0_8px_10px_-6px_rgba(16,24,40,0.1)]"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="flex flex-col space-y-2 px-4 md:px-8">
				<h3 className="text-[22px] font-semibold leading-[30px] tracking-[-0.22px] text-[#323539]">
					Reset it in just a few steps.
				</h3>
				<p className="font-normal tracking-[-1%] text-[#858C95]">
					Enter your registered email to reset your password.
				</p>
			</div>
			<div className="flex flex-col space-y-6 px-4 md:px-8">
				<FormInput
					inputType={"text"}
					label="Email Address"
					minLength={5}
					maxLength={40}
					error={errors.email?.message}
					register={{ ...register("email") }}
					required
				/>
				{forgetPasswordMutation.isSuccess && (
					<p className="text-sm tracking-[-0.1px] text-[#48AA75]">
						Instructions to reset your account has been sent to your
						email
					</p>
				)}
			</div>
			<div className="rounded-b-[10px] bg-[#FAFBFC] px-4 pb-6 pt-4 md:px-8">
				<LoaderButton
					disabled={forgetPasswordMutation.isLoading}
					className={
						"h-10 w-full overflow-hidden bg-[#195388] px-8 text-white hover:border-[#72F4E8] hover:bg-[#72F4E8] hover:text-[#053969] md:h-10 md:w-[168px] mmd:text-[15px]"
					}
					type="submit"
				>
					{forgetPasswordMutation.isLoading ? (
						<Loader size={24} />
					) : (
						"Reset Password"
					)}
				</LoaderButton>
			</div>
		</form>
	);
};

export default ForgotPasswordCard;
