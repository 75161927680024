import { Tabs, TabsList, TabsTrigger } from "@src/components/ui/tabs";
import Header from "@src/layouts/Header/Header";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router";

const SettingsLayout: React.FC = () => {
	const location = useLocation();
	const basePath =
		location.pathname.split("/dashboard/settings/")[1]?.split("/")[0] ||
		"account-setting";
	return (
		<div className="flex flex-1 flex-col">
			<Header title="Settings" showDate={false} />

			<Tabs defaultValue={basePath} className="flex h-full gap-x-10">
				<SettingsTabs />
				<Outlet />
			</Tabs>
		</div>
	);
};

export default SettingsLayout;

const SettingsTabs = () => {
	const navigate = useNavigate();
	return (
		<div className="flex flex-col space-y-8 p-6 pl-0">
			<div className="flex flex-col space-y-1">
				<TabsList className="text-basecolor flex h-full flex-col items-start justify-normal gap-y-2 bg-transparent text-left shadow-none">
					{tabsData.map((tab) => (
						<TabsTrigger
							key={tab.value}
							className="group flex w-full min-w-[236px] items-center justify-between rounded-[8px] border-none p-2 font-inter text-[14px] shadow-none drop-shadow-none transition-all duration-200 ease-in-out data-[state=active]:bg-primary/10 data-[state=active]:text-primary data-[state=active]:shadow-none data-[state=active]:drop-shadow-none"
							value={tab.value}
							onClick={() => {
								navigate(`${tab.value}`, { replace: false });
							}}
						>
							<p>{tab.label}</p>
							<i className="mgc_right_line before:!text-[#858C95]" />
						</TabsTrigger>
					))}
				</TabsList>
			</div>
		</div>
	);
};

const tabsData = [
	{ value: "account-setting", label: "Account Setting" },
	{ value: "business-setting", label: "Business Setting" },
	{ value: "subscription", label: "Subscription" },
	{ value: "billing-payments", label: "Billing & Payments" },
	{ value: "theme", label: "Theme" },
	{ value: "integrations-plugins", label: "Integrations & Plugins" },
];
