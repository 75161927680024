import { MdOutlineModeEditOutline } from "react-icons/md";
import { Button } from "../../../ui/button";
const LinkedCalendars = () => {
	return (
		<div className="flex flex-col space-y-4">
			<div className="flex max-w-[70%] justify-between">
				<div>
					<h1 className="text-xl font-medium text-[#323539] ">
						Linked Calendars
					</h1>
					<p className="text-[14px] text-[#858C95]">
						Link with your daily calendars below
					</p>
				</div>
				<div className="mt-auto">
					<Button className="" variant="outline">
						{" "}
						Link Calendar
					</Button>
				</div>
			</div>
			<div className="flex w-fit flex-col rounded-[20px] border-b border-[#EAEBF0] p-1 px-4">
				<div className="flex w-full justify-end self-end text-[#858C95] ">
					<MdOutlineModeEditOutline />
				</div>
				<div className="flex space-x-4 pb-4">
					<div className="flex items-center gap-4">
						<div className="flex h-6 w-6 items-center justify-center rounded-full shadow-md">
							<img src="/assets/icons/yahoo_logo.svg" alt="" />
						</div>
						<p className="text-xs text-[#858C95]">
							stationspecificemail@yahoo.com
						</p>
					</div>

					<div>
						<div className="flex flex-col items-end gap-y-2 font-normal">
							<p className="flex gap-4 text-xs text-[#858C95]">
								Check for Conflicts{" "}
								<span className="font-bold">YES</span>
							</p>
							<p className="flex gap-4 text-xs font-normal text-[#858C95]">
								Add to Calendar{" "}
								<span className="font-bold">YES</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LinkedCalendars;
