import { useState } from "react";
import NavBar from "../../components/Navbar";
import SignInCard from "../../components/signin/SignInCard";

const SignInComponent = () => {
	const [showBookADemo, setShowBookDemo] = useState(false);
	const [showContactUsModal, setshowContactUsModal] = useState(false);
	// if (import.meta.env.PROD)
	// 	window.location.href = "https://migranium.com/sign-in";

	return (
		<main className="relative flex flex-col md:h-screen">
			<NavBar
				isWhite
				showBookADemo={showBookADemo}
				setShowBookDemo={setShowBookDemo}
				showContactUsModal={showContactUsModal}
				setshowContactUsModal={setshowContactUsModal}
			/>
			<div className="clock-background-image flex h-full w-full items-center justify-center self-stretch px-4">
				<div className="relative flex w-full max-w-[1216px] items-center gap-12 mmd:flex-col-reverse mmd:py-12">
					<SignInCard />
					<div className="z-10 flex flex-col space-y-3 md:space-y-6">
						<h1 className="text-2xl font-bold text-[#323539] md:text-[40px] md:font-semibold md:leading-[32px] md:tracking-[-1.5%]">
							Welcome Back to Migranium
						</h1>
						<p className="tracking-[-1%] text-[#858C95] sm:text-lg">
							Sign in to continue optimizing your operational
							efficiency <br className="msm:hidden" /> and
							enhancing customer experiences.
						</p>
					</div>
				</div>
			</div>
		</main>
	);
};

export const SignIn = () => {
	return <SignInComponent />;
};
