import useLocationSelection from "@hooks/useLocationSelection";
import useStationSelection from "@hooks/useStationSelection";
import InputIcon from "@src/components/ui-extended/input-icon";
import Header from "@src/layouts/Header/Header";
import HeaderTopItem from "@src/layouts/Header/HeaderTopItem";
import { GetQueryListSlice } from "@src/store/slices/waitlist/getWaitlistSlice";
import { cn } from "@utils/functions";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { LuClock3 } from "react-icons/lu";
import GridView from "../../components/Dashboard/waitlist/GridView";
import { CustomSelect } from "../../components/form/CustomSelect";
import { Tabs, TabsList, TabsTrigger } from "../../components/ui/tabs";
import useUserStore from "../../store/useUserStore";
import useWaitListStore from "../../store/useWaitListStore";
import { PatientStatus } from "../../types/wait-list";
import WaitlistShowScheduleAddPatientRow from "./components/WaitlistShowScheduleAddPatientRow";
import WaitlistColumnContainer from "./components/WaitlistViews/WaitlistColumnContainer";

const Waitlist: React.FC = () => {
	const [tabView, setTabView] = useState<"grid_view" | "column_view">(
		"column_view"
	);
	const getQueueListQuery = GetQueryListSlice();
	const patients = useWaitListStore((s) => s.patients);
	// const currentLocationId = useUserStore((s) => s.currentLocationId);

	// const currentLocation = user?.business.locations?.find(
	// 	(location) => location.id === currentLocationId
	// );

	const [isAutoFlowOn, setIsAutoFlowOn] = useState(false);
	const [isScheduleOn, setIsScheduleOn] = useState(false);

	const statuses: PatientStatus[] = [
		PatientStatus.Schedule,
		PatientStatus.Pending,
		PatientStatus.Upcoming,
		PatientStatus.NextInLine,
		PatientStatus.Serving,
	];

	if (isAutoFlowOn) statuses.splice(1, 1);
	if (!isScheduleOn) statuses.splice(0, 1);

	// useGetQueueOrder();

	// const onDragStart = (event) => {
	// 	const { active } = event;
	// 	console.log("drag start", active);
	// 	setActiveId(active.id);
	// };

	// const onDragMove = (event) => {
	// 	const { active, over } = event;
	// 	console.log("drag move", active, over);
	// 	// setActiveId(active.id);
	// };

	const split_estimated_wait_time =
		getQueueListQuery.data &&
		(getQueueListQuery.data?.data.total_wait_time.split(":") as string[]);

	const estimated_wait_time =
		split_estimated_wait_time &&
		(parseInt(split_estimated_wait_time[0]) > 0
			? `${split_estimated_wait_time[0]}h`
			: "") +
			" " +
			`${split_estimated_wait_time[1]}min`;

	return (
		<div className="flex flex-1 flex-col">
			<Header
				title={"Flow Management"}
				content={
					<ul className="flex items-center space-x-6">
						<HeaderTopItem
							icon={"mgc_stopwatch_line"}
							title={estimated_wait_time ?? "N/A"}
							desc={"Estimated Wait Time"}
						/>
						<HeaderTopItem
							icon={"mgc_group_line"}
							title={patients.length ?? "N/A"}
							desc={"Patients Waiting"}
						/>
					</ul>
				}
			/>
			<div className="flex flex-1 flex-col overflow-auto">
				<section className="mt-2 flex items-center justify-between py-2">
					<div className="flex items-center space-x-4">
						<WaitlistLocationsSelectButton className="h-9" />
						<WaitlistLocationsStationsSelectButton className="h-9" />

						<CustomSelect
							className="h-9 w-[180px]"
							placeholder="Joined From"
							label="Joined From"
							options={[]}
							onChange={() => {}}
						/>
					</div>
					<div className="flex items-center space-x-4">
						<div className="w-[311px]">
							<InputIcon
								placeholder="Quick search by name."
								className="placeholder:text-[#858C95]"
								icon="mgc_search_line"
							/>
						</div>
						<div
							className={`transition-opacity ${isScheduleOn ? "pointer-events-none opacity-50" : "pointer-events-auto opacity-100"} duration-200 ease-in-out`}
						>
							<Tabs
								value={tabView}
								className="rounded-lg bg-green-900"
							>
								<TabsList>
									<TabsTrigger
										onClick={() => setTabView("grid_view")}
										value="grid_view"
										className="flex items-center space-x-1.5"
									>
										<i
											className={`mgc_IDcard_line ${tabView === "grid_view" ? "before-text-dark" : "before-text-gray-3"} text-[20px] transition-colors duration-200 ease-in-out`}
										/>
										<p>Grid view</p>
									</TabsTrigger>
									<TabsTrigger
										onClick={() => {
											setTabView("column_view");
											console.log("value");
										}}
										value="column_view"
										className="flex items-center space-x-1.5"
									>
										<i
											className={`mgc_list_check_3_line ${tabView === "column_view" ? "before-text-dark" : "before-text-gray-3"} text-[20px] transition-colors duration-200 ease-in-out`}
										/>
										<p>Column view</p>
									</TabsTrigger>
								</TabsList>
							</Tabs>
						</div>
					</div>
				</section>
				<WaitlistShowScheduleAddPatientRow
					isAutoFlowOn={isAutoFlowOn}
					isScheduleOn={isScheduleOn}
					tabView={tabView}
					setIsAutoFlowOn={setIsAutoFlowOn}
					setIsScheduleOn={setIsScheduleOn}
					setTabView={setTabView}
				/>

				{tabView === "column_view" ? (
					<WaitlistColumnContainer statuses={statuses} />
				) : (
					<div className="mt-4 grid grid-cols-2 gap-4">
						{statuses.map((status, index) => (
							<GridView key={index} status={status} />
						))}
					</div>
				)}
				{/* <NewWaitlist /> */}
				{/* <PatientSwapRequest
				patientSwapDetails={{
					id: 1,
					order: "1",
					swap_id: "21212",
					desired_position: "3",
					reason: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu",
				}}
			/> */}
			</div>
		</div>
	);
};

const HoursTime: React.FC = () => {
	const [currentTime, setCurrentTime] = useState(dayjs().format("HH:mm:ss"));
	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentTime(dayjs().format("HH:mm:ss"));
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	return (
		<div className="flex items-center gap-x-1">
			<LuClock3 size={18} />
			<span className="min-w-[60px]">{currentTime}</span>
		</div>
	);
};

export const WaitlistLocationsSelectButton: React.FC<{
	className?: string;
}> = ({ className }) => {
	const locationsList = useUserStore((s) => s.locationsList);
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const { handleLocationChange } = useLocationSelection();

	return (
		<CustomSelect
			className={cn("w-[180px]", className)}
			label="Locations"
			options={locationsList}
			value={currentLocationId?.toString()}
			onChange={(value) => {
				handleLocationChange(value);
			}}
		/>
	);
};

export const WaitlistLocationsStationsSelectButton: React.FC<{
	className?: string;
}> = ({ className }) => {
	const locations = useUserStore((s) => s.user?.business.locations);
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const currentStationId = useUserStore((s) => s.currentStationId);
	const { handleStationChange } = useStationSelection();

	const currentLocation = locations?.find(
		(location) => location.id === currentLocationId
	);

	const stations = currentLocation
		? [
				{ label: "All Stations", value: "All Stations" },
				...(currentLocation?.stations.map((station) => ({
					label: station.name,
					value: station.id.toString(),
				})) || []),
			]
		: [];

	return (
		<CustomSelect
			className={cn("w-[180px]", className)}
			placeholder="All stations"
			label="Stations"
			options={stations}
			defaultValue={"All Stations"}
			value={currentStationId?.toString()}
			onChange={handleStationChange}
		/>
	);
};

export default Waitlist;
