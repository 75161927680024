import dayjs from "dayjs";
import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import {
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
	Tooltip as UITooltip,
} from "../../../components/ui/tooltip";
import useAnalyticsStore from "../../../store/useAnalyticsStore";

const Summary: React.FC = () => {
	const analyticsData = useAnalyticsStore((s) => s.analyticsData);
	const analyticsQuery = useAnalyticsStore((s) => s.analyticsQuery);
	// console.log(analyticsData);

	const totalVisitsData = analyticsData
		? [
				{
					name: "New",
					value: analyticsData?.summaries?.total_customers_data
						?.new_customers_count,
					fillColor: "#E5E4F6",
				},
				{
					name: "Returning",
					value:
						analyticsData?.summaries.total_customers_data
							.total_customers_count -
						analyticsData?.summaries.total_customers_data
							.new_customers_count,
					fillColor: "#5D57C8",
				},
			]
		: [];

	const averageTimeSpentData = analyticsData
		? [
				{
					name: "Waiting",
					value: analyticsData?.summaries.average_time_spent_data
						.average_time_from_joining_to_serve,
					fillColor: "#C5D0CE",
				},
				{
					name: "Serving",
					value: analyticsData?.summaries.average_time_spent_data
						.average_time_from_serving_to_done,
					fillColor: "#1E8A7C",
				},
			]
		: [];

	const cancellationData = analyticsData
		? [
				{
					name: "By User",
					value: analyticsData?.summaries.total_visits_data
						.cancelled_by_customer_total,
					fillColor: "#F9E1E2",
				},
				{
					name: "By Admin",
					value: analyticsData?.summaries.total_visits_data
						.cancelled_by_business_total,
					fillColor: "#D8434B",
				},
			]
		: [];

	const operationalData = analyticsData
		? [
				{
					name: "Inactive",
					value: +analyticsData?.summaries.working_hours_data?.inactive_working_hour?.toFixed(
						0
					),
					fillColor: "#DDF3E0",
				},
				{
					name: "Active",
					value: +analyticsData?.summaries.working_hours_data.active_working_hour.toFixed(
						0
					),
					fillColor: "#2CB13F",
				},
			]
		: [];

	return (
		<div className="flex w-full flex-col space-y-4 rounded-[20px] bg-[#F8F9FB] p-3">
			<div>
				<h3 className="text-lg font-semibold text-[#111827]">
					Summary
				</h3>
				<p className="text-sm text-[#374151]">
					{dayjs(analyticsQuery?.from_date).format("D MMMM, YYYY")} -{" "}
					{dayjs(analyticsQuery?.before_date).format("D MMMM, YYYY")}
				</p>
			</div>
			{analyticsData && (
				<div className="flex items-center justify-between space-x-4">
					<DonutChart
						title="Total Visits"
						data={totalVisitsData}
						total={
							analyticsData.summaries.total_customers_data
								?.total_customers_count
						}
						chartTooltip="Indicates when serving during operating hours
							(active) or paused due to capacity limits or other
							reasons (inactive)"
						totalType={"visitors"}
					/>
					<DonutChart
						title="Average Time Spent"
						data={averageTimeSpentData}
						total={
							+analyticsData?.summaries.average_time_spent_data.total_average_time.toFixed(
								0
							)
						}
						chartTooltip="Indicates when serving during operating hours
							(active) or paused due to capacity limits or other
							reasons (inactive)"
						totalType={"minutes"}
					/>
					<DonutChart
						title="Average Cancel Rate"
						data={cancellationData}
						total={
							+(
								((analyticsData?.summaries.total_visits_data
									.cancelled_by_customer_total +
									analyticsData?.summaries.total_visits_data
										.cancelled_by_business_total) /
									analyticsData?.summaries.total_visits_data
										.total_visits) *
								100
							).toFixed(1)
						}
						chartTooltip="Indicates when serving during operating hours
							(active) or paused due to capacity limits or other
							reasons (inactive)"
						totalType={"percent"}
					/>
					<DonutChart
						title="Operational Status"
						data={operationalData}
						total={
							+(
								(analyticsData?.summaries.working_hours_data
									.active_working_hour /
									analyticsData?.summaries.working_hours_data
										.total_working_hour) *
								100
							).toFixed(1)
						}
						chartTooltip="Indicates when serving during operating hours
							(active) or paused due to capacity limits or other
							reasons (inactive)"
						totalType={"percent"}
					/>
					<div className="h-[254px] flex-1 self-stretch rounded-lg bg-white px-2.5 pb-4 pt-2">
						<div className="flex flex-col items-center">
							<TooltipProvider>
								<UITooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<i className="mgc_information_line analytics-information-icon before-text-dark pointer-events-none invisible block self-end text-[14px]" />
									</TooltipTrigger>

									<TooltipContent
										side="top"
										sideOffset={10}
										className="max-w-[240px]"
									>
										{/* {chartTooltip} */}
									</TooltipContent>
								</UITooltip>
							</TooltipProvider>
							<p className="font-manrope text-sm font-extrabold tracking-[0.5px] text-[#525D6A]">
								Average Rating
							</p>
						</div>
						<div className="flex h-[192px] flex-col items-center justify-between leading-[120%] tracking-[0.41px]">
							<div className="flex w-full flex-1 flex-col items-center justify-center space-y-4">
								<h2 className="text-[42px] font-semibold">
									{
										analyticsData?.summaries
											.customers_rating.average_rating
									}
								</h2>
								<RatingStars
									total={
										analyticsData?.summaries
											.customers_rating.average_rating
									}
								/>
							</div>
							<p className="pb-1.5 text-xs  text-[#323539]">
								{
									analyticsData?.summaries.customers_rating
										.total_number_of_feedbacks
								}{" "}
								Reviews
							</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Summary;

const DonutChart: React.FC<{
	title: string;
	data: { name: string; value: number; fillColor: string }[];
	chartTooltip: string;
	total: number;
	totalType: "visitors" | "minutes" | "percent";
}> = ({ title, data, chartTooltip, total, totalType }) => {
	return (
		<div className="flex h-[254px] flex-1 flex-col justify-between self-stretch rounded-lg bg-white px-2.5 pb-4 pt-2">
			<div className="flex flex-col items-center">
				<div
					className={`${title === "Operational Status" ? "visible select-none self-end" : "invisible"}`}
				>
					<TooltipProvider>
						<UITooltip delayDuration={0}>
							<TooltipTrigger asChild>
								<i className="mgc_information_line analytics-information-icon before-text-dark cursor-pointer text-[14px]" />
							</TooltipTrigger>

							<TooltipContent
								side="top"
								sideOffset={10}
								className="max-w-[240px]"
							>
								{chartTooltip}
							</TooltipContent>
						</UITooltip>
					</TooltipProvider>
				</div>

				<p className="font-manrope text-sm font-extrabold tracking-[0.5px] text-[#525D6A]">
					{title}
				</p>
			</div>
			<div className="flex flex-col space-y-4">
				<div className="relative">
					<ResponsiveContainer width="100%" height={148}>
						<PieChart>
							<Pie
								data={data}
								cx="50%"
								cy="50%"
								innerRadius={50}
								outerRadius={68}
								height={120}
								startAngle={-270}
								paddingAngle={0}
								dataKey="value"
							>
								{data.map((entry, index) => (
									<Cell
										key={`cell-${index}`}
										fill={entry.fillColor}
									/>
								))}
							</Pie>

							{/* <Tooltip content={<RenderTooltip />} /> */}
						</PieChart>
					</ResponsiveContainer>
					<div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center space-y-[-4px] leading-[120%] tracking-[0.41px] text-[#323539]">
						<h3 className="font-semibold">
							{total}
							{totalType === "percent" && (
								<span className="text-xs font-normal">%</span>
							)}
						</h3>
						<span className="text-xs">
							{totalType === "minutes"
								? "minutes"
								: totalType === "visitors" && "visitors"}
						</span>
					</div>
				</div>
				<RenderLegend payload={data} />
			</div>
		</div>
	);
};

const RenderLegend: React.FC<{
	payload: { name: string; fillColor: string }[];
}> = ({ payload }) => {
	return (
		<>
			<ul className="mt-4 flex items-center justify-center space-x-4">
				{payload.map((entry, index) => (
					<li key={index} className="flex items-center space-x-1">
						<i
							className="block size-2 rounded-[2px]"
							style={{ backgroundColor: entry.fillColor }}
						/>
						<span className="text-xs text-[#323539]">
							{entry.name}
						</span>
					</li>
				))}
			</ul>
		</>
	);
};

// interface ChartPayload {
// 	name: string;
// 	value: number;
// 	payload: {
// 		payload: {
// 			name: string;
// 			value: number;
// 			fillColor: string;
// 		};
// 		cx: string;
// 		cy: string;
// 		height: number;
// 		stroke: string;
// 		fill: string;
// 		name: string;
// 		value: number;
// 		fillColor: string;
// 	};
// 	dataKey: string;
// }

export const RenderTooltip: React.FC = ({ payload }: any) => {
	console.log(payload);
	return (
		<>
			{payload.map((entry, index) => (
				<div
					className="relative z-[999] mb-2 h-full w-full rounded-lg bg-white p-1 pb-2 text-black shadow-lg drop-shadow-md"
					key={index}
				>
					{entry.name}
					<svg
						width="7"
						height="7"
						viewBox="0 0 7 7"
						xmlns="http://www.w3.org/2000/svg"
						className="absolute left-1/2 top-full mt-[-1px] -translate-x-1/2 translate-y-0"
					>
						<path
							d="M0,0 L7,0 L3.5,7 Z"
							fill="white"
							stroke="white"
						/>
					</svg>
				</div>
			))}
		</>
	);
};

const RatingStars: React.FC<{ total: number }> = () => {
	// const flooredTotal = Math.floor(total);
	// const remainderTotal = flooredTotal - total;

	return (
		<div className="flex items-center space-x-2">
			{Array.from({ length: 5 }).map((_, index) => (
				<svg
					width="39"
					height="38"
					viewBox="0 0 39 38"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					key={index}
				>
					<g clipPath="url(#clip0_2801_29431)">
						<path
							d="M17.3904 4.54127C17.5646 4.24227 17.8142 3.99418 18.1143 3.82176C18.4143 3.64933 18.7543 3.55859 19.1004 3.55859C19.4464 3.55859 19.7865 3.64933 20.0865 3.82176C20.3865 3.99418 20.6361 4.24227 20.8104 4.54127L25.2358 12.1381L33.8301 14.0001C34.1682 14.0736 34.4811 14.2344 34.7376 14.4664C34.9942 14.6985 35.1854 14.9938 35.2923 15.3229C35.3991 15.6519 35.4179 16.0032 35.3466 16.3417C35.2754 16.6803 35.1166 16.9942 34.8862 17.2523L29.0279 23.8089L29.9145 32.5568C29.9495 32.9012 29.8935 33.2488 29.7521 33.5649C29.6108 33.881 29.3889 34.1544 29.1088 34.3579C28.8287 34.5614 28.5001 34.6879 28.1559 34.7247C27.8116 34.7615 27.4637 34.7073 27.1469 34.5676L19.1004 31.0209L11.0539 34.5676C10.7371 34.7073 10.3892 34.7615 10.0449 34.7247C9.70063 34.6879 9.37204 34.5614 9.09193 34.3579C8.81182 34.1544 8.59 33.881 8.44861 33.5649C8.30723 33.2488 8.25123 32.9012 8.28621 32.5568L9.17288 23.8089L3.31455 17.2539C3.08374 16.9958 2.92466 16.6817 2.85318 16.343C2.78171 16.0042 2.80034 15.6527 2.90722 15.3234C3.0141 14.9941 3.20549 14.6986 3.46228 14.4664C3.71907 14.2342 4.03228 14.0734 4.37063 14.0001L12.965 12.1381L17.3904 4.54127Z"
							fill="#E08B0A"
						/>
					</g>
					<defs>
						<clipPath id="clip0_2801_29431">
							<rect
								width="38"
								height="38"
								fill="white"
								transform="translate(0.100586)"
							/>
						</clipPath>
					</defs>
				</svg>
			))}
			{/* {remainderTotal !== 0 && (
				<svg
					width="39"
					height="38"
					viewBox="0 0 39 38"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g clipPath="url(#clip0_2801_29434)">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M20.8107 4.54127C20.6364 4.24227 20.3868 3.99418 20.0868 3.82176C19.7867 3.64933 19.4467 3.55859 19.1007 3.55859C18.7546 3.55859 18.4146 3.64933 18.1145 3.82176C17.8145 3.99418 17.5649 4.24227 17.3907 4.54127L12.9668 12.1365L4.37091 14.0001C4.03285 14.0736 3.71995 14.2344 3.4634 14.4664C3.20685 14.6985 3.01562 14.9938 2.90878 15.3229C2.80193 15.6519 2.78319 16.0032 2.85444 16.3417C2.92568 16.6803 3.08442 16.9942 3.31483 17.2523L9.17316 23.8089L8.28649 32.5568C8.25151 32.9012 8.30751 33.2488 8.44889 33.5649C8.59028 33.881 8.8121 34.1544 9.09221 34.3579C9.37232 34.5614 9.70091 34.6879 10.0452 34.7247C10.3895 34.7615 10.7374 34.7073 11.0542 34.5676L19.1007 31.0209L27.1472 34.5676C27.464 34.7073 27.8119 34.7615 28.1561 34.7247C28.5004 34.6879 28.829 34.5614 29.1091 34.3579C29.3892 34.1544 29.611 33.881 29.7524 33.5649C29.8938 33.2488 29.9498 32.9012 29.9148 32.5568L29.0282 23.8089L34.8865 17.2539C35.1173 16.9958 35.2764 16.6817 35.3479 16.343C35.4193 16.0042 35.4007 15.6527 35.2938 15.3234C35.1869 14.9941 34.9955 14.6986 34.7388 14.4664C34.482 14.2342 34.1688 14.0734 33.8304 14.0001L25.2361 12.1381L20.8107 4.54127ZM19.1007 27.745C19.373 27.745 19.6453 27.8004 19.8987 27.9129L26.5582 30.8484L25.8251 23.6078C25.7974 23.3342 25.8271 23.058 25.9122 22.7965C25.9972 22.5351 26.1358 22.2943 26.3191 22.0894L31.1672 16.6633L24.0549 15.1227C23.7863 15.0647 23.5327 14.9514 23.3102 14.79C23.0878 14.6287 22.9014 14.4227 22.7629 14.1853L19.1007 7.89635V27.745Z"
							fill="#E08B0A"
						/>
					</g>
					<defs>
						<clipPath id="clip0_2801_29434">
							<rect
								width="38"
								height="38"
								fill="white"
								transform="translate(0.100586)"
							/>
						</clipPath>
					</defs>
				</svg>
			)} */}
		</div>
	);
};
