import React from "react"; // Ensure React is imported
import { Button } from "@src/components/ui/button";
import { FiMinusCircle } from "react-icons/fi";

interface EditDeletePillProps {
	title: string;
	description: string;
}

// Function component with props typed explicitly
const EditDeletePill = ({ title, description }: EditDeletePillProps) => {
	return (
		<div className="flex items-center justify-between rounded-lg bg-[#F9F9F9] px-3 py-4">
			<div>
				<h2 className="text-md font-medium">{title}</h2>
				<p className="text-gray-400">{description}</p>
			</div>
			<div className="flex space-x-0">
				<Button variant="ghost" size="icon-sm">
					<i className="mgc_edit_2_line text-[24px]" />
				</Button>
				<Button variant="ghost" className="text-red-500" size="icon-sm">
					<FiMinusCircle size={"24"} className="text-red-500" />
				</Button>
			</div>
		</div>
	);
};

export default EditDeletePill;
