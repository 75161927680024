import CustomCheckbox2 from "@components/form/CustomCheckbox2";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { QueueEntry } from "@type/waitlist/waitlist";
import { cn, handleRowCheckboxChange } from "@utils/functions";
import React, { Dispatch, SetStateAction } from "react";
import completeIcon from "@assets/images/complete.svg";
import gridIcon from "@assets/images/grid.svg";
import playIcon from "@assets/images/play-icon.svg";
import rescheduleIcon from "@assets/images/reschedule.svg";
import { PatientStatus } from "../../../../types/wait-list";
import { Button } from "../../../../components/ui/button";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "../../../../components/ui/tooltip";
import ChangePosition from "../../../../components/Dashboard/waitlist/ChangePatientOrder";
import ChangeStation from "../../../../components/Dashboard/waitlist/ChangeStation";
import DeletePatient from "../../../../components/Dashboard/waitlist/DeletePatient";
import NotifyPatient from "../../../../components/Dashboard/waitlist/NotifyPatient";
import PatientApprovedModal from "../../../../components/Dashboard/waitlist/PatientApprovedModal";
import PatientDetails from "../../../../components/Dashboard/waitlist/PatientDetails";
import PatientPriorityModal from "../../../../components/Dashboard/waitlist/PatientPriorityModal";
import PatientSentToServingModal from "../../../../components/Dashboard/waitlist/PatientSentToServingModal";
import TimeAgoShort from "../../../../components/Dashboard/waitlist/TimeAgoShort";

// interface CheckboxTableProps {
// 	rows?: Row[];
// 	status?: PatientStatus;
// }

const WaitlistCard: React.FC<{
	activeId?: number;
	selectedRows?: number[];
	noOfPatients: number;
	status: PatientStatus;
	index: number;
	patient: QueueEntry;
	setSelectAllChecked?: Dispatch<SetStateAction<boolean>>;
	setSelectedRows?: Dispatch<SetStateAction<number[]>>;
}> = ({
	activeId,
	selectedRows,
	status,
	index,
	patient,
	noOfPatients,
	setSelectedRows,
	setSelectAllChecked,
}) => {
	// const stationsList = usepatientStore((s) => s.stationsList);
	const isChecked = selectedRows?.includes(patient.id);
	// const managePatientMutation = useManagePatient();

	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: patient.id });
	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<>
			<div
				ref={setNodeRef}
				className={cn(
					"m-2 flex cursor-grab flex-col space-y-3 rounded-xl bg-white p-3 shadow-sm",
					{
						"m-0 cursor-grabbing": isDragging,
						"border border-[#822D7F]":
							status === PatientStatus.Schedule,
						"border border-gray-400 opacity-50":
							activeId === patient.id,
					}
				)}
				style={style}
				{...attributes}
				{...listeners}
			>
				<div className="flex items-center justify-between">
					<div className="flex items-center gap-x-1.5">
						<p className="text-sm font-semibold leading-[14px] text-[#858C95]">
							{(index + 1).toString().padStart(2, "0")}
						</p>
						<div className="h-[8px] w-[1px] rounded-full bg-[#E5E5E7]" />
						<Tooltip delayDuration={0}>
							<TooltipTrigger asChild>
								<div className="flex items-center space-x-1">
									<p
										className="truncate text-sm font-medium capitalize text-[#323539]"
										title={patient.customer.full_name}
									>
										{patient.customer.full_name}
									</p>
									<PatientDetails patientDetails={patient} />
								</div>
							</TooltipTrigger>
							<TooltipContent side="top" sideOffset={4}>
								{patient.customer.full_name}
							</TooltipContent>
						</Tooltip>
						<PatientPriorityModal
							patientDetails={patient}
							status={status}
						/>
					</div>
					<div className="flex items-center space-x-2">
						<CustomCheckbox2
							handleCheckboxChange={() => {
								if (setSelectedRows && setSelectAllChecked)
									handleRowCheckboxChange(
										!isChecked,
										patient.id,
										setSelectedRows,
										noOfPatients,
										setSelectAllChecked
									);
							}}
							isChecked={
								selectedRows?.includes(patient.id) ?? false
							}
							id={status + " selectAll"}
							className="rounded-sm border-2"
						/>
					</div>
				</div>
				<div className="flex items-center justify-between text-[#6B7280]">
					<div className="flex items-center gap-x-1">
						<img src={gridIcon} alt="grid icon" />
						<p className="text-[10px] leading-[10px]">
							{patient.station.name}
						</p>
					</div>

					<TimeAgoShort patient={patient} ago />

					{/* {status !== PatientStatus.Schedule &&
						!patient.appointment ? (
							<>
							</>
						) : (
							<p className="text-xxs text-[#6B7280]">
								{patient?.appointment
									? dayjs(
											patient?.appointment
												.appointment_date
										).format("DD MMM YYYY")
									: ""}
							</p>
						)} */}
				</div>
				{/* <div className="flex justify-between">
						<p className="text-xxs text-[#6B7280]">
							[Type of Appointment]
						</p>
						{patient?.appointment && (
							<p className="text-xxs text-[#6B7280]">
								{patient?.appointment
									? new Date(
											patient?.appointment.appointment_date
										).getHours() +
										":" +
										new Date(
											patient?.appointment.appointment_date
										).getMinutes() +
										(new Date(
											patient?.appointment.appointment_date
										).getHours() >= 12
											? " PM"
											: " AM")
									: ""}
							</p>
						)}
					</div> */}

				<div className="flex items-center justify-between">
					<div className="flex items-center gap-x-1.5">
						{(status === PatientStatus.Upcoming ||
							status === PatientStatus.NextInLine ||
							status === PatientStatus.Serving) && (
							<NotifyPatient patient={patient} />
						)}

						{status === PatientStatus.Schedule && (
							<Button
								className="size-[30px] rounded-md bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
								// onClick={() => setShowDeleteCustomerModal(true)}
							>
								<i className="mgc_phone_call_line text-[14px]" />
							</Button>
						)}

						{status === PatientStatus.Schedule && (
							<Button
								className="size-[30px] rounded-md bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
								// onClick={() => setShowDeleteCustomerModal(true)}
							>
								<img
									src={rescheduleIcon}
									alt="reschedule icon"
								/>
							</Button>
						)}

						<DeletePatient patientDetails={patient} />
						{status !== PatientStatus.Schedule && (
							<>
								<ChangeStation patientDetails={patient} />
								<ChangePosition patientDetails={patient} />
							</>
						)}
					</div>
					{status === PatientStatus.Pending && (
						<PatientApprovedModal patientDetails={patient} />
					)}

					{status === PatientStatus.Upcoming && (
						<button className="flex size-[30px] cursor-pointer items-center justify-center rounded-md bg-[#D78100]">
							<img src={playIcon} alt="transfer icon" />
						</button>
					)}
					{status === PatientStatus.NextInLine && (
						<PatientSentToServingModal patientDetails={patient} />
					)}
					{status === PatientStatus.Serving && (
						<div className="flex size-[30px] cursor-pointer items-center justify-center rounded-md bg-[#1E8A7C]">
							<img src={completeIcon} alt="transfer icon" />
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default WaitlistCard;
