import { APIVersion1GetWaitlist } from "@src/http/v1";
import useUserStore from "@src/store/useUserStore";
import useWaitListStore from "@src/store/useWaitListStore";
import { PatientStatus } from "@src/types/wait-list";
import { QueueData } from "@src/types/waitlist/waitlist";
import toast from "react-hot-toast";
import { useQuery } from "react-query";

export const GetQueryListSlice = (
	// onSuccess: (data: BusinessQueueOrderLocation[]) => void = () => {
	// 	return;
	// },
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const currentStationId = useUserStore((s) => s.currentStationId);
	const setPatients = useWaitListStore((s) => s.setPatients);
	const setPatientsObject = useWaitListStore((s) => s.setPatientsObject);

	return useQuery<
		{ status: boolean; data: QueueData; message: string },
		Error
	>("queue-list", APIVersion1GetWaitlist, {
		onSuccess: (data) => {
			setPatientsObject({
				[PatientStatus.Pending]:
					data.data.waitlist_info.waiting_to_be_approved.filter(
						(item) =>
							item.location_id === currentLocationId &&
							(currentStationId === "All Stations" ||
								item.station_id === currentStationId)
					),
				[PatientStatus.Upcoming]:
					data.data.waitlist_info.accepted.filter(
						(item) =>
							item.location_id === currentLocationId &&
							(currentStationId === "All Stations" ||
								item.station_id === currentStationId)
					),
				[PatientStatus.NextInLine]:
					data.data.waitlist_info.next_to_serve.filter(
						(item) =>
							item.location_id === currentLocationId &&
							(currentStationId === "All Stations" ||
								item.station_id === currentStationId)
					),
				[PatientStatus.Serving]:
					data.data.waitlist_info.getting_service.filter(
						(item) =>
							item.location_id === currentLocationId &&
							(currentStationId === "All Stations" ||
								item.station_id === currentStationId)
					),
				[PatientStatus.Schedule]: [],
			});
			// setTotalSchedule(
			// 	data.data.locations.flatMap((location) =>
			// 		location.stations.flatMap(
			// 			(station) =>
			// 				station.queue_informations
			// 					?.customers_waiting_to_be_approved
			// 					.appointmets
			// 		)
			// 	) as AppointmentWaitingToBeApproved[]
			// );

			// setSchedule(
			// 	data.data.locations.flatMap((location) =>
			// 		location.id === currentLocationId
			// 			? location.stations.flatMap((station) =>
			// 					currentStationId === "All Stations" ||
			// 					station.id === currentStationId
			// 						? station.queue_informations
			// 								?.customers_waiting_to_be_approved
			// 								.appointmets
			// 						: []
			// 				)
			// 			: []
			// 	) as AppointmentWaitingToBeApproved[]
			// );

			setPatients([
				...data.data.waitlist_info.waiting_to_be_approved.filter(
					(item) =>
						item.location_id === currentLocationId &&
						(currentStationId === "All Stations" ||
							item.station_id === currentStationId)
				),
				...data.data.waitlist_info.accepted.filter(
					(item) =>
						item.location_id === currentLocationId &&
						(currentStationId === "All Stations" ||
							item.station_id === currentStationId)
				),
				...data.data.waitlist_info.next_to_serve.filter(
					(item) =>
						item.location_id === currentLocationId &&
						(currentStationId === "All Stations" ||
							item.station_id === currentStationId)
				),
				...data.data.waitlist_info.getting_service.filter(
					(item) =>
						item.location_id === currentLocationId &&
						(currentStationId === "All Stations" ||
							item.station_id === currentStationId)
				),
			]);
		},
		onError: (error) => {
			onError(error);
			toast.error(
				<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
					<div className="flex items-center space-x-5">
						<p className="text-lg font-medium text-[#E33B32]">
							Error fetching queue order
						</p>
					</div>
					<button
						className="h-fit p-2.5"
						onClick={() => toast.dismiss("queue-list")}
					>
						<i className="mgc_close_line" />
					</button>
				</div>,
				{
					id: "queue-list",
					duration: 10000,
				}
			);
			console.error(error);
		},
	});
};
