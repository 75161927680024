import { z } from "zod";
import { Location } from "./Location";

export interface Business {
	id: number;
	name: string;
	address: string;
	country: string;
	state: string;
	city: string;
	phone_number: string;
	logo_url: string;
	admin_id: number;
	business_category_id: number;
	approximate_wait_time: string;
	created_at: Date;
	updated_at: Date;
	deleted_at: any;
	locations: Location[];
	is_active: boolean;
}

export const AddBusinessInfoSchema = z.object({
	name: z.string().min(4, { message: "Name must be at least 4 characters" }),
	address: z
		.string()
		.min(4, { message: "Address must be at least 4 characters" }),
	country: z.string().optional(),
	state: z.string().optional(),
	city: z.string().optional(),
	phone_number: z.string().optional(),
	business_category_id: z
		.string()
		.min(1, { message: "Kindly select a business category" }),
	postal_code: z.string().optional(),
	product_type: z.string(),
	logo: z.any().optional(),
});

export type AddBusinessInfoData = z.infer<typeof AddBusinessInfoSchema>;

export const UpdateBusinessCardSchema = z.object({
	payment_information: z.object({
		name: z
			.string()
			.min(6, { message: "Name must be at least 6 characters" })
			.optional(),
		zipCode: z
			.string()
			.min(6, { message: "Zip Code must be at least 6 characters" })
			.optional(),
		type: z.string(),
		card: z.object({
			number: z
				.string()
				.min(18, { message: "This card number is too short" }),
			exp_month: z
				.number()
				.min(1, { message: "Expiration date is required" }),
			exp_year: z
				.number()
				.min(1, { message: "Expiration date is required" }),
			cvc: z.string().min(3, { message: "CVC is required" }),
		}),
	}),
});

export const AddBusinessCardSchema = UpdateBusinessCardSchema.extend({
	price_id: z.string(),
});

export type AddBusinessCardData = z.infer<typeof AddBusinessCardSchema>;
export type UpdateBusinessCardData = z.infer<typeof UpdateBusinessCardSchema>;
