import React, { useRef } from "react";
// import useCustomToast from "@components/CustomToast";
import Overlay from "@components/Overlay";
import { Button } from "@components/ui/button";
import { LocationsIProps } from "@src/types/Location";
import { Card } from "@components/ui/card";
import { CustomImage } from "@components/common/CustomImage";
import { IoClose } from "react-icons/io5";
import { PiInfo, PiCopyBold, PiMapPinBold } from "react-icons/pi";
import RatingComponent from "@components/common/RatingComponent";
import { Badge } from "@components/ui/badge";
import { IoMdStopwatch } from "react-icons/io";
import { convertTimeFormat } from "@src/utils/functions";
import { Switch } from "@components/ui/switch";
import profileAvater from "/assets/icons/profile-avatar.svg";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@components/ui/accordion";
import { QRCodeSVG } from "qrcode.react";

export const LocationInfoModal: React.FC<{
	location: LocationsIProps | null;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	showModal: boolean;
	isLoading: boolean;
	buttonAction: () => void;
}> = ({ location, showModal, setShowModal, isLoading, buttonAction }) => {
	const modalRef = useRef(null);

	// const customToast = useCustomToast();
	return (
		<>
			<Overlay
				show={showModal}
				setShow={setShowModal}
				modalRef={modalRef}
				isPersistent={isLoading}
			>
				<Card
					className="relative z-50 w-full max-w-[500px] space-y-6 bg-white px-6 py-8 text-main-1"
					ref={modalRef}
				>
					<div className="flex flex-1 items-center space-x-3">
						<div className="relative">
							<div className="relative h-[112px] w-[112px] overflow-hidden rounded-full">
								<CustomImage
									src={location?.image || ""}
									alt="location"
									imageClass="aspect-square w-full overflow-hidden"
								/>
							</div>
							<div className="absolute bottom-1 z-10 flex translate-x-1 items-center justify-center rounded-sm bg-neutral-100 p-1 shadow-sm">
								<RatingComponent rating={5} readOnly />
							</div>
						</div>
						<div className="space-y-3">
							<div className="flex items-center space-x-3">
								<p className="font-semibold">
									{location?.name}
								</p>
							</div>
							<div className="flex items-start space-x-2 text-gray-400">
								<PiMapPinBold size={14} />
								<p className="text-sm font-normal leading-none tracking-tight">
									{location?.address || "N/A"}
								</p>
							</div>
							<div className="flex flex-1 items-center space-x-3">
								<Badge
									className="rounded-sm bg-[#EFEFF1] px-2 text-xs text-[#323539]"
									variant={"secondary"}
								>
									{/* <i className="mgc_shop_line before-text-gray-4 mr-2 text-[18px]" /> */}
									<span className=" mr-1 font-normal">
										<svg
											width="12"
											height="9"
											viewBox="0 0 12 9"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M8.75999 0.5C9.03337 0.5 9.29346 0.611853 9.48107 0.807153L9.54084 0.875305L11.0658 2.78148C11.4446 3.25501 11.1331 3.94834 10.5464 3.99726L10.4801 4H10.0003V8C10.0003 8.27615 9.77644 8.5 9.50029 8.5C9.24387 8.5 9.03254 8.30699 9.00366 8.05831L9.00029 8V4H8.00029V7.5C8.00029 8.0272 7.59237 8.45908 7.07493 8.49726L7.00029 8.5H3.00029C2.47311 8.5 2.04121 8.09207 2.00304 7.57463L2.00029 7.5V4H1.52045C0.914028 4 0.567273 3.32358 0.895527 2.83496L0.934798 2.78148L2.45974 0.875305C2.63053 0.661811 2.88034 0.528609 3.15005 0.504104L3.24061 0.5H8.75999ZM3.50029 4H3.00029V7.5H7.00029V4H6.50029V6C6.50029 6.27615 6.27644 6.5 6.00029 6.5H4.00029C3.72415 6.5 3.50029 6.27615 3.50029 6V4ZM5.50029 4H4.50029V5.5H5.50029V4ZM8.75999 1.5H3.24061L2.04061 3H9.95999L8.75999 1.5Z"
												fill="#323539"
											/>
										</svg>
									</span>
									<span className=" mr-1 font-normal ">
										Stations
									</span>{" "}
									{location?.stations?.length}
								</Badge>
								<Badge
									className="my-o rounded-sm bg-[#EFEFF1] px-2"
									variant={"secondary"}
								>
									<IoMdStopwatch className="mr-2" size={14} />
									<span className="mr-1 font-normal">
										Avg. Wait Time
									</span>{" "}
									{convertTimeFormat(
										location?.approximate_waiting_time || ""
									)}
								</Badge>
							</div>
						</div>
						<Button
							type="button"
							className="absolute right-1 top-5 bg-transparent text-black hover:bg-transparent "
							onClick={() => setShowModal(false)}
						>
							<IoClose className="text-gray-400" size={24} />
						</Button>
					</div>
					<Accordion type="single" collapsible className="w-full">
						<LocationDescriptionAccordion />
					</Accordion>
					<div className="mt-6">
						<div className="flex items-center justify-between">
							<div className="flex items-center space-x-1">
								<p className="font-semibold">
									Location Visibility
								</p>
								<Button
									variant="link"
									size="xs"
									className="m-0 p-0"
								>
									<PiInfo
										size={12}
										className=" text-[#C5CEDB]"
									/>
								</Button>
							</div>
							<div className="flex items-center">
								<Switch
									defaultChecked={location?.is_active === 1}
									className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
								/>
								<span className="">
									{location?.is_active === 1 ? "Yes" : "No"}
								</span>
							</div>
						</div>
						<div className="mt-8 flex flex-col items-center space-y-3">
							<h3 className="m-0 p-0 text-[#858C95]">
								Use Link or QR Code to Access Location Website
							</h3>
							<QRCodeComponent url="https://example.com" />
							<div className="flex w-full items-center justify-between rounded-md border px-3 py-2.5">
								<h3 className="text-[15px]">
									https://locationname.com/book
								</h3>
								<Button
									variant="link"
									size="xs"
									className="m-0 p-0"
								>
									<PiCopyBold
										size={15}
										className=" text-[#858C95]"
									/>
								</Button>
							</div>
						</div>
						<div className="mt-7 flex w-full items-center justify-between border-b pb-3">
							<p className="font-semibold">Join via QR code</p>
							<div className="flex items-center">
								<Switch
									defaultChecked={location?.is_active === 1}
									className="scale-[0.70] data-[state=checked]:bg-primary"
								/>
								<span className="">
									{location?.is_active === 1 ? "On" : "Off"}
								</span>
							</div>
						</div>
						<div className="mt-3 flex w-full items-center justify-between border-b pb-3">
							<div className="flex items-center space-x-1">
								<p className="font-semibold">Join Online</p>
								<Button
									variant="link"
									size="xs"
									className="m-0 p-0"
								>
									<PiInfo
										size={12}
										className=" text-[#C5CEDB]"
									/>
								</Button>
							</div>

							<div className="flex items-center">
								<Switch
									defaultChecked={location?.is_active === 1}
									className="scale-[0.70] data-[state=checked]:bg-primary"
								/>
								<span className="">
									{location?.is_active === 1 ? "On" : "Off"}
								</span>
							</div>
						</div>
					</div>

					<div className="flex items-center space-x-3 bg-neutral-50 px-4 py-4">
						<div className="">
							<img src={profileAvater} alt="avatar" />
						</div>
						<div className="flex flex-col">
							<div className="flex items-center space-x-3 text-sm">
								<span className=" font-semibold">
									{location?.admin?.name}
								</span>
								<span>(Admin)</span>
							</div>
							<div className="flex items-center space-x-3 text-sm leading-none text-[#596574]">
								<p>+ {location?.admin?.phone_number || 0}</p>
								<p>{location?.admin?.email || "N/A"}</p>
							</div>
						</div>
					</div>
					<div className="flex items-start justify-between space-x-2">
						<div className="flex flex-1 flex-col">
							<div className="mt-6 flex justify-between space-x-1">
								<Button
									className="font-semibold text-[#323539]"
									variant={"outline"}
									type="button"
									onClick={() => setShowModal(false)}
								>
									Close
								</Button>
								<Button
									className="font-semibold"
									type="button"
									onClick={buttonAction}
								>
									Edit Location
								</Button>
							</div>
						</div>
					</div>
				</Card>
			</Overlay>
		</>
	);
};

const LocationDescriptionAccordion: React.FC = () => {
	return (
		<AccordionItem value="item-2" className="border-0">
			<AccordionTrigger className="border-b py-1 text-main-1 hover:no-underline">
				Description
			</AccordionTrigger>
			<AccordionContent className="flex flex-col pb-0">
				<p className="border-b py-1">
					Windows 11 is a simple yet effective way to enhance your
					computing experience. Whether you&apos;re a professional
					juggling multiple tasks, a student doing research, or are
					just looking to improve your productivity, utilizing this
					small feature while you work can provide a significant boost
					to your levels of focus.
				</p>
			</AccordionContent>
		</AccordionItem>
	);
};

const QRCodeComponent: React.FC<{ url: string | null }> = ({ url }) => {
	return (
		<QRCodeSVG
			value={url || ""}
			size={114}
			bgColor={"#ffffff"}
			fgColor={"#000000"}
			level={"L"}
			includeMargin={false}
		/>
	);
};
