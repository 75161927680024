import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import useAnalyticsStore from "../../../../store/useAnalyticsStore";

const SimpleLineChart: React.FC = () => {
	const analyticsData = useAnalyticsStore((s) => s.analyticsData);

	const formattedData =
		analyticsData?.trends.customers_by_weekdays.map((dayData) => ({
			day: Object.keys(dayData)[0].substring(0, 3),
			visits: Object.values(dayData)[0],
		})) ?? [];

	const total = formattedData.reduce((acc, item) => acc + item.visits, 0);

	return (
		<section className="flex h-[320px] flex-col rounded-lg bg-white p-8 pb-[41px]">
			<div className="mb-6 flex flex-col space-y-2">
				<h3 className="font-manrope text-lg font-bold leading-[16px] tracking-[0.5px] text-[#454D56]">
					Visits By Location
				</h3>
				<p className="text-xs leading-[120%] tracking-[0.41px] text-[#596574]">
					Total Visits :{" "}
					<span className="text-base font-semibold">{total}</span>
				</p>
			</div>
			<ResponsiveContainer width="100%" className="flex-1">
				<LineChart
					data={formattedData}
					margin={{ top: 0, right: 15, left: 15, bottom: 0 }}
				>
					<XAxis
						dataKey="day"
						tickLine={false}
						axisLine={{ stroke: "#E5E5E7", strokeWidth: 1 }}
						className="fill-[#858C95] text-xs leading-[20] tracking-[0%]"
					/>
					<YAxis axisLine={false} hide={true} />
					{/* <Tooltip /> */}
					<Line
						type="monotone"
						dataKey="visits"
						stroke="#5D57C8"
						strokeWidth={1}
						dot={false}
					/>
				</LineChart>
			</ResponsiveContainer>
		</section>
	);
};

export default SimpleLineChart;
