import { zodResolver } from "@hookform/resolvers/zod";
import { QueueEntry } from "@type/waitlist/waitlist";
import React, { useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LuX } from "react-icons/lu";
import { useQueryClient } from "react-query";
import { z } from "zod";
import transferIcon from "../../../../public/assets/images/transfer.svg";
import { useChangePatientStation } from "../../../hooks/useLocationData";
import { Location as BusinessQueueOrderLocation } from "../../../types/Waitlist";
import useCustomToast from "../../CustomToast";
import Overlay from "../../Overlay";
import { CustomSelect, Option } from "../../form/CustomSelect";
import { LoaderButton } from "../../form/LoaderButton";
import { Button } from "../../ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../../ui/card";
import { Label } from "../../ui/label";

const FormSchema = z.object({
	station_id: z.string(),
});

const ChangeStation: React.FC<{ patientDetails: QueueEntry }> = ({
	patientDetails,
}) => {
	const {
		handleSubmit,
		setError,
		setValue,
		formState: { errors },
	} = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
	});

	const [showChangePatientStationModal, setShowChangePatientStationModal] =
		useState(false);
	const modalRef = useRef(null);
	const customToast = useCustomToast();

	const { mutate, isLoading } = useChangePatientStation(
		patientDetails.customer_id,
		() => {
			setShowChangePatientStationModal(false);
		}
	);

	const queryClient = useQueryClient();
	const queueData: {
		status: boolean;
		data: { locations: BusinessQueueOrderLocation[] };
	} = (queryClient.getQueryData("queue-order") || []) as {
		status: boolean;
		data: { locations: BusinessQueueOrderLocation[] };
	};

	const stationListForLocation: Option[] =
		queueData?.data?.locations
			?.find((location) => location.id === patientDetails.location_id)
			?.stations.filter(
				(station) => station.id !== patientDetails.station_id
			)
			.map((item) => ({
				value: item.id.toString(),
				label: item.name,
			})) ?? [];

	const onSubmit: SubmitHandler<z.infer<typeof FormSchema>> = async (
		data
	) => {
		try {
			customToast("Changing patient's station", {
				id: "change-patient-station",
				type: "loading",
			});
			mutate(data);
			// console.log(data);
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	return (
		<>
			<Button
				className="size-[30px] rounded-md bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
				onClick={() => {
					setShowChangePatientStationModal(true);
				}}
			>
				<img src={transferIcon} alt="transfer icon" />
			</Button>
			<Overlay
				show={showChangePatientStationModal}
				setShow={setShowChangePatientStationModal}
				modalRef={modalRef}
				isPersistent={isLoading}
			>
				<form
					className="w-full max-w-[400px]"
					onSubmit={handleSubmit(onSubmit)}
				>
					<Card className="flex flex-col space-y-8">
						<CardHeader className="pb-0">
							<div className="flex justify-between">
								<CardTitle className="text-[22px] leading-[30px] -tracking-[1%] text-[#323539]">
									Change Station
								</CardTitle>
								<LuX
									color="#858C95"
									size={20}
									className="cursor-pointer self-center"
									onClick={() => {
										if (!isLoading)
											setShowChangePatientStationModal(
												false
											);
									}}
								/>
							</div>
							<CardDescription>
								Current Station: {patientDetails.station.name}
							</CardDescription>
						</CardHeader>
						<CardContent className="pb-0">
							<div className="grid w-full items-center gap-4">
								<div className="flex flex-col space-y-1.5">
									<Label>Change station to</Label>
									<CustomSelect
										placeholder="Select"
										options={stationListForLocation}
										onChange={(value) => {
											setValue("station_id", value);
										}}
									/>
								</div>
							</div>
						</CardContent>
						{errors.root?.message && (
							<p className="!-mt-0 px-6 text-sm tracking-[-0.1px] text-red-500">
								{" "}
								{errors.root?.message}
							</p>
						)}
						<CardFooter className="flex justify-end">
							<Button
								variant="ghost"
								disabled={isLoading}
								onClick={() =>
									setShowChangePatientStationModal(false)
								}
							>
								Cancel
							</Button>

							<LoaderButton
								disabled={isLoading}
								// loading={isLoading}
								// loaderSize={18}
								className="max-w-[176px] px-8"
							>
								Update Station
							</LoaderButton>
						</CardFooter>
					</Card>
				</form>
			</Overlay>
		</>
	);
};

export default ChangeStation;
