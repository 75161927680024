import React from "react";
import Select, { Props as SelectProps } from "react-select";
import { IoClose } from "react-icons/io5";

type CustomSelectProps = SelectProps<any>;

// Dont forget to write test cases for this component

const CustomSelect: React.FC<CustomSelectProps> = ({ ...props }) => {
	return (
		<Select
			{...props}
			styles={{
				clearIndicator: (provided) => ({
					...provided,
					display: "none",
				}),
			}}
			components={{
				ValueContainer: customValueContainer,
				MultiValueLabel: customMultiValueLabel,
				// MultiValue: CustomMultiValue,
			}}
		/>
	);
};

export default CustomSelect;

const customValueContainer = ({ getValue, clearValue, selectProps }) => {
	const selectedOptions = getValue();
	const handleClear = () => {
		clearValue();
	};
	const displayText =
		selectedOptions.length > 0
			? `${selectedOptions.length}`
			: selectProps.placeholder;
	if (displayText !== selectProps.placeholder) {
		return (
			<div className="flex items-center gap-2">
				<div className="ml-[5px] flex items-center rounded-md border-[#005893] bg-[#0058930A] bg-opacity-5 px-3 py-1">
					{displayText}
					<button
						className="ml-[5px] cursor-pointer text-[#005893]"
						onClick={handleClear}
					>
						<IoClose />
					</button>
				</div>
				<p>{selectProps.placeholder}</p>
			</div>
		);
	}
	return <div className="ml-[10px]">{displayText}</div>;
};

const customMultiValueLabel = ({ data }) => (
	<div className="border-[#005893] bg-gray-900">{data.length}</div>
);
