import TimeAgo from "@components/Dashboard/waitlist/TimeAgo";
import { QueueEntry } from "@type/waitlist/waitlist";
import { StatusOfPatient, StatusOfPatientType } from "@type/wait-list";
import React from "react";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../../ui/tooltip";
import PatientDetails from "../waitlist/PatientDetails";
import PatientPriorityModal from "../waitlist/PatientPriorityModal";
import { toTitleCase } from "@utils/functions";

const DisplayPatientCard: React.FC<{
	status: StatusOfPatientType;
	patientDetails: QueueEntry;
	displayFullName: boolean;
}> = ({ patientDetails, status, displayFullName }) => {
	const formattedPatientStatus = {
		[StatusOfPatient.Pending]: "Pending",
		[StatusOfPatient.GettingService]: "In Service",
		[StatusOfPatient.Upcoming]: "Confirmed",
		[StatusOfPatient.NextInLine]: "Next",
	};

	return (
		<li className="flex items-center justify-stretch gap-x-3 rounded-lg border border-[#E5E5E7] bg-white px-4 py-6 shadow-[0_0_4px_0_rgba(0,0,0,0.25)]">
			<div className="flex min-w-[400px] flex-1 items-center gap-x-10  lg:min-w-[500px] xl:min-w-[550px]">
				<div className="flex items-center gap-x-1 ">
					<TooltipProvider>
						<Tooltip delayDuration={0}>
							<TooltipTrigger asChild>
								<p
									className={`block overflow-hidden text-ellipsis whitespace-nowrap text-[20px] font-semibold capitalize leading-[160%] tracking-normal text-[#323539] ${!displayFullName ? "max-w-[4ch]" : "max-w-[200px]"}`}
								>
									{patientDetails.customer.full_name}
								</p>
							</TooltipTrigger>
							<TooltipContent
								side="top"
								className="capitalize"
								sideOffset={10}
							>
								{patientDetails.customer.full_name}
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
					<div className="flex items-center gap-x-4">
						<PatientDetails patientDetails={patientDetails} />
						<PatientPriorityModal
							patientDetails={patientDetails}
							status={status}
						/>

						<i
							className={`block size-2 rounded-full ${patientDetails.checked_in ? "bg-[#2EBF43]" : "bg-red-500"}`}
						/>
					</div>
				</div>
				{/* <div className="flex space-x-[15px] rounded-full bg-[#EAE2F4] py-[5px] pl-2.5 pr-[27px]">
					<i className="mgc_calendar_time_add_line text-[22px] text-[#323539]" />
					<p className="text-[#323539]">Scheduled</p>
				</div> */}
			</div>

			<div className="flex flex-1 items-center space-x-1">
				<i className="mgc_shop_line before-text-gray-4 text-[18px]" />
				<p className="leading-[100%] text-[#858C95]">
					{patientDetails.station.name}
				</p>
			</div>

			<TimeAgo
				timeStart={
					status !== StatusOfPatient.GettingService
						? patientDetails.created_at
						: patientDetails.service_start_at
				}
				className="flex-1 text-base text-[#858C95]"
			/>

			<div
				className="grid h-7 w-[100px] place-content-center rounded-md text-[14px] leading-[24px] tracking-[-1%] text-white"
				style={{
					backgroundColor:
						status === StatusOfPatient.GettingService
							? "#138576"
							: status === StatusOfPatient.NextInLine
								? "#E4AC29"
								: status === StatusOfPatient.Upcoming
									? "#005893"
									: status === StatusOfPatient.Pending
										? "#C8322B"
										: "#005893",
				}}
			>
				{formattedPatientStatus[status] &&
					toTitleCase(
						formattedPatientStatus[status].replace(/_/g, " ")
					)}
			</div>
		</li>
	);
};

export default DisplayPatientCard;
