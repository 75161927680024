import { Label } from "@components/ui/label";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoaderButton } from "@src/components/form/LoaderButton";
import MultiSelect from "@src/components/ui-extended/multi-select";
import MonthPicker from "@src/components/ui-extended/year-month-picker";
import { Button } from "@src/components/ui/button";
import { DialogTitle } from "@src/components/ui/dialog";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import addPatientSlice from "@src/store/slices/waitlist/addPatientSlice";
import useUserStore from "@src/store/useUserStore";
import dayjs from "dayjs";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LuX } from "react-icons/lu";
import { z } from "zod";
import { ModalProps } from "../../../../types";
import { countryCodes, waitlistPriority } from "../../../../utils/constants";
import { CustomSelect, Option } from "../../../form/CustomSelect";
import FormInput from "../../../form/FormInput";
import Modal from "../../../ui-extended/Modal";

const AddPatientSchema = z.object({
	email: z.string().email({ message: "Invalid email address format" }),
	full_name: z
		.string()
		.min(5, { message: "Full name must be at least 5 characters" }),
	is_manual: z.boolean(),
	phone_number: z.string().nullable(),
	health_card_expire_date: z.string(),
	custom_fields: z.record(z.string()).optional(),
	selected_stations: z.array(z.string()).optional(),
});

export type AddPatientData = z.infer<typeof AddPatientSchema>;

const AddPatient: React.FC<ModalProps> = ({ show, setShow }) => {
	const user = useUserStore((s) => s.user);
	const [countryCode, setCountryCode] = useState("+1");
	const [selectedLocationId, setSelectedLocationId] = useState<
		string | undefined
	>(user?.business.locations[0].id.toString() ?? undefined);
	const [firstName, setFirstName] = useState("");
	const addPatientMutation = addPatientSlice();
	const [healthCardExpiryDate, setHealthCardExpiryDate] = useState(
		new Date()
	);
	const [healthCardExpiryDateError, setHealthCardExpiryDateError] =
		useState<string>("");
	// const [selectedLocations, setSelectedLocations] = useState<Option[]>([]);
	const [selectedStations, setSelectedStations] = useState<Option[]>([]);

	const {
		register,
		handleSubmit,
		setValue,
		setError,
		reset,
		formState: { errors },
	} = useForm<AddPatientData>({
		resolver: zodResolver(AddPatientSchema),
		defaultValues: {
			is_manual: true,
			health_card_expire_date:
				healthCardExpiryDate.toLocaleDateString("en-CA"),
		},
	});

	const onSubmit: SubmitHandler<AddPatientData> = async (data) => {
		try {
			const submitData = {
				full_name: (data.full_name + " " + firstName).trim(),
				phone_number: countryCode + data.phone_number,
				email: data.email,
				health_card_expire_date:
					dayjs(healthCardExpiryDate).format("YYYY-MM-DD"),
				custom_fields: data.custom_fields,
			};
			console.log(submitData);
			const queueUrl =
				user?.business.locations
					.find(
						(item) => item.id === parseInt(selectedLocationId ?? "")
					)
					?.stations.find(
						(station) =>
							station.id === parseInt(selectedStations[0].value)
					)?.url_code ?? "";

			addPatientMutation.mutate({
				data: {
					...data,
					phone_number: countryCode + data.phone_number,
				},
				queueUrl,
			});

			setTimeout(() => {
				setShow(false);
				reset();
			}, 2000);
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	// console.log(errors);

	return (
		<>
			<Modal
				className="max-w-[516px] px-0"
				header={
					<div className="flex justify-between px-6">
						<DialogTitle className="text-[22px] font-medium capitalize leading-[30px] -tracking-[1%] text-main-1">
							Add Patient
						</DialogTitle>
						<LuX
							onClick={() => {
								setShow(false);
								reset();
							}}
							color="#858C95"
							className="cursor-pointer"
							width="20px"
							height="20px"
						/>
					</div>
				}
				isOpen={show}
				setIsOpen={setShow}
				onClose={() => {
					reset();
					setSelectedStations([]);
				}}
			>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="mt-8 flex flex-col space-y-4 px-6 pb-6"
				>
					<div className="flex items-center justify-between space-x-3">
						<FormInput
							label="Last Name"
							inputType="text"
							className="rounded-[6px]"
							error={errors.full_name?.message}
							register={{ ...register("full_name") }}
							onChange={(e) => {
								setValue("full_name", e.target.value);
							}}
							required
						/>
						<FormInput
							label="First Name"
							inputType="text"
							className="rounded-[6px]"
							value={firstName}
							onChange={(e) => {
								setFirstName(e.target.value);
							}}
						/>
					</div>

					<div className="flex w-full flex-col space-y-1.5 self-center">
						<label className="text-sm font-medium tracking-[-0.1px] text-[#323539]">
							Phone Number{" "}
							<span className="text-red-500"> *</span>
						</label>
						<div className="flex items-stretch">
							<div>
								<CustomSelect
									className="w-fit rounded-r-none border-r-transparent"
									placeholder="+1"
									value={countryCode}
									label="Country Codes"
									options={countryCodes}
									onChange={(value) => {
										setCountryCode(value);
									}}
									labelClassName="px-2"
									itemClassName="px-8"
								/>
							</div>
							<FormInput
								inputType="text"
								className="h-full self-stretch rounded-l-none py-0"
								register={{
									...register("phone_number"),
								}}
								maxLength={11}
								onChange={(e) => {
									setValue("phone_number", e.target.value);
								}}
								required
							/>
						</div>
						{errors.phone_number?.message && (
							<p className="text-sm text-red-500">
								{errors.phone_number?.message}
							</p>
						)}
					</div>

					<FormInput
						label="Email Address"
						inputType="text"
						className="h-10 self-stretch py-0"
						register={{ ...register("email") }}
						onChange={(e) => {
							setValue("email", e.target.value);
						}}
						error={errors.email?.message}
					/>

					<div className="space-y-1.5 bg-white">
						<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
							Location<span className="text-red-500"> *</span>
						</Label>
						<Select
							value={selectedLocationId}
							onValueChange={(value) => {
								setSelectedLocationId(value);
								setSelectedStations([]);
							}}
						>
							<SelectTrigger>
								<SelectValue
									placeholder={"Select Location"}
									className="text-[#858C95]"
								/>
							</SelectTrigger>
							<SelectContent className="!z-[9999]">
								<SelectGroup>
									<SelectLabel className="px-2">
										Select Location
									</SelectLabel>

									{(user?.business.locations.length
										? user?.business.locations?.map(
												(location) => ({
													label: location.name,
													value: location.id.toString(),
												})
											) || []
										: []
									).map((option) => {
										return (
											<SelectItem
												key={option.value}
												value={option.value}
												className="px-8"
											>
												{option.label}
											</SelectItem>
										);
									})}
								</SelectGroup>
							</SelectContent>
						</Select>
					</div>
					<div className="space-y-1.5">
						<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
							Station <span className="text-red-500"> *</span>
						</Label>
						<MultiSelect
							selected={selectedStations}
							onUnselect={(selectedOption) => {
								setSelectedStations((prev) =>
									prev.filter(
										(s) => s.value !== selectedOption.value
									)
								);
							}}
							maxSelections={1}
							options={
								selectedLocationId
									? user?.business.locations
											.find(
												(location) =>
													location.id.toString() ===
													selectedLocationId
											)
											?.stations.map((station) => ({
												label: station.name,
												value: station.id.toString(),
											})) || []
									: []
							}
							placeholder={"Select Station(s)"}
							onSelect={(newSelectedOptions) => {
								if (newSelectedOptions.length >= 2) return;
								setSelectedStations(newSelectedOptions);
							}}
						/>
					</div>

					<div className="rounded-12 border-primary-3 space-y-1.5 bg-white">
						<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
							Priority
						</Label>
						<Select
							value="Normal"
							onValueChange={() => {
								// setCountryCode(value);
							}}
						>
							<SelectTrigger>
								<SelectValue
									placeholder={"Select Priority"}
									className="text-[#858C95]"
								/>
							</SelectTrigger>
							<SelectContent className="!z-[9999]">
								<SelectGroup>
									<SelectLabel className="px-2">
										Select Priority
									</SelectLabel>

									{waitlistPriority.map((option) => {
										return (
											<SelectItem
												key={option.value}
												value={option.value}
												className="px-8"
											>
												{option.label}
											</SelectItem>
										);
									})}
								</SelectGroup>
							</SelectContent>
						</Select>
					</div>

					<div className="rounded-12 border-primary-3 flex flex-col space-y-1.5 bg-white">
						<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
							Health Card Expiry Date{" "}
							<span className="text-red-500">*</span>
						</Label>
						<MonthPicker
							currentMonth={healthCardExpiryDate}
							onMonthChange={(newHealthCardExpiryDate) => {
								setHealthCardExpiryDate(
									newHealthCardExpiryDate
								);
								setValue(
									"health_card_expire_date",
									newHealthCardExpiryDate.toLocaleDateString(
										"en-CA"
									)
								);
								// Check if the selected month and year are less than the current month and year
								const currentMonth = dayjs().month();
								const currentYear = dayjs().year();
								const selectedMonth = dayjs(
									newHealthCardExpiryDate
								).month();
								const selectedYear = dayjs(
									newHealthCardExpiryDate
								).year();

								if (
									selectedYear < currentYear ||
									(selectedYear === currentYear &&
										selectedMonth < currentMonth)
								) {
									setHealthCardExpiryDateError(
										"Health Card has expired. You can still continue"
									);
								} else {
									setHealthCardExpiryDateError("");
								}
							}}
							className="w-full"
						/>
						{!!healthCardExpiryDateError.length && (
							<small className="mt-1.5 text-sm text-red-500">
								{healthCardExpiryDateError}
							</small>
						)}
					</div>
					{user?.business.custom_fields && (
						<>
							{user.business.custom_fields
								.filter((item) => item.is_active)
								.sort((a, b) => a.is_optional - b.is_optional)
								.map((item, index) => (
									<FormInput
										label={item.field}
										key={index}
										inputType="text"
										className="rounded-[6px]"
										error={
											errors.custom_fields?.[
												item.fieldKey
											]?.message
										}
										register={{
											...register(
												`custom_fields.${item.fieldKey}`
											),
										}}
										required={!item.is_optional}
									/>
								))}
						</>
					)}
					<div className="flex justify-end">
						<Button
							className="text-[#323539]"
							variant={"ghost"}
							onClick={() => {
								setShow(false);
								reset();
							}}
						>
							Cancel
						</Button>
						<LoaderButton
							className="px-6 text-white"
							type="submit"
							loading={addPatientMutation.isLoading}
							disabled={addPatientMutation.isLoading}
							loaderSize={20}
						>
							Add
						</LoaderButton>
					</div>
				</form>
			</Modal>

			{/* <ShouldDoAutoSequenceForAddingPatient
				show={showShouldDoAutoSequenceModal}
				setShow={setShowShouldDoAutoSequenceModal}
				setShowPatientDetailsModal={setShowPatientDetailsModal}
				setStationList={setStationList}
			/>

			<PatientDetail
				show={showPatientDetailsModal}
				setShow={setShowPatientDetailsModal}
				patientDetails={patientDetails}
				stationList={stationList}
				setStationList={setStationList}
			/> */}
		</>
	);
};

export default AddPatient;
