import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Analytics } from "../types/Analytics";

export interface AnalyticsQuery {
	location_id?: string;
	station_id?: string;
	date?: string;
	from_date?: string;
	before_date?: string;
}

interface AnalyticsStore {
	analyticsQuery: AnalyticsQuery | undefined;
	analyticsData: Analytics | undefined;
	setAnalyticsQuery: (searchQuery: AnalyticsQuery) => void;
	setAnalyticsData: (analyticsData: Analytics) => void;
	reset: () => void;
}

// const data = {
// 	summaries: {
// 		customers_rating: {
// 			average_rating: 3.5,
// 			total_number_of_feedbacks: 8,
// 			per_rating: {
// 				"1": 3,
// 				"2": 0,
// 				"3": 0,
// 				"4": 0,
// 				"5": 5,
// 			},
// 		},
// 		average_time_spent_data: {
// 			average_time_from_joining_to_serve: 77.69,
// 			average_time_from_serving_to_done: 88.62,
// 			total_average_time: 83.16003640456874,
// 			wait_time_percent: 49.73,
// 			service_time_percent: 50.26,
// 		},
// 		total_visits_data: {
// 			total_visits: 3374,
// 			walk_in: 3374,
// 			scheduled: 0,
// 			cancelled_by_customer_total: 392,
// 			cancelled_by_business_total: 2300,
// 		},
// 		total_customers_data: {
// 			total_customers_count: 6070,
// 			new_customers_count: 3818,
// 		},
// 		working_hours_data: {
// 			total_working_hour: 19312.5,
// 			active_working_hour: 17987.07,
// 			inactive_working_hour: 1325.42,
// 		},
// 	},
// 	data_statics: {
// 		customers_by_locations: [
// 			{
// 				id: 3,
// 				name: "North York City Center Medical Clinic",
// 				customers_count: 7060,
// 			},
// 			{
// 				id: 31,
// 				name: "Tester",
// 				customers_count: 3500,
// 			},
// 		],
// 		customers_by_stations: [
// 			{
// 				id: 3,
// 				name: "General",
// 				customers_count: 4272,
// 			},
// 			{
// 				id: 12,
// 				name: "Room 1",
// 				customers_count: 632,
// 			},
// 			{
// 				id: 13,
// 				name: "Room 2",
// 				customers_count: 588,
// 			},
// 			{
// 				id: 14,
// 				name: "Room 3",
// 				customers_count: 410,
// 			},
// 			{
// 				id: 15,
// 				name: "Room 4",
// 				customers_count: 98,
// 			},
// 			{
// 				id: 16,
// 				name: "Dr Ali",
// 				customers_count: 39,
// 			},
// 			{
// 				id: 17,
// 				name: "Dr Majlessi",
// 				customers_count: 25,
// 			},
// 			{
// 				id: 53,
// 				name: "Station 1",
// 				customers_count: 1,
// 			},
// 			{
// 				id: 54,
// 				name: "Ttesting station",
// 				customers_count: 1,
// 			},
// 			{
// 				id: 55,
// 				name: "Ttesting station",
// 				customers_count: 0,
// 			},
// 		],
// 		hours_by_stations: [
// 			{
// 				id: 3,
// 				name: "General",
// 				serve_time_in_hours: 2884.96,
// 				wait_time_in_hours: 3234.5,
// 			},
// 			{
// 				id: 12,
// 				name: "Room 1",
// 				serve_time_in_hours: 675.53,
// 				wait_time_in_hours: 673.99,
// 			},
// 			{
// 				id: 13,
// 				name: "Room 2",
// 				serve_time_in_hours: 663.8,
// 				wait_time_in_hours: 552.37,
// 			},
// 			{
// 				id: 14,
// 				name: "Room 3",
// 				serve_time_in_hours: 498.01,
// 				wait_time_in_hours: 407.43,
// 			},
// 			{
// 				id: 15,
// 				name: "Room 4",
// 				serve_time_in_hours: 160.77,
// 				wait_time_in_hours: 82.93,
// 			},
// 			{
// 				id: 16,
// 				name: "Dr Ali",
// 				serve_time_in_hours: 30.7,
// 				wait_time_in_hours: 32.23,
// 			},
// 			{
// 				id: 17,
// 				name: "Dr Majlessi",
// 				serve_time_in_hours: 28.82,
// 				wait_time_in_hours: 12.07,
// 			},
// 			{
// 				id: 53,
// 				name: "Station 1",
// 				serve_time_in_hours: 0,
// 				wait_time_in_hours: 0,
// 			},
// 			{
// 				id: 54,
// 				name: "Ttesting station",
// 				serve_time_in_hours: 0,
// 				wait_time_in_hours: 0,
// 			},
// 			{
// 				id: 55,
// 				name: "Ttesting station",
// 				serve_time_in_hours: 0,
// 				wait_time_in_hours: 0,
// 			},
// 		],
// 	},
// 	trends: {
// 		customers_by_weekdays: [
// 			{
// 				Monday: 965,
// 			},
// 			{
// 				Tuesday: 1312,
// 			},
// 			{
// 				Wednesday: 854,
// 			},
// 			{
// 				Thursday: 939,
// 			},
// 			{
// 				Friday: 1316,
// 			},
// 			{
// 				Saturday: 664,
// 			},
// 			{
// 				Sunday: 20,
// 			},
// 		],
// 		customers_by_time_of_day: [
// 			{
// 				time: "00:00:00",
// 				served: 2,
// 			},
// 			{
// 				time: "01:00:00",
// 				served: 0,
// 			},
// 			{
// 				time: "02:00:00",
// 				served: 0,
// 			},
// 			{
// 				time: "03:00:00",
// 				served: 0,
// 			},
// 			{
// 				time: "04:00:00",
// 				served: 0,
// 			},
// 			{
// 				time: "05:00:00",
// 				served: 1,
// 			},
// 			{
// 				time: "06:00:00",
// 				served: 1,
// 			},
// 			{
// 				time: "07:00:00",
// 				served: 0,
// 			},
// 			{
// 				time: "08:00:00",
// 				served: 60,
// 			},
// 			{
// 				time: "09:00:00",
// 				served: 510,
// 			},
// 			{
// 				time: "10:00:00",
// 				served: 515,
// 			},
// 			{
// 				time: "11:00:00",
// 				served: 512,
// 			},
// 			{
// 				time: "12:00:00",
// 				served: 443,
// 			},
// 			{
// 				time: "13:00:00",
// 				served: 366,
// 			},
// 			{
// 				time: "14:00:00",
// 				served: 341,
// 			},
// 			{
// 				time: "15:00:00",
// 				served: 329,
// 			},
// 			{
// 				time: "16:00:00",
// 				served: 322,
// 			},
// 			{
// 				time: "17:00:00",
// 				served: 264,
// 			},
// 			{
// 				time: "18:00:00",
// 				served: 132,
// 			},
// 			{
// 				time: "19:00:00",
// 				served: 13,
// 			},
// 			{
// 				time: "20:00:00",
// 				served: 5,
// 			},
// 			{
// 				time: "21:00:00",
// 				served: 0,
// 			},
// 			{
// 				time: "22:00:00",
// 				served: 0,
// 			},
// 			{
// 				time: "23:00:00",
// 				served: 0,
// 			},
// 		],
// 		customers_types_by_location: [
// 			{
// 				id: 3,
// 				name: "North York City Center Medical Clinic",
// 				new_customers: 3813,
// 				old_customers: 2247 + 1000,
// 			},
// 			{
// 				id: 31,
// 				name: "Tester",
// 				new_customers: 3000,
// 				old_customers: 500,
// 			},
// 		],
// 		walk_in_vs_scheduled_by_location: [
// 			{
// 				id: 3,
// 				name: "North York City Center Medical Clinic",
// 				walk_in_count: 6060,
// 				scheduled_count: 2000,
// 			},
// 			{
// 				id: 31,
// 				name: "Tester",
// 				walk_in_count: 2000,
// 				scheduled_count: 1400,
// 			},
// 			{
// 				id: 31,
// 				name: "Richardson",
// 				walk_in_count: 1333,
// 				scheduled_count: 600,
// 			},
// 		],
// 		walk_in_vs_scheduled_by_days: [
// 			{
// 				day: "Monday",
// 				walk_in_count: 566,
// 				scheduled_count: 0,
// 			},
// 			{
// 				day: "Tuesday",
// 				walk_in_count: 869,
// 				scheduled_count: 0,
// 			},
// 			{
// 				day: "Wednesday",
// 				walk_in_count: 485,
// 				scheduled_count: 0,
// 			},
// 			{
// 				day: "Thursday",
// 				walk_in_count: 568,
// 				scheduled_count: 0,
// 			},
// 			{
// 				day: "Friday",
// 				walk_in_count: 949,
// 				scheduled_count: 0,
// 			},
// 			{
// 				day: "Saturday",
// 				walk_in_count: 380,
// 				scheduled_count: 0,
// 			},
// 			{
// 				day: "Sunday",
// 				walk_in_count: 0,
// 				scheduled_count: 0,
// 			},
// 		],
// 	},
// };

const initialState = {
	analyticsQuery: undefined,
	analyticsData: undefined,
};

const useAnalyticsStore = create<
	AnalyticsStore,
	[["zustand/persist", AnalyticsStore]]
>(
	persist(
		(set) => ({
			...initialState,
			setAnalyticsQuery: (newQuery) =>
				set((state) => ({
					analyticsQuery: { ...state.analyticsQuery, ...newQuery },
				})),
			setAnalyticsData: (analyticsData: Analytics) =>
				set({ analyticsData }),
			reset: () => set(initialState),
		}),
		{
			name: "anaytics-storage",
			getStorage: () => localStorage,
		}
	)
);

export default useAnalyticsStore;
