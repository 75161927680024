import { QueueEntry } from "@type/waitlist/waitlist";
import dayjs from "dayjs";
import React, { useEffect } from "react";

const TimeAgoShort: React.FC<{
	patient: QueueEntry;
	ago?: boolean;
}> = ({ patient, ago }) => {
	const getCardTime: () => string = () => {
		let time = "";
		const t = dayjs(
			new Date().getTime() - new Date(patient.created_at).getTime()
		);
		const hours = t.format("H[h] ");
		const minutes = t.format("m[m]");

		time = hours + minutes;

		return time;
	};

	useEffect(() => {
		const interval = setInterval(() => {
			getCardTime();
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	return (
		<p className="text-xxs text-[#858C95]">
			{getCardTime() + " " + (ago ? "ago" : "")}
		</p>
	);
};

export default TimeAgoShort;
