import { LuBellRing, LuCheckCircle2, LuInfo, LuTrash2 } from "react-icons/lu";

import rescheduleIcon from "/assets/images/reschedule.svg";
import gridIcon from "/assets/images/grid.svg";
import transferIcon from "/assets/images/transfer.svg";
import { cn } from "../../../utils/functions";
import { FaTag } from "react-icons/fa6";
import { BiPhoneCall } from "react-icons/bi";

interface AppointmentCardProps {
	patientData?: Array<Record<string, object>>;
	status: "pending" | "confirmed";
	online?: boolean;
}
const AppointmentCard = ({ status, online }: AppointmentCardProps) => {
	return (
		<div
			className={cn(
				"shadow-sm-x-0-y-1-blur-3-spread-0-color-101828-opacity-10 m-2 flex cursor-grab space-x-3 rounded-xl border bg-white p-3 lg:flex-col lg:space-x-0 lg:space-y-3",
				{
					"hover:border-[#C8322B]": status == "pending",
					"hover:border-[#138576]": status == "confirmed",
				}
			)}
		>
			<div className="flex items-center justify-between">
				<div className="flex items-center gap-x-1.5">
					<p className="font-medium text-[#323539]">Pavel Mahmud</p>
					<div className="rounded-md bg-[#F5F5F5] px-1.5 py-1">
						<LuInfo width="14px" height="14px" color="#323539" />
					</div>
					<div className="rounded-md bg-[#EAEBF0] px-1.5 py-1 text-red-500">
						<FaTag />
					</div>
				</div>
			</div>
			<div className="flex items-center justify-between text-[#6B7280]">
				<div className="flex items-center gap-x-1">
					<img src={gridIcon} alt="grid icon" />
					<p className="text-[10px] leading-[10px]">
						Dr parvez Hasen
					</p>
					{online && (
						<span className="h-1.5 w-1.5 rounded-full bg-[#2EBF43]"></span>
					)}
				</div>
				<p className="text-[10px] leading-[10px]">22 Jan 2024</p>
			</div>
			<div className="flex items-center justify-between text-[#6B7280]">
				<p className="text-[10px] leading-[10px]">First Consultation</p>
				<p className="text-[10px] leading-[10px]">6:30 AM - 7:00 AM</p>
			</div>
			<div className="flex items-center justify-between">
				<div className="flex items-center gap-x-1.5">
					{status === "confirmed" && (
						<>
							{!online && (
								<button className="cursor-pointer rounded-lg bg-[#F5F5F5] p-2">
									<BiPhoneCall width="14px" height="14px" />
								</button>
							)}

							<button className="cursor-pointer rounded-lg bg-[#F5F5F5] p-2">
								<img src={rescheduleIcon} alt="transfer icon" />
							</button>
							<button className="cursor-pointer rounded-lg bg-[#F5F5F5] p-2">
								<LuTrash2 width="14px" height="14px" />
							</button>
						</>
					)}
					{status === "pending" && (
						<>
							{!online && (
								<button className="cursor-pointer rounded-lg bg-[#F5F5F5] p-2">
									<img
										src={transferIcon}
										alt="transfer icon"
									/>
								</button>
							)}

							<button className="cursor-pointer rounded-lg bg-[#F5F5F5] p-2">
								<LuTrash2 width="14px" height="14px" />
							</button>
						</>
					)}
				</div>
				{status === "confirmed" && (
					<>
						{online ? (
							<button className="rounded-lg border border-[#3F9590] bg-[#F5F5F5] p-2 text-[#3F9590]">
								<BiPhoneCall width="14px" height="14px" />
							</button>
						) : (
							<button className="rounded-lg border border-[#3F9590] bg-[#F5F5F5] p-2 text-[#3F9590]">
								<LuBellRing width="14px" height="14px" />
							</button>
						)}
					</>
				)}
				{status === "pending" && (
					<button className="rounded-lg border border-[#C8322B] bg-[#F5F5F5] p-2 text-[#3F9590]">
						<LuCheckCircle2
							className="bg-[#F5F5F5]"
							color="#C8322B"
							width="14px"
							height="14px"
						/>
					</button>
				)}
			</div>
		</div>
	);
};

export default AppointmentCard;
