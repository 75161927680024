import { Dialog, DialogContent, DialogHeader } from "@components/ui/dialog";
import React, { Dispatch, ReactNode, SetStateAction } from "react";

const Modal: React.FC<{
	isOpen: boolean;
	className?: string;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	onClose?: () => void;
	header?: ReactNode;
	isFromDND?: boolean;
	children: ReactNode;
}> = ({ className, children, header, isOpen, setIsOpen, onClose }) => {
	return (
		<Dialog
			onOpenChange={(newOpen) => {
				onClose?.();
				console.log(onClose);
				setIsOpen(newOpen);
			}}
			open={isOpen}
		>
			<DialogContent className={className}>
				{header && <DialogHeader>{header}</DialogHeader>}

				{children}
			</DialogContent>
		</Dialog>
	);
};

export default Modal;
