import React from "react";
import { BsInfoCircle } from "react-icons/bs";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { PiCheckCircle } from "react-icons/pi";
import { TbCalendarStats } from "react-icons/tb";
import { cn } from "../../../utils/functions";

interface DetailsPaneProps {
	onClick: () => void;
}
const DetailsPane = ({ onClick }: DetailsPaneProps) => {
	return (
		<div
			className={cn(
				"flex flex-col items-center justify-start gap-4 text-[14px] font-[400]"
			)}
		>
			<button
				className="flex items-center gap-2 self-start px-0 text-[14px]"
				onClick={onClick}
			>
				<BsInfoCircle className="text-[14px]" />
				Details
			</button>
			<button className="flex items-center gap-2 self-start text-[14px] text-[#2AA63C]">
				<PiCheckCircle />
				Approve
			</button>
			<button className="flex items-center gap-2 self-start text-[14px]">
				<TbCalendarStats />
				Reschedule
			</button>
			<button className=" flex items-center gap-2 self-start text-[14px] text-[#C8322B]">
				<IoMdRemoveCircleOutline />
				Decline
			</button>
		</div>
	);
};

export default DetailsPane;
