import { APIVersion1Analytics } from "@src/http/v1";
import useAnalyticsStore from "@src/store/useAnalyticsStore";
import useUserStore from "@src/store/useUserStore";
import { Analytics } from "@src/types/Analytics";
import { useQuery } from "react-query";

export const GetAnalyticsSlice = (
	// onSuccess: (data: { status: boolean; data: Analytics }) => void = () => {
	// 	return;
	// },
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const user = useUserStore((s) => s.user);

	const analyticsQuery = useAnalyticsStore((s) => s.analyticsQuery);
	const setAnalyticsData = useAnalyticsStore((s) => s.setAnalyticsData);

	return useQuery<
		{
			status: boolean;
			data: Analytics;
		},
		Error
	>({
		queryKey: ["analytics", analyticsQuery],
		queryFn: () =>
			APIVersion1Analytics(user?.business_id, {
				location_id: analyticsQuery?.location_id,
				from_date: analyticsQuery?.from_date,
				before_date: analyticsQuery?.before_date,
				date: analyticsQuery?.date,
				station_id: analyticsQuery?.station_id,
			}),
		onSuccess: (data) => {
			setAnalyticsData(data.data);
		},
		onError,
	});
};
