import { OperatingHour } from "@type/DaySlots";
import { AxiosError, AxiosResponse } from "axios";
import { UseFormSetError } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import useCustomToast from "../components/CustomToast";
import APIClient from "../services/api-client";
import useUserStore from "../store/useUserStore";
import { AddBusinessCardData, UpdateBusinessCardData } from "../types/Business";
import { AddLocationData } from "../types/Location";
import { Location, User } from "../types/NewUser";
import { getCookie } from "../utils/cookies";
import {
	addBusiness,
	addBusinessCard,
	addBusinessLocation,
	getBusinessCategories,
	getBusinessCurrentPlan,
	getSubscriptionPlans,
} from "./api/business";

export const useGetBusinessCategories = (
	onSuccess: (
		data: AxiosResponse<{
			data: {
				id: number;
				name: string;
				created_at: Date | null;
				updated_at: Date | null;
			}[];
		}>
	) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const setBusinessCategories = useUserStore((s) => s.setBusinessCategories);
	return useQuery<
		AxiosResponse<{
			data: {
				id: number;
				name: string;
				created_at: Date | null;
				updated_at: Date | null;
			}[];
		}>,
		Error
	>("business_categories", getBusinessCategories, {
		onSuccess: (data) => {
			onSuccess(data);
			setBusinessCategories(
				data.data.data.map((category) => ({
					label: category.name,
					value: category.id.toString(),
				}))
			);
		},
		onError,
	});
};

export const useGetBusinessCurrentPlan = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	}
) => {
	return useQuery("business_current_plan", getBusinessCurrentPlan, {
		onSuccess,
		onError,
	});
};

export const useGetSubscriptionPlans = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	}
) => {
	return useQuery("subscription_plans", getSubscriptionPlans, {
		onSuccess,
		onError,
	});
};

export const useAddBusiness = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	}
) => {
	const navigate = useNavigate();
	const setOnboardingState = useUserStore((s) => s.setOnboardingState);
	const setUser = useUserStore((s) => s.setUser);
	const customToast = useCustomToast();

	return useMutation(addBusiness, {
		onSuccess: (data: any) => {
			onSuccess(data);
			setUser({ business: data.data.data } as User);
			setOnboardingState(1);
			navigate("/onboarding/add-location");
		},
		onError: (error: AxiosError<any>) => {
			onError(error);

			if (
				error.response?.data?.message ===
				"Business related to this account already exists"
			) {
				customToast("Business related to this account already exists", {
					id: "reset-password",
					type: "success",
				});
				toast.success(
					"Business related to this account already exists",
					{
						duration: 3000,
					}
				);
				navigate("/onboarding/add-location");
			}
		},
	});
};

export const useUpdateBusiness = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError<any>) => void = () => {
		return;
	}
) => {
	const navigate = useNavigate();
	const setOnboardingState = useUserStore((s) => s.setOnboardingState);
	const setUser = useUserStore((s) => s.setUser);
	const apiClient = new APIClient<{ data: User }, FormData>(
		"/my-business/update"
	);

	return useMutation<{ data: User }, AxiosError<any>, FormData>(
		(data) =>
			apiClient.post(data, {
				headers: {
					Authorization: "Bearer " + getCookie("ac-token"),
				},
			}),
		{
			onSuccess: (data: any) => {
				onSuccess(data);
				setUser({ business: data.data } as User);
				setOnboardingState(1);
				navigate("/onboarding/add-location");
			},
			onError: (error: AxiosError<any>) => {
				onError(error);

				if (error.response?.data?.message) {
					toast.success(error.response?.data?.message, {
						duration: 3000,
					});
					navigate("/onboarding/add-location");
				}
			},
		}
	);
};

export const useGetBusinessInfo = (
	isEnabled: boolean,
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError<any>) => void = () => {
		return;
	}
) => {
	const apiClient = new APIClient<
		AxiosResponse<{ data: AddLocationData }>,
		unknown
	>("/my-business");

	return useQuery<
		unknown,
		AxiosError<any>,
		AxiosResponse<{ data: AddLocationData }>
	>(
		"business_info",
		() =>
			apiClient.get({
				headers: {
					Authorization: "Bearer " + getCookie("ac-token"),
				},
			}),

		{
			enabled: isEnabled,
			onSuccess: (data) => {
				onSuccess(data);
			},
			onError: (error: AxiosError<any>) => {
				onError(error);
			},
		}
	);
};

export const useAddBusinessLocation = (
	onSuccess: (data: AxiosResponse<{ location: Location }>) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	},
	slots: OperatingHour[]
) => {
	const navigate = useNavigate();
	const setOnboardingState = useUserStore((s) => s.setOnboardingState);
	const setOnboardingLocationInfo = useUserStore(
		(s) => s.setOnboardingLocationInfo
	);

	return useMutation(addBusinessLocation, {
		onSuccess: (data: AxiosResponse<{ location: Location }>) => {
			onSuccess(data);

			setOnboardingLocationInfo({
				id: data.data.location.id,
				approximate_waiting_time:
					data.data.location.approximate_waiting_time ?? "00:15:00",
				schedule_block_in_min:
					data.data.location.schedule_block_in_min ?? 15,
				time_zone: data.data.location.time_zone ?? "",
				time_slots: slots,
			});
			setOnboardingState(2);
			navigate("/onboarding/add-payment-method");
		},
		onError: (error: AxiosError<any>) => {
			if (error.status === 403) {
				toast.error("Kindly login", { duration: 4000 });
				setTimeout(() => {
					navigate("/sign-in");
				}, 1000);
				return;
			}

			if (error.response?.data?.errors.name[0]) {
				toast.error(error.response?.data?.errors.name[0], {
					duration: 3000,
				});
				setOnboardingState(2);
				setTimeout(() => {
					navigate("/onboarding/add-payment-method");
				}, 1000);
				return;
			}
			toast.error("An error occured kindly try again");
			onError(error);
		},
	});
};

export const useUpdateBusinessLocation = (
	onSuccess: (data: AxiosResponse<{ location: Location }>) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	},
	slots: OperatingHour[]
) => {
	const navigate = useNavigate();
	const setOnboardingState = useUserStore((s) => s.setOnboardingState);
	const setOnboardingLocationInfo = useUserStore(
		(s) => s.setOnboardingLocationInfo
	);
	const onboardingLocationInfo = useUserStore(
		(s) => s.onboardingLocationInfo
	);

	const apiClient = new APIClient(
		"/my-business/locations/" + onboardingLocationInfo?.id + "/update"
	);

	return useMutation<any, AxiosError<any>, any>(
		(data) =>
			apiClient.post(data, {
				headers: {
					Authorization: "Bearer " + getCookie("ac-token"),
				},
			}),
		{
			onSuccess: (data: any) => {
				onSuccess(data);
				setOnboardingLocationInfo({
					id: data.location.id,
					approximate_waiting_time:
						data.location.approximate_waiting_time,
					schedule_block_in_min: data.location.schedule_block_in_min,
					time_zone: data.location.time_zone,
					time_slots: slots,
				});
				setOnboardingState(2);
				toast.success("Business schedule updated!", {
					id: "update-business-schedule",
				});
				navigate("/onboarding/add-payment-method");
			},
			onError: (error: AxiosError<any>) => {
				if (error.status === 403) {
					toast.error("Kindly login", { duration: 4000 });
					setTimeout(() => {
						navigate("/sign-in");
					}, 1000);
					return;
				}

				if (error.response?.data?.errors.name[0]) {
					toast.error(error.response?.data?.errors.name[0], {
						duration: 3000,
					});
					setOnboardingState(2);
					setTimeout(() => {
						navigate("/onboarding/add-payment-method");
					}, 1000);
					return;
				}
				toast.error("An error occured kindly try again");
				onError(error);
			},
		}
	);
};

export const useUpdateSpacesOperatingHours = (
	onSuccess: (data: AxiosResponse<{ location: Location }>) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	}
	// slots: OperatingHour[]
) => {
	const navigate = useNavigate();
	const setOnboardingState = useUserStore((s) => s.setOnboardingState);
	// const setOnboardingLocationInfo = useUserStore(
	// 	(s) => s.setOnboardingLocationInfo
	// );
	const user = useUserStore((s) => s.user);

	return useMutation<any, AxiosError<any>, any>(
		(data) => {
			const apiClient = new APIClient(
				"room_booking/business/" +
					user?.business_id +
					"/locations/" +
					user?.business.room_booking_locations[0].id +
					"/working-hours"
			);

			return apiClient.post(data, {
				headers: {
					Authorization: "Bearer " + getCookie("ac-token"),
				},
			});
		},
		{
			onSuccess: (data: any) => {
				onSuccess(data);
				// setOnboardingLocationInfo({
				// 	id: data.location.id,
				// 	approximate_waiting_time:
				// 		data.location.approximate_waiting_time,
				// 	schedule_block_in_min: data.location.schedule_block_in_min,
				// 	time_zone: data.location.time_zone,
				// 	time_slots: slots,
				// });
				setOnboardingState(2);
				toast.success("Business Working Hours updated!", {
					id: "update-business-schedule",
				});
				navigate("/onboarding/add-payment-method");
			},
			onError: (error: AxiosError<any>) => {
				if (error.status === 403) {
					toast.error("Kindly login", { duration: 4000 });
					setTimeout(() => {
						navigate("/sign-in");
					}, 1000);
					return;
				}

				if (error.response?.data?.errors.name[0]) {
					toast.error(error.response?.data?.errors.name[0], {
						duration: 3000,
					});
					setOnboardingState(2);
					setTimeout(() => {
						navigate("/onboarding/add-payment-method");
					}, 1000);
					return;
				}
				toast.error("An error occured kindly try again");
				onError(error);
			},
		}
	);
};

export const useAddBusinessCard = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	},
	setError: UseFormSetError<AddBusinessCardData>
) => {
	const navigate = useNavigate();
	const setOnboardingState = useUserStore((s) => s.setOnboardingState);

	return useMutation(addBusinessCard, {
		onSuccess: (data: AxiosResponse) => {
			onSuccess(data);
			setOnboardingState(3);
			const productType = localStorage.getItem("product_type");
			if (productType === "room_booking")
				window.location.href = "https://spaces.migranium.com/";
			else if (import.meta.env.PROD && productType === "primary") {
				window.location.href = "https://migranium.com/sign-in";
			} else {
				navigate("/dashboard/waitlist");
			}
		},
		onError: (error: AxiosError<any>) => {
			onError(error);

			if (error.status === 403) {
				toast.error("Kindly login", { duration: 4000 });
				setTimeout(() => {
					navigate("/sign-in");
				}, 1000);
				return;
			}
			if (typeof error.response?.data.error === "string") {
				toast.error(error.response?.data.error);
				setError("payment_information.card.number", {
					message: error.response?.data.error,
				});
				return;
			}

			toast.error("An error occured kindly try again");
		},
	});
};

export const useUpdateBusinessCard = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	},
	setError: UseFormSetError<AddBusinessCardData>
) => {
	return useMutation<any, AxiosError, UpdateBusinessCardData>(
		(data) => {
			const apiClient = new APIClient<any, UpdateBusinessCardData>(
				"/my-business/subscription/update-payment-method"
			);

			return apiClient.post(data, {
				headers: {
					Authorization: "Bearer " + getCookie("ac-token"),
				},
			});
		},
		{
			onSuccess: (data: AxiosResponse) => {
				onSuccess(data);
			},
			onError: (error: AxiosError<any>) => {
				onError(error);
				if (typeof error.response?.data.error === "string") {
					toast.error(error.response?.data.error);
					setError("payment_information.card.number", {
						message: error.response?.data.error,
					});
					return;
				}

				toast.error("An error occured kindly try again");
			},
		}
	);
};
