import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@src/components/ui/accordion";
import { Button } from "@src/components/ui/button";
import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import { ScrollArea } from "@src/components/ui/scroll-area";
import { cn } from "@src/utils/functions";
import React, { useEffect, useRef, useState } from "react";
import ErrorMessageDialog from "../../ErrorMessageDialog";
import FirstTimeStepFour from "./StepFour";
import FirstTimeStepOne from "./StepOne";
import FirstTimeStepThree from "./StepThree";
import FirstTimeStepTwo from "./StepTwo";

const FirstTimeOscarIntegration: React.FC = () => {
	const [step, setStep] = useState(1);
	const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isCompleted] = useState(false);
	const observer = useRef<IntersectionObserver | null>(null);

	// Create refs for each step component
	const stepRefs = useRef<(HTMLDivElement | null)[]>([]);

	// Function to handle scrolling to a specific step
	const scrollToStep = (stepIndex: number) => {
		if (stepRefs.current[stepIndex]) {
			stepRefs.current[stepIndex]?.scrollIntoView({ behavior: "smooth" });
		}
	};

	useEffect(() => {
		observer.current = new IntersectionObserver(
			(entries) => {
				// Loop over each entry
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						const stepIndex = stepRefs.current.indexOf(
							entry.target as HTMLDivElement
						);
						console.log(stepIndex, stepRefs.current, entry.target);
						// If the stepIndex is valid, update the current step
						if (stepIndex !== -1) {
							setStep(stepIndex + 1);
						}
					}
				});
			},
			{ threshold: 0.5 } // Trigger callback when 50% of the element is visible
		);

		// Observe each step
		stepRefs.current.forEach((ref) => {
			if (ref) {
				observer.current?.observe(ref);
			}
		});

		// Cleanup
		return () => {
			observer.current?.disconnect();
		};
	}, []);

	return (
		<>
			<section className="flex h-0 min-h-full flex-1 flex-col ">
				<h2 className="text-[22px] font-semibold leading-[24px] tracking-[-1%] text-[#323539]">
					Integrate EMR
				</h2>
				<ScrollArea className="flex-1">
					<div className="flex h-[75dvh] flex-1 flex-col">
						<div className="mb-[30px] mt-[42px] flex items-center space-x-[27px]">
							<figure className="size-16 overflow-hidden rounded-xl border border-[#DEDEDE] drop-shadow-[0_0_20px_0_rgba(0,0,0,0.10)]">
								<img
									src="/assets/images/integration/oscar/oscar.png"
									alt="Oscar Pro Image"
								/>
							</figure>
							<p className="text-[22px] font-semibold leading-[24px] tracking-[-1%] text-[#323539]">
								OSCAR Pro
							</p>
						</div>
						<hr />
						{!isCompleted ? (
							<div className="flex flex-1 space-x-[18px] pt-[34px]">
								<div className="flex flex-col items-center justify-between self-stretch">
									{Array.from({ length: 4 }).map(
										(_, index) => (
											<StepCounter
												key={index}
												step={index + 1}
												isActive={step >= index + 1}
												setStep={(stepIndex) => {
													setStep(stepIndex);
													scrollToStep(stepIndex - 1); // Scroll to the step
												}}
											/>
										)
									)}
								</div>
								<ScrollArea className="h-0 min-h-[100%] flex-1 px-8">
									<div className="space-y-[50px]">
										<div
											ref={(el) =>
												(stepRefs.current[0] = el)
											}
											className="flex flex-1"
										>
											<FirstTimeStepOne
												step={step}
												setStep={setStep}
												isErrorMessageOpen={
													isErrorMessageOpen
												}
												errorMessage={errorMessage}
												setErrorMessage={
													setErrorMessage
												}
												setIsErrorMessageOpen={
													setIsErrorMessageOpen
												}
											/>
										</div>
										<div
											ref={(el) =>
												(stepRefs.current[1] = el)
											}
											className="flex flex-1"
										>
											<FirstTimeStepTwo
												step={step}
												setStep={setStep}
												isErrorMessageOpen={
													isErrorMessageOpen
												}
												errorMessage={errorMessage}
												setErrorMessage={
													setErrorMessage
												}
												setIsErrorMessageOpen={
													setIsErrorMessageOpen
												}
											/>
										</div>
										<div
											ref={(el) =>
												(stepRefs.current[2] = el)
											}
											className="flex flex-1"
										>
											<FirstTimeStepThree
												step={step}
												setStep={setStep}
												isErrorMessageOpen={
													isErrorMessageOpen
												}
												errorMessage={errorMessage}
												setErrorMessage={
													setErrorMessage
												}
												setIsErrorMessageOpen={
													setIsErrorMessageOpen
												}
											/>
										</div>
										<div
											ref={(el) =>
												(stepRefs.current[3] = el)
											}
											className="flex flex-1"
										>
											<FirstTimeStepFour
												step={step}
												setStep={setStep}
												isErrorMessageOpen={
													isErrorMessageOpen
												}
												errorMessage={errorMessage}
												setErrorMessage={
													setErrorMessage
												}
												setIsErrorMessageOpen={
													setIsErrorMessageOpen
												}
											/>
										</div>
									</div>
								</ScrollArea>
							</div>
						) : (
							<section className="m-1.5 flex flex-1 flex-col items-center pb-4 pt-[57px]">
								<div className="flex items-center space-x-[52px]">
									<img
										src="/assets/images/logo-white.svg"
										className="size-16 rounded-xl border border-[#DEDEDE] bg-[#005893] p-3"
										alt="Migranium logo"
									/>
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M0 16L7.19836 7.91111L0.163599 0H1.86503L8.01636 6.99259L14.135 0H15.8364L8.80164 7.91111L16 16H14.2658L8.01636 8.82963L1.73415 16H0Z"
											fill="#858C95"
										/>
									</svg>

									<img
										className="size-16 rounded-xl border border-[#28C466] bg-white p-1.5 shadow-[0px_0px_12px_0px_rgba(40,196,102,0.5),0px_0px_20px_0px_rgba(0,0,0,0.1)]"
										src="/assets/images/integration/oscar/oscar.png"
										alt="Oscar Pro logo"
									/>
								</div>
								<div className="mt-12 space-y-3 text-center">
									<p className="text-[24px] font-semibold leading-[30px] text-[#09090B]">
										You are{" "}
										<span className="text-[#28C466]">
											connected!
										</span>{" "}
									</p>
									<p className="text-[18px] text-[#71717A]">
										Connected since: DD MM YYYY{" "}
									</p>
								</div>
								<div className="mt-[26px] w-full max-w-[805px] rounded-xl border border-[#E4E4E7] p-6 shadow-[0px_10px_15px_-3px_rgba(0,0,0,0.1),0px_4px_6px_-4px_rgba(16,24,40,0.1)]">
									<Accordion
										type="single"
										collapsible
										className="w-full max-w-[805px]"
									>
										<AccordionItem
											value="item-1"
											className="border-none"
										>
											<AccordionTrigger className="flex w-full items-center justify-between p-0 hover:no-underline [&[data-state=open]>figure>i]:rotate-180">
												<div className="flex flex-col">
													<h3 className="text-lg font-semibold text-[#09090B]">
														EMR Integration
														Information
													</h3>
													<p className="text-start text-[14px] font-normal text-[#71717A]">
														View your connection
														details
													</p>
												</div>
												<figure className="grid size-[38px] place-content-center rounded-lg bg-[#09244B0A]">
													<i className="mgc_down_line text-[18px] transition-transform duration-200" />
												</figure>
											</AccordionTrigger>
											<AccordionContent className="pb-0">
												<form className="flex flex-col space-y-4">
													<div className="flex flex-col space-y-4 py-4">
														<div className="flex flex-col space-y-1.5">
															<Label>
																Client ID / Key
															</Label>
															<Input
																value="39409-skokos"
																className="text-opacity-30 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
															/>
														</div>
														<div className="flex flex-col space-y-1.5">
															<Label>
																Client Secret
															</Label>
															<Input
																value="39409-skokos"
																className="text-opacity-30 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
																readOnly
															/>
														</div>
														<div className="flex flex-col space-y-1.5">
															<Label>
																EMR Base URI
															</Label>
															<Input
																value="https://hospitalname.migranium.com"
																className="text-opacity-30 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
															/>
														</div>
													</div>
													<div className="flex justify-between space-x-4">
														<Button
															variant="outline"
															className="self-end border-[#989898] px-6 text-[#989898]"
														>
															Cancel
														</Button>
														<Button className="px-6 text-white">
															Update
														</Button>
													</div>
												</form>
											</AccordionContent>
										</AccordionItem>
									</Accordion>
								</div>
								<div className="mt-10 flex w-full flex-col space-y-3 rounded-lg bg-[#F8F9FB] px-4 py-3.5">
									<div className="space-y-2">
										<h3 className="font-medium leading-[16px] text-[#E33B32]">
											Disconnect
										</h3>
										<p className="text-[14px] leading-[18px] text-[#71717A]">
											Disconnecting this EMR will limit
											certain functions. You may
											disconnect here or contact{" "}
											<span className="text-primary underline">
												support
											</span>{" "}
											.
										</p>
									</div>
									<Button
										variant="outline"
										className="self-end border-[#989898] text-[#989898]"
									>
										Disconnect
									</Button>
								</div>
							</section>
						)}
					</div>
				</ScrollArea>
			</section>
			<ErrorMessageDialog
				isOpen={isErrorMessageOpen}
				// isOpen
				setIsOpen={setIsErrorMessageOpen}
				errorMessage={"Deqdiqeidonqed qedqiend"}
				setErrorMessage={setErrorMessage}
			/>
		</>
	);
};

export default FirstTimeOscarIntegration;

const StepCounter: React.FC<{
	step: number;
	isActive: boolean;
	setStep: (step: number) => void;
}> = ({ isActive, setStep, step }) => {
	return (
		<>
			<div
				className={cn(
					"grid size-8 cursor-pointer place-content-center rounded-full  font-medium leading-[24px] tracking-[-1%] duration-200 ease-in-out",
					{
						"bg-primary text-white": isActive,
						"border border-[#E5E5E7] bg-white text-[#858C95]":
							!isActive,
					}
				)}
				onClick={() => setStep(step)}
			>
				{step}
			</div>
			<div
				className={cn(
					"w-[2px] flex-1 border-l border-dashed duration-200 ease-in-out last:hidden",
					{
						"border-[#E5E5E7]": !isActive,
						"border-primary": isActive,
					}
				)}
			/>
		</>
	);
};
