import { AxiosResponse } from "axios";
import { request } from "../../utils/axios-utils";
import { AddBusinessCardData, AddBusinessInfoData } from "../../types/Business";
import { getCookie } from "../../utils/cookies";
import { AddLocationData } from "../../types/Location";

export const getBusinessCategories = (): Promise<AxiosResponse> => {
	return request({
		url: "/business-categories",
	});
};

export const getBusinessCurrentPlan = (): Promise<AxiosResponse> => {
	const token: string | null = getCookie("ac-token");
	return request({
		url: "/my-business/subscription",
		headers: {
			Authorization: "Bearer " + token,
		},
	});
};

export const addBusiness = (data: any): Promise<AxiosResponse> => {
	const token: string | null = getCookie("ac-token");
	return request({
		url: "/my-business/add",
		method: "POST",
		data,
		headers: {
			Authorization: "Bearer " + token,
		},
	});
};

export const getBusinessInfo = (): Promise<AxiosResponse> => {
	const token: string | null = getCookie("ac-token");
	return request({
		url: "/my-business",
		headers: {
			Authorization: "Bearer " + token,
		},
	});
};

export const addBusinessLocation = (
	data: AddLocationData
): Promise<AxiosResponse> => {
	const token: string | null = getCookie("ac-token");
	return request({
		url: "/my-business/locations/add",
		method: "POST",
		data,
		headers: {
			Authorization: "Bearer " + token,
		},
	});
};

export const addBusinessCard = (
	data: AddBusinessCardData
): Promise<AxiosResponse> => {
	const token: string | null = getCookie("ac-token");
	return request({
		url: "/my-business/subscription/subscribe",
		method: "POST",
		data,
		headers: {
			Authorization: "Bearer " + token,
		},
	});
};

export const getSubscriptionPlans = (): Promise<AxiosResponse> => {
	return request({
		url: "/subscription-plans",
	});
};

export const getLocationsData = (): Promise<AxiosResponse> => {
	const token: string | null = getCookie("ac-token");
	return request({
		url: "/my-business/locations",
		headers: {
			Authorization: "Bearer " + token,
		},
	});
};
