import { useState, useRef, FC } from "react";

interface ImageData {
	src: string | undefined | null;
	alt: string;
	imageClass?: string;
}

const getDefaultImageDataUrl = (): string => {
	const svg = `
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="64" height="64" rx="4" fill="#D9D9D9"/>
      <path d="M19.856 37H18.036V27.2H20.444L24.028 34.046H24.308V27.2H26.128V37H23.706L20.136 30.154H19.856V37ZM28.7402 39.8H27.0182L30.8682 26.78H32.5902L28.7402 39.8ZM42.0323 37H40.0583L39.3163 34.83H35.1303L34.3883 37H32.4143L35.9003 27.2H38.5463L42.0323 37ZM37.0903 29.132L35.6903 33.206H38.7563L37.3703 29.132H37.0903Z" fill="#979AA0"/>
    </svg>
  `;
	return `data:image/svg+xml;base64,${btoa(svg)}`;
};

export const CustomImage: FC<ImageData> = ({ src, alt, imageClass }) => {
	const [error, setError] = useState(false);
	const defaultImage = getDefaultImageDataUrl();

	const imgRef = useRef<HTMLImageElement>(null);

	const handleError = () => {
		setError(true);
	};

	return (
		<img
			className={`h-auto w-auto object-cover object-center ${
				imageClass ? imageClass : ""
			}`}
			src={error || !src ? defaultImage : src}
			alt={alt}
			ref={imgRef}
			onError={handleError}
		/>
	);
};
