import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import AppointmentColumn from "./AppointmentColumn";
import AutoApprove from "./AutoApprove";

const TabList = () => {
	return (
		<Tabs defaultValue="pending" className="w-full space-y-4">
			<TabsList className="relative">
				<TabsTrigger
					value="pending"
					className="flex gap-2 rounded-md data-[state=active]:border-primary data-[state=active]:text-primary"
				>
					<p className="flex items-center gap-1 text-[14px]">
						Pending{" "}
						<span className="flex h-[12px] w-[16px] items-center justify-center rounded-full bg-[#E33B32] p-2 text-xs text-white">
							2
						</span>
					</p>
				</TabsTrigger>
				<TabsTrigger
					value="current"
					className="rounded-md text-[14px] data-[state=active]:border-primary data-[state=active]:text-primary"
				>
					Current
				</TabsTrigger>
				<div className="absolute -right-[110%]">
					<AutoApprove />
				</div>
			</TabsList>
			<TabsContent value="pending">
				<AppointmentColumn status="Pending" />
			</TabsContent>
			<TabsContent value="current">
				<AppointmentColumn status="Current" />
			</TabsContent>
		</Tabs>
	);
};

export default TabList;
