import fileIcon from "../../../../public/assets/images/file.svg";
import CustomCheckbox2 from "@components/form/CustomCheckbox2";
import { Button } from "@components/ui/button";
import { handleSelectAllChange } from "@utils/functions";
import React, { useState } from "react";
import useWaitListStore from "../../../store/useWaitListStore";
import { PatientStatus } from "../../../types/wait-list";
import { ScrollArea } from "../../ui/scroll-area";
import StatusCard from "./StatusCard";
import {
	SortableContext,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import WaitlistCard from "../../../pages/waitlist/components/WaitlistViews/WaitlistColumnCard";
import { QueueEntry } from "@src/types/waitlist/waitlist";
import { Switch } from "@src/components/ui/switch";
import { DragOverlay, useDroppable } from "@dnd-kit/core";

const WaitlistColumn: React.FC<{
	status: PatientStatus;
	statuses: PatientStatus[];
	activeId?: number;
	setActiveId: any;
}> = ({ status, statuses, activeId }) => {
	// const [{ isOver }, drop] = useDrop(() => ({
	//     accept: "task",
	//     drop: (task) => addItemToSelection(task.id),
	//     collect: (monitor) => ({
	//         isOver: !!monitor.isOver()
	//     })
	// }));

	// const addItemToSelection = (id) => {
	//     setTasks((prev) => {
	//         const mTask = prev.map((task) => {
	//             if (task.id === id) {
	//                 console.log(task.id === id)
	//                 return { ...task, status: status }
	//             }
	//             return task;
	//         })
	//         return mTask;
	//     });
	//     toast.success(`Patient Moved to ${status}`)
	// };

	const [selectAllChecked, setSelectAllChecked] = useState(false);
	const [selectedRows, setSelectedRows] = useState<number[]>([]);
	const patientsObject = useWaitListStore((s) => s.patientsObject);

	const number: number = patientsObject[status].length;
	const { setNodeRef } = useDroppable({ id: status });

	return (
		<SortableContext
			items={patientsObject[status]}
			strategy={verticalListSortingStrategy}
		>
			<div
				ref={setNodeRef}
				className={`flex w-full flex-1 flex-col overflow-hidden rounded-lg bg-[#FAFBFC] shadow-[0px_0px_16px_0px_rgba(0,0,0,0.02)] m1.5xl:min-w-[318px] ${statuses.length !== 4 && "min-w-[318px]"}`}
			>
				<div className="sticky top-0 z-10 rounded-t-lg">
					<StatusCard
						status={status}
						statusCardBg="bg-status-1"
						statusText={`Showing ${number} of ${number} `}
					>
						{status !== PatientStatus.Pending &&
							status !== PatientStatus.Schedule && (
								<Switch
									id={"queue-on-of-" + status}
									className="scale-75 data-[state=checked]:bg-[#32D74B] data-[state=unchecked]:bg-[#E5E5E7]"
								/>
							)}
					</StatusCard>
					<div className="flex items-center justify-between bg-[#FAFBFC] px-3">
						<div className="flex items-center gap-2  py-4">
							{/* <CustomCheckbox
							id={status + " selectAll"}
							isChecked={selectAllChecked}
							handleCheckboxChange={handleSelectAllChange}
							className=""
						/> */}
							<CustomCheckbox2
								handleCheckboxChange={() =>
									handleSelectAllChange(
										!selectAllChecked,
										status,
										setSelectedRows,
										setSelectAllChecked,
										patientsObject
									)
								}
								isChecked={selectAllChecked}
								id={status + " selectAll"}
								className="rounded-sm border-2"
							/>
							<p className="text-sm leading-6 -tracking-[1%] text-[#7D7E80]">
								Select All
							</p>
						</div>

						<div className="flex space-x-2.5 opacity-90">
							<Button
								className="before-text-gray-2 flex h-8 w-8 items-center justify-center rounded-[6px] border border-[#6B7280] bg-transparent"
								variant="outline"
							>
								<i className="mgc_delete_2_line before-text-gray-2 before-text-gray-2 text-[12px]" />
							</Button>
							{(status === PatientStatus.Upcoming ||
								status === PatientStatus.NextInLine) && (
								<Button
									className="before-text-gray-2 flex h-8 w-8 items-center justify-center rounded-[6px] border border-[#6B7280] bg-transparent"
									variant="outline"
								>
									<i className="mgc_bell_ringing_line before-text-gray-2 before-text-gray-2 text-[12px]" />
								</Button>
							)}

							{(status === PatientStatus.Pending ||
								status === PatientStatus.NextInLine) && (
								<Button
									className="before-text-gray-2 flex h-8 w-8 items-center justify-center rounded-[6px] border border-[#6B7280] bg-transparent"
									variant="outline"
								>
									<i className="mgc_check_circle_line before-text-gray-2 before-text-gray-2 text-[12px]" />
								</Button>
							)}
							{status === PatientStatus.Serving && (
								<Button
									className="before-text-gray-2 flex h-8 w-8 items-center justify-center rounded-[6px] border border-[#6B7280] bg-transparent"
									variant="outline"
								>
									<i className="mgc_check_circle_fill before-text-gray-2 before-text-gray-2 text-[12px]" />
								</Button>
							)}
						</div>
					</div>
				</div>

				<ScrollArea className="flex-1">
					<TooltipProvider>
						{!patientsObject[status]?.length ? (
							<div className="mt-6 flex justify-center text-base text-[#A1AAB6]">
								<img src={fileIcon} alt="file" />
								<span className="ml-1">No Data</span>
							</div>
						) : (
							patientsObject[status].map(
								(patient: QueueEntry, index: number) => (
									<React.Fragment key={patient.id}>
										<WaitlistCard
											selectedRows={selectedRows}
											setSelectAllChecked={
												setSelectAllChecked
											}
											setSelectedRows={setSelectedRows}
											noOfPatients={
												patientsObject[status].length
											}
											status={status}
											index={index}
											patient={patient}
											activeId={activeId}
										/>
									</React.Fragment>
								)
							)
						)}
					</TooltipProvider>
				</ScrollArea>
			</div>
		</SortableContext>
	);
};

export default WaitlistColumn;
