import { QueueEntry } from "@type/waitlist/waitlist";
import React from "react";
import { useNotifyPatient } from "../../../hooks/useLocationData";
import useCustomToast from "../../CustomToast";
import { Button } from "../../ui/button";

const NotifyPatient: React.FC<{ patient: QueueEntry }> = ({ patient }) => {
	const customToast = useCustomToast();

	const notifyPatientMutation = useNotifyPatient(patient.customer_id, () =>
		customToast("Patient notified", {
			id: "notify-patient",
			type: "success",
		})
	);

	return (
		<Button
			className="size-[30px] rounded-md bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
			onClick={() => {
				customToast("Patient being notified", {
					id: "notify-patient",
					type: "loading",
					duration: 5000,
				});
				notifyPatientMutation.mutate({});
			}}
		>
			<i className="mgc_bell_ringing_line text-[14px]" />
		</Button>
	);
};

export default NotifyPatient;
