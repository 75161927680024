import React, { useRef } from "react";
// import useCustomToast from "@components/CustomToast";
import Overlay from "@components/Overlay";
import { Button } from "@components/ui/button";
// import { LocationsIProps } from "@src/types/Location";
import { Card } from "@components/ui/card";
// import { CustomImage } from "@components/common/CustomImage";
import { IoClose } from "react-icons/io5";
import {
	PiInfo,
	PiCopyBold,
	// PiMapPinBold,
	PiBuildingOfficeBold,
	// PiGridNine,
} from "react-icons/pi";
import { TbUserShield } from "react-icons/tb";
// import RatingComponent from "@components/common/RatingComponent";
import { Badge } from "@components/ui/badge";
import { IoMdStopwatch } from "react-icons/io";
import {
	LuGrid,
	LuChevronRight,
	LuChevronLeft,
	LuCalendarClock,
} from "react-icons/lu";
import { RiListCheck2 } from "react-icons/ri";
import { convertTimeFormat, formatDate } from "@src/utils/functions";
import { Switch } from "@components/ui/switch";
// import profileAvater from "/assets/icons/profile-avatar.svg";
import {
	// Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@components/ui/accordion";
import { QRCodeSVG } from "qrcode.react";
// import Loader from "@src/components/Loader/Loader";
import { StationsProps } from "@src/types/Location";

export const StationInfoModal: React.FC<{
	station: StationsProps | null;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	showModal: boolean;
	isLoading: boolean;
	buttonAction: () => void;
}> = ({ station, showModal, setShowModal, isLoading, buttonAction }) => {
	const modalRef = useRef(null);
	const [activeScreen, setActiveScreen] = React.useState("station");

	// const customToast = useCustomToast();

	const handleScreenToggle = (screen: string) => {
		setActiveScreen(screen);
	};

	const RenderScreen = (activeStep: string) => {
		switch (activeStep) {
			case "station":
				return (
					<Card
						className="relative z-50 max-h-screen w-full max-w-[500px] space-y-6 overflow-hidden bg-white p-5 text-main-1"
						ref={modalRef}
					>
						<Button
							className="absolute right-1 top-7 bg-transparent text-black hover:bg-transparent "
							onClick={() => setShowModal(false)}
						>
							<IoClose className=" text-black" size={20} />
						</Button>

						<div className="flex flex-1 items-center space-x-3">
							<div className="flex flex-1 flex-col items-center space-y-3">
								<div className="flex items-center space-x-3">
									<PiBuildingOfficeBold size={14} />
									<p className="font-semibold">
										{station?.name}
									</p>
								</div>
								<div className="flex items-start space-x-2 text-gray-400">
									<p className="text-sm font-normal leading-none tracking-tight">
										Last activity-{" "}
										{formatDate(station?.updated_at || "")}
									</p>
								</div>
								<div className="flex flex-1 items-center space-x-3">
									<Badge
										className="my-o rounded-sm bg-[#EFEFF1] px-2"
										variant={"secondary"}
									>
										<IoMdStopwatch
											className="mr-2"
											size={14}
										/>
										<span className="mr-1 font-normal">
											Avg. Wait Time
										</span>{" "}
										{convertTimeFormat(
											station?.average_waiting_time || ""
										)}
									</Badge>
									<Badge
										className="rounded-sm bg-[#EFEFF1] px-2 text-xs text-[#323539]"
										variant={"secondary"}
									>
										{/* <i className="mgc_shop_line before-text-gray-4 mr-2 text-[18px]" /> */}
										<LuGrid className="mr-2" size={14} />
										<span className=" mr-1 font-normal ">
											Services
										</span>{" "}
										{"N/A"}
									</Badge>
								</div>
							</div>
						</div>
						<div className="flex flex-1 flex-col items-center space-y-3">
							<div className=" item-center flex w-full justify-between rounded-l-md bg-neutral-50 p-5 text-main-1">
								<span className="flex items-center gap-x-2">
									<RiListCheck2 size={24} />
									Waitlist
								</span>
								<Button
									variant="secondary"
									size="icon-sm"
									className="bg-white"
									onClick={() =>
										handleScreenToggle("waitList")
									}
								>
									<LuChevronRight size={24} />
								</Button>
							</div>
							<div className=" item-center flex w-full justify-between rounded-l-md bg-neutral-50 p-5 text-main-1">
								<span className="flex items-center gap-x-2">
									<LuCalendarClock size={24} />
									Schedule
								</span>
								<Button
									variant="secondary"
									size="icon-sm"
									className="bg-white"
									onClick={() =>
										handleScreenToggle("schedule")
									}
								>
									<LuChevronRight size={24} />
								</Button>
							</div>
						</div>
						<div className="space-y-2">
							<h3 className="font-medium">Members</h3>
							<div className="flex max-h-[207px] flex-1 flex-col divide-y-2 divide-gray-200 overflow-y-auto">
								<div className="flex items-center space-x-3 px-4 py-3 ">
									<TbUserShield size={16} />
									<div>
										<h3 className="space-x-3 text-sm">
											<span className="font-semibold">
												Daniella Dalsheim
											</span>
											<span className="font-normal">
												(Admin)
											</span>
										</h3>
										<div className="flex items-center space-x-3 text-xs font-normal">
											<p>+ 1 416 938 8888</p>
											<p>email@example.com</p>
										</div>
									</div>
								</div>
								<div className="flex items-center space-x-3 px-4 py-3 ">
									<TbUserShield size={16} />
									<div>
										<h3 className="space-x-3 text-sm">
											<span className="font-semibold">
												Daniella Dalsheim
											</span>
											<span className="font-normal">
												(Admin)
											</span>
										</h3>
										<div className="flex items-center space-x-3 text-xs font-normal">
											<p>+ 1 416 938 8888</p>
											<p>email@example.com</p>
										</div>
									</div>
								</div>
								<div className="flex items-center space-x-3 px-4 py-3 ">
									<TbUserShield size={16} />
									<div>
										<h3 className="space-x-3 text-sm">
											<span className="font-semibold">
												Daniella Dalsheim
											</span>
											<span className="font-normal">
												(Admin)
											</span>
										</h3>
										<div className="flex items-center space-x-3 text-xs font-normal">
											<p>+ 1 416 938 8888</p>
											<p>email@example.com</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="flex items-start justify-between space-x-2">
							<div className="flex flex-1 flex-col">
								<div className="mt-6 flex justify-end space-x-3">
									<Button
										className="font-semibold text-[#323539]"
										variant={"ghost"}
										onClick={() => setShowModal(false)}
									>
										Close
									</Button>
									<Button
										className="font-semibold"
										// variant={""}
										onClick={buttonAction}
									>
										{/* <Loader size={24} /> */}
										View Details
									</Button>
								</div>
							</div>
						</div>
					</Card>
				);
			case "waitList":
				return (
					<Card
						className="relative z-50 max-h-screen w-full max-w-[500px] space-y-6 overflow-hidden bg-white p-5 text-main-1"
						ref={modalRef}
					>
						<div className="relative">
							<Button
								className="absolute left-1 top-1 bg-transparent p-1 text-black hover:bg-transparent"
								onClick={() => handleScreenToggle("station")}
							>
								<LuChevronLeft
									className=" text-black"
									size={24}
								/>
							</Button>

							<Button
								className="absolute right-1 top-1 bg-transparent p-1 text-black hover:bg-transparent"
								onClick={() => setShowModal(false)}
							>
								<IoClose className=" text-black" size={24} />
							</Button>
						</div>

						<div className="flex w-full flex-1 items-center justify-center space-x-3 border-b">
							<p className="-mb-0.5 border-b border-b-primary pb-2 font-semibold text-primary">
								Join Waitlist
							</p>
						</div>

						<div className="mt-6">
							<div className="flex items-center justify-between border-b">
								<div className="flex items-center space-x-1">
									<p className="font-semibold">
										Waitlist Active
									</p>
									<Button
										variant="link"
										size="xs"
										className="m-0 p-0"
									>
										<PiInfo
											size={12}
											className=" text-[#C5CEDB]"
										/>
									</Button>
								</div>
								<div className="flex items-center">
									<Switch
										defaultChecked={
											station?.is_active === 1
										}
										className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
									/>
									<span className="">
										{station?.is_active === 1
											? "Yes"
											: "No"}
									</span>
								</div>
							</div>
							<div className="mt-2 flex flex-col items-center space-y-3">
								<h3 className="m-0 p-0 text-[#858C95]">
									Use this QR code/link to join the{" "}
									<span className="font-bold">waitlist</span>{" "}
									of {station?.name}
								</h3>
								<QRCodeComponent url="https://example.com" />
								<h3 className="text-xs font-semibold">
									Waitlist QR CODE
								</h3>
								<div className="flex w-full items-center justify-between rounded-md border px-3 py-2.5">
									<h3 className="text-[15px]">
										https://locationname.com/book
									</h3>
									<Button
										variant="link"
										size="xs"
										className="m-0 p-0"
									>
										<PiCopyBold
											size={15}
											className=" text-[#858C95]"
										/>
									</Button>
								</div>
							</div>
							<div className="mt-7 flex w-full items-center justify-between border-b pb-3">
								<p className="font-semibold">
									Join through QR code
								</p>
								<div className="flex items-center">
									<Switch
										defaultChecked={
											station?.is_active === 1
										}
										className="scale-[0.70] data-[state=checked]:bg-primary"
									/>
									<span className="">
										{station?.is_active === 1
											? "On"
											: "Off"}
									</span>
								</div>
							</div>
							<div className="mt-3 flex w-full items-center justify-between pb-3">
								<div className="flex items-center space-x-1">
									<p className="font-semibold">
										Join through web link
									</p>
									<Button
										variant="link"
										size="xs"
										className="m-0 p-0"
									>
										<PiInfo
											size={12}
											className=" text-[#C5CEDB]"
										/>
									</Button>
								</div>

								<div className="flex items-center">
									<Switch
										defaultChecked={
											station?.is_active === 1
										}
										className="scale-[0.70] data-[state=checked]:bg-primary"
									/>
									<span className="">
										{station?.is_active === 1
											? "On"
											: "Off"}
									</span>
								</div>
							</div>
						</div>

						<div className="mt-6 flex justify-end space-x-3">
							<Button
								className="font-semibold text-[#323539]"
								variant={"ghost"}
								onClick={() => setShowModal(false)}
							>
								Close
							</Button>
							<Button
								className="font-semibold"
								// variant={""}
								onClick={buttonAction}
							>
								{/* <Loader size={24} /> */}
								View Details
							</Button>
						</div>
					</Card>
				);
			case "schedule":
				return (
					<Card
						className="relative z-50 max-h-screen w-full max-w-[500px] space-y-6 overflow-hidden bg-white p-5 text-main-1"
						ref={modalRef}
					>
						<div className="relative">
							<Button
								className="absolute left-1 top-1 bg-transparent p-1 text-black hover:bg-transparent"
								onClick={() => handleScreenToggle("station")}
							>
								<LuChevronLeft
									className=" text-black"
									size={24}
								/>
							</Button>

							<Button
								className="absolute right-1 top-1 bg-transparent p-1 text-black hover:bg-transparent"
								onClick={() => setShowModal(false)}
							>
								<IoClose className=" text-black" size={24} />
							</Button>
						</div>

						<div className="flex w-full flex-1 items-center justify-center space-x-3 border-b">
							<p className="-mb-0.5 border-b border-b-primary pb-2 font-semibold text-primary">
								Schedule Appointment
							</p>
						</div>

						<div className="mt-6">
							<div className="flex items-center justify-between border-b">
								<div className="flex items-center space-x-1">
									<p className="font-semibold">
										Allow New Appointments
									</p>
									<Button
										variant="link"
										size="xs"
										className="m-0 p-0"
									>
										<PiInfo
											size={12}
											className=" text-[#C5CEDB]"
										/>
									</Button>
								</div>
								<div className="flex items-center">
									<Switch
										defaultChecked={
											station?.is_active === 1
										}
										className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
									/>
									<span className="">
										{station?.is_active === 1
											? "Yes"
											: "No"}
									</span>
								</div>
							</div>
							<div className="mt-2 flex flex-col items-center space-y-3">
								<h3 className="m-0 p-0 text-[#858C95]">
									Use this QR code/link to join the{" "}
									<span className="font-bold">schedule</span>{" "}
									of {station?.name}
								</h3>
								<QRCodeComponent url="https://example.com" />
								<h3 className="text-xs font-semibold">
									Schedule QR CODE
								</h3>
								<div className="flex w-full items-center justify-between rounded-md border px-3 py-2.5">
									<h3 className="text-[15px]">
										https://locationname.com/book
									</h3>
									<Button
										variant="link"
										size="xs"
										className="m-0 p-0"
									>
										<PiCopyBold
											size={15}
											className=" text-[#858C95]"
										/>
									</Button>
								</div>
							</div>
							<div className="mt-7 flex w-full items-center justify-between border-b pb-3">
								<p className="font-semibold">
									Schedule through QR code
								</p>
								<div className="flex items-center">
									<Switch
										defaultChecked={
											station?.is_active === 1
										}
										className="scale-[0.70] data-[state=checked]:bg-primary"
									/>
									<span className="">
										{station?.is_active === 1
											? "On"
											: "Off"}
									</span>
								</div>
							</div>
							<div className="mt-3 flex w-full items-center justify-between pb-3">
								<div className="flex items-center space-x-1">
									<p className="font-semibold">
										Schedule through web link
									</p>
									<Button
										variant="link"
										size="xs"
										className="m-0 p-0"
									>
										<PiInfo
											size={12}
											className=" text-[#C5CEDB]"
										/>
									</Button>
								</div>

								<div className="flex items-center">
									<Switch
										defaultChecked={
											station?.is_active === 1
										}
										className="scale-[0.70] data-[state=checked]:bg-primary"
									/>
									<span className="">
										{station?.is_active === 1
											? "On"
											: "Off"}
									</span>
								</div>
							</div>
						</div>

						<div className="mt-6 flex justify-end space-x-3">
							<Button
								className="font-semibold text-[#323539]"
								variant={"ghost"}
								onClick={() => setShowModal(false)}
							>
								Close
							</Button>
							<Button
								className="font-semibold"
								// variant={""}
								onClick={buttonAction}
							>
								{/* <Loader size={24} /> */}
								View Details
							</Button>
						</div>
					</Card>
				);
			// case 2:
			// 	return <LocationMember />;
			default:
				return "";
		}
	};

	return (
		<>
			<Overlay
				show={showModal}
				setShow={setShowModal}
				modalRef={modalRef}
				isPersistent={isLoading}
			>
				{RenderScreen(activeScreen)}
			</Overlay>
		</>
	);
};

const WaitListAccordion: React.FC = () => {
	return (
		<AccordionItem value="item-2" className="border-0">
			<AccordionTrigger className="rounded-l-md bg-neutral-50 p-5 text-main-1 hover:no-underline">
				<span className="flex items-center gap-x-2">
					<RiListCheck2 size={24} />
					Waitlist
				</span>
			</AccordionTrigger>
			<AccordionContent className="flex flex-col pb-0">
				<p className="border-b py-1">
					Windows 11 is a simple yet effective way to enhance your
					computing experience. Whether you&apos;re a professional
					juggling multiple tasks, a student doing research, or are
					just looking to improve your productivity, utilizing this
					small feature while you work can provide a significant boost
					to your levels of focus.
				</p>
			</AccordionContent>
		</AccordionItem>
	);
};

const QRCodeComponent: React.FC<{ url: string | null }> = ({ url }) => {
	return (
		<QRCodeSVG
			value={url || ""}
			size={114}
			bgColor={"#ffffff"}
			fgColor={"#000000"}
			level={"L"}
			includeMargin={false}
		/>
	);
};
