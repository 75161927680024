import dayjs from "dayjs";
import { LuArrowBigUp, LuBell, LuCalendarDays } from "react-icons/lu";
import { useLocation } from "react-router";
import useUserStore from "../../store/useUserStore";
import DisplayTimeAndCustomerCount from "../../components/Dashboard/display/DisplayTimeAndCustomerCount";
import { Button } from "../../components/ui/button";
import React, { ReactNode } from "react";

const Header: React.FC<{
	content?: ReactNode;
	title: string;
	showDate?: boolean;
}> = ({ content, title, showDate = true }) => {
	const location = useLocation();
	const pageName = [
		"waitlist",
		"schedule",
		"analytics",
		"display",
		"locations",
	];
	const currentPage = location.pathname.split("/")[2];
	// const title = pageName.includes(currentPage) ? currentPage : "Dashboard";
	const user = useUserStore((s) => s.user);

	return (
		<header className="flex items-center justify-between pb-4">
			<div>
				<div className="text-[#6D748D]">{user?.business.name}</div>
				<h2 className="text-[28px] font-semibold capitalize leading-[28px] text-[#323539]">
					{/* {currentPage.includes("display")
						? "Today's Waitlist"
						: title} */}
					{title}
				</h2>
			</div>
			{content}
			{currentPage.includes("locations") && (
				<DisplayTimeAndCustomerCount />
			)}
			<div className="flex items-center space-x-8">
				{showDate && (
					<div className="flex items-center space-x-2 text-[16px] font-medium">
						<LuCalendarDays size={18} />
						<div>{dayjs().format("MMM DD, YYYY")}</div>
					</div>
				)}

				{currentPage === "waitlist" && (
					<div>
						<Button size="icon-sm" variant="outline">
							<LuBell size={18} />
						</Button>
					</div>
				)}

				<Button size="sm" variant="outline">
					<LuArrowBigUp size={18} />
					<span className="ml-2">Upgrade plan</span>
				</Button>
			</div>
		</header>
	);
};

export default Header;
