import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "@src/components/Loader/Loader";

import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { Button } from "@src/components/ui/button";
import { DetailCard } from "./DetailCard";
import {
	useForm,
	FormProvider,
	useFieldArray,
	Controller,
	useWatch,
} from "react-hook-form";
import {
	UpdateBusinessLocationStationSlice,
	GetLocationStationsSlice,
	DeleteStationSlice,
} from "@src/store/slices/locations/locationSlice";
import {
	LocationsIProps,
	LocationArray,
	StationsArrayProps,
	AddBusinessLocationResponseType,
	StationsProps,
	LocationResponse,
} from "@src/types/Location";
import { EditStationType } from "@src/types/Station";
import useCustomToast from "@components/CustomToast";
import { AddStationModal, StationInfoModal, DeleteLocationModal } from "./";
import { CgAdd } from "react-icons/cg";

interface StationsIProps {}
interface FormData {
	stations: StationsArrayProps;
}

const Stations: React.FC<StationsIProps> = () => {
	const queryClient = useQueryClient();
	const customToast = useCustomToast();
	const [searchParams] = useSearchParams();
	const locationId = searchParams.get("locationId") || "";

	const [selectedStation, setSelectedStation] =
		useState<StationsProps | null>(null);
	const [showDeleteStationModal, setShowDeleteStationModal] = useState(false);
	const [showAddStationModal, setShowAddStationModal] = useState(false);
	const [showStationInfoModal, setShowStationInfoModal] = useState(false);
	const [checkAllStations, setCheckAllStations] = useState(false);
	const [stationIds, setStationIds] = useState<number[]>([]);

	const handleSelectStation = (station: StationsProps) => {
		setSelectedStation(station);
	};

	const {
		data: stationData,
		error: stationError,
		isLoading: stationLoading,
	} = GetLocationStationsSlice(parseInt(locationId));

	const { isLoading: deleteLoading, mutate: deleteStationMutation } =
		DeleteStationSlice(parseInt(locationId), selectedStation?.id || 0, () =>
			setShowDeleteStationModal(false)
		);

	const handleDeleteStation = () => {
		customToast("Deleting station", {
			id: "delete-station",
			type: "loading",
		});
		setShowDeleteStationModal(false);
		if (selectedStation) {
			deleteStationMutation({ id: selectedStation.id });
		}
	};

	const handleCheckAllStations = () => {
		if (stationIds?.length === stationData?.data?.length) {
			setStationIds([]);
			setCheckAllStations(false);
		} else {
			setStationIds(
				stationData?.data?.map(
					(station: StationsProps) => station.id
				) || []
			);
			setCheckAllStations(true);
		}
	};

	const updateStationMutation = UpdateBusinessLocationStationSlice(
		(data) => {
			queryClient.setQueryData<StationsArrayProps>(
				"get-station",
				(oldData) =>
					oldData?.map((station) =>
						station.id === data.id
							? {
									...station,
									is_queue_active: data.is_queue_active,
								}
							: station
					) ?? []
			);
		},
		(error) => console.log("🚀 ~ LocationTabList ~ error:", error)
	);

	const methods = useForm<FormData>({
		defaultValues: {
			stations: [],
		},
	});

	const { control, setValue } = methods;
	const { fields } = useFieldArray<FormData>({
		control,
		name: "stations",
		keyName: "_id" as "id",
	});

	const watchLocations = useWatch({
		control,
		name: "stations",
	});

	const handleSwitchChange = useCallback(
		async (
			locationId: number,
			stationId: number,
			value: boolean,
			index: number
		) => {
			const isActive = value;

			try {
				customToast("Updating station", {
					id: "update-station",
					type: "loading",
				});

				setValue(`stations.${index}.is_queue_active`, isActive);

				const result = await updateStationMutation.mutateAsync({
					locationId: locationId,
					stationId: stationId,
					data: { is_queue_active: isActive },
				});

				const serverIsActive = result.location.is_queue_active;
				setValue(`stations.${index}.is_queue_active`, serverIsActive);

				customToast("Station updated successfully", {
					id: "update-station",
					type: "success",
				});
			} catch (error) {
				setValue(`stations.${index}.is_queue_active`, !isActive);
			}
		},
		[updateStationMutation, setValue, customToast]
	);

	const handleShowAddStationModal = () => {
		setShowAddStationModal(true);
	};

	const handleDeleteStationModal = (station: any) => {
		setSelectedStation(station);
		setShowDeleteStationModal(true);
	};

	useEffect(() => {
		if (stationData) {
			methods.reset({ stations: stationData.data });
		}
	}, [stationData, methods]);

	return (
		<>
			<div className="mb flex items-center justify-between">
				<div className="flex items-center space-x-2">
					<CustomCheckbox2
						isChecked={checkAllStations}
						handleCheckboxChange={handleCheckAllStations}
						className="checked:border-[#195388] checked:bg-[#195388]"
						id={""}
					/>
					<label className="font-normal text-gray-400">
						Select all
					</label>
				</div>
				<Button
					variant="outline"
					onClick={() => handleShowAddStationModal()}
					className="flex items-center justify-center space-x-1 text-sm leading-none"
				>
					<CgAdd size={18} />
					{/* <i className="mgc_add_circle_line flex items-center text-[16px] text-primary"></i> */}
					<span>Add Station</span>
				</Button>
			</div>
			<FormProvider {...methods}>
				<form>
					<div className=" grid grid-flow-row grid-cols-4 gap-5">
						{!stationLoading &&
							fields.map((station: any, index: number) => (
								<div key={station.id}>
									<DetailCard
										stationData={station}
										watchLocations={watchLocations}
										handleSwitchChange={handleSwitchChange}
										index={index}
										control={control}
										handleSelectStation={
											handleSelectStation
										}
										setShowInfoModal={
											setShowStationInfoModal
										}
										stationIds={stationIds}
										setStationIds={setStationIds}
										handleDeleteStationModal={
											handleDeleteStationModal
										}
									/>
								</div>
							))}
					</div>
				</form>
			</FormProvider>
			<AddStationModal
				locationId={parseInt(locationId)}
				showModal={showAddStationModal}
				setShowModal={setShowAddStationModal}
				isLoading={updateStationMutation.isLoading}
				buttonAction={() => {}}
			/>
			<StationInfoModal
				station={selectedStation}
				showModal={showStationInfoModal}
				setShowModal={setShowStationInfoModal}
				isLoading={true}
				buttonAction={() => {}}
			/>

			<DeleteLocationModal
				locationName={selectedStation?.name || ""}
				showModal={showDeleteStationModal}
				setShowModal={setShowDeleteStationModal}
				isLoading={deleteLoading}
				buttonAction={handleDeleteStation}
				DeleteContent={DeleteStationContent}
				buttonText="Yes, delete station"
			/>
		</>
	);
};

export default Stations;

const DeleteStationContent = ({ displayString }: { displayString: string }) => {
	return (
		<div className="space-y-4">
			<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
				Are you sure you want to delete{" "}
				<span className="font-semibold">
					&quot;{displayString}?&quot;
				</span>
				{". "}
			</p>
			<p className="mt-2 text-sm leading-[20px] -tracking-[0.1px] text-[#6D748D]">
				This action cannot be undone.
			</p>
		</div>
	);
};
