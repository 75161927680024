import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import toast from "react-hot-toast";
import { deleteAllCookies } from "../utils/cookies";
import { CONFIGS } from "@src/configs";

const axiosInstance = axios.create({
	baseURL: CONFIGS.URL.API_BASE_URL,
	withCredentials: true,
});

const handleResponse = <ResponseData>(
	response: AxiosResponse<ResponseData>
) => {
	if (response.status >= 400) {
		return Promise.reject(response);
	}
	return response.data;
};

const handleForceLogout = () => {
	localStorage.clear();
	deleteAllCookies();
	window.location.replace("/sign-in");
};

const handleError = (error: AxiosError) => {
	if (error.response) {
		if (error.response.status === 403) {
			toast.error("Session expired! Kindly login.");
			setTimeout(() => {
				handleForceLogout();
			}, 500);
		}
	} else {
		toast.error("Kindly check your network connection and try again");
	}
	return Promise.reject(error);
};

class APIClient<ResponseData, PostData> {
	endpoint: string;

	constructor(endpoint: string) {
		this.endpoint = endpoint;
	}

	isAbsoluteUrl(url: string) {
		return /^https?:\/\//.test(url);
	}

	resolveUrl(url: string) {
		if (this.isAbsoluteUrl(url)) {
			axiosInstance.defaults.withCredentials = false;
		}
		return this.isAbsoluteUrl(url)
			? url
			: `${axiosInstance.defaults.baseURL}/${url}`;
	}

	get = async (config: AxiosRequestConfig = {}) => {
		const url = this.resolveUrl(this.endpoint);
		return axiosInstance
			.get<ResponseData>(url, config)
			.then(handleResponse)
			.catch(handleError);
	};

	delete = async (config: AxiosRequestConfig = {}) => {
		const url = this.resolveUrl(this.endpoint);
		return axiosInstance
			.delete<ResponseData>(url, config)
			.then(handleResponse)
			.catch(handleError);
	};

	post = async (data: PostData, config: AxiosRequestConfig = {}) => {
		const url = this.resolveUrl(this.endpoint);
		return axiosInstance
			.post<ResponseData>(url, data, config)
			.then(handleResponse)
			.catch(handleError);
	};
}

export default APIClient;
