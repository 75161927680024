import DisplayPatientCards from "@components/Dashboard/display/DisplayPatientCards";
import DisplayTimeAndCustomerCount from "@components/Dashboard/display/DisplayTimeAndCustomerCount";
import dayjs from "dayjs";
import React, { Dispatch, SetStateAction } from "react";
import { LuCalendarDays } from "react-icons/lu";
import logoBlueFooter from "../../../public/assets/images/logo-blue-footer.svg";
import { Button } from "@components/ui/button";

const DisplayFullScreen: React.FC<{
	displayFullName: boolean;
	displayText: string;
	toggleFullScreen: () => void;
}> = ({ displayFullName, displayText, toggleFullScreen }) => {
	return (
		<div className="flex min-h-screen flex-col bg-[#F9F9F9] px-[52px] pt-6">
			<section className="flex items-center justify-between border-b border-b-[#09244B] pb-5">
				<h2 className="font-hoves text-3xl font-[600] capitalize">
					Today&apos;s Waitlist
				</h2>
				<DisplayTimeAndCustomerCount />
				<div className="flex items-center justify-between space-x-6">
					<div className="flex items-center gap-x-2 text-[16px] font-medium">
						<LuCalendarDays size={18} />
						<div>{dayjs().format("DD MMM, YYYY")}</div>
					</div>
					<Button
						variant="outline"
						className="size-fit bg-white px-3 py-2"
						onClick={toggleFullScreen}
					>
						<i className="mgc_fullscreen_exit_line" />
					</Button>
				</div>
			</section>
			<div className="mb-5 mt-[26px] flex-1">
				<DisplayPatientCards
					displayFullName={displayFullName}
					isFullScreen
				/>
			</div>
			<footer className="sticky bottom-0 flex flex-col">
				<p className="px-4 py-3 font-medium leading-[18px] tracking-normal text-[#323539]">
					{displayText}
				</p>
				<div className="flex items-center justify-center space-x-2 pb-3.5 pt-2.5">
					<small className="py-0.5 text-[12px] text-[#6D748D]">
						Powered by
					</small>
					<img
						src={logoBlueFooter}
						alt="Migranium logo"
						className="py-0.5"
					/>
				</div>
			</footer>
		</div>
	);
};

export default DisplayFullScreen;
