import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { PiCreditCard } from "react-icons/pi";
import { useQueryClient } from "react-query";
import Loader from "../../components/Loader/Loader";
import { useGetUserInformation } from "../../hooks/useAuthData";
import { useAddBusinessCard } from "../../hooks/useBusinessData";
import { SubscriptionPlan } from "../../types/SubscriptionPlan";
import { formatCreditCard, formatStringToMMYY } from "../../utils/functions";
import useCustomToast from "../CustomToast";
import { CustomButton } from "../form/CustomButton";
import FormInput from "../form/FormInput";
import RequestIsLoading from "../RequestIsLoading";
import { AddBusinessCardData, AddBusinessCardSchema } from "@type/Business";

const AddCard: React.FC = () => {
	const {
		handleSubmit,
		setValue,
		getValues,
		register,
		setError,
		watch,
		formState: { errors },
	} = useForm<AddBusinessCardData>({
		resolver: zodResolver(AddBusinessCardSchema),
		defaultValues: {
			price_id: "",
			payment_information: {
				type: "card",
				card: {
					number: "",
					exp_month: 0,
					exp_year: 0,
					cvc: "",
				},
			},
		},
	});

	const queryClient = useQueryClient();
	const customToast = useCustomToast();

	const addBusinessCardMutation = useAddBusinessCard(
		() => {
			customToast("Payment method added successfully", {
				id: "add-card",
				type: "success",
			});
			setTimeout(() => {
				getUserMutation.mutate({});
			}, 300);
		},
		undefined,
		setError
	);

	const getUserMutation = useGetUserInformation(() => {
		customToast("An error occured kindly try again later", {
			id: "get-user-information",
			type: "error",
		});
	});

	const handleAddCard: SubmitHandler<AddBusinessCardData> = async () => {
		const subscriptionPlansData = queryClient.getQueryData(
			"subscription_plans"
		) as { data: SubscriptionPlan[] };
		const priceId = subscriptionPlansData.data.find(
			(plan: SubscriptionPlan) => plan.price === 0
		)?.default_price.price_id;
		setValue("price_id", priceId ?? "");

		addBusinessCardMutation.mutate({
			payment_information: {
				card: { ...getValues().payment_information.card },
				type: getValues().payment_information.type,
			},
			price_id: getValues().price_id,
		});
	};

	const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		const formattedValue = formatCreditCard(inputValue);
		setValue("payment_information.card.number", formattedValue);
	};

	return (
		<form
			className="flex h-fit max-h-fit w-full max-w-[656px] flex-col space-y-4 rounded-[10px] bg-white shadow-[0px_20px_25px_-5px_rgba(16,24,40,0.10),0px_8px_10px_-6px_rgba(16,24,40,0.10)]"
			onSubmit={handleSubmit(handleAddCard)}
		>
			<p className="px-8 py-3 text-[22px] font-semibold text-[#323539] ">
				Add your card details
			</p>
			<div className="flex flex-col space-y-7 px-8">
				<FormInput
					inputType={"text"}
					label="Name on card"
					maxLength={40}
					register={{ ...register("payment_information.name") }}
					error={errors?.payment_information?.name?.message}
				/>
				<div className="">
					<label className="bt-1.5 mb-1.5 block text-sm font-medium tracking-[-0.1px] text-[#323539]">
						Debit / Credit Card
					</label>
					<div
						className={`flex items-center rounded-md border ${errors?.payment_information?.card?.number?.message ? "border-red-500" : "border-[#E5E5E7]"} `}
					>
						<PiCreditCard
							className="mx-3.5"
							size={18}
							fill="#195388"
						/>
						<div className="flex flex-1 space-x-4">
							<input
								{...register(
									"payment_information.card.number",
									{
										onChange(event) {
											handleCardNumberChange(event);
										},
									}
								)}
								value={watch("payment_information.card.number")}
								placeholder="Card Number"
								className={`w-full rounded-none border-0 border-l-[1px] bg-white px-3 py-2 text-[#323539] placeholder:text-[#323539]/50  focus:shadow-none ${errors?.payment_information?.card?.number?.message ? "border-red-500 focus:border-red-500" : "border-[#E5E5E7] focus:border-[#E5E5E7]"}`}
								inputMode="numeric"
							/>
							<input
								type="text"
								placeholder="MM/YY"
								onChange={(e) => {
									setValue(
										"payment_information.card.exp_month",
										+formatStringToMMYY(e).slice(0, 2)
									);
									setValue(
										"payment_information.card.exp_year",
										+formatStringToMMYY(e).slice(3)
									);
								}}
								maxLength={5}
								inputMode="numeric"
								className="w-[50px] max-w-fit border-none px-0 text-center placeholder:text-center placeholder:text-sm"
							/>
							<input
								type="text"
								placeholder="CVC"
								onChange={(e) => {
									setValue(
										"payment_information.card.cvc",
										e.target.value
									);
								}}
								maxLength={3}
								inputMode="numeric"
								className="w-[50px] max-w-fit rounded-r-[10px] border-none px-0 text-center placeholder:text-center placeholder:text-sm"
							/>
						</div>
					</div>
					{(errors.payment_information?.card?.number?.message ||
						errors.payment_information?.card?.exp_month?.message ||
						errors.payment_information?.card?.exp_year?.message ||
						errors.payment_information?.card?.cvc?.message) && (
						<p
							className={`mt-1.5 text-sm tracking-[-0.1px] text-red-500 `}
						>
							{errors.payment_information?.card?.number?.message
								? errors.payment_information?.card?.number
										?.message
								: errors.payment_information?.card?.exp_month
											?.message
									? errors.payment_information?.card
											?.exp_month?.message
									: errors.payment_information?.card?.exp_year
												?.message
										? errors.payment_information?.card
												?.exp_year?.message
										: errors.payment_information?.card?.cvc
												?.message &&
											errors.payment_information?.card
												?.cvc?.message}
						</p>
					)}
				</div>
				<div className="flex flex-col space-y-1.5">
					<FormInput
						inputType={"text"}
						label="Zip Code"
						className="block max-w-[195px]"
						maxLength={10}
						value={watch("payment_information.zipCode")}
						register={{
							...register("payment_information.zipCode", {
								onChange(event) {
									setValue(
										"payment_information.zipCode",
										event.target.value.replace(/\D/g, "")
									);
								},
							}),
						}}
						error={
							errors.payment_information?.zipCode ? "" : undefined
						}
					/>
					{errors.payment_information?.zipCode && (
						<small className="mt-1.5 text-sm text-red-500">
							{errors.payment_information?.zipCode?.message}
						</small>
					)}
				</div>
			</div>
			<div className="flex flex-row-reverse items-center justify-start gap-x-8 rounded-b-[10px] bg-[#FAFBFC] px-8 py-4">
				<CustomButton
					disabled={addBusinessCardMutation.isLoading}
					type="submit"
					className={`relative h-10 max-w-[103px] flex-1 cursor-pointer duration-200 ease-in-out disabled:cursor-not-allowed ${"bg-[#195388] text-white"}`}
				>
					<p
						style={{
							visibility: addBusinessCardMutation.isLoading
								? "hidden"
								: "visible",
						}}
					>
						Finish
					</p>
					{addBusinessCardMutation.isLoading && (
						<figure className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
							<Loader size={24} />
						</figure>
					)}
				</CustomButton>
				<CustomButton
					disabled={
						addBusinessCardMutation.isLoading ||
						getUserMutation.isLoading
					}
					className="flex w-[103px] cursor-pointer items-center justify-center bg-transparent px-0 font-semibold leading-[22px] text-[#323539] hover:border-transparent"
					onClick={() => {
						getUserMutation.mutate({});
					}}
				>
					Skip for now
				</CustomButton>
			</div>
			<RequestIsLoading isWhite isLoading={getUserMutation.isLoading} />
		</form>
	);
};

export default AddCard;
