import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useResetPassword } from "../hooks/useAuthData";
import { ResetParams, ResetSchema } from "../types/Auth";
import FormInput from "./form/FormInput";
import { LoaderButton } from "./form/LoaderButton";
import Loader from "./Loader/Loader";

const ResetPasswordCard: React.FC = () => {
	const { token } = useParams();

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<ResetSchema>({
		resolver: zodResolver(ResetParams),
		defaultValues: {
			password: "",
			password_confirmation: "",
			token: token,
		},
	});

	const resetPasswordMutation = useResetPassword(setError);

	const onSubmit: SubmitHandler<ResetSchema> = async (data) => {
		try {
			resetPasswordMutation.mutate(data);
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	return (
		<>
			<form
				className="z-10 flex w-full max-w-[488px] flex-col space-y-6 rounded-[10px] bg-white pt-6 shadow-[0_20px_25px_-5px_rgba(16,24,40,0.1),_0_8px_10px_-6px_rgba(16,24,40,0.1)]"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="flex flex-col space-y-2 px-4 md:px-8">
					<h3 className="text-[22px] font-semibold leading-[30px] tracking-[-0.22px] text-[#323539]">
						Reset it in just a few steps.
					</h3>
					<p className="font-normal tracking-[-1%] text-[#858C95]">
						Enter your new password.
					</p>
				</div>
				<div className="flex flex-col space-y-6 px-4 md:px-8">
					<FormInput
						inputType={"password"}
						label="Password"
						minLength={5}
						maxLength={40}
						error={errors.password?.message}
						register={{ ...register("password") }}
						required
					/>
					<FormInput
						inputType={"password"}
						label="Confirm Password"
						minLength={5}
						maxLength={40}
						error={errors.password_confirmation?.message}
						register={{ ...register("password_confirmation") }}
						required
					/>
				</div>
				<div
					className="rounded-b-[10px] bg-[#FAFBFC] px-4 pb-6 pt-4 md:px-8"
					onClick={() => console.log(errors)}
				>
					<LoaderButton
						disabled={resetPasswordMutation.isLoading}
						className={
							"h-10 w-full overflow-hidden bg-[#195388] px-8 text-white hover:border-[#72F4E8] hover:bg-[#72F4E8] hover:text-[#053969] md:h-10 md:w-[104px] mmd:text-[15px]"
						}
						type="submit"
					>
						{resetPasswordMutation.isLoading ? (
							<Loader size={24} />
						) : (
							"Submit"
						)}
					</LoaderButton>
				</div>
			</form>
		</>
	);
};

export default ResetPasswordCard;
