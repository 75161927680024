import { useLocation, Navigate, Outlet } from "react-router";
import { useGetUserInformation } from "../hooks/useAuthData";
import useUserStore from "../store/useUserStore";
import useCustomToast from "./CustomToast";

const RequireAuth: React.FC = () => {
	const customToast = useCustomToast();
	const location = useLocation();
	const user = useUserStore((s) => s.user);
	const onboardingState = useUserStore((s) => s.onboardingState);

	const getUserMutation = useGetUserInformation(() => {
		customToast("An error occured kindly try again later", {
			id: "get-user-information",
			type: "error",
		});
	});

	if (!user) {
		return <Navigate to="/sign-in" state={{ from: location }} replace />;
	}

	if (
		onboardingState === 0 &&
		location.pathname !== "/onboarding/about-business"
	) {
		return (
			<Navigate
				to="/onboarding/about-business"
				state={{ from: location }}
				replace
			/>
		);
	} else if (
		onboardingState === 1 &&
		location.pathname !== "/onboarding/add-location"
	) {
		return (
			<Navigate
				to="/onboarding/add-location"
				state={{ from: location }}
				replace
			/>
		);
	} else if (
		onboardingState === 2 &&
		location.pathname !== "/onboarding/add-payment-method"
	) {
		return (
			<Navigate
				to="/onboarding/add-payment-method"
				state={{ from: location }}
				replace
			/>
		);
	} else if (
		onboardingState === 3 &&
		location.pathname.includes("/onboarding/")
	) {
		getUserMutation.mutate({});
		return (
			<Navigate
				to="/dashboard/waitlist"
				state={{ from: location }}
				replace
			/>
		);
	}

	return <Outlet />;
};

export default RequireAuth;
