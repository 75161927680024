import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
	SubmitHandler,
	useForm,
	FieldValues,
	FieldError,
} from "react-hook-form";
import Loader from "../../components/Loader/Loader";
import {
	useAddBusiness,
	useGetBusinessCategories,
	useUpdateBusiness,
} from "../../hooks/useBusinessData";
import useUserStore from "../../store/useUserStore";
import {
	AddBusinessInfoData,
	AddBusinessInfoSchema,
} from "../../types/Business";
import {
	countryCodes,
	countryOptions,
	findCountry,
	findState,
	ProductTypeOptions,
	statesOptions,
} from "../../utils/constants";
import RequestIsLoading from "../RequestIsLoading";
import { CustomButton } from "../form/CustomButton";
import { CustomSelect } from "../form/CustomSelect";
import FormInput from "../form/FormInput";
import AddressComponent, {
	findCountryByLabel,
	findProvinceByLabel,
} from "./AddressComponent";

const AboutBusiness: React.FC = () => {
	const user = useUserStore((s) => s.user);
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		watch,
		trigger,
		formState: { errors, isValid },
	} = useForm<AddBusinessInfoData | FieldValues>({
		resolver: zodResolver(AddBusinessInfoSchema),
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: {
			name: "",
			address: "",
			country: "",
			state: "",
			city: "",
			phone_number: "",
			postal_code: "",
			business_category_id: "0",
			product_type: "primary",
			logo: null,
		},
	});

	const [countryCode, setCountryCode] = useState("+1");
	const [logoFile, setLogoFile] = useState<null | File>(null);
	const businessCategories = useUserStore((s) => s.businessCategories);

	const businessCategoriesQuery = useGetBusinessCategories(() => {
		//
	});

	const addBusinessMutation = useAddBusiness();
	const updateBusinessMutation = useUpdateBusiness();

	const [provinceOptions, setProvinceOptions] = useState<
		{
			label: string;
			value: string;
		}[]
	>([]);

	const logoRef: any | null = useRef(null);

	const businessCategoryIdValue = useMemo(() => {
		const categoryId = watch("business_category_id");
		return categoryId === "0" ? undefined : categoryId.toString();
	}, [watch("business_category_id")]);

	const handleFileChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		if (event && event.target) {
			const files: FileList | null = event.target.files;
			if (files && files.length > 0) {
				const file = files[0];
				setValue("logo", file);
				setLogoFile(file);
			}
		}
	};

	const handleAddBusiness: SubmitHandler<
		AddBusinessInfoData | FieldValues
	> = (data) => {
		const updatedPhoneNumber =
			countryCode === "+1" && data.phone_number?.length === 0
				? ""
				: countryCode + data.phone_number;

		const updatedCountry = findCountry(data.country || "");
		const updatedState = findState(data.state || "", data.country || "");

		const newFormData = new FormData();
		newFormData.append("name", data.name);
		newFormData.append("address", data.address);
		newFormData.append("country", updatedCountry);
		newFormData.append("state", updatedState);
		newFormData.append("city", data.city ?? "");
		newFormData.append("phone_number", updatedPhoneNumber);
		newFormData.append("product_type", data.product_type);
		localStorage.setItem("product_type", data.product_type);
		newFormData.append(
			"business_category_id",
			data.business_category_id.toString()
		);
		if (data.logo) newFormData.append("logo", data.logo);
		if (user?.business) updateBusinessMutation.mutate(newFormData);
		else addBusinessMutation.mutate(newFormData);
	};

	const updateBusinessCheckThenUpdateFields = () => {
		if (user?.business) {
			setValue("name", user?.business?.name ?? "");
			setValue("address", user?.business.address ?? "");
			const countryValue = findCountryByLabel(
				user?.business?.country ?? ""
			);
			const newProvinceOptions = changeCountry(countryValue);

			const newProvince = findProvinceByLabel(
				newProvinceOptions,
				user?.business.state ?? ""
			);

			updateCountryAndState(
				setValue,
				setProvinceOptions,
				true,
				newProvince,
				countryValue
			);

			setValue(
				"business_category_id",
				user?.business?.business_category_id?.toString() ?? "0"
			);

			setValue("city", user?.business.city ?? "");
			setValue(
				"phone_number",
				user?.business.phone_number?.slice(-10) ?? ""
			);
			setCountryCode(user?.business.phone_number?.slice(0, -10) ?? "+1");
			trigger();
		} else {
			updateCountryAndState(
				setValue,
				setProvinceOptions,
				true,
				"ON",
				"CA"
			);
		}
	};

	useEffect(() => {
		updateBusinessCheckThenUpdateFields();
	}, []);

	return (
		<form
			className="relative flex h-fit max-h-fit w-full max-w-[656px] flex-col space-y-4 rounded-[10px] bg-white shadow-[0px_10px_15px_-3px_rgba(16,24,40,0,1)]"
			encType="multipart/form-data"
			onSubmit={handleSubmit(handleAddBusiness)}
		>
			<p className="px-8 py-3 text-[22px] font-semibold text-[#323539]">
				Tell us about your business
			</p>
			<div className="flex flex-col space-y-7 px-8">
				<FormInput
					inputType="text"
					label="Business Name"
					register={{ ...register("name") }}
					error={errors.name?.message as string}
					minLength={5}
					maxLength={40}
					className="h-10"
					required
				/>
				<div className="flex justify-between space-x-4">
					<div className="flex-1">
						<label className="bt-1.5 mb-1.5 block text-sm font-medium tracking-[-0.1px] text-[#323539]">
							Business Category{" "}
							<span className="text-red-500">*</span>
						</label>
						<div>
							<CustomSelect
								placeholder="Select"
								options={businessCategories}
								value={businessCategoryIdValue}
								onChange={(value) => {
									setValue("business_category_id", value);
								}}
							/>
							{errors.business_category_id?.message && (
								<small className="mt-1.5 text-sm text-red-500">
									{
										errors.business_category_id
											?.message as string
									}
								</small>
							)}
						</div>
					</div>

					<div className="flex-1">
						<label className="bt-1.5 mb-1.5 block text-sm font-medium tracking-[-0.1px] text-[#323539]">
							Product
						</label>
						<CustomSelect
							placeholder="Select"
							options={ProductTypeOptions}
							value={watch("product_type")}
							onChange={(value) => {
								setValue("product_type", value);
							}}
						/>
					</div>
				</div>
				<div className="flex w-full flex-col space-y-1.5 self-center">
					<label
						className="text-sm font-medium tracking-[-0.1px] text-[#323539]"
						// onClick={() => {
						// 	console.log(getValues());
						// }}
					>
						Phone Number
					</label>
					<div className="flex max-w-[293px] items-stretch">
						<div className="flex">
							<CustomSelect
								className="w-fit rounded-r-none border-r-transparent"
								placeholder="+1"
								value={countryCode}
								label="Country Codes"
								options={countryCodes}
								onChange={(value) => {
									setCountryCode(value);
								}}
								labelClassName="px-2"
								itemClassName="px-8"
							/>
						</div>
						<FormInput
							inputType="text"
							className="h-full self-stretch rounded-l-none py-0"
							register={{
								...register("phone_number"),
							}}
							onChange={(e) => {
								setValue("phone_number", e.target.value);
							}}
							required
						/>
					</div>
				</div>
				<div className="flex w-full items-center space-x-7">
					<AddressComponent
						setFormValue={setValue}
						setProvinceOptions={setProvinceOptions}
						formTrigger={trigger}
						errorMessage={errors.address?.message}
					/>
					<div>
						<FormInput
							inputType="text"
							label="Zip Code"
							className="max-w-[179px] flex-1"
							register={{ ...register("postal_code") }}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between space-x-7">
					<FormInput
						inputType="text"
						label="City"
						minLength={5}
						maxLength={40}
						className="h-10 flex-1"
						register={{ ...register("city") }}
					/>

					<div className="flex  flex-1 flex-col space-y-1.5">
						<label className="text-sm font-medium tracking-[-0.1px] text-[#323539]">
							Country
						</label>
						<CustomSelect
							placeholder="Select"
							label="Country"
							options={countryOptions}
							value={watch("country")}
							onChange={(value) => {
								updateCountryAndState(
									setValue,
									setProvinceOptions,
									false,
									undefined,
									value
								);
							}}
						/>
					</div>

					<div className="flex  flex-1 flex-col space-y-1.5">
						<label
							className="text-sm font-medium tracking-[-0.1px] text-[#323539]"
							onClick={() => {
								// console.log(isValid);
							}}
						>
							Province
						</label>
						<CustomSelect
							placeholder="Select"
							label="Province"
							options={provinceOptions}
							value={watch("state")}
							onChange={(value) => {
								updateCountryAndState(
									setValue,
									setProvinceOptions,
									false,
									value,
									getValues("country")
								);
							}}
						/>
					</div>
				</div>
				<div>
					<label className="bt-1.5 mb-1.5 block text-sm font-medium tracking-[-0.1px] text-[#323539]">
						Logo
					</label>
					<div
						className="flex w-full cursor-pointer items-center space-x-2 rounded-md border border-[#E5E5E7] bg-white px-3 py-2 text-[#323539] placeholder:text-[#323539]/50"
						onClick={() => {
							logoRef.current.click();
						}}
					>
						<input
							type="file"
							ref={logoRef}
							onChange={handleFileChange}
							className="hidden"
						/>
						<CustomButton
							className="h-fit w-fit rounded-[4px] bg-[#195388] px-2.5 py-0.5 !font-normal text-white"
							type="button"
							disabled={false}
						>
							Choose File
						</CustomButton>

						<p>{logoFile && logoFile.name}</p>
					</div>
				</div>
			</div>

			<div className="flex items-center justify-end space-x-8 rounded-b-[10px] bg-[#FAFBFC] px-8 py-4">
				<CustomButton
					disabled={
						updateBusinessMutation.isLoading ||
						addBusinessMutation.isLoading
					}
					className={`relative h-10 max-w-[103px] flex-1 rounded-md transition-colors duration-200 ease-in-out 
					${isValid ? "bg-[#195388] text-white" : "bg-[#E5E5E7] text-[#858C95]"}
					`}
					type="submit"
				>
					<p
						style={{
							visibility:
								updateBusinessMutation.isLoading ||
								addBusinessMutation.isLoading
									? "hidden"
									: "visible",
						}}
					>
						Next
					</p>
					{(updateBusinessMutation.isLoading ||
						addBusinessMutation.isLoading) && (
						<figure className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
							<Loader size={24} />
						</figure>
					)}
				</CustomButton>
			</div>
			<RequestIsLoading
				isWhite
				isLoading={businessCategoriesQuery.isLoading}
			/>
		</form>
	);
};

export default AboutBusiness;

export const changeCountry = (country: string) => {
	if (country) {
		return statesOptions(country);
	} else {
		return [];
	}
};

export const updateCountryAndState = (
	setValue: any,
	setProvinceOptions: any,
	isFromAddress: boolean,
	stateValue?: string,
	countryValue?: string
) => {
	if (isFromAddress) {
		setValue("country", countryValue);
		setProvinceOptions(changeCountry(countryValue ?? ""));
		// console.log(12, stateValue);
		setValue("state", stateValue);
	} else {
		if (countryValue) {
			// console.log(11);
			setValue("country", countryValue);
			setProvinceOptions(changeCountry(countryValue));
		}
		if (stateValue) {
			setValue("state", stateValue);
			// console.log(22, stateValue);
		}
	}
};
