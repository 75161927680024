import useAnalyticsStore from "../../../../store/useAnalyticsStore";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../../../ui/tooltip";
import VisitsByLocationsAndRatingsLine from "./VisitsByLocationsAndRatingsLine";

const ReviewRatings: React.FC = () => {
	const analyticsData = useAnalyticsStore((s) => s.analyticsData);

	const ratings = analyticsData?.summaries.customers_rating.per_rating ?? 0;
	const ratingKeys = Object.keys(ratings);
	const sortedRatings = Object.values(ratings).sort();

	const totalCustomers =
		analyticsData?.trends.customers_types_by_location.reduce(
			(acc, item) => acc + item.old_customers + item.new_customers,
			0
		) ?? 0;

	// const maxTotalCount = Math.max(
	// 	...locations.map((item) => item.walk_in_count + item.scheduled_count)
	// );

	return (
		<section className="rounded-lg bg-white p-8 pb-[41px]">
			<div className="mb-6 flex flex-col space-y-2">
				<h3 className="font-manrope text-lg font-bold leading-[16px] tracking-[0.5px] text-[#454D56]">
					Review Ratings
				</h3>
				{analyticsData && (
					<div className="flex items-center justify-between space-x-4 leading-[120%] tracking-[0.5px] text-[#596574]">
						<div className="flex items-center space-x-1">
							<div className="flex items-center space-x-1">
								<p className="text-base font-semibold">
									{(
										(analyticsData.summaries
											.customers_rating
											.total_number_of_feedbacks /
											totalCustomers) *
										100
									).toFixed(2)}
									%
								</p>
								<small className="text-xs">Participation</small>
							</div>
							<TooltipProvider>
								<Tooltip delayDuration={0}>
									<TooltipTrigger asChild>
										<i className="mgc_information_line analytics-information-icon before-text-dark block self-end text-[14px]" />
									</TooltipTrigger>

									<TooltipContent
										side="top"
										sideOffset={10}
										className="max-w-[240px]"
									>
										Percentage of customers who left a
										review. Ex. 10% means 10 out of a total
										100 customers gave a rating
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</div>
						<p className="font-semibold">
							{
								analyticsData.summaries.customers_rating
									.average_rating
							}{" "}
							<span className="text-xs font-normal">Average</span>
						</p>
						<div className="flex items-center space-x-2">
							<div className="flex flex-row items-center space-x-1">
								{Array.from({ length: 4 }).map((_, index) => (
									<StarIcon key={index} />
								))}
							</div>
							<p className="rounded-full bg-[#F7F7F8] px-2 py-1 text-lg font-bold leading-[100%]">
								{
									analyticsData.summaries.customers_rating
										.total_number_of_feedbacks
								}
							</p>
						</div>
					</div>
				)}
			</div>
			<ul className="flex flex-col space-y-2">
				{sortedRatings.map((_, index) => {
					return (
						<VisitsByLocationsAndRatingsLine
							key={index}
							title={
								ratingKeys.length -
								index +
								(index === ratingKeys.length - 1
									? " star"
									: " stars")
							}
							completeTotal={Math.max(...Object.values(ratings))}
							rowTotal={ratings[ratingKeys.length - index]}
							halfTotal={0}
							backgroundColor="#E08B0A"
						/>
					);
				})}
			</ul>
		</section>
	);
};

export default ReviewRatings;

const StarIcon = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_2801_29672)">
				<path
					d="M7.27999 1.91376C7.35335 1.78786 7.45845 1.68341 7.58478 1.61081C7.71112 1.53821 7.85428 1.5 7.99999 1.5C8.1457 1.5 8.28886 1.53821 8.4152 1.61081C8.54153 1.68341 8.64663 1.78786 8.71999 1.91376L10.5833 5.11242L14.202 5.89642C14.3443 5.92735 14.4761 5.99506 14.5841 6.09278C14.6921 6.1905 14.7726 6.31483 14.8176 6.45337C14.8626 6.59191 14.8705 6.73983 14.8405 6.88237C14.8105 7.02491 14.7437 7.1571 14.6467 7.26576L12.18 10.0264L12.5533 13.7098C12.5681 13.8548 12.5445 14.0012 12.4849 14.1342C12.4254 14.2673 12.332 14.3824 12.2141 14.4681C12.0961 14.5538 11.9578 14.6071 11.8128 14.6226C11.6679 14.6381 11.5214 14.6152 11.388 14.5564L7.99999 13.0631L4.61199 14.5564C4.4786 14.6152 4.33212 14.6381 4.18716 14.6226C4.0422 14.6071 3.90385 14.5538 3.7859 14.4681C3.66796 14.3824 3.57457 14.2673 3.51504 14.1342C3.45551 14.0012 3.43193 13.8548 3.44666 13.7098L3.81999 10.0264L1.35332 7.26642C1.25614 7.15777 1.18916 7.02553 1.15907 6.8829C1.12897 6.74027 1.13682 6.59224 1.18182 6.45359C1.22682 6.31494 1.30741 6.19052 1.41553 6.09276C1.52365 5.99499 1.65553 5.92729 1.79799 5.89642L5.41666 5.11242L7.27999 1.91376Z"
					fill="#E08B0A"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2801_29672">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
