import useUserStore from "@store/useUserStore";
import { QueueEntry } from "@type/waitlist/waitlist";
import { StatusOfPatient, StatusOfPatientType } from "@type/wait-list";
import React from "react";
import useWaitListStore from "../../../store/useWaitListStore";

import DisplayPatientCard from "./DisplayPatientCard";
import { cn } from "@utils/functions";

const DisplayPatientCards: React.FC<{
	displayFullName: boolean;
	isFullScreen?: boolean;
}> = ({ displayFullName, isFullScreen = false }) => {
	const user = useUserStore((s) => s.user);
	const patientsObject = useWaitListStore((s) => s.patientsObject);
	const statusMappings = {
		[StatusOfPatient.Pending]: patientsObject["Pending Approval"],
		[StatusOfPatient.Upcoming]: patientsObject["Confirmed"],
		[StatusOfPatient.NextInLine]: patientsObject["Next to Serve"],
		[StatusOfPatient.GettingService]: patientsObject["Serving"],
	};

	const currentPatientStatus = useUserStore((s) => s.currentPatientStatus);

	const filteredStatusMappings =
		currentPatientStatus === StatusOfPatient.AllStatus
			? Object.keys(statusMappings)
			: [currentPatientStatus];

	return (
		<div
			className={cn(
				"flex w-full flex-1 flex-col space-y-2 overflow-auto rounded-lg px-4 py-2.5",
				{ "bg-[#F2F2F2]": !isFullScreen }
			)}
		>
			{Object.values(statusMappings).some((item) => item.length) &&
			user?.business.is_active ? (
				filteredStatusMappings.map((status, keyIndex: number) =>
					statusMappings[status].map(
						(data: QueueEntry, index: number) => (
							<DisplayPatientCard
								key={`${keyIndex}-${index}`}
								status={status as StatusOfPatientType}
								patientDetails={data}
								displayFullName={displayFullName}
							/>
						)
					)
				)
			) : !Object.values(statusMappings).some((item) => item.length) &&
			  user?.business.is_active ? (
				<div className="mx-auto my-auto flex flex-col items-center">
					<i></i>
					<h2 className="text-[28px] font-semibold leading-[28px] text-[#323539]">
						No one has joined today&apos;s list.
					</h2>
				</div>
			) : (
				!(
					Object.values(statusMappings).some((item) => item.length) &&
					user?.business.is_active
				) && (
					<h2 className="text-[28px] font-semibold leading-[28px] text-[#323539]">
						The Waitlist is currently closed.
					</h2>
				)
			)}
		</div>
	);
};

export default DisplayPatientCards;
