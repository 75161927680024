import React from "react";

const HeaderTopItem: React.FC<{
	icon: string;
	title: string | number;
	desc: string;
}> = ({ icon, title, desc }) => {
	return (
		<li className="flex items-center space-x-[5px] rounded-full bg-[#F1F3FC] py-1 pl-1 pr-6">
			<span className="grid size-12 place-content-center rounded-full bg-[#d4ddf4]">
				<i
					className={`${icon} before-text-dark-blue-3 size-8 text-[32px]`}
				/>
			</span>

			<div className="flex flex-col py-[3.5px]">
				<p className="traking-[-1%] text-[18px] font-semibold leading-[28px] text-[#043B6D]">
					{title}
				</p>
				<small className="text-[13px] leading-[100%] tracking-[-0.1px] text-[#858C95]">
					{desc}
				</small>
			</div>
		</li>
	);
};

export default HeaderTopItem;
