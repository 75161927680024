import { zodResolver } from "@hookform/resolvers/zod";
import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import Modal from "@src/components/ui-extended/Modal";
import ManageWaitlistSlice from "@src/store/slices/waitlist/manageWaitlistSlice";
import { waitlistPriority } from "@src/utils/constants";
import {
	ManageWaitlistSchema,
	ManageWaitlistType,
	QueueEntry,
} from "@type/waitlist/waitlist";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { LuX } from "react-icons/lu";
import { PatientStatus } from "../../../types/wait-list";
import { LoaderButton } from "../../form/LoaderButton";
import { Button } from "../../ui/button";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../../ui/card";
import useUserStore from "@src/store/useUserStore";
import { isEqual } from "lodash";
import useCustomToast from "@src/components/CustomToast";

const PatientPriorityModal: React.FC<{
	patientDetails: QueueEntry;
	status: PatientStatus | any;
}> = ({ status, patientDetails }) => {
	const business_id = useUserStore((s) => s.user?.business_id) ?? 0;
	const initialFormState: ManageWaitlistType = {
		waitlist_id: patientDetails.queue_id,
		priority: patientDetails.priority,
	};

	const {
		setError,
		setValue,
		handleSubmit,
		watch,
		reset,
		getValues,
		formState: { errors },
	} = useForm<ManageWaitlistType>({
		resolver: zodResolver(ManageWaitlistSchema),
		defaultValues: {
			waitlist_id: patientDetails.queue_id,
			priority: patientDetails.priority,
		},
	});

	const [isDisabled, setIsDisabled] = React.useState(true);
	const [showPriorityModal, setShowPriorityModal] = useState(false);

	const customToast = useCustomToast();

	const manageWaitlistMutation = ManageWaitlistSlice(
		() => {
			setShowPriorityModal(false);
			customToast(`Patient's priority updated successfully`, {
				id: "manage-patient",
				type: "success",
			});
		},
		() => {
			customToast(`Patient's priority could not be updated`, {
				id: "manage-patient",
				type: "success",
			});
		}
	);

	const onSubmit = async (data: ManageWaitlistType) => {
		try {
			manageWaitlistMutation.mutate({
				data,
				queryParams: { business_id },
			});
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	React.useEffect(() => {
		const subscription = watch(() => {
			setIsDisabled(isEqual(getValues(), initialFormState));
		});
		return () => subscription.unsubscribe();
	}, [watch, initialFormState]);

	return (
		<>
			<Button
				className="flex h-[18px] w-[22px] items-center justify-center rounded-[4px] bg-[#EAEBF0] p-0 hover:bg-[#EAEBF0]"
				onClick={() => {
					setShowPriorityModal(true);
				}}
			>
				<i
					className={`mgc_tag_fill before:!text-[#969696] ${status === PatientStatus.Schedule && "before-text-red"}  text-[14px]`}
				/>
			</Button>
			<Modal
				setIsOpen={() => {
					if (!manageWaitlistMutation.isLoading) {
						reset();
						setShowPriorityModal(!showPriorityModal);
					}
				}}
				isOpen={showPriorityModal}
				className="max-w-[400px] p-0"
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Card className="flex flex-col space-y-8">
						<CardHeader className="flex flex-row justify-between pb-0">
							<div className="flex flex-col">
								<p className="text-sm leading-[24px] tracking-[-1%] text-main-4">
									Patient Name
								</p>
								<CardTitle className="text-[22px] leading-[30px] -tracking-[1%] text-[#323539]">
									{patientDetails.customer.full_name}
								</CardTitle>
							</div>
							<LuX
								color="#858C95"
								size={20}
								className="cursor-pointer"
								onClick={() => {
									if (!manageWaitlistMutation.isLoading) {
										reset();
										setShowPriorityModal(false);
									}
								}}
							/>
						</CardHeader>
						<div className="flex flex-col items-start space-y-2 px-6">
							<CardTitle className="text-[22px] leading-[30px] -tracking-[1%]">
								Select Priority Level
							</CardTitle>
							<p className="text-sm leading-[24px] tracking-[-1%] text-main-4">
								Designate Priority Level for Patient&apos;s
								Attention
							</p>
						</div>
						<CardContent className="space-y-5 pb-0">
							{waitlistPriority.map((item, index) => (
								<div
									key={index}
									className="flex items-center space-x-1.5"
								>
									<CustomCheckbox2
										id={`${index}`}
										isChecked={
											watch("priority") === item.value
										}
										handleCheckboxChange={() => {
											setValue("priority", item.value);
										}}
										className="checked:border-[#A72020] checked:bg-[#A72020]"
									/>
									<label htmlFor="">{item.label}</label>
								</div>
							))}
						</CardContent>
						{errors.root?.message && (
							<p className="!-mt-0 px-8 text-sm tracking-[-0.1px] text-red-500">
								{errors.root?.message}
							</p>
						)}
						<CardFooter className="flex justify-between space-x-4">
							<Button
								variant="outline"
								disabled={manageWaitlistMutation.isLoading}
								className="w-full flex-1 px-8"
								onClick={() => {
									if (!manageWaitlistMutation.isLoading) {
										reset();
										setShowPriorityModal(false);
									}
								}}
							>
								Cancel
							</Button>

							<LoaderButton
								disabled={
									isDisabled ||
									manageWaitlistMutation.isLoading
								}
								loading={manageWaitlistMutation.isLoading}
								loaderSize={20}
								className="flex-1 px-8 text-white"
								type="submit"
							>
								Update
							</LoaderButton>
						</CardFooter>
					</Card>
				</form>
			</Modal>
		</>
	);
};

export default PatientPriorityModal;
