// import CustomSelect from "@src/components/common/CustomSelect";
// import CustomSwitchAlt from "@src/components/global/CustomSwitchAlt";
// import { FaRegFile } from "react-icons/fa";
// import EditDeletePill from "../EditDeletePill";

// interface CustomFieldSettingsProps {}

// const CustomFieldSettings: React.FC<CustomFieldSettingsProps> = () => {
// 	return (
// 		<div className="space-y-8">
// 			<h3 className="text-xl font-semibold">Custom Field Settings</h3>
// 			<div className=" space-y-5">
// 				<div className="space-y-2">
// 					<div className="flex items-center justify-between">
// 						<p className="font-medium">
// 							Add from Existing Custom Fields
// 						</p>
// 						<p className="text-primary underline">
// 							Add New Custom Field
// 						</p>
// 					</div>
// 					<CustomSelect placeholder="Select all that apply" />
// 				</div>

// 				<div className="space-y-1 rounded-xl bg-[#F8F8F8] p-6 text-center">
// 					<FaRegFile size={32} className="mx-auto text-gray-500" />
// 					<p>No custom time slots have been added</p>
// 					<p className="text-gray-400">
// 						Custom time slots will appear here once created.
// 					</p>
// 				</div>

// 				<div className="space-y-3">
// 					<h3 className="text-xl font-semibold">
// 						Selected Time Slots for this Station
// 					</h3>
// 					<EditDeletePill
// 						title={"New Appointment"}
// 						description={"45 mins"}
// 					/>
// 					<EditDeletePill
// 						title={"Report Reading"}
// 						description={"10 mins"}
// 					/>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// export default CustomFieldSettings;

import React from "react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Switch } from "@radix-ui/react-switch";
import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { MdOutlineDragIndicator } from "react-icons/md";
import CustomSwitchAlt from "@src/components/global/CustomSwitchAlt";
import { Input } from "@src/components/ui/input";
import { Slider } from "@src/components/ui/slider";
import FormInput from "@src/components/form/FormInput";
import CustomSelect from "@src/components/common/CustomSelect";
import { Button } from "@src/components/ui/button";
import { GoEye } from "react-icons/go";
import { HiOutlineTrash } from "react-icons/hi";

type Item = {
	id: string;
	title: string;
	checked: boolean;
	quantity: number;
	duration: string;
	isRange?: boolean;
};

const initialItems: Item[] = [
	{
		id: "1",
		title: "Fullname",
		checked: false,
		quantity: 10,
		duration: "Weeks away",
		isRange: false,
	},
	{
		id: "2",
		title: "Email",
		checked: false,
		quantity: 10,
		duration: "Weeks away",
		isRange: false,
	},
	{
		id: "3",
		title: "Phone",
		checked: true,
		quantity: 4,
		duration: "Weeks away",
		isRange: true,
	},
	{
		id: "4",
		title: "Age",
		checked: true,
		quantity: 4,
		duration: "Weeks away",
		isRange: true,
	},
	{
		id: "5",
		title: "Gender",
		checked: true,
		quantity: 4,
		duration: "Weeks away",
		isRange: true,
	},
];

const SortableItem = ({ id, item }: { id: string; item: Item }) => {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			{...attributes}
			className="mb-2 flex overflow-hidden rounded-lg bg-gray-100"
		>
			<div
				{...listeners}
				className="flex h-auto cursor-grab items-center px-3 hover:bg-[#000]/5"
			>
				<MdOutlineDragIndicator className="text-gray-400" size={"24"} />
			</div>
			<div className="flex flex-1 items-center justify-between space-x-4 p-2">
				<CustomCheckbox2
					isChecked={item.checked}
					handleCheckboxChange={() => {}}
					className="checked:border-[#195388] checked:bg-[#195388]"
					id={item.id}
				/>
				<div className="flex-1">
					<Input
						defaultValue={item.title}
						className="h-10 w-full"
						maxLength={40}
					/>
				</div>
				<p className="w-[100px]">Text</p>
				<CustomCheckbox2
					isChecked={item.checked}
					handleCheckboxChange={() => {}}
					className="checked:border-[#195388] checked:bg-[#195388]"
					id={item.id}
				/>
				<p className="w-[100px]">Required</p>
				<div className="flex space-x-0">
					<Button variant="ghost" size="icon-sm">
						<HiOutlineTrash
							size={"24"}
							className="text-[#5373A1]"
						/>
					</Button>
					<Button
						variant="ghost"
						className="text-red-500"
						size="icon-sm"
					>
						<GoEye size={"24"} className="text-primary" />
					</Button>
				</div>
			</div>
		</div>
	);
};

const CustomFieldSettings = () => {
	const [items, setItems] = React.useState(initialItems);

	const handleDragEnd = (event: any) => {
		const { active, over } = event;

		if (active.id !== over.id) {
			setItems((items) => {
				const oldIndex = items.findIndex(
					(item) => item.id === active.id
				);
				const newIndex = items.findIndex((item) => item.id === over.id);
				return arrayMove(items, oldIndex, newIndex);
			});
		}
	};

	return (
		<div>
			<h3 className="text-xl font-semibold">Custom Field Settings</h3>

			<div className="space-y-2">
				<div className="flex items-center justify-between">
					<p className="font-medium">
						Add from Existing Custom Fields
					</p>
					<p className="text-primary underline">
						Add New Custom Field
					</p>
				</div>
				<CustomSelect placeholder="Select all that apply" />
			</div>

			<div className="py-4">
				<h2 className="text-lg">
					Select categories you want notified in order.
				</h2>
				<p className="mb-4">
					You may also select the quantity of people who will be
					notified.
				</p>
				<DndContext
					collisionDetection={closestCenter}
					onDragEnd={handleDragEnd}
				>
					<SortableContext
						items={items}
						strategy={verticalListSortingStrategy}
					>
						{items.map((item) => (
							<SortableItem
								key={item.id}
								id={item.id}
								item={item}
							/>
						))}
					</SortableContext>
				</DndContext>
			</div>
		</div>
	);
};

export default CustomFieldSettings;
