import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { Button } from "@src/components/ui/button";
import { Card } from "@src/components/ui/card";
import { useState } from "react";
import General from "./General";
import ScheduleOptimizer from "./ScheduleOptimizer";
import OpreatingTime from "./OperatingTime";
import CustomTimeSlot from "./CustomTimeSlot";
import CustomFieldSettings from "./CustomFieldSettings";

interface ScheduleSettingsProps {}

const ScheduleSettings: React.FC<ScheduleSettingsProps> = () => {
	const [tabView, setTabView] = useState<
		| "General"
		| "Schedule Optimizer"
		| "Operating hours"
		| "Custom Time Slot"
		| "Custom Intake Field"
		| "Linked Calendar"
	>("General");

	const tabList = [
		"General",
		"Schedule Optimizer",
		"Operating hours",
		"Custom Time Slot",
		"Custom Intake Field",
		"Linkd Calendar",
	];
	return (
		<Card className="p-4 shadow-md">
			<div className="flex items-center justify-between space-x-2">
				<p className="flex-1">Schedule</p>
				<div className="flex items-center space-x-2">
					<p>Allow appointments</p>
					<CustomCheckbox2
						isChecked
						handleCheckboxChange={() => {}}
						className="checked:border-[#195388] checked:bg-[#195388]"
						id={""}
						hasText
					/>
				</div>
				<Button variant={"secondary"} size="icon-sm">
					<i className="mgc_edit_2_line text-[16px] text-primary"></i>
				</Button>
				<Button variant={"secondary"}>
					Settings
					<i className="mgc_settings_4_line ml-2 text-[16px] text-primary"></i>
				</Button>
			</div>

			<div className="space-y-8 pt-5">
				{/* <h3 className="text-xl font-medium">Operating Hours</h3> */}

				<div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
					<nav className="grid gap-1 text-sm text-muted-foreground">
						{tabList.map((list: any, index) => (
							<Button
								key={index}
								type="button"
								variant={"ghost"}
								onClick={() => setTabView(list)}
								className={`flex items-center justify-start rounded-lg px-3 py-2 font-medium text-[#323539]  opacity-100 hover:bg-transparent hover:text-muted-foreground ${tabView === list ? "bg-muted text-primary hover:bg-muted" : "text-foreground"}`}
							>
								{list}
							</Button>
						))}
					</nav>
					<div className="grid max-w-[800px] gap-6">
						<General />
						<ScheduleOptimizer />
						<OpreatingTime />
						<CustomTimeSlot />
						<CustomFieldSettings />
					</div>
				</div>
			</div>
		</Card>
	);
};

export default ScheduleSettings;
