import useStatusSelection from "@hooks/useStatusSelection";
import DisplayTimeAndCustomerCount from "@src/components/Dashboard/display/DisplayTimeAndCustomerCount";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import Header from "@src/layouts/Header/Header";
import { GetQueryListSlice } from "@src/store/slices/waitlist/getWaitlistSlice";
import { StatusOfPatient } from "@type/wait-list";
import { toTitleCase } from "@utils/functions";
import React, { useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { SubmitHandler, useForm } from "react-hook-form";
import useCustomToast from "../../components/CustomToast";
import DisplayPatientCards from "../../components/Dashboard/display/DisplayPatientCards";
import { CustomSelect } from "../../components/form/CustomSelect";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Switch } from "../../components/ui/switch";
import useUserStore from "../../store/useUserStore";
import {
	WaitlistLocationsSelectButton,
	WaitlistLocationsStationsSelectButton,
} from "../waitlist/Waitlist";
import DisplayFullScreen from "./DisplayFullScreen";

const Display: React.FC = () => {
	GetQueryListSlice();

	const [displayFullName, setDisplayFullName] = useState(true);
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [patientType, setPatientType] = useState("both");
	const { register, handleSubmit, getValues } = useForm<{
		displayText: string;
	}>({
		defaultValues: {
			displayText: localStorage.getItem("display-text") || "",
		},
	});
	const customToast = useCustomToast();
	const fullScreenHandle = useFullScreenHandle();

	const onSubmit: SubmitHandler<{ displayText: string }> = (data) => {
		localStorage.setItem("display-text", data.displayText);

		customToast("Updated Display Text", {
			id: "display-text",
			type: "success",
		});
	};

	const handleFullScreenChange = (state: boolean) => {
		setIsFullScreen(state);
	};

	const toggleFullScreen = () => {
		if (isFullScreen) {
			fullScreenHandle.exit();
		} else {
			fullScreenHandle.enter();
		}
	};

	return (
		<>
			<Header
				title={"Today's Waitlist"}
				content={<DisplayTimeAndCustomerCount />}
			/>
			<section className="flex flex-1 flex-col space-y-4">
				<div className="mt-0.5 flex items-center justify-end space-x-4">
					<WaitlistLocationsSelectButton className="h-9 flex-1" />
					<WaitlistLocationsStationsSelectButton className="h-9 max-w-[231px] flex-1" />
					<Select value={patientType} onValueChange={setPatientType}>
						<SelectTrigger className="h-9 w-[186px]">
							<SelectValue
								placeholder="Patient Type"
								className="text-[#858C95]"
							/>
						</SelectTrigger>
						<SelectContent className="">
							{displayPatientType.map((option, mainIndex) => {
								return (
									<SelectGroup key={mainIndex}>
										<SelectItem value={option.value}>
											{option.label}
										</SelectItem>
									</SelectGroup>
								);
							})}
						</SelectContent>
					</Select>
					<StatusSelection />

					<div className="flex items-center space-x-1.5">
						<Switch
							id="airplane-mode"
							checked={displayFullName}
							className="scale-75"
							onCheckedChange={() =>
								setDisplayFullName(!displayFullName)
							}
						/>
						<Label
							htmlFor="airplane-mode"
							className="text-[#323539]"
						>
							Display Full Name
						</Label>
					</div>
					<Button
						className="h-9 w-fit space-x-1.5 px-3"
						onClick={toggleFullScreen}
					>
						<i className="mgc_fullscreen_line before-text-white text-[16px]" />
						<p className="text-[14px] font-semibold leading-[20px] tracking-normal text-white">
							Full Screen
						</p>
					</Button>
				</div>

				<DisplayPatientCards
					displayFullName={displayFullName}
					isFullScreen={isFullScreen}
				/>

				<form
					className="flex max-h-[65px] flex-1 justify-between space-x-2.5 rounded-md border border-[#E5E5E7] px-4 py-3 outline-none"
					onSubmit={handleSubmit(onSubmit)}
				>
					<Input
						type="text"
						className="h-fit flex-1 border-none px-0"
						placeholder="Enter Here"
						{...register("displayText")}
					/>
					<Button
						className="h-8 max-w-[140px] flex-1 self-center rounded-md text-white"
						type="submit"
					>
						Update
					</Button>
				</form>
			</section>
			<FullScreen
				handle={fullScreenHandle}
				onChange={handleFullScreenChange}
			>
				{isFullScreen && (
					<DisplayFullScreen
						displayFullName={displayFullName}
						toggleFullScreen={toggleFullScreen}
						displayText={getValues("displayText")}
					/>
				)}
			</FullScreen>
		</>
	);
};

export default Display;

const StatusSelection: React.FC = () => {
	const currentPatientStatus = useUserStore((s) => s.currentPatientStatus);
	const { handlePatientStatusChange } = useStatusSelection();

	// console.log(currentPatientStatus);
	return (
		<CustomSelect
			className="h-9 w-[186px]"
			placeholder="Status"
			label="Status"
			options={Object.values(StatusOfPatient)
				.slice(0, 5)
				.map((item) => ({
					label: toTitleCase(item.replace(/_/g, " ")),
					value: item,
				}))}
			value={currentPatientStatus}
			onChange={(value) => {
				handlePatientStatusChange(value);
			}}
			labelClassName="px-2"
			itemClassName="px-8"
		/>
	);
};

const displayPatientType = [
	{ label: "Scheduled", value: "scheduled" },
	{ label: "Walk-in", value: "walkin" },
	{ label: "Both", value: "both" },
];
