import { Tabs, TabsContent, TabsList, TabsTrigger } from "@components/ui/tabs";
import React, { useState } from "react";
import { WaitingHours } from "./WaitingHours";
import { Button } from "@components/ui/button";
import { useFormContext } from "react-hook-form";

export default function ScheduleForm() {
	const { setValue } = useFormContext();
	const [tabView, setTabView] = useState<"waitlist" | "schedule">("schedule");

	const handleTabSwitch = (tab: "waitlist" | "schedule") => {
		setTabView(tab);
		if (tab === "waitlist") {
			setValue("is_waitlist", true);
			setValue("is_schedule", false);
		} else {
			setValue("is_schedule", true);
			setValue("is_waitlist", false);
		}
	};

	return (
		<div className="space-y-8">
			<h3 className="text-xl font-medium">Operating Hours</h3>

			<div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
				<nav className="grid gap-1 text-sm text-muted-foreground">
					<Button
						type="button"
						variant={"ghost"}
						onClick={() => handleTabSwitch("waitlist")}
						className={`flex items-center justify-start rounded-lg px-3 py-2 font-medium text-[#323539]  opacity-100 hover:bg-transparent hover:text-muted-foreground ${tabView === "waitlist" ? "bg-muted text-primary hover:bg-muted" : "text-foreground"}`}
					>
						Waitlist
					</Button>
					<Button
						type="button"
						variant={"ghost"}
						onClick={() => handleTabSwitch("schedule")}
						className={`flex items-center justify-start rounded-lg px-3 py-2 font-medium text-[#323539]  opacity-100 hover:bg-transparent hover:text-muted-foreground ${tabView === "schedule" ? "bg-muted text-primary hover:bg-muted" : "text-foreground"}`}
					>
						Schedule
					</Button>
				</nav>
				<div className="grid gap-6">
					<WaitingHours tabView={tabView} />
				</div>
			</div>
		</div>
	);
}
