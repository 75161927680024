import { useState } from "react";
import CustomCalendar from "../../components/calendar/customcalendar/CustomCalendar";
import TabList from "../../components/Dashboard/schedule/TabList";
import AddAppointment from "../../components/modals/AddAppointment";
import ScheduleHeader from "../../components/Dashboard/schedule/ScheduleHeader";
import { EVENTS } from "../../services/mockdata/dummyEventData";
import ScheduleSettings from "../../components/modals/ScheduleSettings";
import { cn } from "../../utils/functions";

const Schedule = () => {
	const [showAppointmentModal, setShowAppointmentModal] = useState(false);
	const [showSettingsModal, setShowSettingsModal] = useState(false);
	const [events, setEvents] = useState(EVENTS);
	const [selectedStations, setSelectedStations] = useState<
		{
			value: string;
			label: string;
		}[]
	>([]);
	const handleRemoveSchedule = (selectedStations, station) => {
		// console.log(setSelectedStations);
		setSelectedStations(
			selectedStations.filter(
				(selectedStation) => selectedStation !== station
			)
		);
	};
	return (
		<div className="flex flex-col gap-y-4">
			<ScheduleHeader
				events={events}
				setEvents={setEvents}
				selectedStations={selectedStations}
				setSelectedStations={setSelectedStations}
				setShowAppointmentModal={setShowAppointmentModal}
				setShowSettingsModal={setShowSettingsModal}
			/>
			<div className="flex gap-6">
				<div className="flex-[0.2]">
					<TabList />
				</div>

				<div
					className={cn("h-[72vh] w-full flex-[0.8]", {
						"h-[65vh]": selectedStations.length > 1,
					})}
				>
					<CustomCalendar
						handleRemoveSchedule={handleRemoveSchedule}
						setSelectedStations={setSelectedStations}
						selectedStations={selectedStations}
						events={events}
						setEvents={setEvents}
						setShowAppointmentModal={setShowAppointmentModal}
					/>
				</div>
			</div>
			<AddAppointment
				show={showAppointmentModal}
				setShow={setShowAppointmentModal}
			/>
			<ScheduleSettings
				show={showSettingsModal}
				setShow={setShowSettingsModal}
			/>
		</div>
	);
};

export default Schedule;
