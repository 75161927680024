import useCustomToast from "@components/CustomToast";
import { APIVersion1ManageWaitlist } from "@src/http/v1";
import useUserStore from "@src/store/useUserStore";
import { ManageWaitlistType } from "@src/types/waitlist/waitlist";
import { toTitleCase } from "@src/utils/functions";
import { Station } from "@type/NewUser";
import { useMutation, useQueryClient } from "react-query";

const ManageWaitlistSlice = (
	onSuccess: (data: Station) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();
	const queryClient = useQueryClient();

	return useMutation<
		Station,
		Error,
		{
			data: ManageWaitlistType;
			queryParams: {
				business_id: number | string;
				selected_location_ids?: number[];
				selected_station_ids?: number[];
				sorted_by_priority?: boolean;
			};
		}
	>(
		({ data, queryParams }) =>
			APIVersion1ManageWaitlist({ data, queryParams }),
		{
			onSuccess: (data) => {
				onSuccess(data);

				queryClient.invalidateQueries("queue-list");
			},
			onError: (error) => {
				onError(error);

				console.error(error);
			},
		}
	);
};

export default ManageWaitlistSlice;
