import React, { useState } from "react";
import { cn } from "@utils/functions";
import { IoIosStar, IoIosStarHalf } from "react-icons/io";
import { FaStarHalf, FaStar } from "react-icons/fa";

interface RatingComponentProps {
	rating: number;
	onChange?: (rating: number) => void;
	readOnly?: boolean;
	precision?: 0.5 | 1;
	className?: string;
}

const RatingComponent: React.FC<RatingComponentProps> = ({
	rating,
	onChange,
	readOnly = false,
	precision = 0.5,
	className,
}) => {
	const [hoverRating, setHoverRating] = useState<number>(0);

	const handleClick = (index: number) => {
		if (readOnly) return;
		const newRating = Math.max(index, 0.5);
		if (onChange) onChange(newRating);
	};

	const handleMouseMove = (
		event: React.MouseEvent<HTMLDivElement>,
		index: number
	) => {
		if (readOnly) return;
		const rect = event.currentTarget.getBoundingClientRect();
		const x = event.clientX - rect.left;
		const percent = x / rect.width;

		let newHoverRating: number;
		if (precision === 0.5) {
			newHoverRating = percent <= 0.5 ? index - 0.5 : index;
		} else {
			newHoverRating = index;
		}
		setHoverRating(newHoverRating);
	};

	const handleMouseLeave = () => {
		if (readOnly) return;
		setHoverRating(0);
	};

	const renderStar = (index: number) => {
		const filled = hoverRating > 0 ? hoverRating >= index : rating >= index;
		const halfFilled =
			hoverRating > 0
				? hoverRating >= index - 0.5
				: rating >= index - 0.5;

		return (
			<div
				key={index}
				className={cn(
					"relative flex h-auto w-auto items-center justify-center space-x-4",
					!readOnly && "cursor-pointer"
				)}
				onClick={() => handleClick(index)}
				onMouseMove={(e) => handleMouseMove(e, index)}
				onMouseLeave={handleMouseLeave}
			>
				<FaStar
					className={cn(
						" h-4 w-4 transition-colors",
						filled ? "text-[#D78100]" : "text-gray-300"
					)}
				/>
				{halfFilled && !filled && (
					<FaStarHalf className=" h-4 w-4 fill-[#D78100] text-[#D78100]" />
				)}
			</div>
		);
	};

	return (
		<div className={cn("flex items-center space-x-1", className)}>
			{[1, 2, 3, 4, 5].map(renderStar)}
			{!readOnly && (
				<span className="ml-2 text-sm text-gray-600">
					{(hoverRating > 0 ? hoverRating : rating).toFixed(1)}
				</span>
			)}
		</div>
	);
};

export default RatingComponent;
