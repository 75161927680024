import CustomSelect from "@src/components/common/CustomSelect";
import CustomSwitchAlt from "@src/components/global/CustomSwitchAlt";
import { FaRegFile } from "react-icons/fa";
import EditDeletePill from "../EditDeletePill";

interface CustomTimeSlotProps {}

const CustomTimeSlot: React.FC<CustomTimeSlotProps> = () => {
	return (
		<div className="space-y-8">
			<h3 className="text-xl font-semibold">Custom Time Slots</h3>
			<div className=" space-y-5">
				<div className="flex items-center border-b pb-5">
					<div className="flex-1">
						<p>Activate Custom Time Slots</p>
						<p className="text-sm text-gray-400">
							These slots allow patients to book specific
							appointments with allocated times
						</p>
					</div>
					<CustomSwitchAlt />
				</div>

				<div className="space-y-2">
					<div className="flex items-center justify-between">
						<p className="font-medium">
							Add from Existing Time Slots
						</p>
						<p className="text-primary underline">
							Add New Time Slots
						</p>
					</div>
					<CustomSelect placeholder="Select all that apply" />
				</div>

				<div className="space-y-1 rounded-xl bg-[#F8F8F8] p-6 text-center">
					<FaRegFile size={32} className="mx-auto text-gray-500" />
					<p>No custom time slots have been added</p>
					<p className="text-gray-400">
						Custom time slots will appear here once created.
					</p>
				</div>

				<div className="space-y-3">
					<h3 className="text-xl font-semibold">
						Selected Time Slots for this Station
					</h3>
					<EditDeletePill
						title={"New Appointment"}
						description={"45 mins"}
					/>
					<EditDeletePill
						title={"Report Reading"}
						description={"10 mins"}
					/>
				</div>
			</div>
		</div>
	);
};

export default CustomTimeSlot;
