import { QueueEntry } from "@type/waitlist/waitlist";
import { OperatingHour, ScheduleData } from "@type/DaySlots";
import { type ClassValue, clsx } from "clsx";
import { Dispatch, SetStateAction } from "react";
import { twMerge } from "tailwind-merge";
import { FieldErrorsImpl, FieldError, Merge } from "react-hook-form";
import { convertColumnTimeToMinutesOrSeconds } from "@utils/constants";
import toast from "react-hot-toast";
import { ErrorResponse } from "@src/types";
type FormError =
	| string
	| FieldError
	| Merge<FieldError, FieldErrorsImpl<any>>
	| undefined;

export const formatStringToMMYY = (e: any): string => {
	const code = e.keyCode;
	const allowedKeys = [8];
	if (allowedKeys.indexOf(code) !== -1) {
		return "";
	}

	e.target.value = e.target.value
		.replace(/^([1-9]\/|[2-9])$/g, "0$1/")
		.replace(/^(0[1-9]|1[0-2])$/g, "$1/")
		.replace(/^([0-1])([3-9])$/g, "0$1/$2")
		.replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, "$1/$2")
		.replace(/^([0]+)\/|[0]+$/g, "0")
		.replace(/[^\d/]|^[/]*$/g, "")
		.replace(/\/\//g, "/");
	return e.target.value;
};

export const formatCreditCard = (string: string) => {
	const inputVal = string.replace(/ /g, "");
	let inputNumbersOnly = inputVal.replace(/\D/g, "");

	if (inputNumbersOnly.length > 20) {
		inputNumbersOnly = inputNumbersOnly.slice(0, 20);
	}

	const splits = inputNumbersOnly.match(/.{1,4}/g);

	let spacedNumber = "";
	if (splits) {
		spacedNumber = splits.join(" ");
	}
	return spacedNumber;
};

export const scheduleBlockOptions = [
	{ value: "5", label: "5" },
	{ value: "10", label: "10" },
	...Array.from({ length: 8 }).map((_, index) => ({
		value: ((index + 1) * 15).toString(),
		label: ((index + 1) * 15).toString(),
	})),
];

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const toTitleCase = (string?: string): string => {
	if (!string || typeof string !== "string") {
		return "";
	}

	return string
		.toLocaleLowerCase()
		.split(" ")
		.map((item) => item[0].toUpperCase() + item.slice(1, item.length))
		.join(" ");
};

export function encodeValue(val: string | object | null) {
	if (typeof val === "string") {
		return val;
	}

	return JSON.stringify(val);
}

// how can val be object or string

export function decodeValue(val: string | object | null) {
	if (typeof val === "string") {
		try {
			return JSON.parse(val);
		} catch (_) {
			/* empty */
		}
	}

	return val;
}

/**
 * Extracts and returns a substring from the last period to the end of the given string.
 * If no period is found in the input string, the function returns the original string.
 *
 * @function
 * @param {string} inputString - The string from which to extract the substring.
 *
 * @returns {string} A substring from the last period to the end of the input string, or the original string if no period is found.
 *
 * Example usage:
 * ```javascript
 * const result = spliceToEndAtFirstPeriod("paul.pdf");
 * console.log(result); // Output: ".pdf"
 * ```
 */

export const getFormatOfFileUsingName: (inputString: string) => string = (
	inputString
) => {
	const periodIndex = inputString.lastIndexOf(".");
	if (periodIndex === -1) {
		// No period found, return original string or handle as needed
		return inputString;
	}
	// Extract from the period to the end of the string
	return inputString.slice(periodIndex);
};

export const handleSelectAllChange = (
	isChecked: boolean,
	status: string,
	setSelectedRows: Dispatch<SetStateAction<number[]>>,
	setSelectAllChecked: Dispatch<SetStateAction<boolean>>,
	statusMappings: Record<string, QueueEntry[]>
) => {
	setSelectAllChecked(isChecked);
	if (isChecked) {
		const allIds =
			(statusMappings[status] as QueueEntry[])?.map((item) => item.id) ||
			[];

		setSelectedRows(allIds);
	} else {
		setSelectedRows([]);
	}
};

export const handleRowCheckboxChange = (
	isChecked: boolean,
	id: number,
	setSelectedRows: Dispatch<SetStateAction<number[]>>,
	noOfPatients: number,
	setSelectAllChecked: Dispatch<SetStateAction<boolean>>
) => {
	setSelectedRows((prevSelectedRows) => {
		if (isChecked) {
			const newSelectedRows = [...prevSelectedRows, id];
			setSelectAllChecked(newSelectedRows.length === noOfPatients);
			return newSelectedRows;
		} else {
			const newSelectedRows = prevSelectedRows.filter(
				(rowId) => rowId !== id
			);
			setSelectAllChecked(newSelectedRows.length === noOfPatients);
			return newSelectedRows;
		}
	});
};

export const convertSchedule = (input: OperatingHour[]): ScheduleData => {
	const daysMapping: { [key: string]: string } = {
		Monday: "monday",
		Tuesday: "tuesday",
		Wednesday: "wednesday",
		Thursday: "thursday",
		Friday: "friday",
		Saturday: "saturday",
		Sunday: "sunday",
	};

	const output: ScheduleData = {};

	input.forEach((daySchedule) => {
		const dayKey = daysMapping[daySchedule.day];
		if (dayKey) {
			output[dayKey] = {
				is_active: Boolean(daySchedule.is_active),
				time_slots: daySchedule.time_slots.map((slot) => ({
					start_time: slot.start_time.slice(0, 5),
					end_time: slot.end_time.slice(0, 5),
					...(slot.is_active !== undefined && {
						is_active: slot.is_active,
					}),
				})),
			};
		}
	});

	return output;
};

/**
 * Converts a time string in "HH:MM:SS" format to a human-readable format.
 * @param timeString - The input time string in "HH:MM:SS" format.
 * @returns A formatted time string.
 */
export const convertTimeFormat = (timeString: string): string => {
	const [hoursStr, minutesStr] = timeString.split(":");
	const hours = parseInt(hoursStr, 10);
	const minutes = parseInt(minutesStr, 10);

	let hrString = "";
	let minString = "";

	if (hours > 0) {
		hrString = `${hours} hr${hours > 1 ? "s" : ""}`;
	}

	if (minutes > 0) {
		minString = `${minutes} min${minutes > 1 ? "s" : ""}`;
	}

	// Construct the combined format
	const combinedFormat = [hrString, minString].filter(Boolean).join(" ");

	return timeString
		? combinedFormat
		: combinedFormat
			? combinedFormat
			: "N/A";
};

/**
 * Extracts the error message from a form error object.
 *
 * @param {FormError} error - The error object which can be a string, FieldError, or Merge<FieldError, FieldErrorsImpl<any>>.
 * @returns {string | null} - The extracted error message as a string, or null if no valid message is found.
 */
export const getErrorMessage = (error: FormError): string | null => {
	if (typeof error === "string") {
		return error;
	} else if (error && typeof error.message === "string") {
		return error.message;
	} else {
		return null;
	}
};

type CustomToastFunction = (message: string, options: any) => void;

/**
 * Displays error messages with a timeout between each message.
 *
 * @param {ErrorResponse | string} errorData - The error data object or a single error message string.
 * @param {CustomToastFunction} customToast - The function to display toast notifications.
 * @param {object} options - Additional options for customization.
 * @param {number} options.delay - The delay in milliseconds between each message (default: 1000ms).
 * @param {string} options.toastIdPrefix - The prefix for the toast ID (default: 'error').
 */
export const displayErrorsWithTimeout = (
	errorData: ErrorResponse | string,
	customToast: CustomToastFunction,
	options: { delay?: number; toastIdPrefix?: string } = {}
) => {
	const { delay = 2000, toastIdPrefix = "error" } = options;

	if (typeof errorData === "string") {
		customToast(errorData, {
			id: `${toastIdPrefix}-single`,
			type: "error",
		});
		return;
	} else if (errorData.errors) {
		Object.entries(errorData.errors).forEach(([field, messages], index) => {
			messages.forEach((message, messageIndex) => {
				const toastDelay =
					(index * messages.length + messageIndex) * delay;
				setTimeout(() => {
					customToast(`${message}`, {
						id: `${toastIdPrefix}-${field}-${messageIndex}`,
						type: "error",
					});
				}, toastDelay);
			});
		});
		return;
	} else if (errorData.message) {
		customToast(errorData.message, {
			id: `${toastIdPrefix}-message`,
			type: "error",
		});
	}
};

/** 
Function to check if the time slots are overlapping
 * @param {OperatingHour[]} time_slots - Array of time slots
 * @param slots.start - Start time of the time slot
 * @param slots.end - End time of the time slot
 * @returns {boolean} - Returns true if the time slots are overlapping
 */

export const isTimeOverlapping = (time_slots: OperatingHour[]): boolean => {
	for (let h = 0; h < time_slots.length; h++) {
		const slots = time_slots[h].time_slots.map((slot) => ({
			start: convertColumnTimeToMinutesOrSeconds(
				slot.start_time,
				"minutes"
			),
			end: convertColumnTimeToMinutesOrSeconds(slot.end_time, "minutes"),
			day_value: time_slots[h].day_value,
		}));
		for (let i = 0; i < slots.length; i++) {
			for (let j = i + 1; j < slots.length; j++) {
				if (
					(slots[i].start < slots[j].end &&
						slots[i].end > slots[j].start) ||
					(slots[j].start < slots[i].end &&
						slots[j].end > slots[i].start)
				) {
					toast.error(
						`${time_slots[h].day}'s times are overlapping`,
						{
							id: "add-location-form",
						}
					);
					return true;
				}
			}
		}
	}

	return false;
};

/**
 * Converts an ISO date string to a formatted string "DD Month YYYY, HH:MM:SS".
 *
 * @param {string} dateString - The ISO date string to be formatted.
 * @returns {string} The formatted date string.
 */
export function formatDate(dateString: string): string {
	const date = new Date(dateString);

	const options: Intl.DateTimeFormatOptions = {
		day: "2-digit",
		month: "long",
		year: "numeric",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: false,
	};

	// Formatting date part
	const datePart = date.toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "long",
		year: "numeric",
	});

	// Formatting time part
	const timePart = date.toLocaleTimeString("en-GB", {
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: false,
	});

	return `${datePart}, ${timePart}`;
}

/**
 * Filters out undefined values from an object.
 *
 * @param {Record<string, any>} obj - The object to filter.
 * @returns {Record<string, any>} A new object with all undefined values removed.
 *
 * @example
 * // Returns { a: 1, b: 2 }
 * filterUndefined({ a: 1, b: 2, c: undefined });
 */
export const filterUndefinedFromObject = (
	obj: Record<string, any>
): Record<string, any> => {
	return Object.fromEntries(
		Object.entries(obj).filter(([_, v]) => v !== undefined)
	);
};

/**
 * Serializes an object of parameters into a query string.
 *
 * @param {Record<string, any>} params - The parameters to serialize.
 * @returns {string} - The serialized query string.
 */
export const serializeParamsToQueryString = (params: Record<string, any>) =>
	Object.keys(params)
		.map((key) => {
			if (Array.isArray(params[key])) {
				return `${key}=[${params[key].toString()}]`;
			}
			return `${key}=${params[key]}`;
		})
		.join("&");

/**
 * Extracts the initials from a given name.
 *
 * @param {string} name - The full name from which to extract initials.
 * @returns {string} The initials extracted from the name.
 *
 * @example
 * // returns 'J'
 * getInitials('John Doe');
 *
 * @example
 * // returns 'A'
 * getInitials('Alice');
 */
export const getInitials = (name: string) => {
	const nameParts = name.trim().split(" ");

	// Extract the first letter of each part and join them
	const initials = nameParts
		.map((part) => part.charAt(0).toUpperCase())
		.slice(0, 1)
		.join("");

	return initials;
};
