import { QueueEntry } from "@type/waitlist/waitlist";
import React, { useRef, useState } from "react";
import { LuX } from "react-icons/lu";
import { usePatientApprove } from "../../../hooks/useLocationData";
import { toTitleCase } from "../../../utils/functions";
import useCustomToast from "../../CustomToast";
import { Overlay } from "../../Overlay";
import { Button } from "../../ui/button";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../../ui/card";

const PatientApprovedModal: React.FC<{
	patientDetails: QueueEntry;
}> = ({ patientDetails }) => {
	const [
		showSwapPatientRequestConfirmationModal,
		setShowSwapPatientRequestConfirmationModal,
	] = useState(false);

	const modalRef = useRef(null);

	const customToast = useCustomToast();

	const { mutate } = usePatientApprove(patientDetails.customer_id, () => {
		customToast("Patient Approved", {
			id: "move-patient-approved",
			duration: 5000,
			type: "success",
			undoText: "Undo",
		});
		setTimeout(() => {
			setShowSwapPatientRequestConfirmationModal(true);
		}, 1100);
	});

	return (
		<>
			<button
				className="flex size-[30px] items-center justify-center rounded-md border border-[#C8322B] bg-[#F5F5F5]"
				onClick={() => {
					customToast("Approving a patient", {
						id: "move-patient-approved",
						type: "loading",
					});
					mutate({});
				}}
			>
				<i className="mgc_check_circle_line before-text-red text-[14px]" />
			</button>
			<Overlay
				show={showSwapPatientRequestConfirmationModal}
				setShow={setShowSwapPatientRequestConfirmationModal}
				modalRef={modalRef}
			>
				<form className="w-full max-w-[400px]">
					<Card className="flex flex-col space-y-4 p-0">
						<div className="space-y-1">
							<CardHeader className="p-4 pb-0">
								<div className="flex justify-between">
									<CardTitle className="text-[22px] leading-[30px] -tracking-[1%] text-[#268E34]">
										Approved
									</CardTitle>
									<LuX
										color="#858C95"
										size={20}
										className="cursor-pointer self-center"
										onClick={() =>
											setShowSwapPatientRequestConfirmationModal(
												false
											)
										}
									/>
								</div>
							</CardHeader>
							<CardContent className="px-4 py-0 text-sm leading-[20px] tracking-[-0.1px] text-main-4">
								{toTitleCase(patientDetails.customer.full_name)}{" "}
								waiting request has been approved. This action
								can&apos;t be undone after 10 seconds.
							</CardContent>
						</div>
						<CardFooter className="p-4 pt-0">
							<Button
								variant="ghost"
								type="submit"
								className="px-0 text-[#005893] hover:!bg-transparent hover:!text-[#005893]"
							>
								Undo Action
							</Button>
						</CardFooter>
					</Card>
				</form>
			</Overlay>
		</>
	);
};

export default PatientApprovedModal;
