import { Dispatch, SetStateAction, useState } from "react";
import ModalWrapper from "../common/ModalWrapper";
import { Calendar } from "../ui/calendar";
import FormInput from "../form/FormInput";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "../ui/button";
// import { Button } from "react-day-picker";
import { Appointment_Time } from "../../services/mockdata/appointmentTime";
import TimeSlot from "../common/TimeSlot";
import { appointmentTypes, mockStations } from "../../utils/constants";
import Select from "react-select";

interface AddAppointmentProps {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
}

type FormFields = z.infer<typeof schema>;
const schema = z.object({
	name: z.string().min(4, { message: "Name must be at least 4 characters" }),
	email: z.string().email({ message: "Invalid email address format" }),
	phone: z
		.string()
		.min(10, { message: "Phone number must be at least 10 characters" })
		.refine((value) => /^[0-9]+$/.test(value), {
			message: "Phone number must only contain numbers 0-9",
		}),
	address: z
		.string()
		.min(10, { message: "Address must be at least 10 characters" }),
	// message: z
	// 	.string()
	// 	.min(10, { message: "Message must be at least 10 characters" }),
	// agreeToTerms: z.boolean().refine((value) => value === true, {
	// 	message: "You must agree to the terms and conditions",
	// }),
});

const AddAppointment = ({ show, setShow }: AddAppointmentProps) => {
	const {
		register,
		handleSubmit,
		setError,
		reset,
		setValue,
		formState: { errors },
	} = useForm<FormFields>({
		resolver: zodResolver(schema),
	});
	const [selectedTime, setSelectedTime] = useState(null);
	const [selectedStationId, setSelectedStationId] = useState(null);
	const [selectedStation, setSelectedStation] = useState<any>(null);
	const handleSelectTimeSlot = (stationId, time) => {
		setSelectedTime(time);
		setSelectedStationId(stationId);
	};

	// console.log(selectedStation);

	return (
		<ModalWrapper show={show} setShow={setShow}>
			<div className="flex max-h-[80vh] w-[80vw] flex-col gap-y-4 bg-white">
				<div className="flex w-full flex-col gap-x-2 gap-y-6 md:flex-row md:divide-x">
					<div className="w-full space-y-2 pr-4">
						<h5 className="text-xl font-[600] ">
							Add Patient Information
						</h5>
						<div className="flex flex-col gap-y-3">
							<FormInput
								inputType={"text"}
								label="Full Name"
								placeholder="Enter Patient's Full Name"
								register={{ ...register("name") }}
								error={errors.name?.message}
								minLength={5}
								maxLength={40}
							/>
							<FormInput
								inputType={"email"}
								label="Email Address"
								placeholder="Enter Patient's Email Address"
								register={{ ...register("email") }}
								error={errors.email?.message}
								minLength={8}
								maxLength={40}
							/>
							<FormInput
								inputType={"phone"}
								label="Phone Number"
								placeholder="Enter Patient's Phone Number"
								register={{ ...register("phone") }}
								error={errors.phone?.message}
								minLength={8}
								maxLength={13}
							/>
							<div className="">
								<Select
									isMulti
									options={mockStations}
									// label="Station"
									placeholder="Available Stations"
									value={selectedStation}
									onChange={(selectedOption) => {
										// Create an action here later so to allow it pull the data and save it on the state of a object so
										setSelectedStation(selectedOption);
									}}
									// register={{ ...register("phone") }}
									// error={errors.phone?.message}
								/>
								<p className="text-[14px] font-[400] text-[#858C95]">
									Maximum of 3 stations can be selected
								</p>
							</div>

							<Select
								// inputType={"select"}
								className="w-full"
								placeholder="Appointment Type"
								// label="Select Appointment Type"
								options={appointmentTypes}
								// register={{ ...register("appointment") }}
							/>
						</div>
					</div>
					<div className="w-full justify-center px-4">
						<h5 className="text-xl font-[600] ">
							Select Appointment Date
						</h5>
						<div className="flex justify-center">
							<Calendar
								className="text-[20px]"
								styles={{
									head_cell: {
										width: "60px",
									},
									cell: {
										width: "60px",
									},
									table: {
										maxWidth: "none",
									},
									day: {
										margin: "auto",
									},
								}}
							/>
						</div>
					</div>
				</div>
				<hr className="mb-2 mt-3 bg-[#E5E5E7]" />
				{selectedStation && selectedStation.length > 0 ? (
					<div className="space-y-4">
						<h5 className="text-xl font-[600] ">
							Select Appointment Time
						</h5>
						{/* Should be a time block for the select type and is fetched based off the selected station  */}
						<div className="grid auto-cols-fr grid-flow-col gap-4 ">
							{selectedStation?.length < 2 ? (
								<div className="">
									<h1 className="pb-3">
										{selectedStation[0]?.value}
									</h1>
									<div className="flex flex-wrap gap-x-2 gap-y-4">
										{Appointment_Time.map(
											(timeslot, index) => (
												<TimeSlot
													selected={
														selectedTime ===
															timeslot.start_time &&
														selectedStationId ===
															selectedStation[0]
																?.id
													}
													onClick={() =>
														handleSelectTimeSlot(
															selectedStation[0]
																.id,
															timeslot.start_time
														)
													}
													className="w-[144px]"
													timeslot={timeslot}
													key={index}
												/>
											)
										)}
									</div>
								</div>
							) : (
								selectedStation.map(
									(station: any, id: number) => (
										<div
											className="flex flex-col items-center bg-[#F6F6F6] p-4 "
											key={`${id} + ${station.value} + "pane"`}
										>
											<h1 className="w-full pb-3 text-center">
												{station.value}
											</h1>
											<div className="flex flex-wrap justify-center gap-x-2 gap-y-4">
												{Appointment_Time.map(
													(timeslot, i) => (
														<TimeSlot
															selected={
																selectedTime ===
																	timeslot.start_time &&
																selectedStationId ===
																	station.id
															}
															onClick={() =>
																handleSelectTimeSlot(
																	station.id,
																	timeslot.start_time
																)
															}
															className="w-[144px]"
															timeslot={timeslot}
															key={i}
														/>
													)
												)}
											</div>
										</div>
									)
								)
							)}
						</div>
					</div>
				) : null}

				<div className="mt-auto flex w-full justify-end gap-4 py-4">
					<Button
						className="bg-transparent text-[#323539] hover:bg-transparent"
						onClick={() => setShow(false)}
					>
						Cancel
					</Button>
					<Button onClick={() => setShow(false)}>
						Add New Appointment
					</Button>
				</div>
			</div>
		</ModalWrapper>
	);
};

export default AddAppointment;
