import React, { useState } from "react";
import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { Button } from "@src/components/ui/button";
import { Input } from "@src/components/ui/input";
import CustomSelect from "@src/components/common/CustomSelect";

interface MemberListProps {}

const MemberList: React.FC<MemberListProps> = () => {
	const [isEdit, setIsEdit] = useState(false);
	return (
		<div className="flex cursor-pointer items-center justify-between rounded-lg border p-2 text-sm hover:bg-slate-50">
			<div className="flex w-[25%] items-center space-x-2 pr-2">
				{/* user_security_line */}
				<i className="mgc_user_security_line text-[16px] text-primary"></i>
				{!isEdit ? <p>Timothy E. Welch</p> : <Input />}
			</div>
			<div className="flex w-[35%] items-center space-x-3 divide-x pr-2">
				{!isEdit ? (
					<p>Super Admin</p>
				) : (
					<div className="flex-1">
						<CustomSelect placeholder="Select" />
					</div>
				)}
				{!isEdit ? (
					<p className="pl-3 text-gray-400">All Station</p>
				) : (
					<div className="flex-1 pl-3">
						<CustomSelect placeholder="Select" />
					</div>
				)}
			</div>
			<div className="w-[15%] pr-2 text-gray-400">
				{!isEdit ? <p>+14 169 388 888</p> : <Input />}
			</div>
			{/* <div className="w-[15%]">Waitlist schedule</div> */}
			<div className="flex w-[25%] items-center justify-end space-x-2">
				{!isEdit ? (
					<p className="text-gray-400">examples@gmail.com</p>
				) : (
					<div>
						<Input />
					</div>
				)}
				{isEdit && (
					<>
						<Button
							variant="secondary"
							size="icon-sm"
							onClick={() => setIsEdit(false)}
						>
							<i className="mgc_close_line text-[16px] text-primary"></i>
						</Button>
						<Button
							variant="default"
							size="icon-sm"
							className="text-white"
							// onClick={() => setShowClearQueueModal(true)}
						>
							<i className="mgc_save_2_line text-[16px]"></i>
						</Button>
					</>
				)}
				{!isEdit && (
					<Button
						variant="secondary"
						size="icon-sm"
						onClick={() => setIsEdit(true)}
					>
						<i className="mgc_edit_2_line text-[16px] text-primary"></i>
					</Button>
				)}
				<Button
					variant="secondary"
					size="icon-sm"
					// onClick={() => setShowClearQueueModal(true)}
				>
					<i className="mgc_delete_2_line text-[16px] text-primary"></i>
				</Button>
			</div>
		</div>
	);
};

export default MemberList;
