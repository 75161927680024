import useCustomToast from "@components/CustomToast";
import { APIVersion1UpdateBusinessSettings } from "@src/http/v1";
import useUserStore from "@src/store/useUserStore";
import { WaitlistGeneralSettings } from "@src/types/waitlist/waitlist";
import { Station } from "@type/NewUser";
import { useMutation, useQueryClient } from "react-query";

const BusinessSettingsSlice = (
	onSuccess: (data: Station) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();
	const queryClient = useQueryClient();
	const user = useUserStore((s) => s.user);

	return useMutation<Station, Error, { data: WaitlistGeneralSettings }>(
		({ data }) =>
			APIVersion1UpdateBusinessSettings(data, user?.business_id),
		{
			onSuccess: (data) => {
				onSuccess(data);
				setTimeout(() => {
					customToast("Settings updated successfully", {
						id: "settings-update",
						type: "success",
					});
				}, 750);
			},
			onError: (error) => {
				onError(error);
				customToast("Settings could not be updated", {
					id: "settings-update",
					type: "error",
				});

				console.error(error);
			},
		}
	);
};

export default BusinessSettingsSlice;
