import { zodResolver } from "@hookform/resolvers/zod";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useLoginUser } from "../../hooks/useAuthData";
import useUserStore from "../../store/useUserStore";
import { UserLoginParams, UserLoginSchema } from "../../types/Auth";
import useCustomToast from "../CustomToast";
import Loader from "../Loader/Loader";
import RequestIsLoading from "../RequestIsLoading";
import { CustomButton } from "../form/CustomButton";
import FormInput from "../form/FormInput";
import { GoogleLoginSlice } from "@src/store/slices/auth/googleLoginSlice";

const SignInCard: React.FC = () => {
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<UserLoginSchema>({
		resolver: zodResolver(UserLoginParams),
	});

	const loginUserMutaion = useLoginUser(undefined, undefined, setError);
	const reset = useUserStore((s) => s.reset);
	const customToast = useCustomToast();

	const onSubmit = async (data) => {
		try {
			loginUserMutaion.mutate(data);
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	const googleLoginUserMutation = GoogleLoginSlice();

	const responseMessage = (response: CredentialResponse) => {
		googleLoginUserMutation.mutate({
			token: response.credential ?? "",
		});
	};

	const errorMessage = () => {
		customToast("Google sign in failed", {
			id: "google-signin",
			type: "success",
		});
	};

	useEffect(() => {
		reset();
	}, []);

	return (
		<form
			className="relative z-10 flex w-full max-w-[488px] flex-col space-y-6 overflow-hidden rounded-[10px] bg-white pt-6 shadow-[0_20px_25px_-5px_rgba(16,24,40,0.1),_0_8px_10px_-6px_rgba(16,24,40,0.1)]"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="flex flex-col space-y-2 px-4 md:px-8">
				<h3 className="text-[22px] font-semibold leading-[30px] tracking-[-0.22px] text-[#323539]">
					Sign in to my account
				</h3>
				<Link
					to={"/sign-up"}
					className="font-normal tracking-[-1%] text-[#858C95]"
				>
					Don&apos;t have an account?{" "}
					<span className="text-[#195388]">Sign up</span>
				</Link>
			</div>
			<div className="flex flex-col space-y-6 px-4 md:px-8">
				<FormInput
					inputType={"text"}
					label="Email Address"
					minLength={5}
					maxLength={40}
					error={errors.email?.message}
					register={{ ...register("email") }}
					required
				/>
				<div className="flex flex-col space-y-1.5">
					<FormInput
						inputType={"password"}
						label="Password"
						maxLength={40}
						register={{ ...register("password") }}
						error={
							errors.password?.message !==
							"Password must be at least 8 characters"
								? errors.password?.message
								: null
						}
						required
					/>
				</div>
				{/* {errors.root?.message && (
					<p className="text-sm tracking-[-0.1px] text-red-500">
						{errors.root?.message}
					</p>
				)} */}
			</div>

			<Link
				to={"/forgot-password"}
				className="px-4 font-normal tracking-[-1%] underline md:px-8"
			>
				Forgot Password?
			</Link>
			<div className="flex items-center justify-between rounded-b-[10px] bg-[#FAFBFC] px-4 pb-6 pt-4 md:px-8 mmd:flex-col mmd:space-y-6">
				<CustomButton
					disabled={loginUserMutaion.isLoading}
					className={
						"h-[46px] w-full bg-[#195388] text-white hover:border-[#72F4E8] hover:bg-[#72F4E8] hover:text-[#053969] md:h-10 md:w-[104px] mmd:text-[15px]"
					}
					type="submit"
				>
					{loginUserMutaion.isLoading ? (
						<div className="">
							<Loader size={24} />
						</div>
					) : (
						"Sign in"
					)}
				</CustomButton>
				<div className="flex flex-col justify-end space-y-2 mmd:w-full mmd:flex-col mmd:space-y-2">
					{/* <p className="text-sm tracking-[-0.1px] text-[#858C95]">
						Or Sign up with
					</p> */}
					<div>
						<GoogleLogin
							onSuccess={responseMessage}
							onError={errorMessage}
						/>
					</div>
				</div>
			</div>
			<RequestIsLoading
				isWhite
				isLoading={googleLoginUserMutation.isLoading}
			/>
		</form>
	);
};

export default SignInCard;
