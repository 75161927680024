import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import {
	APIVersion1GetLocations,
	APIVersion1AddBusinessLocation,
	APIVersion1GetSingleBusinessLocationDetail,
	APIVersion1UpdateBusinessLocation,
	APIVersion1UpdateBusinessLocationStation,
	APIVersion1DeleteLocation,
	APIVersion1GetBusinessLocationStations,
	APIVersion1AddBusinessLocationStation,
	APIVersion1DeleteStation,
	APIVersion1AddBusinessWorkingHours,
} from "@src/http/v1";
import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";

import useLocationsStore from "@src/store/useLocationsStore";
import {
	EditLocationInfoData,
	AddLocationData,
	AddBusinessLocationResponseType,
	LocationResponse,
	StationsArrayProps,
} from "@src/types/Location";
import { EditStationType, AddStationType } from "@src/types/Station";
import useCustomToast from "@src/components/CustomToast";
import { displayErrorsWithTimeout } from "@src/utils/functions";
import { ErrorResponse } from "@src/types";

export const GetLocationsSlice = (
	onError: (error: Error) => void = () => {}
) => {
	const locations = useLocationsStore((s) => s.locations);
	const setLocationsData = useLocationsStore((s) => s.setLocations);

	return useQuery<{ status: boolean; data: AddLocationData }, Error>({
		queryKey: ["get-locations", locations],
		queryFn: APIVersion1GetLocations,
		onSuccess: (response) => {
			if (Array.isArray(response.data)) {
				setLocationsData(response.data);
			} else {
				console.error(
					"Unexpected data structure from APIVersion1GetLocations"
				);
			}
		},
		onError,
	} as UseQueryOptions<{ status: boolean; data: AddLocationData }, Error>);
};

export const GetLocationDetailsSlice = (
	id: number,
	onError: (error: Error) => void = () => {}
) => {
	return useQuery<{ status: boolean; data: LocationResponse }, Error>(
		["locationDetails", id],
		() => APIVersion1GetSingleBusinessLocationDetail(id),
		{
			onSuccess: () => {},
			onError,
		}
	);
};

export const GetLocationStationsSlice = (
	id: number,
	onError: (error: Error) => void = () => {}
) => {
	return useQuery<{ status: boolean; data: StationsArrayProps }, Error>(
		["get-station", id],
		() => APIVersion1GetBusinessLocationStations(id),
		{
			onSuccess: () => {},
			onError,
		}
	);
};

export const AddBusinessLocationSlice = (
	onSuccess?: (data: AddBusinessLocationResponseType) => void,
	onError?: (error: AxiosError) => void
): UseMutationResult<AddBusinessLocationResponseType, AxiosError, FormData> => {
	const queryClient = useQueryClient();
	const customToast = useCustomToast();

	return useMutation<
		AddBusinessLocationResponseType,
		AxiosError<ErrorResponse>,
		FormData
	>(APIVersion1AddBusinessLocation, {
		onSuccess: (data) => {
			queryClient.invalidateQueries("get-locations");
			onSuccess?.(data);
			toast.success(
				<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
					<div className="flex items-center space-x-5">
						<p>{data?.message || "Location added successfully"}</p>
					</div>
					<button
						className="h-fit p-2.5"
						onClick={() => toast.dismiss("add-location")}
					>
						<i className="mgc_close_line" />
					</button>
				</div>,
				{
					id: "add-location",
					duration: 5000,
				}
			);
		},
		onError: (error: AxiosError<ErrorResponse>) => {
			if (error.response?.data) {
				displayErrorsWithTimeout(error.response.data, customToast, {
					toastIdPrefix: "add-locations",
				});
			} else {
				displayErrorsWithTimeout(
					"An unexpected error occurred. Please try again later",
					customToast,
					{
						toastIdPrefix: "add-locations",
					}
				);
			}
			onError?.(error);
		},
	});
};

export const UpdateLocationOperatingHoursSlice = (
	onSuccess?: (data: any) => void,
	onError?: (error: AxiosError) => void
): UseMutationResult<any, AxiosError, any> => {
	const queryClient = useQueryClient();
	const customToast = useCustomToast();

	return useMutation<any, AxiosError<ErrorResponse>, any>(
		APIVersion1AddBusinessWorkingHours,
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("get-locations");
				onSuccess?.(data);
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>
								{data?.message ||
									"Working hours updated successfully"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() =>
								toast.dismiss("update-working-hours")
							}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "update-working-hours",
						duration: 5000,
					}
				);
			},
			onError: (error: AxiosError<ErrorResponse>) => {
				if (error.response?.data) {
					displayErrorsWithTimeout(error.response.data, customToast, {
						toastIdPrefix: "update-working-hourss",
					});
				} else {
					displayErrorsWithTimeout(
						"An unexpected error occurred. Please try again later",
						customToast,
						{
							toastIdPrefix: "update-working-hourss",
						}
					);
				}
				onError?.(error);
			},
		}
	);
};

export const DeleteLocationSlice = (
	locationId: string | number,
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const queryClient = useQueryClient();
	return useMutation<any, Error, object>(
		() => APIVersion1DeleteLocation(locationId),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("get-locations");
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>Location deleted successfully</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("delete-location")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "delete-location",
						duration: 10000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								Location could not be deleted
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("delete-location")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "delete-location",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};

export const UpdateBusinessLocationSlice = (
	onSuccess?: (data: AddBusinessLocationResponseType) => void,
	onError?: (error: AxiosError) => void
): UseMutationResult<
	AddBusinessLocationResponseType,
	AxiosError,
	{ id: number; data: EditLocationInfoData }
> => {
	const customToast = useCustomToast();
	const queryClient = useQueryClient();

	return useMutation<
		AddBusinessLocationResponseType,
		AxiosError<ErrorResponse>,
		{ id: number; data: EditLocationInfoData }
	>(({ id, data }) => APIVersion1UpdateBusinessLocation(id, data), {
		onSuccess: (data) => {
			queryClient.invalidateQueries("get-locations");
			onSuccess?.(data);
			toast.success(
				<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
					<div className="flex items-center space-x-5">
						<p>
							{data?.message || "Location updated successfully"}
						</p>
					</div>
					<button
						className="h-fit p-2.5"
						onClick={() => toast.dismiss("update-location")}
					>
						<i className="mgc_close_line" />
					</button>
				</div>,
				{
					id: "update-location",
					duration: 5000,
				}
			);
		},
		onError: (error: AxiosError<ErrorResponse>) => {
			if (error.response?.data) {
				displayErrorsWithTimeout(error.response.data, customToast, {
					toastIdPrefix: "update-location",
				});
			} else {
				displayErrorsWithTimeout(
					"An unexpected error occurred. Please try again later",
					customToast,
					{
						toastIdPrefix: "update-location",
					}
				);
			}
			if (onError) onError(error);
		},
	});
};

export const UpdateBusinessLocationStationSlice = (
	onSuccess?: (data: any) => void,
	onError?: (error: AxiosError) => void
): UseMutationResult<
	any,
	AxiosError,
	{ locationId: number; stationId: number; data: EditStationType }
> => {
	const customToast = useCustomToast();
	const queryClient = useQueryClient();

	return useMutation<
		any,
		AxiosError<ErrorResponse>,
		{ locationId: number; stationId: number; data: EditStationType }
	>(
		({ locationId, stationId, data }) =>
			APIVersion1UpdateBusinessLocationStation(
				locationId,
				stationId,
				data
			),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("get-station");
				onSuccess?.(data);
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>
								{data?.message ||
									"Location updated successfully"}
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("update-station")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "update-station",
						duration: 5000,
					}
				);
			},
			onError: (error: AxiosError<ErrorResponse>) => {
				if (error.response?.data) {
					displayErrorsWithTimeout(error.response.data, customToast, {
						toastIdPrefix: "update-station",
					});
				} else {
					displayErrorsWithTimeout(
						"An unexpected error occurred. Please try again later",
						customToast,
						{
							toastIdPrefix: "update-station",
						}
					);
				}
				if (onError) onError(error);
			},
		}
	);
};

export const AddBusinessLocationStationSlice = (
	onSuccess?: (data: AddBusinessLocationResponseType) => void,
	onError?: (error: AxiosError) => void
): UseMutationResult<any, AxiosError, { id: number; data: AddStationType }> => {
	const queryClient = useQueryClient();
	const customToast = useCustomToast();

	return useMutation<
		any,
		AxiosError<ErrorResponse>,
		{ id: number; data: AddStationType }
	>(({ id, data }) => APIVersion1AddBusinessLocationStation(id, data), {
		onSuccess: (data) => {
			queryClient.invalidateQueries("get-station");
			queryClient.invalidateQueries("get-locations");
			onSuccess?.(data);
			toast.success(
				<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
					<div className="flex items-center space-x-5">
						<p>{data?.message || "Location added successfully"}</p>
					</div>
					<button
						className="h-fit p-2.5"
						onClick={() => toast.dismiss("add-location")}
					>
						<i className="mgc_close_line" />
					</button>
				</div>,
				{
					id: "add-location",
					duration: 5000,
				}
			);
		},
		onError: (error: AxiosError<ErrorResponse>) => {
			if (error.response?.data) {
				displayErrorsWithTimeout(error.response.data, customToast, {
					toastIdPrefix: "add-locations",
				});
			} else {
				displayErrorsWithTimeout(
					"An unexpected error occurred. Please try again later",
					customToast,
					{
						toastIdPrefix: "add-locations",
					}
				);
			}
			onError?.(error);
		},
	});
};

export const DeleteStationSlice = (
	locationId: number,
	stationId: number,
	onSuccess: (data: any) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const queryClient = useQueryClient();
	return useMutation<any, Error, object>(
		() => APIVersion1DeleteStation(locationId, stationId),
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries("get-station");
				toast.success(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p>Station deleted successfully</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("delete-location")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "delete-station",
						duration: 10000,
					}
				);
				setTimeout(() => {
					onSuccess(data);
				}, 500);
			},
			onError: (error) => {
				onError(error);
				toast.error(
					<div className="-my-2 mr-[-20px] flex items-center space-x-2.5">
						<div className="flex items-center space-x-5">
							<p className="text-lg font-medium text-[#E33B32]">
								Location could not be deleted
							</p>
						</div>
						<button
							className="h-fit p-2.5"
							onClick={() => toast.dismiss("delete-station")}
						>
							<i className="mgc_close_line" />
						</button>
					</div>,
					{
						id: "delete-station",
						duration: 10000,
					}
				);
				console.error(error);
			},
		}
	);
};
