import moment from "moment";

// Types
export interface Appointment {
	id: number;
	status: "Pending" | "Confirmed" | "Follow Up";
	time: any;
	location: string;
	resource: string;
	address: string;
}
export type Blockout = { id: number; name: string };

export type EventItem = {
	title?: string;
	start: Date;
	end: Date;
	data?: { appointment?: Appointment; blockout?: Blockout };
	isDraggable?: boolean;
};

// Data
export const stations = [
	"Dr Kurt Bates",
	"Dr John Doe",
	"Dr Andew",
	"Dr Kimberly",
	"Lab tests",
	"Radiology",
	"Dr Shiela",
];

export const EVENTS: EventItem[] = [
	{
		start: moment("2024-03-29T10:00:00").toDate(),
		end: moment("2024-03-29T10:15:00").toDate(),
		title: "Hello",
		data: {
			appointment: {
				id: 1,
				status: "Pending",
				location: "New York",
				resource: "Dr Kurt Bates",
				time: "10:00 AM - 11:00 AM",
				address:
					"Building 5\nStreet 44\nNear Express Highway\nNew York",
			},
		},
		// isDraggable: true,
	},
	{
		start: moment("2024-03-29T10:16:00").toDate(),
		end: moment("2024-03-29T10:30:00").toDate(),
		title: "Hello",
		data: {
			appointment: {
				id: 2,
				status: "Pending",
				location: "Washington",
				resource: "Dr John Doe",
				address: "Block 1\nSStreet 32\nLong Island\nNew York",
				time: "10:00 AM - 11:00 AM",
			},
		},
		// isDraggable: false,
	},
	{
		start: moment("2024-03-29T17:08:00").toDate(),
		end: moment("2024-03-29T17:20:00").toDate(),
		title: "Hello",
		data: {
			appointment: {
				id: 3,
				status: "Confirmed",
				location: "Washington",
				resource: "Dr Andrew",
				address: "Block 1\nSStreet 32\nLong Island\nNew York",
				time: "10:00 AM - 11:00 AM",
			},
		},
		// isDraggable: false,
	},

	{
		start: moment("2024-03-29T20:31:00").toDate(),
		end: moment("2024-03-29T21:00:00").toDate(),
		title: "Hello",
		data: {
			appointment: {
				id: 4,
				status: "Confirmed",
				location: "Washington",
				resource: "Dr Kimberly",
				address: "Block 1\nSStreet 32\nLong Island\nNew York",
				time: "10:00 AM - 11:00 AM",
			},
		},
		// isDraggable: false,
	},
	{
		start: moment("2024-03-30T20:31:00").toDate(),
		end: moment("2024-03-30T21:00:00").toDate(),
		title: "Hello",
		data: {
			appointment: {
				id: 4,
				status: "Confirmed",
				location: "Washington",
				resource: "Radiology",
				address: "Block 1\nSStreet 32\nLong Island\nNew York",
				time: "10:00 AM - 11:00 AM",
			},
		},
		// isDraggable: false,
	},
	{
		start: moment("2024-03-19T20:31:00").toDate(),
		end: moment("2024-03-19T21:00:00").toDate(),
		title: "Hello",
		data: {
			appointment: {
				id: 4,
				status: "Confirmed",
				location: "Washington",
				resource: "Dr David",
				address: "Block 1\nSStreet 32\nLong Island\nNew York",
				time: "10:00 AM - 11:00 AM",
			},
		},
		// isDraggable: false,
	},
	// Blockouts are meant to be holidays or other non-appointment events
	// {
	// 	start: moment("2024-03-18T11:00:00").toDate(),
	// 	end: moment("2024-03-18T11:00:00").toDate(),
	// 	data: {
	// 		blockout: {
	// 			id: 1,
	// 			name: "Christmas Holidays",
	// 		},
	// 	},
	// },
];
