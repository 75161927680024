import { Badge } from "@src/components/ui/badge";
import { Button } from "@src/components/ui/button";
import { Card } from "@src/components/ui/card";
import { Switch } from "@src/components/ui/switch";
import React from "react";
import { BsInfoCircle } from "react-icons/bs";
import { IoMdStopwatch } from "react-icons/io";
import { CgTikcode } from "react-icons/cg";
import { LuChevronRight, LuTrash2 } from "react-icons/lu";
import { TbListDetails } from "react-icons/tb";
import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { TiSpanner } from "react-icons/ti";
import { StationsProps } from "@src/types/Location";
import { formatDate, convertTimeFormat } from "@src/utils/functions";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router";

interface Props {
	stationData: StationsProps;
	watchLocations: any;
	index: number;
	handleSwitchChange: (
		locationId: number,
		stationId: number,
		value: boolean,
		index: number
	) => Promise<void>;
	control: any;
	handleSelectStation: (station: StationsProps) => void;
	handleDeleteStationModal: (station: StationsProps) => void;
	setShowInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
	stationIds: number[];
	setStationIds: React.Dispatch<React.SetStateAction<number[]>>;
}

export const DetailCard: React.FC<Props> = ({
	stationData,
	watchLocations,
	index,
	handleSwitchChange,
	control,
	handleSelectStation,
	setShowInfoModal,
	stationIds,
	setStationIds,
	handleDeleteStationModal,
}) => {
	const navigate = useNavigate();
	const toggleStationModal = (station: StationsProps) => {
		handleSelectStation(station);
		setShowInfoModal(true);
	};

	return (
		<Card className="space-y-4 divide-y p-4 shadow-lg">
			<div className="space-y-4">
				<div className="flex justify-between space-x-2">
					<CustomCheckbox2
						isChecked={stationIds.includes(stationData.id)}
						handleCheckboxChange={() => {
							setStationIds((prev) => {
								if (prev.includes(stationData.id)) {
									return prev.filter(
										(id) => id !== stationData.id
									);
								} else {
									return [...prev, stationData.id];
								}
							});
						}}
						className="checked:border-[#195388] checked:bg-[#195388]"
						id={""}
					/>
					<div className="flex-1">
						<p className="text-sm font-semibold">
							{stationData.name}
						</p>
						<p className="text-xs text-gray-400">
							Last active : {formatDate(stationData.updated_at)}
						</p>
					</div>
					<Button
						type="button"
						variant="secondary"
						size="icon-sm"
						onClick={() => toggleStationModal(stationData)}
					>
						<BsInfoCircle className="text-[14px]" size={12} />
					</Button>
				</div>
				<Badge className="bg-slate-100 text-sm" variant={"secondary"}>
					<IoMdStopwatch className="mr-2" size={14} />
					<span className="mr-1 text-xs font-normal">
						Avg. Wait Time
					</span>{" "}
					{convertTimeFormat(stationData.average_waiting_time || "")}
				</Badge>
			</div>
			<div className="space-y-4 pt-4">
				<div className="flex items-center justify-between">
					<p className="text-sm font-semibold">Wait List</p>
					<Button
						variant="secondary"
						size="icon-sm"
						// onClick={() => setShowClearQueueModal(true)}
					>
						<CgTikcode className="text-[14px]" size={18} />
					</Button>
				</div>
				<div className="flex items-center justify-between">
					<Badge
						className="bg-slate-100 px-2 text-xs"
						variant={"secondary"}
					>
						<TbListDetails className="mr-2" size={14} />
						<span className="font-semibold">
							{
								stationData?.settings?.settings
									?.total_waitlists_count
							}
						</span>
					</Badge>
					<div className="flex items-center space-x-2">
						<p className="text-xs">Wait List Active</p>
						<Controller
							name={`stations.${stationData?.is_queue_active}`}
							control={control}
							render={({ field }) => (
								<Switch
									ref={field.ref}
									checked={Boolean(
										watchLocations[index]?.is_queue_active
									)}
									className="scale-[0.70]"
									onCheckedChange={(value) =>
										handleSwitchChange(
											stationData?.location_id,
											stationData?.id,
											value,
											index
										)
									}
								/>
							)}
						/>

						<span className="text-sm">No</span>
					</div>
				</div>
			</div>
			<div className="space-y-4 pt-4">
				<div className="flex items-center justify-between">
					<div className="flex items-center">
						<p className="mr-1 text-sm font-semibold">Schedule</p>
						<TiSpanner color="#EEA23E" size={12} />
					</div>
					<Button
						variant="secondary"
						size="icon-sm"
						// onClick={() => setShowClearQueueModal(true)}
					>
						<CgTikcode className="text-[14px]" size={18} />
					</Button>
				</div>
				<div className="flex items-center justify-between">
					<Badge
						className="bg-slate-100 px-1 text-sm"
						variant={"secondary"}
					>
						<IoMdStopwatch className="mr-2" size={14} />
						<span className="mr-1 font-semibold">12</span>
					</Badge>
					<div className="flex items-center space-x-2">
						<p className="text-xs">Allow Appointments</p>
						<Switch
							// defaultChecked={location.is_active === 1}
							className="scale-[0.70]"
						/>
						<span className="text-sm">
							{/* {location.is_active === 1 ? "Yes" : "No"} */}
							No
						</span>
					</div>
				</div>
			</div>
			<div className="space-y-4 pt-4">
				<div className="flex items-center justify-between">
					<Button
						variant="secondary"
						size="icon-sm"
						type="button"
						onClick={() => handleDeleteStationModal(stationData)}
					>
						<LuTrash2 className="text-[14px]" size={14} />
					</Button>
					<Button
						variant="secondary"
						type="button"
						// size="icon-sm"
						onClick={() =>
							navigate(
								"/dashboard/locations/details/station-detail"
							)
						}
					>
						<div className="flex items-center space-x-4">
							Details
							<LuChevronRight className="text-[14px]" size={20} />
						</div>
					</Button>
				</div>
			</div>
		</Card>
	);
};
