import { zodResolver } from "@hookform/resolvers/zod";
import { QueueEntry } from "@type/waitlist/waitlist";
import { NumberOrdinal } from "ordinalify";
import React, { useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LuX } from "react-icons/lu";
import { z } from "zod";
import swapIcon from "../../../../public/assets/images/swap.svg";
import { useChangePatientStation } from "../../../hooks/useLocationData";
import Overlay from "../../Overlay";
import { CustomSelect } from "../../form/CustomSelect";
import { LoaderButton } from "../../form/LoaderButton";
import { Button } from "../../ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../../ui/card";
import { Label } from "../../ui/label";

const FormSchema = z.object({
	order: z.string().refine((value) => !!value, {
		message: "Current order is required",
	}),
	station_id: z.string().refine((value) => !!value, {
		message: "Station is required",
	}),
});

const ChangePosition: React.FC<{
	patientDetails: QueueEntry;
}> = ({ patientDetails }) => {
	const {
		handleSubmit,
		setError,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		// defaultValues: {
		// 	order: patients.filter(
		// 		(item) => item.order !== patientDetails.order
		// 	)[0].order,
		// 	station_id: patientDetails.station,
		// },
	});

	const [changePatientOrderModal, setChangePatientOrderModal] =
		useState(false);
	const modalRef = useRef(null);

	const { mutate, isLoading } = useChangePatientStation(
		patientDetails.customer_id,
		() => {
			setChangePatientOrderModal(false);
		}
	);

	const onSubmit: SubmitHandler<z.infer<typeof FormSchema>> = async (
		data
	) => {
		try {
			mutate(data);
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	return (
		<>
			<Button
				className="size-[30px] rounded-sm bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
				onClick={() => {
					setChangePatientOrderModal(true);
				}}
			>
				<img src={swapIcon} alt="transfer icon" />
			</Button>
			<Overlay
				show={changePatientOrderModal}
				setShow={setChangePatientOrderModal}
				modalRef={modalRef}
				isPersistent={isLoading}
			>
				<form
					className="w-full max-w-[400px]"
					onSubmit={handleSubmit(onSubmit)}
				>
					<Card className="flex flex-col space-y-8">
						<CardHeader className="pb-0">
							<div className="flex justify-between">
								<CardTitle className="text-[22px] leading-[30px] -tracking-[1%] text-[#323539]">
									Change Patient Position
								</CardTitle>
								<LuX
									color="#858C95"
									size={20}
									className="cursor-pointer self-center"
									onClick={() => {
										if (!isLoading)
											setChangePatientOrderModal(false);
									}}
								/>
							</div>
							<CardDescription>
								Current Position:{" "}
								{/* {NumberOrdinal(parseInt(patientDetails.order))} */}
							</CardDescription>
						</CardHeader>
						<CardContent className="pb-0">
							<div className="grid w-full items-center gap-4">
								<div className="flex flex-col space-y-1.5">
									<Label>Change position to</Label>
									<CustomSelect
										defaultValue={NumberOrdinal(
											parseInt(getValues("order"))
										)}
										placeholder="Select"
										options={
											// patients
											// .filter(
											// 	(item) =>
											// 		item.order !==
											// 		patientDetails.order
											// )
											// .map((item) => {
											// 	return {
											// 		label: NumberOrdinal(
											// 			parseInt(item.order)
											// 		),
											// 		value: NumberOrdinal(
											// 			parseInt(item.order)
											// 		),
											// 	};
											// })
											[]
										}
										onChange={(value) => {
											setValue(
												"order",
												value.slice(0, -2)
											);
										}}
									/>
								</div>
							</div>
						</CardContent>
						{(errors.root?.message ||
							errors.order?.message ||
							errors.station_id?.message) && (
							<p
								className={`!-mt-0 px-6 text-sm tracking-[-0.1px] text-red-500 `}
							>
								{errors.root?.message ||
									errors.order?.message ||
									errors.station_id?.message}
							</p>
						)}
						<CardFooter className="flex justify-end">
							<Button variant="ghost" disabled={isLoading}>
								Cancel
							</Button>

							<LoaderButton
								disabled={isLoading}
								// loading={isLoading}
								// loaderSize={18}
								className="max-w-[176px] px-8"
							>
								Update Position
							</LoaderButton>
						</CardFooter>
					</Card>
				</form>
			</Overlay>
		</>
	);
};

export default ChangePosition;
