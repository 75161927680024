import { create } from "zustand";
import { persist } from "zustand/middleware";
import { User } from "../types/NewUser";
import { Option } from "../components/form/CustomSelect";
import { OperatingHour } from "@type/DaySlots";

interface AdminStore {
	user: User | null;
	currentLocationId: number;
	currentStationId: number | "All Stations";
	currentPatientStatus: string;
	locationsList: Option[];
	stationsList: Option[];
	businessCategories: Option[];
	onboardingState: number;
	onboardingLocationInfo: onboardingLocationInfoType | null;
	queueUrl: string;
	setStationsList: (array: any) => void;
	setBusinessCategories: (array: Option[]) => void;
	setLocationsList: (array: any) => void;
	setCurrentLocationId: (id: number) => void;
	setCurrentStationId: (id: number | "All Stations") => void;
	setCurrentPatientStatus: (status: string) => void;
	setUser: (user: User) => void;
	setOnboardingState: (onboardingState: number) => void;
	setOnboardingLocationInfo: (
		onboardingState: onboardingLocationInfoType | null
	) => void;
	setQueueUrl: (queueUrl: string) => void;
	reset: () => void;
}

interface onboardingLocationInfoType {
	id: number;
	approximate_waiting_time: string;
	schedule_block_in_min: number;
	time_zone: string;
	time_slots: OperatingHour[];
}

const initialState = {
	user: null,
	currentLocationId: 0,
	currentStationId: 0,
	locationsList: [],
	stationsList: [],
	businessCategories: [],
	onboardingState: 0,
	onboardingLocationInfo: null,
	queueUrl: "",
	currentPatientStatus: "all_status",
};

const useUserStore = create<AdminStore, [["zustand/persist", AdminStore]]>(
	persist(
		(set) => ({
			...initialState,
			setStationsList: (stationsList) => set(() => ({ stationsList })),
			setCurrentLocationId: (currentLocationId) =>
				set(() => ({ currentLocationId })),
			setCurrentStationId: (currentStationId) =>
				set(() => ({ currentStationId })),
			setCurrentPatientStatus: (newStatus) =>
				set(() => ({ currentPatientStatus: newStatus })),
			setLocationsList: (locationsList) => set(() => ({ locationsList })),
			setUser: (user: User) => {
				set((state) => {
					console.log({ user: { ...state.user, ...user } });
					return { user: { ...state.user, ...user } };
				});
			},
			setBusinessCategories: (businessCategories) =>
				set(() => ({ businessCategories })),
			setOnboardingState: (onboardingState) =>
				set(() => ({ onboardingState })),
			setOnboardingLocationInfo: (onboardingLocationInfo) =>
				set(() => ({ onboardingLocationInfo })),
			setQueueUrl: (queueUrl) => set(() => ({ queueUrl })),
			reset: () => set(initialState),
		}),
		{
			name: "user-storage",
			getStorage: () => localStorage,
		}
	)
);

export default useUserStore;
