import useAnalyticsStore from "../../../../store/useAnalyticsStore";
import { ScrollArea } from "../../../../components/ui/scroll-area";
import VisitsByLocationsAndRatingsLine from "./VisitsByLocationsAndRatingsLine";

const VisitsByLocation: React.FC = () => {
	const analyticsData = useAnalyticsStore((s) => s.analyticsData);

	const locations =
		analyticsData?.trends.walk_in_vs_scheduled_by_location ?? [];

	// Calculate total for the first item
	const total = locations.reduce(
		(acc, item) => acc + item.walk_in_count + item.scheduled_count,
		0
	);

	const maxTotalCount = Math.max(
		...locations.map((item) => item.walk_in_count + item.scheduled_count)
	);

	return (
		<section className="flex flex-col rounded-lg bg-white p-8 pb-[41px]">
			<div className="mb-6 flex flex-col space-y-2">
				<h3 className="font-manrope text-lg font-bold leading-[16px] tracking-[0.5px] text-[#454D56]">
					Visits By Location
				</h3>
				<p className="text-xs leading-[120%] tracking-[0.41px] text-[#596574]">
					Total Visits :{" "}
					<span className="text-base font-semibold">{total}</span>
				</p>
			</div>
			<ScrollArea className="max-h-[320px] flex-1">
				<ul className="flex flex-col space-y-2 ">
					{locations.map((item) => {
						return (
							<VisitsByLocationsAndRatingsLine
								key={item.id}
								title={item.name}
								completeTotal={maxTotalCount}
								rowTotal={item.scheduled_count}
								halfTotal={item.walk_in_count}
								backgroundColor="#E7F0F5"
								hasHalfTotal
							/>
						);
					})}
				</ul>
			</ScrollArea>
			<ul className="mt-8 flex items-center justify-center space-x-4 text-xs tracking-[0%] text-[#323539]">
				<li className="flex items-center space-x-2">
					<i className="block size-2 rounded-[2px] bg-[#005893]" />
					<p>Walk-in</p>
				</li>
				<li className="flex items-center space-x-2">
					<i className="block size-2 rounded-[2px] bg-[#D6E4EE]" />
					<p>Scheduled</p>
				</li>
			</ul>
		</section>
	);
};

export default VisitsByLocation;
