import { BsInfoCircle } from "react-icons/bs";
import { MdMoreVert } from "react-icons/md";
import {
	// AppointmentStatusCode,
	// EVENT_STATUS_COLORS,
	EventItem,
} from "../../../services/data";
import { cn } from "../../../utils/functions";
import moment from "moment";
import { useState } from "react";
import { Button } from "../../ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import PatientDetails from "../../modals/PatientDetails";
import { FaTag } from "react-icons/fa6";
import { Dialog, DialogContent } from "../../ui/dialog";
import DetailsPane from "../../Dashboard/schedule/DetailsPane";

const AppointmentBlock = ({
	appointment,
	// appointments,
	viewType,
}: {
	viewType: "Day" | "Week" | "Month";
	appointments?: EventItem[];
	appointment?: EventItem;
}) => {
	const { start, end, data } = appointment || {};
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [showPatientDetails, setShowPatientDetails] =
		useState<boolean>(false);
	const handleShowPatientDetails = () => {
		setIsPopoverOpen(false);
		setShowPatientDetails(!showPatientDetails);
	};
	const handleOpenPopover = () => {
		setIsPopoverOpen(!isPopoverOpen);
	};
	//you can make the views different when using the week, day and month view
	//but im creating for the day view first
	// console.log(appointment);

	// if(viewType === "Month"){
	// 	return (

	// 	)
	// }
	return (
		<div
			className={cn(
				"relative mx-2 flex h-full items-center justify-between rounded-[8px] bg-[#EFF2F5] px-4 py-2 font-inter text-black",
				{
					"h-[20px] rounded-sm px-2 py-0.5":
						viewType === "Month" || viewType === "Week",
					"bg-[#FFF5F5]": data?.appointment?.status === "Pending",
				}
			)}
		>
			{viewType === "Day" && (
				<>
					<div className="flex flex-[0.8] items-center gap-3 text-[#323539]">
						<div className="flex items-center gap-1">
							<BsInfoCircle className="text-[14px]" />
							<div className=" rounded-md bg-[#EAEBF0] p-0.5">
								<FaTag />
							</div>
						</div>

						<p className="text-xs font-medium ">
							{data?.appointment?.resource}
						</p>
						<p className="text-[10px] font-[400]">{`${moment(start).format("h:mm A")} - ${moment(end).format("h:mm A")}`}</p>
					</div>
				</>
			)}
			{viewType === "Week" && (
				<div className="flex flex-[0.8] flex-col items-center text-[#323539]">
					<p className="text-xs font-medium ">
						{data?.appointment?.resource}
					</p>
				</div>
			)}
			{viewType === "Month" && (
				<div className="flex flex-[0.8] items-center gap-3 text-[#323539]">
					<p className="text-xs font-medium ">
						{data?.appointment?.resource}
					</p>
				</div>
			)}
			{/* for day view  */}
			<Popover onOpenChange={handleOpenPopover} open={isPopoverOpen}>
				<PopoverTrigger asChild>
					<div className="flex flex-[0.2] justify-end">
						<Button
							className=" px-0 py-0 hover:bg-transparent"
							variant="ghost"
						>
							<MdMoreVert width={"14px"} height={"14px"} />
						</Button>
					</div>
				</PopoverTrigger>
				<PopoverContent className=" w-fit bg-[#F8F9FB] px-4 py-3 pr-8">
					<DetailsPane onClick={handleShowPatientDetails} />
				</PopoverContent>
			</Popover>

			<Dialog
				open={showPatientDetails}
				onOpenChange={setShowPatientDetails}
			>
				<DialogContent className="min-h-[551px] min-w-[860px] space-y-4">
					<PatientDetails />
				</DialogContent>
			</Dialog>
			{/* <PatientDetails
				show={showPatientDetails}
				setShow={setShowPatientDetails}
			/> */}
		</div>
	);
};

export default AppointmentBlock;
