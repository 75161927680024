import { QueueData } from "@type/waitlist/waitlist";
import React from "react";
import { useQueryClient } from "react-query";
import useWaitListStore from "../../../store/useWaitListStore";
import HeaderTopItem from "@src/layouts/Header/HeaderTopItem";

const DisplayTimeAndCustomerCount: React.FC = () => {
	const queryClient = useQueryClient();

	const numberOfCustomers = useWaitListStore((s) => s.patients.length);
	const approximateWaitTime = queryClient.getQueryData("queue-list") as {
		status: boolean;
		data: QueueData;
		message: string;
	};

	const [hours, minutes] = approximateWaitTime
		? approximateWaitTime.data.total_wait_time.split(":")
		: "00:00:00".split(":");

	const waitTimeHours = +hours > 1 ? hours + "h" : "";
	const waitTimeMinutes = +minutes > 1 ? minutes + "min" : "";

	return (
		<ul className="flex items-center space-x-6">
			<HeaderTopItem
				icon={"mgc_stopwatch_line"}
				title={`${waitTimeHours} ${waitTimeMinutes}`}
				desc={"Approximate Waiting Time"}
			/>
			<HeaderTopItem
				icon={"mgc_group_line"}
				title={numberOfCustomers}
				desc={"Total Customers"}
			/>
		</ul>
	);
};

export default DisplayTimeAndCustomerCount;
