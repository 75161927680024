/* eslint-disable react-hooks/exhaustive-deps */
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { Button } from "../../ui/button";
import moment from "moment";
import { Views, momentLocalizer } from "react-big-calendar";
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from "react";
import { cn } from "../../../utils/functions";
import { useElementWidth } from "../../../hooks/useElementWidth";
// import { stations } from "../../../services/mockdata/dummyEventData";
import { IoClose } from "react-icons/io5";
import { EVENTS } from "../../../services/mockdata/dummyEventData";
import useScreenDimension from "../../../hooks/useScreenDimension";
import DateSelector from "../../DateSelector";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";

interface CustomCalendarHeaderProps {
	events: any;
	setEvents: any;
	view: Views;
	selectedStations: { value: string; label: string }[];
	setSelectedStations: Dispatch<
		SetStateAction<{ value: string; label: string }[]>
	>;
	handleRemoveSchedule: (selectedStations, station) => void;
	setDate: (date: Date) => void;
	date: Date | undefined;
}
const CustomCalendarHeader = ({
	view,
	setEvents,
	selectedStations,
	handleRemoveSchedule,
	setDate,
	date,
}: CustomCalendarHeaderProps) => {
	const [selected, setSelected] = useState("");
	const isMultiView = selectedStations.length > 1;
	const localizer = momentLocalizer(moment);
	const { elementRef, elementWidth } = useElementWidth();
	const { width } = useScreenDimension();
	const updateFilterEvents = (station) => {
		// because the events have been mutated, so they dont exist anymore
		// get original events then set it and do the filtering;
		setEvents(EVENTS);

		setTimeout(() => {
			const filteredEvents = EVENTS.filter((event) => {
				return (
					event.data &&
					event.data.appointment &&
					event.data.appointment.resource.toLowerCase() ===
						station.toLowerCase()
				);
			});

			if (filteredEvents.length < 1) {
				return alert("No appointment found");
			}
			setSelected(station);
			setEvents(filteredEvents);
		}, 3000);
	};

	const handleStationSelection = (station: {
		value: string;
		label: string;
	}) => {
		updateFilterEvents(station.value);
	};

	const handleStationSelectionRemoval = (
		e,
		selectedStations,
		station: { value: string; label: string }
	) => {
		e.stopPropagation();
		handleRemoveSchedule(selectedStations, station);
	};
	const onPrevClick = useCallback(() => {
		if (view === Views.DAY) {
			setDate(moment(date).subtract(1, "d").toDate());
		} else if (view === Views.WEEK) {
			setDate(localizer.add(date, -5, "day"));
		} else {
			setDate(moment(date).subtract(1, "M").toDate());
		}
	}, [view, date]);
	// console.log(date)

	const onNextClick = useCallback(() => {
		if (view === Views.DAY) {
			setDate(moment(date).add(1, "d").toDate());
		} else if (view === Views.WEEK) {
			setDate(localizer.add(date, 5, "day"));
		} else {
			setDate(moment(date).add(1, "M").toDate());
		}
	}, [view, date]);

	const dateText = useMemo(() => {
		if (view === Views.DAY) return moment(date).format("dddd, MMMM DD");
		if (view === Views.WEEK) {
			const from = moment(date)?.startOf("week");
			const to = moment(date)?.endOf("week");
			return `${from.format("MMMM DD")} to ${to.format("MMMM DD")}`;
		}
		if (view === Views.MONTH) {
			return moment(date).format("MMMM YYYY");
		}
	}, [view, date]);

	// Week View Header Container
	if (view === Views.WEEK) {
		return (
			<>
				<div
					className={cn(
						" rounded-tl-md rounded-tr-md border border-[#E5E7EB]",
						{ hidden: !isMultiView }
					)}
				>
					<div
						className={` flex w-full justify-center gap-4 overflow-x-auto border-b border-b-[#E5E5E7] px-4 `}
						style={{ maxWidth: elementWidth }}
					>
						{selectedStations.length > 1 &&
							selectedStations?.map((station, index) => (
								<Button
									key={index}
									variant="ghost"
									className={cn(
										" flex w-full items-center !justify-between gap-2 rounded-none px-4 py-2 text-[14px] font-[600] ",
										{
											"border-b-2 border-b-[#005893] text-[#09244B]":
												selected === station.value,
										}
									)}
									onClick={() =>
										handleStationSelection(station)
									}
								>
									<p>{station.value}</p>
									<IoClose
										className="text-lg"
										onClick={(e) =>
											handleStationSelectionRemoval(
												e,
												selectedStations,
												station
											)
										}
									/>
								</Button>
							))}
					</div>
				</div>

				<div
					className={cn(
						"absolute top-[11.5%] flex w-full items-center justify-between",
						{
							"top-[22%]": isMultiView,
						}
					)}
				>
					<Button
						className="bg-transparent text-[24px] text-[#09244B] hover:bg-transparent"
						onClick={onPrevClick}
					>
						<ChevronLeftIcon />
					</Button>
					<Button
						className="bg-transparent text-[24px] text-[#09244B] hover:bg-transparent"
						onClick={onNextClick}
					>
						<ChevronRightIcon />
					</Button>
				</div>
			</>
		);
	}

	// Day and Month view container
	return (
		<div
			className={cn(
				"flex h-[56px] w-full items-center rounded-tl-md rounded-tr-md border border-[#E5E7EB] px-4 pt-3 ",
				{
					"h-[110px] flex-col": isMultiView,
				}
			)}
		>
			<div
				className={cn(
					"flex w-full  justify-between overflow-x-auto border-b border-b-[#E5E5E7] px-4",
					{ hidden: !isMultiView }
				)}
				style={{ maxWidth: elementWidth }}
			>
				{selectedStations.length > 1 &&
					selectedStations?.map((station, index) => (
						<Button
							key={index}
							variant="ghost"
							className={cn(
								" flex w-full items-center justify-between gap-2 rounded-none px-4 py-2 text-[15px] font-[600] text-[#858C95] ",
								{
									"border-b-2 border-b-[#005893] text-[#09244B]":
										selected === station.value,
								}
							)}
							onClick={() => handleStationSelection(station)}
						>
							{station.value}

							<IoClose
								className="text-lg"
								onClick={(e) =>
									handleStationSelectionRemoval(
										e,
										selectedStations,
										station
									)
								}
							/>
						</Button>
					))}
			</div>
			<div
				ref={elementRef}
				className="flex w-full items-center justify-between"
			>
				<Button
					className="bg-transparent px-0 text-[#09244B] hover:bg-transparent"
					onClick={onPrevClick}
				>
					<ChevronLeftIcon />
				</Button>
				<p className="flex items-center gap-1  font-inter font-[600] text-[#111827]">
					{dateText}
					<span>
						{width < 1025 ? (
							<>
								<Popover>
									<PopoverTrigger>
										<Button
											className="px-0"
											variant="ghost"
										>
											<CalendarIcon color="#858C95" />
										</Button>
									</PopoverTrigger>
									<PopoverContent className="w-full">
										<DateSelector
											date={date}
											setDate={(date: Date) =>
												setDate(date)
											}
										/>
									</PopoverContent>
								</Popover>
							</>
						) : null}
					</span>
				</p>
				<Button
					className="bg-transparent px-0 text-[#09244B]  hover:bg-transparent"
					onClick={onNextClick}
				>
					<ChevronRightIcon />
				</Button>
			</div>
		</div>
	);
};

export default CustomCalendarHeader;
