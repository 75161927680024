import { TabsContent } from "@src/components/ui/tabs";
import React from "react";

const Settings: React.FC = () => {
	return (
		<TabsContent
			value="account-setting"
			className="mt-0 flex w-full data-[state=inactive]:hidden data-[state=active]:flex-1"
		>
			Account Settings
		</TabsContent>
	);
};

export default Settings;
