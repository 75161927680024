import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from "react";
// import PropTypes from "prop-types";
// import * as dates from "date-arithmetic";
// import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Views } from "react-big-calendar";
import { Calendar } from "../Calendar";
// import TimeGrid from "react-big-calendar/lib/TimeGrid";
// import { EVENTS, EventItem } from "../../../services/data";
import AppointmentBlock from "./AppointmentBlock";
import WeekView from "./WeekView";
import CustomCalendarHeader from "./CustomCalendarHeader";
import CustomCalendarToolbar from "./CustomCalendarToolbar";
import DateSelector from "../../DateSelector";
import useScreenDimension from "../../../hooks/useScreenDimension";

type Keys = keyof typeof Views;

interface CustomCalendarProps {
	events: any;
	setEvents: any;
	handleRemoveSchedule: (selectedStations, station) => void;
	selectedStations: { value: string; label: string }[];
	setSelectedStations: Dispatch<
		SetStateAction<{ value: string; label: string }[]>
	>;
	setShowAppointmentModal: Dispatch<SetStateAction<boolean>>;
}
const CustomCalendar = ({
	events,
	setEvents,
	handleRemoveSchedule,
	selectedStations,
	setSelectedStations,
	setShowAppointmentModal,
}: CustomCalendarProps) => {
	// const [date, setDate] = useState<Date>(moment("2022-10-10").toDate());
	const startTimeShown = moment()
		.set({ hour: 6, minute: 0, second: 0, millisecond: 0 })
		.toDate();
	const [date, setDate] = useState<Date | undefined>(new Date());
	const [view, setView] = useState<(typeof Views)[Keys]>(Views.DAY);
	const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);
	const onView = useCallback((newView) => setView(newView), [setView]);
	const { width } = useScreenDimension();
	const formats = useMemo(
		() => ({
			timeGutterFormat: (date, culture, localizer) =>
				localizer.format(date, "hh A", culture),
		}),
		[]
	);
	const mainevents = useMemo(() => {
		return events?.map((event) => {
			return {
				...event,
				start: moment(event.start).toDate(),
				end: moment(event.end).toDate(),
			};
		});
	}, [events]);
	const { components, views } = useMemo(
		() => ({
			components: {
				day: {
					event: (event) => (
						<AppointmentBlock
							appointment={event.event}
							viewType="Day"
						/>
					),
				},
				week: {
					event: (event) => (
						<AppointmentBlock
							appointment={event.event}
							viewType="Week"
						/>
					),
				},
				month: {
					event: (event) => (
						<AppointmentBlock
							appointment={event.event}
							viewType="Month"
						/>
					),
				},
			},

			views: {
				month: true,
				week: WeekView,
				day: true,
			},
		}),
		[]
	);

	return (
		<div className="relative h-full w-full space-y-4">
			<CustomCalendarToolbar
				view={view}
				date={date}
				setDate={setDate}
				setView={setView}
				setShowAppointmentModal={setShowAppointmentModal}
			/>
			<div className=" h-full ">
				<CustomCalendarHeader
					handleRemoveSchedule={handleRemoveSchedule}
					setSelectedStations={setSelectedStations}
					selectedStations={selectedStations}
					view={view}
					events={events}
					setEvents={setEvents}
					setDate={setDate}
					date={date}
				/>
				<div className="flex h-full w-full">
					<Calendar
						className="w-full"
						components={components}
						date={date}
						view={view}
						views={views}
						onView={onView}
						onNavigate={onNavigate}
						formats={formats}
						events={mainevents}
						scrollToTime={startTimeShown}
						showAllEvents={true}
						defaultDate={date}
						toolbar={false}
					/>
					{width > 1025
						? view === "day" && (
								<div className="border  border-l-0  p-4">
									<DateSelector
										date={date}
										setDate={(date: Date) => setDate(date)}
									/>
								</div>
							)
						: null}
				</div>
			</div>
		</div>
	);
};

export default CustomCalendar;
