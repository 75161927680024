import { zodResolver } from "@hookform/resolvers/zod";
import { LoaderButton } from "@src/components/form/LoaderButton";
import { Button } from "@src/components/ui/button";
import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import { ScrollArea } from "@src/components/ui/scroll-area";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import { Switch } from "@src/components/ui/switch";
import BusinessSettingsSlice from "@src/store/slices/waitlist/businessSettinsSlice";
import useUserStore from "@src/store/useUserStore";
import {
	WaitlistGeneralSettings,
	WaitlistGeneralSettingsSchema,
} from "@src/types/waitlist/waitlist";
import { formatHHMMSSToMinutes, formatTimeToHHMMSS } from "@src/utils/date";
import { isEqual } from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LuX } from "react-icons/lu";

const GeneralSettings: React.FC<{
	setWaitlistSettingsModal: Dispatch<SetStateAction<boolean>>;
}> = ({ setWaitlistSettingsModal }) => {
	const user = useUserStore((s) => s.user);

	const initialFormState: WaitlistGeneralSettings = {
		is_queue_active: user?.business.is_active as 1 | 0,
		use_average_wait_time: user?.business.use_average_wait_time as 1 | 0,
		manual_wait_time: formatTimeToHHMMSS(
			user?.business?.average_waiting_time ?? "30"
		),
	};

	const {
		handleSubmit,
		setValue,
		getValues,
		watch,
		reset,
		// formState: { errors },
	} = useForm<WaitlistGeneralSettings>({
		defaultValues: initialFormState,
		resolver: zodResolver(WaitlistGeneralSettingsSchema),
	});

	const [isAutoClearQueueOn, setIsAutoClearQueueOn] = React.useState<1 | 0>(
		0
	);
	const [isDisabled, setIsDisabled] = React.useState(true);
	const [isUsingHistoricalAvg, setIsUsingHistoricalAvg] = React.useState(
		!!user?.business.use_average_wait_time
	);
	const businessSettingsMutation = BusinessSettingsSlice(undefined, () => {
		// setValue("is_queue_active", getValues("is_queue_active") === 1 ? 0 : 1);
	});

	const updateGeneralSettigs: SubmitHandler<WaitlistGeneralSettings> = async (
		data
	) => {
		// const newIsQueueOn = data.is_queue_active === 1 ? 0 : 1;
		// console.log(data);
		businessSettingsMutation.mutate({
			data,
		});
	};

	React.useEffect(() => {
		const subscription = watch(() => {
			setIsDisabled(isEqual(getValues(), initialFormState));
		});
		return () => subscription.unsubscribe();
	}, [watch, initialFormState]);

	return (
		<form
			className="flex flex-1 flex-col self-stretch"
			onSubmit={handleSubmit(updateGeneralSettigs)}
		>
			<div className="flex items-center justify-between pl-12 pr-6 pt-6">
				<h2 className="text-[22px] font-semibold leading-[30px] tracking-[-1%] text-[#323539]">
					General Settings
				</h2>
				<button
					className="flex items-start"
					onClick={(e) => {
						e.preventDefault();
						setWaitlistSettingsModal(false);
					}}
				>
					<LuX
						color="#858C95"
						size={20}
						className="cursor-pointer self-center"
					/>
				</button>
			</div>
			<ScrollArea className="mt-6 flex-1 border-b border-[#1018280A] pl-12 pr-6">
				<div className="flex flex-col space-y-3 px-3">
					<div className="border-b-1 flex justify-between border-b border-b-[#E5E5E7] pb-4 pt-1">
						<div>
							<h3 className="text-[15px] font-medium leading-[22px] text-[#323539]">
								Join Waitlist
							</h3>
							<p className="traking-[-0.1px] text-[14px] leading-[20px] text-[#858C95]">
								Allow patients to join the waitlist
							</p>
						</div>
						<div className="flex items-center">
							<Switch
								defaultChecked={!!watch("is_queue_active")}
								checked={!!watch("is_queue_active")}
								id="queue-on-off"
								className="scale-75 data-[state=checked]:bg-[#32D74B] data-[state=unchecked]:bg-[#E5E5E7]"
								onClick={() =>
									setValue(
										"is_queue_active",
										getValues("is_queue_active") === 1
											? 0
											: 1
									)
								}
								disabled={businessSettingsMutation.isLoading}
							/>
							<Label
								htmlFor="queue-on-off"
								className="font-medium leading-[24px] tracking-[-0.16px] text-[#323539]"
							>
								{watch("is_queue_active") ? "On" : "Off"}
							</Label>
						</div>
					</div>
					<div className="border-b-1 flex flex-col space-y-2.5 border-b border-b-[#E5E5E7] pb-4 pt-1">
						<div>
							<h3 className="text-[15px] font-medium leading-[22px] text-[#323539]">
								Wait Times
							</h3>
							<p className="traking-[-0.1px] text-[14px] leading-[20px] text-[#858C95]">
								This determines how our system will calculate
								the wait time.
							</p>
						</div>
						<div className="flex items-center gap-x-6">
							<p className="traking-[-0.1px] max-w-[204px] text-[14px] leading-[20px] text-[#858C95]">
								Historical Avg. calculates the total average and
								Custom allows you to enter a number.
							</p>
							<div className="flex flex-1 items-center gap-3">
								<Select
									value={
										isUsingHistoricalAvg
											? "historical-avg"
											: "manual"
									}
									onValueChange={(value) => {
										// setCountryCode(value);
										if (value === "historical-avg") {
											setValue(
												"use_average_wait_time",
												1
											);
											setValue(
												"manual_wait_time",
												undefined
											);
											setIsUsingHistoricalAvg(true);
										} else if (value === "manual") {
											setValue(
												"use_average_wait_time",
												0
											);
											setValue(
												"manual_wait_time",
												formatTimeToHHMMSS(
													user?.business
														?.average_waiting_time ??
														"30"
												)
											);
											setIsUsingHistoricalAvg(false);
										}
									}}
								>
									<SelectTrigger className="h-9 flex-1 py-0">
										<SelectValue
											placeholder={"Select Priority"}
											className="text-[#858C95]"
										/>
									</SelectTrigger>
									<SelectContent className="!z-[9999]">
										<SelectGroup>
											<SelectLabel className="px-2">
												Select Historical Average
											</SelectLabel>

											{waitTimes.map((option) => {
												return (
													<SelectItem
														key={option.value}
														value={option.value}
														className="px-8"
													>
														{option.label}
													</SelectItem>
												);
											})}
										</SelectGroup>
									</SelectContent>
								</Select>
								{!isUsingHistoricalAvg && (
									<div className="flex h-9 items-center rounded-md border border-input py-1.5 focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
										<Input
											defaultValue={formatHHMMSSToMinutes(
												watch("manual_wait_time")
											)}
											className="remove-number-input-arrow max-h-full w-[30px] min-w-[48px] max-w-fit border-none text-[14px] font-medium leading-[20px] focus-within:ring-0 focus:outline-none"
											value={formatHHMMSSToMinutes(
												watch("manual_wait_time")
											)}
											onChange={(e) => {
												if (
													parseInt(e.target.value) ===
														0 ||
													!e.target.value.length
												)
													return;
												setValue(
													"manual_wait_time",
													formatTimeToHHMMSS(
														e.target.value
													)
												);
											}}
											min={1}
											type="number"
										/>
										<p className="border-l border-[#E5E5E7] px-1.5 text-[14px] font-medium leading-[20px] text-[#323539]">
											minutes
										</p>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="flex justify-between pb-4 pt-1">
						<div>
							<h3 className="text-[15px] font-medium leading-[22px] text-[#323539]">
								Auto Clear Queue
							</h3>
							<p className="traking-[-0.1px] max-w-[412px] text-[14px] leading-[20px] text-[#858C95]">
								The time after which the queue will be
								automatically cleared after business hours.
							</p>
						</div>
						<div className="flex items-center">
							<Switch
								defaultChecked={!!isAutoClearQueueOn}
								checked={!!isAutoClearQueueOn}
								id="queue-on-off"
								className="scale-75 data-[state=checked]:bg-[#32D74B] data-[state=unchecked]:bg-[#E5E5E7]"
								onClick={() =>
									setIsAutoClearQueueOn((prev) =>
										prev === 1 ? 0 : 1
									)
								}
							/>
							<Label
								htmlFor="queue-on-off"
								className="font-medium leading-[24px] tracking-[-0.16px] text-[#323539]"
							>
								{isAutoClearQueueOn ? "On" : "Off"}
							</Label>
						</div>
					</div>
				</div>
			</ScrollArea>
			<div className="flex justify-end space-x-6 py-3 pr-12">
				{/* TODO: Make this cancel reset the form data when clicked on */}
				<Button
					variant="outline"
					className="px-5 shadow-[0_1px_2px_0_rgba(16,24,40,0.04)]"
					onClick={() => {
						reset(initialFormState);
						setIsUsingHistoricalAvg(
							!!user?.business.use_average_wait_time
						);
						setValue(
							"use_average_wait_time",
							initialFormState.use_average_wait_time as 1 | 0
						);
						setValue(
							"manual_wait_time",
							formatTimeToHHMMSS(
								initialFormState.manual_wait_time ?? "30"
							)
						);
					}}
					type="button"
				>
					Cancel
				</Button>

				<LoaderButton
					type="submit"
					className="px-5 text-white duration-200"
					loading={businessSettingsMutation.isLoading}
					loaderSize={20}
					disabled={isDisabled || businessSettingsMutation.isLoading}
				>
					Update
				</LoaderButton>
			</div>
		</form>
	);
};

export default GeneralSettings;

const waitTimes = [
	{
		label: "Historical Avg.",
		value: "historical-avg",
	},
	{
		label: "Manual",
		value: "manual",
	},
];
