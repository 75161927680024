import { useFormContext } from "react-hook-form";
import CustomCheckbox2 from "@components/form/CustomCheckbox2";
import FormInput, { InputMode } from "@components/form/FormInput";
import AddLocationTimer from "./AddLocationTimer";
import React, { useEffect, useState } from "react";
import { OperatingHourV2 } from "@src/types/DaySlots";
import { Controller } from "react-hook-form";

interface Props {
	tabView: string;
}

export const WaitingHours: React.FC<Props> = ({ tabView }) => {
	const { control, register, setValue, getValues } = useFormContext();
	console.log("🚀 ~ getValues:", getValues());

	const [slots, setSlots] = useState<OperatingHourV2>(getValues("data"));

	useEffect(() => {
		setValue("data", slots);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slots]);

	return (
		<div className=" divide-y border-b">
			<div className="pb-6">
				<h2 className="mb-5 text-xl font-bold capitalize text-[#323539]">
					{tabView === "waitlist"
						? "Add operating hours (waitlist)"
						: "Add operating hours (Schedule)"}
				</h2>
				<div className="flex items-center space-x-4">
					<label className="max-w-[141px] flex-1 text-base font-medium tracking-[-0.1px] text-[#323539]">
						Est. Wait Time
					</label>
					<FormInput
						register={{
							...register("approximate_waiting_time"),
						}}
						placeholder="15"
						inputMode={InputMode.Numeric}
						className="max-w-[320px]"
						sideLabel="minutes"
						inputWithSide
						required
					/>
				</div>
			</div>
			<div className="flex flex-col space-y-3 py-5">
				{Object.entries(slots).map(([day, dayData]) => (
					<AddLocationTimer
						key={day}
						day={day}
						day_value={day}
						is_active={dayData.is_active}
						time_slots={dayData.time_slots}
						shouldShowDay={true}
						shouldShowPlus={true}
						setOperatingHours={setSlots}
					/>
				))}
			</div>
			<div className="flex space-x-4 py-6">
				<p>Applies to</p>
				<div className="flex items-center space-x-2">
					<Controller
						name={`is_waitlist`}
						control={control}
						render={() => (
							<CustomCheckbox2
								className="checked:border-[#195388] checked:bg-[#195388]"
								isChecked={getValues("is_waitlist")}
								handleCheckboxChange={() =>
									setValue(
										"is_waitlist",
										!getValues("is_waitlist")
									)
								}
								id={"is_waitlist"}
							/>
						)}
					/>
					<label
						htmlFor="is_waitlist"
						className="cursor-pointer font-medium"
					>
						Waitlist
					</label>
				</div>
				<div className="flex items-center space-x-2">
					<Controller
						name={`is_schedule`}
						control={control}
						render={() => (
							<CustomCheckbox2
								className="checked:border-[#195388] checked:bg-[#195388]"
								isChecked={getValues("is_schedule")}
								handleCheckboxChange={() =>
									setValue(
										"is_schedule",
										!getValues("is_schedule")
									)
								}
								id={"is_schedule"}
							/>
						)}
					/>
					<label
						htmlFor="is_schedule"
						className="cursor-pointer font-medium"
					>
						Schedule
					</label>
				</div>
			</div>
		</div>
	);
};
