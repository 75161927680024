import React, { useEffect } from "react";
import moment from "moment-timezone";
import { zodResolver } from "@hookform/resolvers/zod";
import LocationInfo from "../components/LocationInfo";
import { Button } from "@components/ui/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import {
	EditLocationInfoData,
	EditLocationInfoSchema,
} from "@src/types/Location";
// import { defaultTimeSlots } from "@utils/constants";
// import { isTimeOverlapping } from "@utils/functions";
import {
	GetLocationDetailsSlice,
	UpdateBusinessLocationSlice,
} from "@src/store/slices/locations/locationSlice";
import RequestIsLoading from "@components/RequestIsLoading";

export default function LocationTabList() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const locationId = searchParams.get("locationId") || "";

	useEffect(() => {
		if (!locationId) navigate("/dashboard/locations");
	}, [navigate, locationId]);

	const { data, isLoading } = GetLocationDetailsSlice(
		Number(parseInt(locationId))
	);

	const updateBusinessLocationMutation = UpdateBusinessLocationSlice(
		() => {
			navigate("/dashboard/locations");
		},
		(error) => console.log("🚀 ~ LocationTabList ~ error:", error)
	);

	const methods = useForm<EditLocationInfoData>({
		resolver: zodResolver(EditLocationInfoSchema),
		defaultValues: {
			name: "",
			state: "",
			address: "",
			city: "",
			country: "",
		},
	});

	const {
		handleSubmit,
		reset,
		formState: { errors, isValid },
	} = methods;

	useEffect(() => {
		if (locationId && !isLoading && data) {
			reset({
				name: data?.data?.name || "",
				state: data.data.state || "",
				address: data.data.address || "",
				city: data.data.city || "",
				country: data.data.country || "",
			});
		}
	}, [navigate, locationId, isLoading, data, reset]);

	const handleEditLocation: SubmitHandler<EditLocationInfoData> = async (
		data
	) => {
		if (isValid) {
			const body: EditLocationInfoData = {
				name: data.name,
				state: data.state,
				address: data.address,
				city: data.city,
				country: data.country,
			};
			updateBusinessLocationMutation.mutate({
				id: parseInt(locationId),
				data: body,
			});
			return;
		}
	};

	return (
		<FormProvider {...methods}>
			<RequestIsLoading
				isLoading={
					updateBusinessLocationMutation.isLoading || isLoading
				}
			/>
			<form onSubmit={handleSubmit(handleEditLocation)}>
				<div className="mx-auto w-full max-w-[1000px] flex-1 space-y-6">
					<LocationInfo />
				</div>
				<div className="mx-auto flex w-full max-w-[1000px] justify-end gap-2 py-4">
					<Button
						type="button"
						onClick={() => navigate("/dashboard/locations")}
						variant={"ghost"}
					>
						Cancel
					</Button>
					<Button type="submit" className="px-9">
						Save
					</Button>
				</div>
			</form>
		</FormProvider>
	);
}
