import { PatientStatus } from "@src/types/wait-list";
import { QueueData } from "@src/types/waitlist/waitlist";
import useUserStore from "@store/useUserStore";
import useWaitListStore from "@store/useWaitListStore";
import { useQueryClient } from "react-query";

const useStationSelection: () => {
	handleStationChange: (value: string) => void;
} = () => {
	const queryClient = useQueryClient();
	const setCurrentStationId = useUserStore((s) => s.setCurrentStationId);
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const setPatientsObject = useWaitListStore((s) => s.setPatientsObject);
	const getQueryList = queryClient.getQueryData("queue-list") as {
		status: boolean;
		data: QueueData;
		message: string;
	};

	const handleStationChange = (value: string) => {
		if (value !== "All Stations") {
			const stationId = parseInt(value);
			setCurrentStationId(stationId);
			setPatientsObject({
				[PatientStatus.Pending]:
					getQueryList.data.waitlist_info.waiting_to_be_approved.filter(
						(item) => item.station_id === stationId
					),
				[PatientStatus.Upcoming]:
					getQueryList.data.waitlist_info.accepted.filter(
						(item) => item.station_id === stationId
					),
				[PatientStatus.NextInLine]:
					getQueryList.data.waitlist_info.next_to_serve.filter(
						(item) => item.station_id === stationId
					),
				[PatientStatus.Serving]:
					getQueryList.data.waitlist_info.getting_service.filter(
						(item) => item.station_id === stationId
					),
				[PatientStatus.Schedule]: [],
			});
		} else {
			setCurrentStationId("All Stations");
			setPatientsObject({
				[PatientStatus.Pending]:
					getQueryList.data.waitlist_info.waiting_to_be_approved.filter(
						(item) => item.location_id === currentLocationId
					),
				[PatientStatus.Upcoming]:
					getQueryList.data.waitlist_info.accepted.filter(
						(item) => item.location_id === currentLocationId
					),
				[PatientStatus.NextInLine]:
					getQueryList.data.waitlist_info.next_to_serve.filter(
						(item) => item.location_id === currentLocationId
					),
				[PatientStatus.Serving]:
					getQueryList.data.waitlist_info.getting_service.filter(
						(item) => item.location_id === currentLocationId
					),
				[PatientStatus.Schedule]: [],
			});
		}
	};

	return {
		handleStationChange,
	};
};

export default useStationSelection;
