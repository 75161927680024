import { zodResolver } from "@hookform/resolvers/zod";
import React, { Dispatch, ReactNode, SetStateAction } from "react";

import { useForm } from "react-hook-form";
import { z } from "zod";

const StepTwo: React.FC<{
	step?: number;
	isErrorMessageOpen: boolean;
	errorMessage: string;
	setErrorMessage: Dispatch<SetStateAction<string>>;
	setIsErrorMessageOpen: Dispatch<SetStateAction<boolean>>;
	setStep: Dispatch<SetStateAction<number>>;
}> = () => {
	const schema = z.object({
		url: z.string().url(),
	});

	const {
		register,
		// handleSubmit,
		// setError,
		// formState: { errors },
	} = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
	});

	return (
		<div className="m-1.5 flex flex-1 flex-col space-y-3">
			<h2 className="text-[22px] font-semibold leading-[30px] text-[#09090B]">
				Step 2
			</h2>
			<div className="mt-[29px] flex w-full max-w-[805px] flex-col space-y-4 rounded-xl border border-[#E4E4E7] bg-white p-6 shadow-[0_10px_15px_-3px_rgba(0,0,0,0.1)]">
				<h3 className="text-[18px] font-semibold leading-[30px] text-[#09090B]">
					Navigate to REST Clients
				</h3>
				<div className="flex space-x-2">
					<div className="flex max-w-fit flex-1 flex-col space-y-[20px] pl-2.5 pr-2">
						{sub_steps.map((item, index) => (
							<StepTwoSubSteps
								key={index}
								title={item.title}
								desc={item.desc}
								step={String.fromCharCode(97 + index)}
							/>
						))}
					</div>
					<div className="flex flex-1 flex-col space-y-2.5 self-stretch">
						<img
							src="/assets/images/integration/oscar/step-2-1.png"
							className="shadow-[0_0_10px_0_rgba(0,0,0,0.25)]"
							alt="Sub-step-1"
						/>
						<img
							src="/assets/images/integration/oscar/step-2-2.png"
							className="shadow-[0_0_10px_0_rgba(0,0,0,0.25)]"
							alt="Sub-step-2"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StepTwo;

const StepTwoSubSteps: React.FC<{
	title: ReactNode;
	desc: string;
	step: string;
}> = ({ title, desc, step }) => {
	return (
		<>
			<div className="flex space-x-1">
				<p className="text-[14px] leading-[20px] text-[#71717A]">
					{step}.
				</p>
				<div>
					{title}
					<p className="text-[14px] leading-[20px] text-[#71717A]">
						{desc}
					</p>
				</div>
			</div>
			<svg
				width="14"
				height="9"
				viewBox="0 0 14 9"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className="last:hidden"
			>
				<path
					d="M13 1.5L7 7.5L1 1.5"
					stroke="#CACACA"
					strokeWidth="2"
				/>
			</svg>
		</>
	);
};

const sub_steps = [
	{
		title: (
			<p className="text-[14px] leading-[20px] text-[#71717A]">
				Go to the{" "}
				<span className="font-bold text-[#09090B]">
					Administrative Panel
				</span>
			</p>
		),
		desc: "Top middle of the navigation panel",
	},
	{
		title: (
			<p className="text-[14px] leading-[20px] text-[#71717A]">
				Click on{" "}
				<span className="font-bold text-[#09090B]">Integrations</span>
			</p>
		),
		desc: "Left hand panel",
	},
	{
		title: (
			<p className="text-[14px] leading-[20px] text-[#71717A]">
				Then, click on{" "}
				<span className="font-bold text-[#09090B]">REST Clients</span>
			</p>
		),
		desc: "Right below Integrations",
	},
];
