import { PatientStatus } from "@src/types/wait-list";
import { QueueData } from "@src/types/waitlist/waitlist";
import useUserStore from "@store/useUserStore";
import useWaitListStore from "@store/useWaitListStore";
import { QueueEntry } from "@type/waitlist/waitlist";
import { useQueryClient } from "react-query";

const useStatusSelection: () => {
	handlePatientStatusChange: (value: string) => void;
} = () => {
	const queryClient = useQueryClient();
	const setCurrentPatientStatus = useUserStore(
		(s) => s.setCurrentPatientStatus
	);
	const currentLocationId = useUserStore((s) => s.currentLocationId);
	const currentStationId = useUserStore((s) => s.currentStationId);
	const setPatientsObject = useWaitListStore((s) => s.setPatientsObject);
	const getQueryList = queryClient.getQueryData("queue-list") as {
		status: boolean;
		data: QueueData;
		message: string;
	};

	const filterByStation = (items: QueueEntry[]) => {
		if (currentStationId === "All Stations") {
			return items.filter(
				(item) => item.location_id === currentLocationId
			);
		} else {
			return items.filter((item) => item.station_id === currentStationId);
		}
	};

	const handlePatientStatusChange = (value: string) => {
		if (value !== "all_status") {
			setCurrentPatientStatus(value);

			setPatientsObject({
				[PatientStatus.Pending]: filterByStation(
					getQueryList.data.waitlist_info.waiting_to_be_approved.filter(
						(item) => item.status === value
					)
				),
				[PatientStatus.Upcoming]: filterByStation(
					getQueryList.data.waitlist_info.accepted.filter(
						(item) => item.status === value
					)
				),
				[PatientStatus.NextInLine]: filterByStation(
					getQueryList.data.waitlist_info.next_to_serve.filter(
						(item) => item.status === value
					)
				),
				[PatientStatus.Serving]: filterByStation(
					getQueryList.data.waitlist_info.getting_service.filter(
						(item) => item.status === value
					)
				),
				[PatientStatus.Schedule]: [],
			});
		} else {
			setCurrentPatientStatus("all_status");
			setPatientsObject({
				[PatientStatus.Pending]: filterByStation(
					getQueryList.data.waitlist_info.waiting_to_be_approved.filter(
						(item) => item.location_id === +currentLocationId
					)
				),
				[PatientStatus.Upcoming]: filterByStation(
					getQueryList.data.waitlist_info.accepted.filter(
						(item) => item.location_id === +currentLocationId
					)
				),
				[PatientStatus.NextInLine]: filterByStation(
					getQueryList.data.waitlist_info.next_to_serve.filter(
						(item) => item.location_id === +currentLocationId
					)
				),
				[PatientStatus.Serving]: filterByStation(
					getQueryList.data.waitlist_info.getting_service.filter(
						(item) => item.location_id === +currentLocationId
					)
				),
				[PatientStatus.Schedule]: [],
			});
		}
	};

	return {
		handlePatientStatusChange,
	};
};

export default useStatusSelection;
