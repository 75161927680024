import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "@src/components/form/FormInput";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@src/components/ui/accordion";
import { Button } from "@src/components/ui/button";
import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import React, { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const StepTwo: React.FC<{
	step?: number;
	setStep: Dispatch<SetStateAction<number>>;
}> = ({ setStep }) => {
	const schema = z.object({
		url: z.string().url(),
	});

	const {
		register,
		// handleSubmit,
		// setError,
		formState: { errors },
	} = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
	});
	return (
		<div className="m-1.5 flex flex-1 flex-col items-center  pt-[57px]">
			<div className="flex items-center space-x-[52px]">
				<img
					src="/assets/images/logo-white.svg"
					className="size-16 rounded-xl border border-[#DEDEDE] bg-[#005893] p-3"
					alt="Migranium logo"
				/>
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 16L7.19836 7.91111L0.163599 0H1.86503L8.01636 6.99259L14.135 0H15.8364L8.80164 7.91111L16 16H14.2658L8.01636 8.82963L1.73415 16H0Z"
						fill="#858C95"
					/>
				</svg>

				<img
					className="size-16 rounded-xl border border-[#28C466] bg-white p-1.5 shadow-[0px_0px_12px_0px_rgba(40,196,102,0.5),0px_0px_20px_0px_rgba(0,0,0,0.1)]"
					src="/assets/images/integration/oscar/oscar.png"
					alt="Oscar Pro logo"
				/>
			</div>
			<div className="mt-12 space-y-3 text-center">
				<p className="text-[24px] font-semibold leading-[30px] text-[#09090B]">
					You need to{" "}
					<span className="text-[#E42424]">reconnect!</span>{" "}
				</p>
				<p className="text-[18px] text-[#71717A]">
					Connected since: DD MM YYYY{" "}
				</p>
			</div>
			<form className="mt-[29px] flex w-full max-w-[805px] flex-col rounded-xl border border-[#E4E4E7] bg-white p-6 shadow-[0_10px_15px_-3px_rgba(0,0,0,0.1)]">
				<div className="flex flex-col space-y-1.5">
					<Label>
						Go to your organization&apos;s Unique EMR Link
					</Label>
					<Input
						value="www.oscarpro.emr/client-name/39094999"
						className="shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
						{...register("url")}
					/>
				</div>
				<Button
					className="mt-4 h-9 self-end text-white"
					onClick={(e) => {
						e.preventDefault();
						setStep(2);
					}}
				>
					Connect to your EMR
				</Button>
			</form>
			<div className="mt-[26px] w-full max-w-[805px] rounded-xl border border-[#E4E4E7] p-6 shadow-[0px_10px_15px_-3px_rgba(0,0,0,0.1),0px_4px_6px_-4px_rgba(16,24,40,0.1)]">
				<Accordion
					type="single"
					collapsible
					className="w-full max-w-[805px]"
				>
					<AccordionItem value="item-1" className="border-none">
						<AccordionTrigger
							className="flex w-full items-center justify-between p-0 hover:no-underline [&[data-state=open]>figure>i]:rotate-180"
							showChevron={false}
						>
							<div className="flex flex-col">
								<h3 className="text-lg font-semibold text-[#09090B]">
									EMR Integration Information
								</h3>
								<p className="text-start text-[14px] font-normal text-[#71717A]">
									View your connection details
								</p>
							</div>
							<figure className="grid size-[38px] place-content-center rounded-lg bg-[#09244B0A]">
								<i className="mgc_down_line text-[18px] transition-transform duration-200" />
							</figure>
						</AccordionTrigger>
						<AccordionContent>
							<form className="my-4 flex flex-col space-y-4">
								<div className="flex flex-col space-y-1.5">
									<Label>Client ID / Key</Label>
									<Input
										value="39409-skokos"
										className="cursor-default text-opacity-30 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
										disabled
									/>
								</div>
								<div className="flex flex-col space-y-1.5">
									<Label>Client Secret</Label>
									<Input
										value="39409-skokos"
										className="cursor-default text-opacity-30 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
										disabled
										readOnly
									/>
								</div>
								<div className="flex flex-col space-y-1.5">
									<Label>EMR Base URI</Label>
									<Input
										value="https://hospitalname.migranium.com"
										className="cursor-default text-opacity-30 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
										disabled
										readOnly
									/>
								</div>
							</form>
						</AccordionContent>
					</AccordionItem>
				</Accordion>
			</div>
		</div>
	);
};

export default StepTwo;
