import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@src/components/ui/dialog";
import { QueueEntry } from "@type/waitlist/waitlist";
import dayjs from "dayjs";
import React, { useState } from "react";
import { LuX } from "react-icons/lu";
import { Button } from "../../ui/button";

const PatientDetails: React.FC<{
	// patientDetails: IPatientData;
	patientDetails: QueueEntry;
}> = ({ patientDetails }) => {
	const [show, setShow] = useState(false);
	// const [isEditing, setIsEditing] = useState(false);

	// const [stationList, setStationList] = useState<
	// 	{
	// 		name: string;
	// 		state: PatientStatus;
	// 		priority: Priority;
	// 		est_wait_time: string;
	// 		queue_id: string;
	// 		type_of_entry: TypeOfEntry;
	// 		number: number;
	// 	}[]
	// >(patientDetails.station_order);

	const t = dayjs(
		new Date().getTime() - new Date(patientDetails.created_at).getTime()
	);

	const totalWaitTime =
		+t.format("H") < 1
			? +t.format("m") > 1
				? t.format("m [min(s)]")
				: t.format("m [min]")
			: +t.format("H") === 1
				? t.format("H[hr] m [min(s)]")
				: t.format("H[hr(s)] m [min(s)]");

	return (
		<>
			<Button
				className="h-[18px] w-[22px] rounded-sm bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
				onClick={() => setShow(true)}
			>
				<i className="mgc_information_line text-[14px] before:!text-[#323539]" />
			</Button>
			<Dialog open={show} onOpenChange={setShow}>
				<DialogContent
					className="max-w-[860px]"
					onPointerDown={(e) => e.stopPropagation()}
					onPointerDownCapture={(e) => e.stopPropagation()}
					onPointerUp={(e) => e.stopPropagation()}
					onPointerUpCapture={(e) => e.stopPropagation()}
				>
					<DialogHeader>
						<div className="flex justify-between">
							<div className="flex items-center space-x-3">
								<DialogTitle className="text-[22px] font-medium capitalize leading-[30px] -tracking-[1%] text-main-1">
									{patientDetails.customer.full_name}
								</DialogTitle>
								<Button className="flex h-[18px] w-[22px] items-center justify-center rounded-[4px] bg-[#F6E2E1] p-0 hover:bg-[#F6E2E1]">
									<i
										className={`mgc_tag_fill text-[14px] before:!text-[#C8322B]`}
									/>
								</Button>
							</div>
							<LuX
								onClick={() => setShow(false)}
								color="#858C95"
								className="cursor-pointer"
								width="20px"
								height="20px"
							/>
						</div>
					</DialogHeader>
					<div>
						<div className="flex flex-col space-y-2 border-b pb-7">
							<div className="mt-5 flex justify-between text-base">
								<div>
									<p className="text-base leading-6 tracking-[-1%] text-main-4">
										Email Address
									</p>
									<p className="text-main-1">
										{patientDetails.customer.email ?? "NIL"}
									</p>
								</div>
								<div className="flex items-center gap-14">
									<div>
										<p className="text-base leading-6 tracking-[-1%] text-main-4">
											Phone
										</p>
										<p className="text-main-1">
											(
											{patientDetails.customer.phone_number.slice(
												0,
												-10
											)}
											){" "}
											{patientDetails.customer.phone_number.slice(
												-10
											)}
										</p>
									</div>
									<div>
										<p className="text-base leading-6 tracking-[-1%] text-main-4">
											Date
										</p>
										<p className="text-main-1">
											{dayjs(
												patientDetails.created_at
											).format("DD MMM-YYYY")}
										</p>
									</div>
								</div>
							</div>
							<div className="flex flex-col space-y-1">
								<p className="leading-6 tracking-[-1%] text-main-4">
									Tags
								</p>
								<div className="flex w-fit items-center space-x-1 rounded-[6px] bg-[#F6E2E1] px-[7px] py-1">
									{/* <img src={priorityIcon} alt="priority" /> */}
									<svg
										width="14"
										height="14"
										viewBox="0 0 14 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M6.14729 1.26257C6.62469 1.21916 7.09716 1.37359 7.45589 1.68645L7.54314 1.76794L12.1065 6.33132C12.7626 6.98736 12.7888 8.03477 12.1852 8.72217L12.1065 8.80617L8.80664 12.106C8.1506 12.7621 7.10319 12.7883 6.41579 12.1848L6.33179 12.106L1.76843 7.54267C1.42944 7.20365 1.2456 6.74182 1.2563 6.26595L1.26305 6.14675L1.53804 3.12193C1.61134 2.31567 2.22658 1.66989 3.01819 1.55016L3.12242 1.53756L6.14729 1.26257ZM4.6813 4.6814C4.22569 5.13701 4.22569 5.87568 4.6813 6.33132C5.13691 6.7869 5.87563 6.7869 6.33121 6.33132C6.78685 5.87568 6.78685 5.13701 6.33121 4.6814C5.87563 4.22578 5.13691 4.22578 4.6813 4.6814Z"
											fill="#C8322B"
										/>
									</svg>
									<p className="text-[10px] text-status-1">
										{patientDetails.customer.priority}
									</p>
								</div>
							</div>
						</div>
						<div className="mt-5 flex justify-between border-b pb-7 text-base">
							<div>
								<p className="text-base leading-6 tracking-[-1%] text-main-4">
									Location
								</p>
								<p className="text-main-1">
									{patientDetails.location.name}
								</p>
							</div>
							<div className="flex items-center gap-14">
								<div>
									<div className="text-base leading-6 tracking-[-1%] text-main-4">
										Arrival Time
									</div>
									<p className="font-medium text-main-1">
										{dayjs(
											new Date(
												patientDetails.created_at
											).getTime()
										).format("hh:mm a")}
									</p>
								</div>
								<div>
									<div className="text-base leading-6 tracking-[-1%] text-main-4">
										Total Wait Time
									</div>
									<div className="font-medium text-main-1">
										{totalWaitTime}
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="flex flex-col space-y-4">
						<div className="flex justify-between">
							<h1 className="text-[22px] font-medium leading-[30px] text-main-1">
								Activity
							</h1>
							<div className="flex gap-3">
								<Button
									className="flex h-9 items-center rounded-md border border-main-2 text-sm font-medium leading-[22px] text-main-2"
									variant="outline"
								>
									No Sequence
								</Button>
								<Button
									className="flex h-9 items-center rounded-md bg-main-2 text-sm font-medium"
									onClick={() => setIsEditing(!isEditing)}
								>
									{isEditing ? "Editing..." : "Edit Sequence"}
								</Button>
							</div>
						</div>
						<div className="mt-2 flex items-center justify-between">
							<CustomSelect
								className="max-w-[272px]"
								options={[]}
								label="Station"
								placeholder="Station"
								onChange={() => {
									//
								}}
							/>
							<div className="flex max-w-[382px] flex-1 space-x-2.5">
								<CustomSelect
									className="max-w-[186px]"
									options={[]}
									label="Station"
									placeholder="Station"
									onChange={() => {
										//
									}}
								/>
								<CustomSelect
									className="max-w-[186px]"
									options={[]}
									label="Station"
									placeholder="Sort By"
									onChange={() => {
										//
									}}
								/>
							</div>
						</div>
						{patientDetails.has_sequence &&
							stationList?.map((item, index) => (
								<div
									className="mt-4 flex items-center justify-between rounded-lg border border-[#E5E5E7] bg-white pl-4 pr-3 text-xs leading-3"
									key={index}
								>
									<div className="flex items-center py-2 text-main-4">
										<div className="flex items-center space-x-4">
											<p className="flex h-7 items-center border-r px-1.5 font-bold text-main-1">
												{index + 1}
											</p>
											<div className=" flex h-7 items-center gap-1 border-r pr-1.5">
												<p className="w-[30px] text-end">
													{item.number
														.toString()
														.padStart(2, "0")}
												</p>

												{item.type_of_entry ===
													TypeOfEntry.QrCode && (
													<i className="mgc_qrcode_line" />
												)}

												{item.type_of_entry ===
													TypeOfEntry.Web && (
													<svg
														width="10"
														height="10"
														viewBox="0 0 10 10"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M5 0.125C4.03582 0.125 3.09329 0.410914 2.2916 0.946586C1.48991 1.48226 0.865067 2.24363 0.496089 3.13442C0.127112 4.02521 0.030571 5.00541 0.218674 5.95107C0.406777 6.89672 0.871076 7.76536 1.55286 8.44715C2.23464 9.12893 3.10328 9.59323 4.04894 9.78133C4.99459 9.96943 5.97479 9.87289 6.86558 9.50391C7.75637 9.13494 8.51775 8.51009 9.05342 7.7084C9.58909 6.90671 9.875 5.96418 9.875 5C9.87364 3.70749 9.35958 2.46831 8.44564 1.55436C7.5317 0.640418 6.29251 0.126365 5 0.125ZM3.76391 6.875H6.2361C5.98438 7.73469 5.5625 8.50953 5 9.11984C4.4375 8.50953 4.01563 7.73469 3.76391 6.875ZM3.59375 6.125C3.46938 5.38016 3.46938 4.61984 3.59375 3.875H6.40625C6.53063 4.61984 6.53063 5.38016 6.40625 6.125H3.59375ZM0.875002 5C0.874676 4.61958 0.927209 4.24096 1.0311 3.875H2.83391C2.72203 4.62083 2.72203 5.37917 2.83391 6.125H1.0311C0.927209 5.75904 0.874676 5.38042 0.875002 5ZM6.2361 3.125H3.76391C4.01563 2.26531 4.4375 1.49047 5 0.880156C5.5625 1.49047 5.98438 2.26531 6.2361 3.125ZM7.1661 3.875H8.96891C9.17704 4.61056 9.17704 5.38944 8.96891 6.125H7.1661C7.27797 5.37917 7.27797 4.62083 7.1661 3.875ZM8.6736 3.125H7.01281C6.82144 2.37196 6.50005 1.65811 6.06313 1.01562C6.62134 1.16563 7.14197 1.43081 7.59156 1.79409C8.04114 2.15738 8.40972 2.61073 8.6736 3.125ZM3.93688 1.01562C3.49995 1.65811 3.17856 2.37196 2.98719 3.125H1.32641C1.59028 2.61073 1.95886 2.15738 2.40844 1.79409C2.85803 1.43081 3.37867 1.16563 3.93688 1.01562ZM1.32641 6.875H2.98719C3.17856 7.62804 3.49995 8.34189 3.93688 8.98438C3.37867 8.83437 2.85803 8.56919 2.40844 8.20591C1.95886 7.84262 1.59028 7.38927 1.32641 6.875ZM6.06313 8.98438C6.50005 8.34189 6.82144 7.62804 7.01281 6.875H8.6736C8.40972 7.38927 8.04114 7.84262 7.59156 8.20591C7.14197 8.56919 6.62134 8.83437 6.06313 8.98438Z"
															fill="#323539"
														/>
													</svg>
												)}
												{item.type_of_entry ===
													TypeOfEntry.Appointment && (
													<i className="mgc_calendar_2_line" />
												)}
											</div>
											<div className="flex items-center space-x-2.5">
												<p>Hello world</p>
												{item.priority ===
													Priority.High && (
													<div className="flex h-[22px] w-[28px] items-center justify-center rounded-md bg-[#F6E2E1]">
														<i className="mgc_tag_fill before:text-[14px] before:!text-status-1" />
													</div>
												)}
											</div>
										</div>
									</div>
									<div className="flex items-center space-x-3">
										<span className="mr-4 text-main-4">
											1 hr 20m
										</span>

										<PatientStatusButton
											state={item.state}
										/>
									</div>
									{isEditing ? (
										<SequenceItemList
											stationList={stationList}
											setStationList={setStationList}
											typeOfSequence={
												StationSequence.SelectSqeuence
											}
											index={index}
										/>
									) : (
										<div
											className={`flex items-center space-x-1.5 ${item.state === PatientStatus.Done && "invisible"}`}
										>
											<img
												className="size-[30px] rounded-lg bg-[#F5F5F5] p-2"
												src={transferIcon}
												alt="transfer icon"
											/>
											{item.state ===
												PatientStatus.Done && (
												<img
													className="size-[30px] rounded-lg bg-[#F5F5F5] p-2"
													src={transferIcon}
													alt="transfer icon"
												/>
											)}
											<Button
												className={`size-[30px] rounded-lg bg-[#F5F5F5] p-0 hover:bg-[#f5f5f5] ${item.state === PatientStatus.Done && "cursor-default"}`}
											>
												<i className="mgc_transfer_4_line before:size-3.5" />
											</Button>
											<Button
												className={`size-[30px] rounded-lg bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5] ${item.state === PatientStatus.Done && "cursor-default"}`}
											>
												<i className="mgc_bell_ringing_line before:size-3.5" />
											</Button>
											<Button
												className={`size-[30px] rounded-lg bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5] ${item.state === PatientStatus.Done && "cursor-default"}`}
											>
												<i className="mgc_delete_2_line before:size-3.5" />
											</Button>

											{(item.state ===
												PatientStatus.Upcoming ||
												item.state ===
													PatientStatus.NextInLine) && (
												<Button
													className={`size-[30px] rounded-lg bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]`}
												>
													<i className="mgc_pause_circle_line before:size-3.5" />
												</Button>
											)}
											{item.state ===
												PatientStatus.Pending && (
												<Button
													className={`group size-[30px] border border-[#C8322B] bg-white p-0 hover:bg-[#C8322B] hover:bg-opacity-20`}
												>
													<i className="mgc_check_circle_line before:!text-[#C8322B] " />
												</Button>
											)}
											{item.state ===
												PatientStatus.Serving && (
												<Button className="group size-[30px] border border-[#1E8A7C] bg-[#1E8A7C] p-0 hover:bg-[#1E8A7C] hover:bg-opacity-70 ">
													<i className="mgc_check_circle_fill before:!text-white " />
												</Button>
											)}
											<i className="mgc_down_line flex size-8 items-center justify-center before:size-3" />
										</div>
									)}
								</div>
							))}
					</div> */}
				</DialogContent>
			</Dialog>
		</>
	);
};

export default PatientDetails;

// const PatientStatusButton: React.FC<{ state: PatientStatus }> = ({ state }) => {
// 	const backgroundColour = `${state === PatientStatus.Pending ? "bg-status-1" : state === PatientStatus.Upcoming ? "bg-[#EEA23E]" : state === PatientStatus.NextInLine ? "bg-[#285A8A]" : state === PatientStatus.Serving ? "bg-[#138576]" : "bg-[#596574]"} `;

// 	return (
// 		<div
// 			className={
// 				"mr-10 w-[70px] select-none rounded-lg py-1.5 text-center text-xxs text-white " +
// 				backgroundColour
// 			}
// 		>
// 			{state === PatientStatus.Pending
// 				? "Pending"
// 				: state === PatientStatus.Upcoming
// 					? "Upcoming"
// 					: state === PatientStatus.NextInLine
// 						? "Next In Line"
// 						: state === PatientStatus.Serving
// 							? "Serving"
// 							: "Done"}
// 		</div>
// 	);
// };

// const SequenceItemList: React.FC<{
// 	stationList: {
// 		name: string;
// 		state: PatientStatus;
// 		priority: Priority;
// 		est_wait_time: string;
// 		queue_id: string;
// 		type_of_entry: TypeOfEntry;
// 		number: number;
// 	}[];
// 	setStationList: Dispatch<
// 		SetStateAction<
// 			{
// 				name: string;
// 				state: PatientStatus;
// 				priority: Priority;
// 				est_wait_time: string;
// 				queue_id: string;
// 				type_of_entry: TypeOfEntry;
// 				number: number;
// 			}[]
// 		>
// 	>;
// 	typeOfSequence: StationSequence;
// 	index: number;
// }> = ({ stationList, setStationList, typeOfSequence, index }) => {
// 	const swapItem = (currentIndex: number, typeOfButton: "up" | "down") => {
// 		setStationList((prev) => {
// 			const tempItem = prev[currentIndex];
// 			const updatedList = [...prev];
// 			if (typeOfButton === "up" && currentIndex > 0) {
// 				updatedList[currentIndex] = prev[currentIndex - 1];
// 				updatedList[currentIndex - 1] = tempItem;
// 			} else if (
// 				typeOfButton === "down" &&
// 				currentIndex < prev.length - 1
// 			) {
// 				updatedList[currentIndex] = prev[currentIndex + 1];
// 				updatedList[currentIndex + 1] = tempItem;
// 			}
// 			return updatedList;
// 		});
// 	};

// 	return (
// 		<div>
// 			<SequenceItem
// 				typeOfSequence={typeOfSequence}
// 				index={index + 1}
// 				lastIndex={stationList.length}
// 				swapItem={(currentIndex, typeOfButton) => {
// 					swapItem(currentIndex, typeOfButton);
// 				}}
// 			/>
// 		</div>
// 	);
// };

// const SequenceItem: React.FC<{
// 	typeOfSequence: StationSequence;
// 	index: number;
// 	lastIndex: number;
// 	swapItem: (currentIndex: number, typeOfButton: "up" | "down") => void;
// }> = ({ index, typeOfSequence, lastIndex, swapItem }) => {
// 	return (
// 		<>
// 			{StationSequence.SelectSqeuence === typeOfSequence && (
// 				<div className="flex items-center space-x-1">
// 					<button
// 						className={`flex size-[30px] items-center justify-center rounded-md ${index === lastIndex ? "invisible" : "bg-main-6"}`}
// 						onClick={() => {
// 							if (index !== lastIndex) {
// 								swapItem(index - 1, "down");
// 							}
// 						}}
// 					>
// 						<svg
// 							width="14"
// 							height="14"
// 							viewBox="0 0 14 14"
// 							fill="none"
// 							xmlns="http://www.w3.org/2000/svg"
// 						>
// 							<path
// 								d="M7.11914 1C7.32854 1.00003 7.53066 1.07691 7.68714 1.21606C7.84363 1.35521 7.94361 1.54695 7.96811 1.75492L7.97409 1.85495L7.97409 10.0805L9.53694 8.5176C9.69044 8.36264 9.89737 8.2722 10.1154 8.26481C10.3334 8.25741 10.5459 8.33361 10.7096 8.47782C10.8732 8.62202 10.9756 8.82332 10.9957 9.04051C11.0158 9.2577 10.9521 9.47436 10.8177 9.64614L10.7458 9.72651L7.72359 12.7496C7.57637 12.8968 7.3805 12.9852 7.17273 12.9983C6.96495 13.0114 6.75955 12.9482 6.59505 12.8206L6.51469 12.7496L3.49157 9.72651C3.33824 9.57265 3.24922 9.3662 3.24259 9.14908C3.23596 8.93197 3.31222 8.72047 3.45588 8.55755C3.59954 8.39462 3.79983 8.29249 4.01606 8.27189C4.2323 8.25129 4.44827 8.31377 4.62011 8.44664L4.70133 8.5176L6.26419 10.0796L6.26419 1.85495C6.26419 1.62821 6.35426 1.41074 6.5146 1.25041C6.67493 1.09008 6.89239 1 7.11914 1Z"
// 								fill="#09244B"
// 							/>
// 						</svg>
// 					</button>

// 					<button
// 						className={`flex size-[30px] items-center justify-center rounded-md  ${index === 1 ? "invisible" : "bg-main-6"}`}
// 						onClick={() => {
// 							if (index !== 1) {
// 								swapItem(index - 1, "up");
// 							}
// 						}}
// 					>
// 						<svg
// 							width="14"
// 							height="14"
// 							viewBox="0 0 14 14"
// 							fill="none"
// 							xmlns="http://www.w3.org/2000/svg"
// 						>
// 							<path
// 								d="M7.12305 13C6.91364 13 6.71153 12.9231 6.55504 12.7839C6.39856 12.6448 6.29858 12.453 6.27408 12.2451L6.2681 12.145L6.2681 3.91954L4.70524 5.4824C4.55174 5.63736 4.34482 5.7278 4.12683 5.73519C3.90883 5.74259 3.69625 5.66639 3.5326 5.52218C3.36895 5.37798 3.26661 5.17668 3.24652 4.95949C3.22642 4.7423 3.2901 4.52564 3.42452 4.35386L3.49634 4.27349L6.5186 1.25038C6.66581 1.10318 6.86168 1.01475 7.06946 1.00169C7.27724 0.988621 7.48264 1.05182 7.64714 1.17942L7.7275 1.25038L10.7506 4.27349C10.9039 4.42735 10.993 4.6338 10.9996 4.85092C11.0062 5.06803 10.93 5.27953 10.7863 5.44245C10.6426 5.60538 10.4424 5.70751 10.2261 5.72811C10.0099 5.74871 9.79392 5.68623 9.62208 5.55336L9.54086 5.4824L7.978 3.9204L7.978 12.145C7.978 12.3718 7.88793 12.5893 7.72759 12.7496C7.56726 12.9099 7.3498 13 7.12305 13Z"
// 								fill="#09244B"
// 							/>
// 						</svg>
// 					</button>
// 				</div>
// 			)}
// 		</>
// 	);
// };
