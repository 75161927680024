import { cn } from "../../../utils/functions";
import CurrentAppointmentCard from "./CurrentAppointmentCard";
import PendingAppointmentCard from "./PendingAppointmentCard";

interface AppointmentColumnProps {
	status: "Pending" | "Current";
	// viewType: "Today" | "Day" | "Week" | "Month";
	patientData?: Array<Record<string, object>>;
}

const AppointmentColumn = ({ status }: AppointmentColumnProps) => {
	return (
		<div className="flex h-[80vh] w-[318px] max-w-full flex-col rounded-md border-[0.5px] border-[#D9D9D9]  bg-[#F7F7F8]">
			<div
				className={cn(" rounded-tl-md rounded-tr-md bg-[#138576]", {
					"bg-[#C8322B]": status == "Pending",
					"bg-[#138576]": status == "Current",
				})}
			>
				<p className="p-3 text-[20px] font-[600] text-white">
					{status === "Pending" && "Pending Appointments"}
					{status === "Current" && "Today's Appointments"}
				</p>
			</div>
			<div className="h-full  overflow-y-scroll">
				{status == "Pending" ? (
					<>
						<PendingAppointmentCard />
					</>
				) : null}
				{status == "Current" ? (
					<>
						<CurrentAppointmentCard online={true} />
						<CurrentAppointmentCard online={false} />
					</>
				) : null}
				{/* // <CurrentAppointmentCard online={false} />
				// <CurrentAppointmentCard online={false} />
				// <CurrentAppointmentCard online={false} /> */}
			</div>
		</div>
	);
};

export default AppointmentColumn;
