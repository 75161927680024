import { QueueEntry } from "@type/waitlist/waitlist";
import React, { useRef, useState } from "react";
import { LuX } from "react-icons/lu";
import { usePatientSendToServing } from "../../../hooks/useLocationData";
import { toTitleCase } from "../../../utils/functions";
import useCustomToast from "../../CustomToast";
import { Overlay } from "../../Overlay";
import { Button } from "../../ui/button";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../../ui/card";

const PatientSentToServingModal: React.FC<{
	patientDetails: QueueEntry;
}> = ({ patientDetails }) => {
	const [showPatientSentToServingModal, setShowPatientSentToServingModal] =
		useState(false);

	const modalRef = useRef(null);

	const customToast = useCustomToast();

	const { mutate } = usePatientSendToServing(
		patientDetails.customer_id,
		() => {
			customToast("Patient sent to serving", {
				id: "move-patient-serving",
				type: "success",
				undoText: "	Undo",
			});
			setTimeout(() => {
				setShowPatientSentToServingModal(true);
			}, 1100);
		}
	);

	return (
		<>
			<button
				className="rounded-md border border-[#2AA63C] bg-[#F5F5F5] p-2"
				onClick={() => {
					customToast("Moving a customer to serving", {
						id: "move-patient-serving",
						undoText: "	Undo",
						type: "loading",
					});
					mutate({});
				}}
			>
				<svg
					width="12"
					height="13"
					viewBox="0 0 12 13"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M6.00033 0.666626C9.22208 0.666626 11.8337 3.27821 11.8337 6.49996C11.8337 9.72171 9.22208 12.3333 6.00033 12.3333C2.77858 12.3333 0.166992 9.72171 0.166992 6.49996C0.166992 3.27821 2.77858 0.666626 6.00033 0.666626ZM8.06241 4.38888L5.17491 7.27638L3.93824 6.03913C3.88408 5.98493 3.81978 5.94193 3.749 5.91258C3.67822 5.88324 3.60236 5.86812 3.52574 5.86809C3.371 5.86804 3.22257 5.92945 3.11312 6.03883C3.00366 6.14821 2.94214 6.2966 2.94208 6.45134C2.94203 6.60608 3.00345 6.7545 3.11283 6.86396L4.72166 8.47279C4.78125 8.53241 4.852 8.5797 4.92987 8.61197C5.00774 8.64423 5.0912 8.66084 5.17549 8.66084C5.25978 8.66084 5.34325 8.64423 5.42112 8.61197C5.49899 8.5797 5.56974 8.53241 5.62933 8.47279L8.88783 5.21429C8.99728 5.10484 9.05877 4.95638 9.05877 4.80158C9.05877 4.64679 8.99728 4.49833 8.88783 4.38888C8.77837 4.27942 8.62991 4.21793 8.47512 4.21793C8.32032 4.21793 8.17187 4.27942 8.06241 4.38888Z"
						fill="#2AA63C"
					/>
				</svg>
			</button>
			<Overlay
				show={showPatientSentToServingModal}
				setShow={setShowPatientSentToServingModal}
				modalRef={modalRef}
			>
				<form className="w-full max-w-[400px]">
					<Card className="flex flex-col space-y-4 p-0">
						<div className="space-y-1">
							<CardHeader className="p-4 pb-0">
								<div className="flex justify-between">
									<CardTitle className="text-[22px] leading-[30px] -tracking-[1%]">
										Sent to Serving
									</CardTitle>
									<LuX
										color="#858C95"
										size={20}
										className="cursor-pointer self-center"
										onClick={() =>
											setShowPatientSentToServingModal(
												false
											)
										}
									/>
								</div>
							</CardHeader>
							<CardContent className="px-4 py-0 text-sm leading-[20px] tracking-[-0.1px] text-main-4">
								{toTitleCase(patientDetails.customer.full_name)}{" "}
								has been sent to serving from next inline list.
								This action can&apos;t be undone after 10
								seconds.
							</CardContent>
						</div>
						<CardFooter className="p-4 pt-0">
							<Button
								variant="ghost"
								type="submit"
								className="px-0 text-[#005893] hover:!bg-transparent hover:!text-[#005893]"
							>
								Undo Action
							</Button>
						</CardFooter>
					</Card>
				</form>
			</Overlay>
		</>
	);
};

export default PatientSentToServingModal;
