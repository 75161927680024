import { z } from "zod";

export interface Station {
	id: number;
	name: string;
	location_id: number;
	admin_id: number;
	created_at: Date;
	updated_at: Date;
	deleted_at: null;
	laravel_through_key: number;
	teamMembers: TeamMembers;
	url_code: string;
	queue_url: string;
	admin: Admin;
	location: Location;
	is_active: boolean;
	is_queue_active: boolean;
}

interface Admin {
	id: number;
	name: string;
	email: string;
	role: string;
	job_title: null;
	stripe_customer_id: null;
	created_at: Date;
	updated_at: Date;
	deleted_at: null;
	stripe_id: string;
	pm_type: string;
	pm_last_four: string;
	trial_ends_at: null;
	business_id: number;
	is_email_verified: number;
}

interface Location {
	id: number;
	name: string;
	address: string;
	country: string;
	state: string;
	city: string;
	admin_id: number;
	business_id: number;
	approximate_waiting_time: string;
	created_at: Date;
	updated_at: Date;
	deleted_at: null;
}

interface TeamMembers {}

export const settingsSchema = z.object({
	id: z.number().optional(),
	settingable_type: z.string().optional(),
	settingable_id: z.number().optional(),
	created_at: z.string().optional(),
	updated_at: z.string().optional(),
	settings: z
		.object({
			time_zone: z
				.string()
				.refine((value) => !!value, {
					message: "Kindly select time zone",
				})
				.optional(),
			is_queue_active: z.number().optional(),
			manual_wait_time: z.string().optional(),
			auto_flow_enabled: z.number().optional(),
			average_wait_time: z.string().optional(),
			auto_approve_enabled: z.number().optional(),
			schedule_block_in_min: z.number().optional(),
			total_waitlists_count: z.number().optional(),
			use_average_wait_time: z.number().optional(),
			priority_order_enabled: z.number().optional(),
		})
		.optional(),
});

export const stationSchema = z.object({
	id: z.number().optional(),
	name: z.string().optional(),
	location_id: z.number().optional(),
	admin_id: z.number().optional(),
	created_at: z.string().optional(),
	updated_at: z.string().optional(),
	is_active: z.number().optional(),
	business_id: z.number().optional(),
	schedule_before: z.any().optional(),
	average_waiting_time: z.any().optional(),
	use_average_wait_time: z.number().optional(),
	queue_url: z.string().optional(),
	url_code: z.string().optional(),
	schedule_url: z.string().optional(),
	is_queue_active: z.boolean().optional(),
	is_authorized_to_user: z.boolean().optional(),
	settings: settingsSchema.optional(),
});

export type EditStationType = z.infer<typeof stationSchema>;

export const addStationSchema = z.object({
	name: z.string().min(2, { message: "A valid station name is required" }),
});

export type AddStationType = z.infer<typeof addStationSchema>;
