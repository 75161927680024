import { PatientStatus } from "@src/types/wait-list";
import { QueueData } from "@src/types/waitlist/waitlist";
import useUserStore from "@store/useUserStore";
import useWaitListStore from "@store/useWaitListStore";
import { useQueryClient } from "react-query";

const useLocationSelection: () => {
	handleLocationChange: (value: string) => void;
} = () => {
	const queryClient = useQueryClient();
	const setCurrentLocationId = useUserStore((s) => s.setCurrentLocationId);
	const setCurrentStationId = useUserStore((s) => s.setCurrentStationId);
	const setPatientsObject = useWaitListStore((s) => s.setPatientsObject);
	const getQueryList = queryClient.getQueryData("queue-list") as {
		status: boolean;
		data: QueueData;
		message: string;
	};

	const handleLocationChange = (value: string) => {
		if (!getQueryList) return;
		const locationId = parseInt(value);
		setCurrentStationId("All Stations");
		setCurrentLocationId(locationId);
		setPatientsObject({
			[PatientStatus.Pending]:
				getQueryList.data.waitlist_info.waiting_to_be_approved.filter(
					(item) => item.location_id === locationId
				),
			[PatientStatus.Upcoming]:
				getQueryList.data.waitlist_info.accepted.filter(
					(item) => item.location_id === locationId
				),
			[PatientStatus.NextInLine]:
				getQueryList.data.waitlist_info.next_to_serve.filter(
					(item) => item.location_id === locationId
				),
			[PatientStatus.Serving]:
				getQueryList.data.waitlist_info.getting_service.filter(
					(item) => item.location_id === locationId
				),
			[PatientStatus.Schedule]: [],
		});
	};

	return {
		handleLocationChange,
	};
};

export default useLocationSelection;
