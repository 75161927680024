import { Country, State } from "country-state-city";
import ct from "countries-and-timezones";
import moment from "moment-timezone";
import { OperatingHour, OperatingHourV2 } from "@type/DaySlots";
import { Option } from "@src/components/form/CustomSelect";
import {
	PatientPriority,
	PatientPriorityType,
} from "@src/types/waitlist/waitlist";

export const InputPatterns = {
	elevenTelephone: /^\d{11}$/,
	tenTelephone: /^\d{10}$/,
	universalTelephone:
		/^\+(?:[1-9]|[1-9][0-9]|1\d{2}|2[0-4][0-9]|25[0-9])\d{10}$/,
	password: /^(?=.*[\W_]).{8,20}$/,
	email: /^([a-z\d.-_]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?(\.[a-z]{2,8})?(\.[a-z]{2,8})?$/,
};

export const countryCodes = Array.from({ length: 249 }, (_, i) => {
	return { label: "+" + (i + 1), value: "+" + (i + 1) };
});

const initialCountryOptions = Object.values(Country.getAllCountries())
	.map((country) => ({
		value: country.isoCode,
		label: country.name,
	}))
	.filter((country) => country.value !== "CA" && country.value !== "US");

export const countryOptions = [
	{ value: "CA", label: "Canada" },
	{ value: "US", label: "United States" },
	...initialCountryOptions,
];

export const statesOptions = (country: string) =>
	country
		? Object.values(State.getStatesOfCountry(country)).map((state) => ({
				label: state.name,
				value: state.isoCode,
			}))
		: [];

export const findCountry = (current: string = "") =>
	countryOptions.find((country) => country.value === current)?.label || "";

export const findState = (current: string = "", country: string = "") =>
	statesOptions(country).find((state) => state.value === current)?.label ||
	"";

export const getAllCountriesWithTimezones = () => {
	const allCountries = ct.getAllCountries();
	const allTimezones = ct.getAllTimezones();
	let countriesTimezones: any = [];

	for (const countryCode in allCountries) {
		const country = allCountries[countryCode];
		const countryTimezones = country.timezones;
		const timezones = countryTimezones.map((tz) => {
			const timezone = allTimezones[tz];

			const offset = parseInt(timezone.utcOffsetStr.split(":")[0]);
			return {
				label: `${country.name} (UTC ${offset > 0 ? "+" + offset : offset})`,
				value: `${country.id}|UTC ${offset > 0 ? "+" + offset : offset}`,
			};
		});
		countriesTimezones = [...countriesTimezones, ...timezones];
	}

	return countriesTimezones;
};

export const RoutePath = {
	HOME: "/",
	SIGN_IN: "/sign-in",
	SIGN_UP: "/sign-up",
	WAITLIST: "/dashboard/waitlist",
	ANALYTICS: "/dashboard/analytics",
	LOCATIONS: "/dashboard/locations",
	DISPLAY: "/dashboard/display",
	SCHEDULE: "/dashboard/schedule",
	SERVING: "/dashboard/serving",
	SETTINGS: "/dashboard/settings",
	TEAM_MEMBERS: "/dashboard/team-members",
};

export const timeZonesData = [
	{
		continent: "US/Canada",
		times: [
			{ id: "1", country: "Hawaii Time", offsetHours: -10 },
			{ id: "2", country: "Alaska Time", offsetHours: -8 },
			{ id: "3", country: "Pacific Time - US & Canada", offsetHours: -7 },
			{ id: "4", country: "Arizona, Yukon Time", offsetHours: -7 },
			{
				id: "5",
				country: "Mountain Time - US & Canada",
				offsetHours: -6,
			},
			{ id: "6", country: "Central Time - US & Canada", offsetHours: -5 },
			{ id: "7", country: "Eastern Time - US & Canada", offsetHours: -4 },
			{ id: "8", country: "Newfoundland Time", offsetHours: -2.5 },
		],
	},
	{
		continent: "America",
		times: [
			{ id: "9", country: "America/Adak", offsetHours: -9 },
			{ id: "10", country: "America/Mazatlan", offsetHours: -7 },
			{ id: "11", country: "America/Santa Isabel", offsetHours: -7 },
			{
				id: "12",
				country: "Saskatchewan, Costa Rica Time",
				offsetHours: -6,
			},
			{ id: "13", country: "Mexico City Time", offsetHours: -6 },
			{
				id: "14",
				country: "Bogota, Jamaica, Lima Time",
				offsetHours: -5,
			},
			{ id: "15", country: "America/Campo Grande", offsetHours: -4 },
			{ id: "16", country: "Caracas Time", offsetHours: -4 },
			{ id: "17", country: "America/Havana", offsetHours: -4 },
			{ id: "18", country: "Atlantic Standard Time", offsetHours: -4 },
			{ id: "19", country: "Buenos Aires Time", offsetHours: -3 },
			{ id: "20", country: "Asuncion Time", offsetHours: -3 },
			{ id: "21", country: "Atlantic Time", offsetHours: -3 },
			{ id: "22", country: "Montevideo Time", offsetHours: -3 },
			{ id: "23", country: "Santiago Time", offsetHours: -3 },
			{ id: "24", country: "Brasilia Time", offsetHours: -3 },
			{ id: "25", country: "America/Godthab", offsetHours: -2 },
			{ id: "26", country: "America/Miquelon", offsetHours: -2 },
			{ id: "27", country: "America/Noronha", offsetHours: -2 },
		],
	},
	{
		continent: "Africa",
		times: [
			{ id: "28", country: "West Africa Time", offsetHours: +1 },
			{ id: "29", country: "Cairo", offsetHours: 2 },
			{ id: "30", country: "Central Africa Time", offsetHours: +2 },
			{ id: "31", country: "Africa/Windhoek", offsetHours: +2 },
		],
	},
	{
		continent: "Asia",
		times: [
			{ id: "32", country: "Lebanon Time", offsetHours: +2 },
			{ id: "33", country: "Asia/Gaza", offsetHours: +2 },
			{ id: "35", country: "Jordan Time", offsetHours: +3 },
			{ id: "36", country: "Baghdad, East Africa Time", offsetHours: +3 },
			{ id: "37", country: "Syria Time", offsetHours: +3 },
			{ id: "38", country: "Tehran Time", offsetHours: +3.5 },
			{ id: "39", country: "Asia/Baku", offsetHours: +3 },
			{ id: "40", country: "Dubai Time", offsetHours: +4 },
			{ id: "41", country: "Asia/Yerevan", offsetHours: +4 },
			{ id: "42", country: "Kabul Time", offsetHours: +4.5 },
			{ id: "43", country: "Pakistan, Maldives Time", offsetHours: +4 },
			{ id: "44", country: "Yekaterinburg Time", offsetHours: +5 },
			{ id: "45", country: "India, Sri Lanka Time", offsetHours: +5.5 },
			{ id: "46", country: "Kathmandu Time", offsetHours: +5.75 },
			{ id: "47", country: "Asia/Dhaka", offsetHours: +6 },
			{ id: "48", country: "Asia/Omsk", offsetHours: +6 },
			{ id: "49", country: "Asia/Rangoon", offsetHours: +6.5 },
			{ id: "50", country: "Indochina Time", offsetHours: +7 },
			{ id: "51", country: "Krasnoyarsk Time", offsetHours: +8 },
			{ id: "52", country: "Asia/Irkutsk", offsetHours: +9 },
			{ id: "53", country: "China, Singapore, Perth", offsetHours: +8 },
			{ id: "54", country: "Japan, Korea Time", offsetHours: +9 },
			{ id: "55", country: "Asia/Yakutsk", offsetHours: +9 },
			{ id: "56", country: "Asia/Vladivostok", offsetHours: +10 },
			{ id: "57", country: "Pacific/Majuro", offsetHours: +12 },
		],
	},
	{
		continent: "Atlantic",
		times: [
			{ id: "58", country: "Azores Time", offsetHours: -1 },
			{ id: "59", country: "Cape Verde Time", offsetHours: -1 },
		],
	},
	{
		continent: "Australia",
		times: [
			{ id: "60", country: "Australia/Perth", offsetHours: +8 },
			{ id: "61", country: "Australia/Eucla", offsetHours: +8.75 },
			{ id: "62", country: "Australia/Darwin", offsetHours: +9.5 },
			{ id: "63", country: "Brisbane Time", offsetHours: +10 },
			{ id: "64", country: "Adelaide Time", offsetHours: +10.5 },
			{ id: "65", country: "Australia/Lord Howe", offsetHours: +11 },
			{ id: "66", country: "Sydney, Melbourne Time", offsetHours: +11 },
		],
	},
	{
		continent: "UTC",
		times: [{ id: "67", country: "UTC Time", offsetHours: 0 }],
	},
	{
		continent: "Europe",
		times: [
			{ id: "68", country: "UK, Ireland, Lisbon Time", offsetHours: 0 },
			{ id: "69", country: "Central European Time", offsetHours: +1 },
			{ id: "70", country: "Eastern European Time", offsetHours: +2 },
			{ id: "71", country: "Minsk Time", offsetHours: +3 },
			{ id: "72", country: "Moscow Time", offsetHours: +3 },
			{ id: "73", country: "Turkey Time", offsetHours: +3 },
		],
	},
	{
		continent: "Pacific",
		times: [
			{ id: "74", country: "Pacific/Pago Pago", offsetHours: -11 },
			{ id: "75", country: "Pacific/Marquesas", offsetHours: -9.5 },
			{ id: "76", country: "Pacific/Gambier", offsetHours: -9 },
			{ id: "77", country: "Pacific/Pitcairn", offsetHours: -8 },
			{ id: "78", country: "Pacific/Easter", offsetHours: -5 },
			{ id: "79", country: "Pacific/Noumea", offsetHours: +11 },
			{ id: "80", country: "Pacific/Fiji", offsetHours: +13 },
			{ id: "81", country: "Pacific/Majuro", offsetHours: +12 },
			{ id: "82", country: "Pacific/Norfolk", offsetHours: +11 },
			{ id: "83", country: "Pacific/Tarawa", offsetHours: +12 },
			{ id: "84", country: "Pacific/Apia", offsetHours: +14 },
			{ id: "85", country: "Auckland Time", offsetHours: +13 },
			{ id: "86", country: "Pacific/Tongatapu", offsetHours: +13 },
			{ id: "87", country: "Pacific/Chatham", offsetHours: +13.75 },
			{ id: "88", country: "Pacific/Kiritimati", offsetHours: +14 },
		],
	},
];

export const ProductTypeOptions = [
	{
		value: "primary",
		label: "Scheduler + Flow",
	},
	{
		value: "room_booking",
		label: "Spaces",
	},
];
export const appointmentTypes = [
	{ label: "Follow Up", value: "Follow Up" },
	{ label: "Initial Consultation", value: "Initial Consultation" },
];

export const mockStations = [
	{ value: "Dr Kurt Bates", label: "Dr Kurt", id: 1 },
	{ value: "Dr Paula Morra", label: "Dr Paula", id: 2 },
	{ value: "Dr James", label: "Dr James", id: 3 },
];

export type TimeZoneInfo = {
	id: string; // The IANA timezone identifier
	country: string; // The name of the country extracted from the timezone string
	offsetHours: number; // The UTC offset in hours
};

export type ContinentTimeZones = {
	continent: string; // The name of the continent
	times: TimeZoneInfo[]; // An array of time zones within this continent
};

/**
 * Retrieves an array of time zones structured by continents. Each continent contains
 * multiple time zones with relevant country names and offsets.
 *
 * @returns {ContinentTimeZones[]} An array of continents each containing a list of time zones.
 */

export const newTimeZonesData = (): ContinentTimeZones[] => {
	const countryTimezones: ContinentTimeZones[] = [];
	moment.tz.names().forEach((timezone) => {
		// Get the timezone offset
		const offset = moment.tz(timezone).utcOffset() / 60;
		// Get the country name from the timezone
		const countryName = timezone.split("/")[0]; // Assumes the country name is the first part of the timezone

		// Find the continent of the country
		let continent = "";
		const continentIndex = timezone.indexOf("/");
		if (continentIndex !== -1) {
			continent = timezone.substring(0, continentIndex);
		}

		// Find the existing continent object or create a new one
		let continentObject = countryTimezones.find(
			(c) => c.continent === continent
		);
		if (!continentObject) {
			continentObject = { continent: continent, times: [] };
			countryTimezones.push(continentObject);
		}

		// Push the country data into the continent object
		continentObject.times.push({
			id: timezone,
			country: countryName,
			offsetHours: offset,
		});
	});
	return countryTimezones;
};

export const defaultTimeSlots: OperatingHour[] = [
	{
		day: "Monday",
		day_value: 1,
		is_active: 1,
		time_slots: [{ start_time: "09:00:00", end_time: "17:00:00" }],
	},
	{
		day: "Tuesday",
		day_value: 2,
		is_active: 1,
		time_slots: [{ start_time: "09:00:00", end_time: "17:00:00" }],
	},
	{
		day: "Wednesday",
		day_value: 3,
		is_active: 1,
		time_slots: [{ start_time: "09:00:00", end_time: "17:00:00" }],
	},
	{
		day: "Thursday",
		day_value: 4,
		is_active: 1,
		time_slots: [{ start_time: "09:00:00", end_time: "17:00:00" }],
	},
	{
		day: "Friday",
		day_value: 5,
		is_active: 1,
		time_slots: [{ start_time: "09:00:00", end_time: "17:00:00" }],
	},
	{
		day: "Saturday",
		day_value: 6,
		is_active: 0,
		time_slots: [{ start_time: "09:00:00", end_time: "17:00:00" }],
	},
	{
		day: "Sunday",
		day_value: 0,
		is_active: 0,
		time_slots: [{ start_time: "09:00:00", end_time: "17:00:00" }],
	},
];

export const defaultTimeSlotsV2: OperatingHourV2 = {
	monday: {
		is_active: true,
		time_slots: [
			{
				start_time: "09:00",
				end_time: "17:00",
			},
		],
	},
	tuesday: {
		is_active: true,
		time_slots: [
			{
				start_time: "09:00",
				end_time: "17:00",
			},
		],
	},
	wednesday: {
		is_active: true,
		time_slots: [
			{
				start_time: "09:00",
				end_time: "17:00",
			},
		],
	},
	thursday: {
		is_active: true,
		time_slots: [
			{
				start_time: "09:00",
				end_time: "17:00",
			},
		],
	},
	friday: {
		is_active: true,
		time_slots: [
			{
				start_time: "09:00",
				end_time: "17:00",
			},
		],
	},
	saturday: {
		is_active: false,
		time_slots: [
			{
				start_time: "09:00",
				end_time: "17:00",
			},
		],
	},
	sunday: {
		is_active: false,
		time_slots: [
			{
				start_time: "09:00",
				end_time: "17:00",
			},
		],
	},
};

export const convertColumnTimeToMinutesOrSeconds = (
	time: string,
	convert: "minutes" | "seconds"
): number => {
	const [hours, minutes, seconds] = time.split(":").map(Number);
	if (convert === "minutes") return hours * 60 + minutes + seconds / 60;
	else if (convert === "seconds")
		return hours * 3600 + minutes * 60 + seconds;
	return 0;
};

export const waitlistPriority: { label: string; value: PatientPriorityType }[] =
	[
		{
			label: "High Prioritty",
			value: PatientPriority.High,
		},
		{
			label: "Normal Priority",
			value: PatientPriority.Normal,
		},
		{
			label: "Low Priority",
			value: PatientPriority.Low,
		},
	];
