import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { cn, getInitials } from "../../utils/functions";
import { Button } from "../ui/button";
import { TooltipProvider } from "../ui/tooltip";
import useUserStore from "@src/store/useUserStore";

// type NavItem = {
// 	icon: React.ReactNode;
// 	label: string;
// 	path: string;
// };

// const navItems: NavItem[] = [
// 	{
// 		icon: <LuMonitor size={20} />,
// 		label: "Display",
// 		path: RoutePath.DISPLAY,
// 	},
// 	{
// 		icon: <LuLoader size={20} />,
// 		label: "Waitlist",
// 		path: RoutePath.WAITLIST,
// 	},
// 	{
// 		icon: <LuBarChart2 size={20} />,
// 		label: "Analytics",
// 		path: RoutePath.ANALYTICS,
// 	},
// 	{
// 		icon: <LuClock4 size={20} />,
// 		label: "Serving",
// 		path: RoutePath.SERVING,
// 	},
// 	{
// 		icon: <LuCalendarDays size={20} />,
// 		label: "Schedule",
// 		path: RoutePath.SCHEDULE,
// 	},
// 	{
// 		icon: <LuMapPin size={20} />,
// 		label: "Locations",
// 		path: RoutePath.LOCATIONS,
// 	},
// 	{
// 		icon: <LuUsers size={20} />,
// 		label: "Team members",
// 		path: RoutePath.TEAM_MEMBERS,
// 	},
// 	// {
// 	// 	icon: <LuSettings size={20} />,
// 	// 	label: "Settings",
// 	// 	path: RoutePath.SETTINGS,
// 	// },
// ];

type NavItem = {
	icon: React.ReactNode;
	label: string;
	path: string;
	subMenu?: {
		icon?: React.ReactNode;
		label: string;
		path: string;
	}[];
};

const navItems: NavItem[] = [
	{
		icon: "mgc_list_check_3_line",
		label: "Waitlist",
		path: "waitlist",
	},
	{
		icon: "mgc_airplay_line",
		label: "Display",
		path: "display",
	},
	{
		icon: "mgc_chart_bar_line",
		label: "Analytics",
		path: "analytics",
	},
	{
		icon: "mgc_calendar_time_add_line",
		label: "Schedule",
		path: "schedule",
	},
	{
		icon: "mgc_location_2_line",
		label: "Locations",
		path: "locations",
	},
	{
		icon: "mgc_group_line",
		label: "Users",
		path: "users",
	},
	{
		icon: "mgc_classify_add_line",
		label: "Team",
		path: "team-members",
	},
	{
		icon: "mgc_paint_brush_line",
		label: "Theme",
		path: "theme",
	},
	{
		icon: "mgc_book_6_line",
		label: "Tutorial",
		path: "tutorial",
	},
];

const Sidebar = () => {
	const location = useLocation();
	const [isIconView, setIsIconView] = useState(true);
	const user = useUserStore((s) => s.user);

	const [variant, setVariant] = useState<"icon" | "default">("icon");
	const handleMouseEnter = () => {
		setVariant("default");
		setIsIconView(false);
	};
	// const businessName = user?.name ?? "";
	const handleMouseLeave = () => {
		setVariant("icon");
		setIsIconView(true);
	};

	const isActive = (path) => location.pathname.includes(path);

	return (
		<TooltipProvider>
			<aside
				className={cn(
					"absolute left-0 top-0 z-20 hidden h-[100dvh] bg-primary opacity-100  transition-all md:block",
					{
						"w-[160px] min-w-[160px]": variant === "default",
						"w-[60px] min-w-[60px]": variant === "icon",
					}
				)}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<div className="relative flex h-full w-full flex-col">
					<div className="flex flex-1 flex-col">
						<NavLink
							to={"/dashboard/settings"}
							className={cn(
								"flex items-center space-x-3 px-6 py-3.5 text-sm text-white transition-all",
								{
									"before-text-primary bg-white text-primary":
										isActive("settings"),
									"duration-200 ease-in-out hover:bg-white/10":
										!isActive("settings"),
									"justify-center px-0": isIconView,
								}
							)}
						>
							<figure className="grid size-10 flex-shrink-0 place-content-center rounded-full bg-[#043B6D] text-[14px] font-semibold leading-[20px] tracking-[-1%] text-white">
								{getInitials(user?.business?.name ?? "")}
							</figure>
							{!isIconView && (
								<p className="font-medium">Settings</p>
							)}
						</NavLink>
						{navItems.map((item) => {
							return isIconView ? (
								<NavLink
									to={item.path}
									className={cn(
										"flex items-center justify-center space-x-3 py-3.5 text-sm text-white transition-all",
										{
											"before-text-primary bg-white text-primary":
												isActive(item.path),
											"duration-200 ease-in-out hover:bg-white/10":
												!isActive(item.path),
										}
									)}
								>
									<i
										className={cn(
											`${item.icon} text-[20px] before:!text-white`,
											{
												"before:!text-primary":
													isActive(item.path),
											}
										)}
									/>
								</NavLink>
							) : (
								<React.Fragment key={item.label}>
									<NavLink
										className={cn(
											"flex items-center space-x-3 px-6 py-3.5 font-medium text-white",
											{
												"bg-white text-primary":
													isActive(item.path),
												"duration-200 ease-in-out hover:bg-white/10 ":
													!isActive(item.path),
											}
										)}
										to={item.path}
									>
										<i
											className={cn(
												`${item.icon} text-[20px] before:!text-white`,
												{
													"before:!text-primary":
														isActive(item.path),
												}
											)}
										/>

										<p>{item.label}</p>
									</NavLink>
									{item.subMenu &&
										item.subMenu.map((subItem) => {
											const isSubItemActive =
												location.pathname.includes(
													subItem.path
												);
											return (
												<NavLink
													className={cn("flex pl-6", {
														"bg-white":
															isSubItemActive,
														"duration-200 ease-in-out hover:bg-white/10":
															!isSubItemActive,
													})}
													key={subItem.label}
													to={subItem.path}
												>
													<div
														className={cn(
															"flex items-center space-x-3 px-6 py-2.5 text-base font-medium text-white transition-all ",
															{
																"text-primary":
																	isSubItemActive,
															}
														)}
													>
														<div className="text-sm">
															{subItem.icon}
														</div>
														<div>
															{subItem.label}
														</div>
													</div>
												</NavLink>
											);
										})}
								</React.Fragment>
							);
						})}
					</div>
					<div className="mb-8 flex flex-col">
						{isIconView ? (
							<>
								<button
									className="space-x-3 rounded-none py-3.5 text-base text-white hover:bg-white/10 hover:text-white"
									// onClick={logout}
								>
									<i className="mgc_entrance_line text-[20px] before:!text-white hover:before:!text-primary" />
								</button>
							</>
						) : (
							<>
								<button
									className="flex items-center space-x-3 rounded-none px-6 py-3.5 text-base text-white hover:bg-white/10 hover:text-white"
									// onClick={logout}
								>
									<i className="mgc_entrance_line text-[20px] before:!text-white hover:before:!text-primary" />
									<p className="font-medium">Sign out</p>
								</button>
							</>
						)}
					</div>
				</div>
			</aside>
		</TooltipProvider>
	);
};

export default Sidebar;
