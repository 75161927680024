import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogTitle,
	DialogTrigger,
} from "@components/ui/dialog";
import { LoaderButton } from "@src/components/form/LoaderButton";
import React, { useState } from "react";
import { LuX } from "react-icons/lu";
import { useClearQueue } from "../../../hooks/useLocationData";
import useCustomToast from "../../CustomToast";
import { Button } from "../../ui/button";

const WaitlistClearQueue: React.FC = () => {
	const [showClearQueueModal, setShowClearQueueModal] = useState(false);

	const { isLoading, mutate } = useClearQueue();
	const customToast = useCustomToast();

	return (
		<Dialog
			open={showClearQueueModal}
			onOpenChange={(newState) => {
				if (!isLoading) setShowClearQueueModal(newState);
			}}
		>
			<DialogTrigger asChild>
				<Button
					variant="outline"
					className="size-9 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
				>
					<i className="mgc_delete_2_line text-[18px] before:!text-[#C8322B]" />
				</Button>
			</DialogTrigger>
			<DialogContent className="max-w-[360px] p-4">
				<div className="flex justify-between space-x-2">
					<i className="mgc_delete_2_line py-1 text-[20px] before:!text-[#C8322B]" />
					<div className="flex flex-col">
						<DialogTitle className="text-[22px] font-semibold capitalize leading-[30px] -tracking-[1%] text-[#323539]">
							Clear Queue
						</DialogTitle>
						<p className="trakcing-[-0.1px] text-[14px] leading-[20px] text-[#6D748D]">
							Are you sure you want to clear the queue?
							<br /> This action can&apos;t be undone.
						</p>
						<DialogFooter className="mt-6 space-x-1 sm:space-x-1">
							<Button
								variant="ghost"
								className="space-x-5 text-[#323539]"
								onClick={() => {
									if (!isLoading)
										setShowClearQueueModal(false);
								}}
								disabled={isLoading}
							>
								Cancel
							</Button>
							<LoaderButton
								variant="destructive"
								className="px-5 text-white"
								onClick={() => {
									customToast("Clearing queue", {
										id: "clear-queue",
										type: "loading",
									});
									mutate();
								}}
								loading={isLoading}
								disabled={isLoading}
								loaderSize={20}
							>
								Clear Queue
							</LoaderButton>
						</DialogFooter>
					</div>
					<button
						disabled={isLoading}
						className="flex items-start"
						onClick={(e) => {
							e.preventDefault();
							if (!isLoading) setShowClearQueueModal(false);
						}}
					>
						<LuX
							color="#858C95"
							className="cursor-pointer"
							width="20px"
							height="20px"
						/>
					</button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default WaitlistClearQueue;
