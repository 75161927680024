import { zodResolver } from "@hookform/resolvers/zod";
import React, { useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CiCircleInfo } from "react-icons/ci";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import { z } from "zod";
import { useContactUs } from "../../hooks/useAuthData";
import { ModalProps } from "../../types";
import { countryCodes } from "../../utils/constants";
import useCustomToast from "../CustomToast";
import Loader from "../Loader/Loader";
import Overlay from "../Overlay";
import { CustomButton } from "../form/CustomButton";
import { CustomSelect } from "../form/CustomSelect";
import FormInput from "../form/FormInput";
import "./contact-us-modal.css";

const schema = z.object({
	name: z.string().min(4, { message: "Name must be at least 4 characters" }),
	email: z.string().email({ message: "Invalid email address format" }),
	phone: z
		.string()
		.min(10, { message: "Phone number must be at least 10 characters" })
		.refine((value) => /^[0-9]+$/.test(value), {
			message: "Phone number must only contain numbers 0-9",
		}),
	message: z
		.string()
		.min(10, { message: "Message must be at least 10 characters" }),
	agreeToTerms: z.boolean().refine((value) => value === true, {
		message: "You must agree to the terms and conditions",
	}),
});

type FormFields = z.infer<typeof schema>;

const ContactUsModal: React.FC<ModalProps> = ({ show, setShow }) => {
	const modalRef = useRef(null);

	const {
		register,
		handleSubmit,
		setError,
		reset,
		setValue,
		formState: { errors },
	} = useForm<FormFields>({
		resolver: zodResolver(schema),
	});

	const [isChecked, setIsChecked] = useState(false);
	const [countryCode, setCountryCode] = useState("+1");
	const customToast = useCustomToast();

	const contactUsOnSuccess = () => {
		customToast("Message sent 😁, you would here from us soon", {
			id: "contact-us",
			type: "success",
			duration: 10000,
		});

		setTimeout(() => {
			setShow(false);
		}, 1500);
	};

	const contactUsOnError = () => {
		customToast("An error occured kindly try again later", {
			id: "contact-us",
			type: "error",
			duration: 5000,
		});
	};

	const contactUsMutaion = useContactUs(contactUsOnSuccess, contactUsOnError);

	const onSubmit: SubmitHandler<FormFields> = async (data) => {
		try {
			customToast("Sending message...", {
				id: "contact-us",
				type: "loading",
			});
			contactUsMutaion.mutate({
				name: data.name,
				email: data.email,
				phone: countryCode + data.phone,
				subject: data.message,
				message: data.message,
			});
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	return (
		<Overlay
			show={show}
			setShow={setShow}
			modalRef={modalRef}
			onClose={() => {
				reset();
				setCountryCode("+1");
			}}
		>
			{show && (
				<>
					<form
						className="relative flex w-full max-w-[886px] flex-col items-center space-y-6 rounded-[10px] bg-white p-6"
						onSubmit={handleSubmit(onSubmit)}
					>
						<div className="flex w-full items-center justify-between">
							<CiCircleInfo
								className="fill-[#323539]"
								size={32}
							/>
							<figure
								className="cursor-pointer rounded-full p-2 duration-300 ease-in-out hover:bg-black hover:bg-opacity-10"
								onClick={() => setShow(false)}
							>
								<IoClose className="fill-[#858C95]" size={24} />
							</figure>
						</div>
						<div className="flex flex-col space-y-2">
							<h4 className="text-xl font-medium tracking-[-1%] text-[#323539]">
								Get in Touch with Us
							</h4>
							<p className="text-[18px] leading-[24px] tracking-[-1%] text-[#858C95]">
								Got questions or need more information? We are
								here and happy to help! Please leave a message
								and we will get back to you shortly.
							</p>
						</div>
						<div className="w-full flex-col space-y-6">
							<div className="flex w-full items-start justify-between sm:space-x-4 msm:flex-col msm:space-y-4">
								<FormInput
									inputType={"text"}
									label="Full Name"
									register={{ ...register("name") }}
									className="h-10"
									error={errors.name?.message}
									minLength={5}
									maxLength={40}
								/>
								<FormInput
									inputType={"email"}
									label="Email Address"
									register={{ ...register("email") }}
									className="h-10"
									error={errors.email?.message}
									minLength={8}
									maxLength={40}
								/>
								<div className="flex w-full flex-1 flex-col space-y-1.5 self-start">
									<label className="text-sm font-medium tracking-[-0.1px] text-[#323539]">
										Phone Number
									</label>
									<div className="flex items-stretch">
										<CustomSelect
											className="w-fit rounded-r-none border-r-transparent"
											placeholder="+1"
											value={countryCode}
											label="Country Codes"
											options={countryCodes}
											onChange={(value) => {
												setCountryCode(value);
											}}
											labelClassName="px-2"
											itemClassName="px-8"
										/>

										<FormInput
											inputType="text"
											className="h-full self-stretch rounded-l-none py-0"
											register={{
												...register("phone"),
											}}
											onChange={(e) => {
												setValue(
													"phone",
													e.target.value
												);
											}}
											required
										/>
									</div>
									{errors.phone?.message && (
										<p className="mt-1.5 text-sm text-red-500">
											{errors.phone?.message}
										</p>
									)}
								</div>
							</div>
							<FormInput
								label="Message"
								register={{ ...register("message") }}
								error={errors.message?.message}
								className="h-[124px] min-h-[75px]"
								minLength={5}
								maxLength={1024}
								textarea
							/>

							<div>
								<div
									className="flex items-center space-x-3 self-start"
									onClick={() => {
										setIsChecked(!isChecked);
									}}
								>
									<div
										className="inline-flex items-center"
										onClick={() => {
											setValue(
												"agreeToTerms",
												!isChecked
											);
										}}
									>
										<label
											className="relative flex cursor-pointer items-center rounded-full"
											htmlFor="check"
										>
											<input
												type="checkbox"
												className="peer relative h-[17px] w-[17px] cursor-pointer appearance-none rounded-md border border-gray-300 transition-all checked:border-[#053969] checked:bg-[#053969] hover:before:opacity-10"
												defaultChecked={isChecked}
												{...register("agreeToTerms")}
											/>
											<span className="pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="h-[11px] w-[11px]"
													viewBox="0 0 20 20"
													fill="currentColor"
													stroke="currentColor"
													strokeWidth="1"
												>
													<path
														fillRule="evenodd"
														d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
														clipRule="evenodd"
													></path>
												</svg>
											</span>
										</label>
									</div>
									<label className="font-medium text-[#323539]">
										I agree with Migranium{" "}
										<Link
											to={""}
											className="text-[#053969] underline"
										>
											Privacy Policy
										</Link>
									</label>
								</div>
								{errors.agreeToTerms?.message && (
									<p className="mt-1.5 text-sm text-red-500">
										{errors.agreeToTerms?.message}
									</p>
								)}
							</div>
						</div>
						<CustomButton
							disabled={contactUsMutaion.isLoading}
							className="relative h-[46px] w-full self-end bg-[#053969] text-white duration-200 ease-in-out hover:bg-[#72F4E8] hover:text-[#053969] sm:max-w-[144px]"
							type="submit"
						>
							<p
								style={{
									visibility: contactUsMutaion.isLoading
										? "hidden"
										: "visible",
								}}
							>
								Send Message
							</p>
							{contactUsMutaion.isLoading && (
								<figure className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
									<Loader size={24} />
								</figure>
							)}
						</CustomButton>
					</form>
				</>
			)}
		</Overlay>
	);
};

export default ContactUsModal;
