import { Dialog, DialogContent, DialogTrigger } from "@components/ui/dialog";
import { Button } from "@src/components/ui/button";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@src/components/ui/tabs";
import React, { useState } from "react";
import GeneralSettings from "./GeneralSettings";
import OperatingHours from "./OperatingHours";

const WaitlistSettings: React.FC = () => {
	const [showWaitlistSettingsModal, setWaitlistSettingsModal] =
		useState(false);

	return (
		<Dialog
			open={showWaitlistSettingsModal}
			// open
			onOpenChange={setWaitlistSettingsModal}
		>
			<DialogTrigger asChild>
				<Button
					variant="outline"
					className="size-9 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
				>
					<i className="mgc_settings_5_line text-[18px] before:stroke-2 before:!text-[#4E4E4E]" />
				</Button>
			</DialogTrigger>
			<DialogContent className="flex min-h-[620px] max-w-[900px] flex-col overflow-hidden p-0">
				<Tabs defaultValue="general" className="flex h-full flex-1">
					<div className="flex flex-col space-y-8 bg-[#F9F9F9] p-6 px-[9px]">
						<h2 className="px-[13px] text-[22px] font-semibold leading-[30px] tracking-[-1%] text-[#323539]">
							Waitlist Settings
						</h2>
						<div className="flex flex-col space-y-1">
							<h3 className="px-4 text-[14px] font-medium leading-[16.8px] tracking-[-1%] text-[#858C95]">
								DEFAULT
							</h3>
							<TabsList className="text-basecolor flex h-full flex-col items-start justify-normal gap-y-0 bg-transparent text-left shadow-none">
								{tabsData.map((tab) => (
									<TabsTrigger
										key={tab.value}
										className="group flex w-full items-center justify-between rounded-[8px] border-none px-4 py-2 text-[#323539] shadow-none drop-shadow-none transition-all duration-200 ease-in-out data-[state=active]:bg-primary/10 data-[state=active]:text-primary"
										value={tab.value}
									>
										{tab.label}
									</TabsTrigger>
								))}
							</TabsList>
						</div>
					</div>
					<div className="flex flex-1 flex-col">
						<TabsContent
							value="general"
							className="mt-0 flex w-full data-[state=inactive]:hidden data-[state=active]:flex-1"
						>
							<GeneralSettings
								setWaitlistSettingsModal={
									setWaitlistSettingsModal
								}
							/>
						</TabsContent>
						<TabsContent
							value="operating-hours"
							className="mt-0 flex w-full data-[state=inactive]:hidden data-[state=active]:flex-1"
						>
							<OperatingHours
								setWaitlistSettingsModal={
									setWaitlistSettingsModal
								}
							/>
						</TabsContent>
						<TabsContent
							value="custom-fields"
							className="mt-0 flex w-full data-[state=inactive]:hidden data-[state=active]:flex-1"
						>
							<p>fqfqef</p>
						</TabsContent>
					</div>
				</Tabs>
			</DialogContent>
		</Dialog>
	);
};

export default WaitlistSettings;

const tabsData = [
	{ value: "general", label: "General" },
	{ value: "operating-hours", label: "Operating Hours" },
	{ value: "custom-fields", label: "Custom Fields" },
];
