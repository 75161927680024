import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import { ScrollArea } from "@src/components/ui/scroll-area";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@src/components/ui/select";
import { Switch } from "@src/components/ui/switch";
import { defaultTimeSlots, waitlistPriority } from "@src/utils/constants";
import React, { Dispatch, SetStateAction } from "react";
import { Button } from "@src/components/ui/button";
import { LuX } from "react-icons/lu";
import { OperatingHour } from "@src/types/DaySlots";
import AddLocationTimer from "@src/components/Onboarding/AddLocationTimer";

const OperatingHours: React.FC<{
	setWaitlistSettingsModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setWaitlistSettingsModal }) => {
	const [slots, setSlots] = React.useState<OperatingHour[]>(defaultTimeSlots);
	const [isQueueOn, setIsQueueOn] = React.useState(false);
	const [isAutoClearQueueOn, setIsAutoClearQueueOn] = React.useState(false);

	return (
		<div className="flex flex-1 flex-col self-stretch">
			<div className="flex items-center justify-between pl-12 pr-6 pt-6">
				<h2 className="text-[22px] font-semibold leading-[30px] tracking-[-1%] text-[#323539]">
					Operating Hours Settings
				</h2>
				<button
					className="flex items-start"
					onClick={() => setWaitlistSettingsModal(false)}
				>
					<LuX
						color="#858C95"
						size={20}
						className="cursor-pointer self-center"
					/>
				</button>
			</div>
			<ScrollArea className="mt-6 max-h-[482px] flex-1 border-b border-[#1018280A] pl-12 pr-6">
				<div className="flex flex-col space-y-4 border-t border-[#979AA0] pt-5">
					{slots.map((slot, i) => (
						<AddLocationTimer
							{...slot}
							key={i}
							index={i}
							slots={slots}
							shouldShowDay
							shouldShowPlus
							setSlots={setSlots}
						/>
					))}
				</div>
			</ScrollArea>
			<div className="flex justify-end space-x-6 py-3 pr-12">
				{/* TODO: Make this cancel reset the form data when clicked on */}
				<Button
					variant="outline"
					className="px-5 shadow-[0_1px_2px_0_rgba(16,24,40,0.04)]"
				>
					Cancel
				</Button>
				<Button className="px-5 text-white">Update</Button>
			</div>
		</div>
	);
};

export default OperatingHours;
