import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "@components/ui/button";
import { CustomSelect } from "@components/form/CustomSelect";
import FormInput from "@components/form/FormInput";
import { useFormContext } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { HiOutlineCloudUpload } from "react-icons/hi";
import { CustomImage } from "@components/common/CustomImage";
import {
	countryCodes,
	countryOptions,
	findCountry,
	findState,
	ProductTypeOptions,
	statesOptions,
} from "@utils/constants";
import useUserStore from "@store/useUserStore";
import { getErrorMessage } from "@utils/functions";
import { updateCountryAndState, changeCountry } from "../components";
import AddressComponent, {
	findCountryByLabel,
	findProvinceByLabel,
} from "@src/components/Onboarding/AddressComponent";

const schema = z.object({
	name: z.string().min(4, { message: "Name must be at least 4 characters" }),
	email: z.string().email({ message: "Invalid email address format" }),
	phone: z
		.string()
		.min(10, { message: "Phone number must be at least 10 characters" })
		.refine((value) => /^[0-9]+$/.test(value), {
			message: "Phone number must only contain numbers 0-9",
		}),
	message: z
		.string()
		.min(10, { message: "Message must be at least 10 characters" }),
	agreeToTerms: z.boolean().refine((value) => value === true, {
		message: "You must agree to the terms and conditions",
	}),
});

type FormFields = z.infer<typeof schema>;

type ImagePreviewCallback = (preview: string) => void;

function createImagePreview(file: File, callback: ImagePreviewCallback): void {
	const reader = new FileReader();
	reader.onload = (event: ProgressEvent<FileReader>) => {
		const img = new Image();
		img.onload = () => {
			const canvas = document.createElement("canvas");
			const ctx = canvas.getContext("2d");
			const MAX_WIDTH = 300;
			const MAX_HEIGHT = 300;
			let width = img.width;
			let height = img.height;

			if (width > height) {
				if (width > MAX_WIDTH) {
					height *= MAX_WIDTH / width;
					width = MAX_WIDTH;
				}
			} else {
				if (height > MAX_HEIGHT) {
					width *= MAX_HEIGHT / height;
					height = MAX_HEIGHT;
				}
			}

			canvas.width = width;
			canvas.height = height;
			ctx?.drawImage(img, 0, 0, width, height);
			callback(canvas.toDataURL("image/jpeg"));
		};
		img.src = event.target?.result as string;
	};
	reader.readAsDataURL(file);
}

export default function LocationInfo() {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const user = useUserStore((s) => s.user);
	const [countryCode, setCountryCode] = useState("+1");
	const [previewUrl, setPreviewUrl] = useState<string | null>(null);
	console.log("🚀 ~ LocationInfo ~ previewUrl:", previewUrl);
	const [provinceOptions, setProvinceOptions] = useState<
		{
			label: string;
			value: string;
		}[]
	>([]);

	const {
		register,
		watch,
		setValue,
		getValues,
		trigger,
		formState: { errors },
	} = useFormContext();
	console.log("🚀 ~ LocationInfo ~ getValues:", getValues());

	const updateBusinessCheckThenUpdateFields = () => {
		if (user?.business) {
			// setValue("name", user?.business?.name ?? "");
			setValue("address", user?.business.address ?? "");
			const countryValue = findCountryByLabel(
				user?.business?.country ?? ""
			);
			const newProvinceOptions = changeCountry(countryValue);

			const newProvince = findProvinceByLabel(
				newProvinceOptions,
				user?.business.state ?? ""
			);

			updateCountryAndState(
				setValue,
				setProvinceOptions,
				true,
				newProvince,
				countryValue
			);

			// setValue(
			// 	"business_category_id",
			// 	user?.business?.business_category_id?.toString() ?? "0"
			// );

			setValue("city", user?.business.city ?? "");
			setValue(
				"phone_number",
				user?.business.phone_number?.slice(-10) ?? ""
			);
			setCountryCode(user?.business.phone_number?.slice(0, -10) ?? "+1");
			trigger();
		} else {
			updateCountryAndState(
				setValue,
				setProvinceOptions,
				true,
				"ON",
				"CA"
			);
		}
	};

	const handleImageChange = (
		e: React.ChangeEvent<HTMLInputElement>
		// onChange: (file: File | null) => void
	) => {
		const file = e.target.files?.[0] || null;
		if (file) {
			// setPreviewUrl(URL.createObjectURL(file));
			setValue("image", file);
			// createImagePreview(file, (preview: string) => {
			// 	setPreviewUrl(preview);
			// });
			setPreviewUrl(URL.createObjectURL(file));
		}
		// onChange(file);
		// if (file) {
		// 	const reader = new FileReader();
		// 	reader.onloadend = () => {
		// 		setPreviewUrl(reader.result as string);
		// 	};
		// 	reader.readAsDataURL(file);
		// } else {
		// 	setPreviewUrl(null);
		// }
	};

	const handleButtonClick = () => {
		fileInputRef.current?.click();
	};

	// const { ref, ...rest } = register("image");

	// const inputRef = useCallback(
	// 	(instance: HTMLInputElement | null) => {
	// 		fileInputRef.current = instance;
	// 		ref(instance);
	// 	},
	// 	[ref]
	// );

	const imageFile = watch("image");
	console.log("🚀 ~ LocationInfo ~ imageFile:", imageFile);

	// useEffect(() => {
	// 	const updatePreview = async () => {
	// 		if (imageFile && imageFile[0]) {
	// 			const preview = await getImagePreview(imageFile[0]);
	// 			setPreviewUrl(preview);
	// 		} else {
	// 			setPreviewUrl(null);
	// 		}
	// 	};
	// 	updatePreview();
	// }, [imageFile]);

	// useEffect(() => {
	// 	if (!imageFile) {
	// 		return;
	// 	}
	// 	setPreviewUrl(URL.createObjectURL(imageFile));
	// 	return () => {
	// 		URL.revokeObjectURL(previewUrl || "");
	// 	};
	// }, [imageFile]);

	// useEffect(() => {
	// 	updateBusinessCheckThenUpdateFields();
	// }, []);

	return (
		<div className=" space-y-4">
			<h3 className="text-xl font-medium">Location information</h3>
			<div className="flex items-center space-x-4">
				<CustomImage
					src={previewUrl}
					alt="location"
					imageClass="aspect-square w-[112px] rounded-full border bg-slate-50 max-h-[112px] min-h-[112px]"
				/>
				<div>
					<input
						type="file"
						accept="image/jpeg,image/png"
						{...register("image", {
							onChange: (e) => handleImageChange(e),
						})}
						ref={fileInputRef}
						className="hidden"
						id="image-upload"
					/>
					<Button
						type="button"
						variant={"outline"}
						className="mb-2"
						onClick={handleButtonClick}
					>
						<HiOutlineCloudUpload className="mr-2 text-lg" />
						Replace Image
					</Button>

					<p>File must be a JPG or PNG. Max size of 5MB</p>
					{getErrorMessage(errors.name?.message) &&
						getErrorMessage(errors.name?.message)?.length && (
							<small className="mt-1.5 text-sm text-red-500">
								{getErrorMessage(errors.image?.message)}
							</small>
						)}
				</div>
			</div>
			<div className="grid grid-cols-6 gap-4">
				<div className="col-span-6">
					<FormInput
						inputType={"text"}
						label="Location Name *"
						register={{ ...register("name") }}
						className="h-10"
						error={getErrorMessage(errors.name?.message)}
						// minLength={5}
						maxLength={40}
					/>
				</div>
				<div className="col-span-3">
					<FormInput
						inputType={"text"}
						label="Email Address "
						register={{ ...register("email") }}
						className="h-10"
						error={getErrorMessage(errors.email?.message)}
						// minLength={5}
						maxLength={40}
					/>
				</div>
				<div className="col-span-3">
					<FormInput
						inputType={"text"}
						label="Phone Number  "
						register={{ ...register("phone_number") }}
						className="h-10"
						error={getErrorMessage(errors.phone_number?.message)}
						// minLength={5}
						maxLength={14}
					/>
				</div>

				<div className="col-span-6 flex w-full">
					{/* <AddressComponent
						setFormValue={setValue}
						setProvinceOptions={setProvinceOptions}
						formTrigger={trigger}
						errorMessage={errors.address?.message}
						buttonClassName="w-full max-w-full"
					/> */}
					<FormInput
						inputType={"text"}
						label="Address  *"
						register={{ ...register("address") }}
						className="h-10"
						error={getErrorMessage(errors.address?.message)}
						// minLength={5}
						// maxLength={40}
					/>
					{/* <AddressComponent
						setFormValue={setValue}
						setProvinceOptions={setProvinceOptions}
						formTrigger={trigger}
						errorMessage={errors.address?.message}
					/> */}
				</div>

				<div className="col-span-2">
					<label className="text-sm font-medium tracking-[-0.1px] text-[#323539]">
						Country
					</label>
					<CustomSelect
						placeholder="Select"
						label="Country  *"
						className="mt-1"
						options={countryOptions}
						value={watch("country")}
						onChange={(value) => {
							updateCountryAndState(
								setValue,
								setProvinceOptions,
								false,
								undefined,
								value
							);
						}}
						error={getErrorMessage(errors.city?.message)}
					/>
				</div>
				<div className="col-span-2">
					<label className="mb-3 text-sm font-medium tracking-[-0.1px] text-[#323539]">
						Province
					</label>
					<CustomSelect
						placeholder="Select"
						label="Province  *"
						className="mt-1"
						options={provinceOptions}
						value={watch("state")}
						onChange={(value) => {
							updateCountryAndState(
								setValue,
								setProvinceOptions,
								false,
								value,
								getValues("country")
							);
						}}
					/>
				</div>
				<div className="col-span-2">
					<FormInput
						inputType={"text"}
						label="City *"
						register={{ ...register("city") }}
						className="h-10"
						error={getErrorMessage(errors.city?.message)}
						minLength={5}
						maxLength={40}
					/>
				</div>
				<div className="col-span-6">
					<FormInput
						label="Location Description"
						register={{ ...register("description") }}
						error={getErrorMessage(errors.description?.message)}
						className="h-[124px] min-h-[75px]"
						minLength={5}
						maxLength={1024}
						textarea
					/>
				</div>
			</div>
		</div>
	);
}
