import useCustomToast from "@components/CustomToast";
import { AddPatientData } from "@components/Dashboard/waitlist/AddPatient/AddPatient";
import { APIVersion1AddPatient } from "@src/http/v1";
import { Station } from "@type/NewUser";
import { useMutation, useQueryClient } from "react-query";

const AddPatientSlice = (
	onSuccess: (data: Station) => void = () => {
		return;
	},
	onError: (error: Error) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();
	const queryClient = useQueryClient();

	return useMutation<
		Station,
		Error,
		{ data: AddPatientData; queueUrl: string }
	>(({ data, queueUrl }) => APIVersion1AddPatient(data, queueUrl), {
		onSuccess: (data) => {
			onSuccess(data);
			setTimeout(() => {
				customToast("Patient added successfully", {
					id: "add-patient-to-queue",
					type: "success",
				});
			}, 750);
			queryClient.invalidateQueries("queue-list");
		},
		onError: (error) => {
			onError(error);
			customToast("	Patient could not be added", {
				id: "add-patient-to-queue",
				type: "error",
			});

			console.error(error);
		},
	});
};

export default AddPatientSlice;
