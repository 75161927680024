import AddPatient from "@src/components/Dashboard/waitlist/AddPatient/AddPatient";
import WaitlistClearQueue from "@src/components/Dashboard/waitlist/WaitlistClearQueue";
import { Button } from "@src/components/ui/button";
import { Label } from "@src/components/ui/label";
import { Switch } from "@src/components/ui/switch";
import React, { Dispatch, SetStateAction, useState } from "react";
import { LuRotateCcw } from "react-icons/lu";
import { useQueryClient } from "react-query";
import WaitlistSettings from "./WaitlistSettings/WaitlistSettings";

const WaitlistShowScheduleAddPatientRow: React.FC<{
	isAutoFlowOn: boolean;
	isScheduleOn: boolean;
	tabView: "grid_view" | "column_view";
	setIsAutoFlowOn: Dispatch<SetStateAction<boolean>>;
	setIsScheduleOn: Dispatch<SetStateAction<boolean>>;
	setTabView: Dispatch<SetStateAction<"grid_view" | "column_view">>;
}> = ({
	isAutoFlowOn,
	isScheduleOn,
	setIsAutoFlowOn,
	setIsScheduleOn,
	tabView,
	setTabView,
}) => {
	const queryClient = useQueryClient();
	const [showAddPatientModal, setShowAddPatientModal] =
		useState<boolean>(false);
	const [isQueueOpen, setIsQueueOpen] = useState<0 | 1>(0);

	// const businessSettingsMutation = BusinessSettingsSlice(undefined, () => {
	// 	// setValue("is_queue_active", getValues("is_queue_active") === 1 ? 0 : 1);
	// });

	// const updateGeneralSettigs: (
	// 	data: WaitlistGeneralSettings
	// ) => void = async (data) => {
	// 	// const newIsQueueOn = data.is_queue_active === 1 ? 0 : 1;
	// 	// console.log(data);
	// 	try {
	// 		businessSettingsMutation.mutate({
	// 			data,
	// 		});
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

	return (
		<>
			<section className="flex items-center justify-between">
				<div className="flex items-center gap-x-6 text-sm">
					<div className="flex items-center">
						<Label
							htmlFor="queue-on-off"
							className="font-medium leading-[24px] tracking-[-0.16px] text-[#323539]"
						>
							Waitlist
						</Label>
						<Switch
							defaultChecked={!!isQueueOpen}
							checked={!!isQueueOpen}
							onClick={() => {
								setIsQueueOpen(isQueueOpen === 1 ? 0 : 1);
							}}
							id="queue-on-off"
							className="scale-75 data-[state=checked]:bg-[#32D74B] data-[state=unchecked]:bg-[#E5E5E7]"
						/>
						<Label
							htmlFor="queue-on-off"
							className="font-medium leading-[24px] tracking-[-0.16px] text-[#858C95]"
						>
							{isQueueOpen ? "On" : "Off"}
						</Label>
					</div>
					<div className="flex items-center">
						<Label
							htmlFor="schedule-on-off"
							className="font-medium leading-[24px] tracking-[-0.16px] text-[#323539]"
						>
							Show Scheduled Appointments
						</Label>
						<Switch
							className="scale-75 data-[state=checked]:bg-[#32D74B] data-[state=unchecked]:bg-[#E5E5E7]"
							defaultChecked={isScheduleOn}
							id="schedule-on-off"
							onClick={() => {
								if (tabView === "grid_view") {
									setTabView("column_view");
									setIsScheduleOn(!isScheduleOn);
								} else {
									setIsScheduleOn(!isScheduleOn);
								}
							}}
						/>
						<Label
							htmlFor="schedule-on-off"
							className="font-medium leading-[24px] tracking-[-0.16px] text-[#858C95]"
						>
							{isScheduleOn ? "On" : "Off"}
						</Label>
					</div>
					<div className="flex items-center">
						<Label htmlFor="autoflow-on-off">Auto Flow</Label>
						<Switch
							className="scale-75 data-[state=checked]:bg-[#32D74B] data-[state=unchecked]:bg-[#E5E5E7]"
							defaultChecked={isAutoFlowOn}
							id="autoflow-on-off"
							onClick={() => setIsAutoFlowOn(!isAutoFlowOn)}
						/>
						<Label
							htmlFor="schedule-on-off"
							className="font-medium leading-[24px] tracking-[-0.16px] text-[#858C95]"
						>
							{isAutoFlowOn ? "On" : "Off"}
						</Label>
					</div>
				</div>
				<div className="flex items-center gap-x-4 text-sm">
					<Button
						onClick={() =>
							setShowAddPatientModal(!showAddPatientModal)
						}
						className="flex space-x-2 p-3 shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
					>
						<i className="mgc_add_circle_line text-[16px] before:!text-white"></i>
						<p className="text-[14px] font-semibold leading-[20px] text-white">
							Add Patient
						</p>
					</Button>

					<WaitlistClearQueue />

					<Button
						variant="outline"
						size="icon-sm"
						className="shadow-[0px_1px_2px_0_rgba(16,24,40,0.04)]"
						onClick={() =>
							queryClient.invalidateQueries("queue-list")
						}
					>
						<LuRotateCcw size={18} />
					</Button>

					<WaitlistSettings />
				</div>
			</section>

			<AddPatient
				show={showAddPatientModal}
				setShow={setShowAddPatientModal}
			/>
		</>
	);
};

export default WaitlistShowScheduleAddPatientRow;

// const NumberOfUser: React.FC = () => {
// 	const patients = useWaitListStore((s) => s.patients);
// 	return (
// 		<div className="relative">
// 			<span className="absolute -right-3 -top-2 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-primary p-[2px] text-[9px] text-white">
// 				{patients.length}
// 			</span>
// 			<LuUsers size={18} />
// 		</div>
// 	);
// };
