import useCustomToast from "@src/components/CustomToast";
import { useHandleLoginSuccess } from "@src/hooks/useLoginSuccess";
import { APIVersion1GoogleLogin } from "@src/http/v1";
import { User } from "@src/types/NewUser";
import { AxiosError } from "axios";
import { useMutation } from "react-query";

export const GoogleLoginSlice = (
	onSuccess: (data: {
		status: boolean;
		message: string;
		user: User;
		token: string;
	}) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void = () => {
		return;
	}
) => {
	const customToast = useCustomToast();
	const handleLoginSuccess = useHandleLoginSuccess();

	return useMutation<
		{
			status: boolean;
			message: string;
			user: User;
			token: string;
		},
		AxiosError,
		{ token: string }
	>(APIVersion1GoogleLogin, {
		onSuccess: (data) => {
			onSuccess(data);
			handleLoginSuccess(data);
		},
		onError: (error: AxiosError) => {
			onError(error);
			customToast("An error occured kindly try again later", {
				id: "google-signup",
				type: "error",
			});
		},
	});
};
