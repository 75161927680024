import React from "react";

export interface ButtonProps extends React.PropsWithChildren {
	disabled: boolean;
	className?: string;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	type?: "button" | "submit" | "reset";
}

export const CustomButton: React.FC<ButtonProps> = ({
	disabled,
	className,
	onClick,
	children,
	type,
	...props
}) => {
	return (
		<button
			className={`${
				className
					? className +
						" flex transform items-center justify-center rounded-lg font-semibold transition-transform duration-300 ease-in-out focus:outline-none"
					: "flex transform items-center justify-center rounded-lg font-semibold transition-transform duration-300 ease-in-out focus:outline-none"
			} `}
			disabled={disabled}
			type={type}
			{...(onClick && { onClick })}
			{...props}
		>
			{children}
		</button>
	);
};
