import InputIcon from "@src/components/ui-extended/input-icon";
import { ScrollArea } from "@src/components/ui/scroll-area";
import { title } from "process";
import React from "react";
import { useNavigate } from "react-router";

const IntegationsAndPlugins: React.FC = () => {
	const navigate = useNavigate();
	return (
		<section className="flex h-[80dvh] flex-1 flex-col">
			<h2 className="mb-6 font-inter text-[22px] font-semibold leading-[24px] tracking-[-1%]">
				Integrations
			</h2>
			<ScrollArea className="flex-1">
				<InputIcon
					placeholder="Enter to search."
					className="placeholder:text-[#858C95]"
					icon="mgc_search_line"
				/>

				<div className="mt-12 flex flex-col gap-y-[70px]">
					<div className="">
						<h2 className="mb-6 font-inter text-[22px] font-semibold leading-[24px] tracking-[-1%]">
							Your Integrated Solutions
						</h2>

						{integrations.your_integrated_solutions.length
							? integrations.your_integrated_solutions.map(
									(_, index) => (
										<figure
											key={index}
											className="size-[160px] cursor-pointer rounded-xl border border-[#DEDEDE] bg-[#F6F6F6] shadow-[0_0_20px_0_rgba(0,0,0,0.1)]"
										/>
									)
								)
							: "No integrated solutions found."}
					</div>
					<div className="">
						<h2 className="mb-6 font-inter text-[22px] font-semibold leading-[24px] tracking-[-1%]">
							EMR Solutions
						</h2>

						{integrations.emr_solutions.length
							? integrations.emr_solutions.map((item, index) => (
									<figure
										key={index}
										onClick={() =>
											navigate(item.value, {
												replace: false,
											})
										}
										className="grid size-[160px] cursor-pointer place-content-center rounded-xl border border-[#DEDEDE] bg-[#F6F6F6] px-4 shadow-[0_0_20px_0_rgba(0,0,0,0.1)]"
									>
										<img
											src="/assets/images/integration/oscar/oscar-full.png"
											alt="Oscar Pro Logo"
										/>
									</figure>
								))
							: "No EMR solutions found."}
					</div>
					<div className="">
						<h2 className="mb-6 font-inter text-[22px] font-semibold leading-[24px] tracking-[-1%]">
							Enhancements
						</h2>

						{integrations.enhancements.length
							? integrations.enhancements.map((_, index) => (
									<figure
										key={index}
										className="size-[160px] cursor-pointer rounded-xl border border-[#DEDEDE] bg-[#F6F6F6] shadow-[0_0_20px_0_rgba(0,0,0,0.1)]"
									/>
								))
							: "No Enhancements found."}
					</div>
				</div>
			</ScrollArea>
		</section>
	);
};

export default IntegationsAndPlugins;

const integrations = {
	your_integrated_solutions: [],
	emr_solutions: [
		{ title: "OSCAR Pro", value: "oscar", image: "oscar-full.png" },
	],
	enhancements: [],
};
