import { AxiosResponse } from "axios";
import {
	ContactUsBody,
	UserLoginSchema,
	UserRegisterParams,
} from "../../types/Auth";
import { request } from "../../utils/axios-utils";

export const registerUser = (data: UserRegisterParams) => {
	return request({ url: "/register", method: "post", data });
};

export const loginUser = (data: UserLoginSchema) => {
	return request({ url: "/login", method: "post", data });
};

export const changePassword = (data: {
	password: string;
	password_confirmation: string;
}) => {
	return request({ url: "/change-password", method: "post", data });
};

export const resetPassword = (data: {
	email: string;
	password: string;
	password_confirmation: string;
}) => {
	return request({ url: "/reset-password", method: "post", data });
};

export const forgotPassword = (data: { email: string }) => {
	return request({ url: "/forgot-password", method: "post", data });
};

export const logoutUser = (): Promise<AxiosResponse<AxiosResponse>> => {
	return request({ url: "/auth/logout/", method: "post" });
};

export const passwordReset = (data: {
	email: string;
}): Promise<AxiosResponse> => {
	return request({
		url: "/forgot-password",
		method: "post",
		data,
	});
};

export const useQuerySample = (data: any): Promise<AxiosResponse> => {
	return request({
		url: "/",
		data,
	});
};

export const contactUs = (data: ContactUsBody) => {
	return request({ url: "/admin/customercontacts", method: "post", data });
};
