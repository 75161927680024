import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { Button } from "@src/components/ui/button";
import { Card } from "@src/components/ui/card";
import React from "react";
import { IoMdStopwatch } from "react-icons/io";
import ScheduleSettings from "../components/ScheduleSettings";
import { TbListDetails } from "react-icons/tb";
import { PiMapPinAreaBold } from "react-icons/pi";
import { RiCalendarScheduleLine } from "react-icons/ri";

// import ScheduleSettings from "../components/ScheduleSettings";
interface StationDetailsProps {}

const StationDetails: React.FC<StationDetailsProps> = () => {
	return (
		<div className="flex-1 space-y-4">
			<div className=" grid grid-cols-6 gap-6">
				<Card className="col-span-4 space-y-8 p-4 shadow-md">
					<div className="flex items-center justify-between">
						<div>
							<h3 className="mb-1 text-xl font-medium">
								[Station name]
							</h3>
							<p className="text-sm text-gray-400">
								Last activity: 12 march 2024, 23:54:00
							</p>
						</div>
						<Button variant={"secondary"} size="icon-sm">
							<i className="mgc_edit_2_line text-[16px] text-primary"></i>
						</Button>
					</div>
					<div className="flex justify-between">
						<div>
							<h3 className="mb-1 text-xl font-medium">
								Station Visits (Past 24 hours)
							</h3>
							<p className="text-sm text-gray-400">
								Total Visits: 82
							</p>
						</div>
					</div>

					<div className="flex justify-between">
						<div className="flex items-center">
							<IoMdStopwatch className="mr-2" size={42} />
							<div className="flex-1">
								<p className="text-xl font-medium">
									2 hr 30mins
								</p>
								<p className="text-sm">Date goes here</p>
							</div>
						</div>
						<div className="flex">
							<RiCalendarScheduleLine
								className="mr-2"
								size={42}
							/>
							<div className="flex-1">
								<p className="text-l mb-xl font-medium">
									2 hr 30mins
								</p>
								<p className="text-sm text-gray-400">
									Date goes here
								</p>
							</div>
						</div>
						<div className="flex">
							<TbListDetails className="mr-2" size={42} />
							<div className="flex-1">
								<p className="text-l mb-xl font-medium">
									2 hr 30mins
								</p>
								<p className="text-sm text-gray-400">
									Date goes here
								</p>
							</div>
						</div>
						<div className="flex">
							<PiMapPinAreaBold className="mr-2" size={42} />
							<div className="flex-1">
								<p className="text-l mb-xl font-medium">
									2 hr 30mins
								</p>
								<p className="text-sm text-gray-400">
									Date goes here
								</p>
							</div>
						</div>
					</div>
				</Card>
				<Card className="col-span-2 space-y-3 p-4 shadow-md">
					<div className="flex items-center justify-between space-x-2">
						<div className="flex-1">
							<h3 className="mb-1 text-xl font-medium">
								Station members
							</h3>
						</div>
						<Button variant={"secondary"} size="icon-sm">
							<i className="mgc_user_add_line text-[16px] text-primary"></i>
						</Button>
						<Button variant={"secondary"} size="icon-sm">
							<i className="mgc_edit_2_line text-[16px] text-primary"></i>
						</Button>
					</div>
					<div className="rounded-lg bg-gray-50 p-3">
						<p className="text-sm font-medium">
							Name{" "}
							<span className="font-light">(Station name)</span>
						</p>
						<p className="text-sm text-gray-400">
							(303) 555-454 email@g.com
						</p>
					</div>
					<div className="rounded-lg bg-gray-50 p-3">
						<p className="text-sm font-medium">
							Name{" "}
							<span className="font-light">(Station name)</span>
						</p>
						<p className="text-sm text-gray-400">
							(303) 555-454 email@g.com
						</p>
					</div>
					<div className="rounded-lg bg-gray-50 p-3">
						<p className="text-sm font-medium">
							Name{" "}
							<span className="font-light">(Station name)</span>
						</p>
						<p className="text-sm text-gray-400">
							(303) 555-454 email@g.com
						</p>
					</div>
				</Card>
			</div>
			<ScheduleSettings />
			<Card className="flex items-center justify-between space-x-2 p-4 shadow-md">
				<p className="flex-1">Waitlist</p>
				<div className="flex items-center space-x-2">
					<p>Waitlist Active</p>
					<CustomCheckbox2
						isChecked
						handleCheckboxChange={() => {}}
						className="checked:border-[#195388] checked:bg-[#195388]"
						id={""}
						hasText
					/>
				</div>
				<Button variant={"secondary"} size="icon-sm">
					<i className="mgc_edit_2_line text-[16px] text-primary"></i>
				</Button>
				<Button variant={"secondary"}>
					Settings
					<i className="mgc_settings_4_line ml-2 text-[16px] text-primary"></i>
				</Button>
			</Card>
		</div>
	);
};

export default StationDetails;
