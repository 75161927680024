import useHelpCrunchIdentify from "@src/hooks/useHelpCrunchIdentify";
import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutBusiness from "./components/Onboarding/AboutBusiness";
import AddCard from "./components/Onboarding/AddCard";
import AddLocation from "./components/Onboarding/AddLocation";
import OnboardingLayout from "./components/Onboarding/OnboardingLayout";
import RequireAuth from "./components/RequireAuth";
import Layout from "./layouts/Layout";
import Analytics from "./pages/analytics/Analytics";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import { SignIn } from "./pages/auth/SignIn";
import { SignUp } from "./pages/auth/SignUp";
import Display from "./pages/display/Display";
import Locations from "./pages/locations";
import LocationTabList from "./pages/locations/add-location/index";
import EditLocation from "./pages/locations/edit-location";
import LocationDetails from "./pages/locations/location-details";
// import LocationDetails from "./pages/locations/details";
import StationDetails from "./pages/locations/station-details";
import Schedule from "./pages/schedule/Schedule";
import Waitlist from "./pages/waitlist/Waitlist";
import Settings from "./pages/settings/components/AccountSettings";
import SettingsRoutes from "./pages/settings/SettingsRoutes";

const AppRoutes: React.FC = () => {
	useHelpCrunchIdentify();
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<SignIn />} />
				<Route path="/sign-up" element={<SignUp />} />
				<Route path="/sign-in" element={<SignIn />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route
					path="/reset-password/:token"
					element={<ResetPassword />}
				/>
				<Route element={<RequireAuth />}>
					<Route path="/onboarding" element={<OnboardingLayout />}>
						<Route
							path="/onboarding/about-business"
							element={<AboutBusiness />}
						/>
						<Route
							path="/onboarding/add-location"
							element={<AddLocation />}
						/>
						<Route
							path="/onboarding/add-payment-method"
							element={<AddCard />}
						/>
						<Route index element={<AboutBusiness />} />
					</Route>
				</Route>

				{/* <Route element={<RequireAuth />}>
					<Route path="/dashboard" element={<DashboardLayout />}>
						<Route
							path="/dashboard/waitlist"
							element={<Waitlist />}
						/>
					</Route> */}
				<Route element={<RequireAuth />}>
					<Route path="/dashboard" element={<Layout />}>
						<Route
							path="/dashboard/display"
							element={<Display />}
						/>
						<Route
							path="/dashboard/analytics"
							element={<Analytics />}
						/>
						<Route
							path="/dashboard/schedule"
							element={<Schedule />}
						/>
						<Route
							path="/dashboard/waitlist"
							index
							element={<Waitlist />}
						/>
						<Route
							path="/dashboard/locations"
							element={<Locations />}
						/>
						<Route
							path="/dashboard/locations/add-location"
							element={<LocationTabList />}
						/>
						<Route
							path="/dashboard/locations/edit-location"
							element={<EditLocation />}
						/>
						<Route
							path="/dashboard/locations/details"
							element={<LocationDetails />}
						/>
						<Route
							path="/dashboard/locations/details/station-detail"
							element={<StationDetails />}
						/>
						<Route
							path="/dashboard/settings/*"
							index
							element={<SettingsRoutes />}
						/>
					</Route>
				</Route>
			</Routes>
			<ToastContainer />
		</BrowserRouter>
	);
};

const ToastContainer: React.FC = () => {
	return (
		<Toaster
			position="bottom-center"
			toastOptions={{
				style: {
					maxWidth: "1000px",
				},
			}}
		/>
	);
};

export default AppRoutes;
