import { z } from "zod";
import { StatusOfPatient, StatusOfPatientType } from "../wait-list";

type ObjectValues<T> = T[keyof T];

export interface QueueEntry {
	id: number;
	queue_id: number;
	business_id: number;
	location_id: number;
	station_id: number;
	customer_id: number;
	assigned_user_id: number | null;
	approved_user_id: number | null;
	cancelled_user_id: number | null;
	service_start_at: string;
	service_done_at: string | null;
	approved_at: string | null;
	cancelled_at: string | null;
	cancelled_reason: string | null;
	order: number;
	track_code: string;
	status: string;
	created_at: string;
	updated_at: string;
	checked_in: number;
	location_approximate_waiting_time: string;
	track_url: string;
	approximate_waiting_time: string;
	feedback: string | null;
	station: Station;
	location: Location;
	customer: CustomerInfo;
	priority: PatientPriorityType;
}

interface WaitlistInfo {
	next_to_serve: QueueEntry[];
	accepted: QueueEntry[];
	getting_service: QueueEntry[];
	waiting_to_be_approved: QueueEntry[];
}

export interface QueueData {
	is_business_active: boolean;
	total_wait_time: string;
	waitlist_info: WaitlistInfo;
}

export const WaitlistGeneralSettingsSchema = z.object({
	manual_wait_time: z.string().optional(),
	time_zone: z.string().optional(),
	use_average_wait_time: z.union([z.literal(0), z.literal(1)]).optional(),
	schedule_block_in_min: z.union([z.literal(0), z.literal(1)]).optional(),
	is_queue_active: z.union([z.literal(0), z.literal(1)]).optional(),
	auto_approve_enabled: z.union([z.literal(0), z.literal(1)]).optional(),
});

export type WaitlistGeneralSettings = z.infer<
	typeof WaitlistGeneralSettingsSchema
>;

export const PatientPriority = {
	High: "high",
	Normal: "normal",
	Low: "low",
} as const;

export type PatientPriorityType = ObjectValues<typeof PatientPriority>;

export const ManageWaitlistSchema = z.object({
	waitlist_id: z.number(),
	status: z
		.enum(
			Object.values(StatusOfPatient) as [
				StatusOfPatientType,
				...StatusOfPatientType[],
			]
		)
		.optional(),
	checked_in: z.number().int().min(0).max(1).optional(),
	order: z.number().int().optional(),
	priority: z
		.enum(
			Object.values(PatientPriority) as [
				PatientPriorityType,
				...PatientPriorityType[],
			]
		)
		.optional(),
});

export type ManageWaitlistType = z.infer<typeof ManageWaitlistSchema>;
