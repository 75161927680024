import { zodResolver } from "@hookform/resolvers/zod";
import InputIcon from "@src/components/ui-extended/input-icon";
import { Label } from "@src/components/ui/label";
import React, { Dispatch, SetStateAction } from "react";

import useCustomToast from "@src/components/CustomToast";
import { useForm } from "react-hook-form";
import { z } from "zod";

const StepThree: React.FC<{
	step?: number;
	isErrorMessageOpen: boolean;
	errorMessage: string;
	setErrorMessage: Dispatch<SetStateAction<string>>;
	setIsErrorMessageOpen: Dispatch<SetStateAction<boolean>>;
	setStep: Dispatch<SetStateAction<number>>;
}> = () => {
	const customToast = useCustomToast();

	const schema = z.object({
		url: z.string().url(),
	});

	const {
		register,
		// handleSubmit,
		// setError,
		formState: { errors },
	} = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
	});

	return (
		<div className="m-1.5 flex flex-1 flex-col space-y-3">
			<h2 className="text-[22px] font-semibold leading-[30px] text-[#09090B]">
				Step 3
			</h2>
			<div className="mt-[29px] flex w-full max-w-[805px] flex-col space-y-8 rounded-xl border border-[#E4E4E7] bg-white p-6 shadow-[0_10px_15px_-3px_rgba(0,0,0,0.1)]">
				<div className="flex flex-col space-y-4">
					<div>
						<h3 className="text-[18px] font-semibold leading-[30px] text-[#09090B]">
							Once at REST Clients, click &apos;
							<span className="text-[#2D99C4]">Add New</span>
							&apos; client to your EMR
						</h3>
						<p className="text-[14px] leading-[20px] text-[#71717A]">
							Click on{" "}
							<span className="font-semibold text-[#09090B]">
								&apos;Add New&apos;
							</span>{" "}
							under Manage Clients
						</p>
					</div>
					<img
						src="/assets/images/integration/oscar/step-3.png"
						className="max-w-[501px] shadow-[0_0_10px_0_rgba(0,0,0,0.25)]"
						alt="Sub-step-3"
					/>
				</div>
				<div className="flex flex-col space-y-4">
					<div className="space-y-1">
						<h3 className="text-[18px] font-semibold text-[#09090B]">
							Fill in the information to complete adding a new
							client
						</h3>
						<p className="text-[14px] leading-[20px] text-[#71717A]">
							Copy the information from below and paste them on
							the{" "}
							<span className="font-semibold text-[#09090B]">
								&apos;Add New&apos;
							</span>{" "}
							pop up.
						</p>
					</div>
					<div className="flex space-x-[38px]">
						<div className="max-w-[344px] flex-1 flex-col space-y-2.5 py-1.5">
							<div className="space-y-1.5">
								<Label>Name</Label>

								<InputIcon
									outerClassName="h-9 shadow-[0px_0px_2px_0.5px_rgba(16,24,40,0.05)]"
									icon="mgc_copy_2_fill cursor-pointer"
									position="right"
									value="Migranium Inc"
									iconClick={() => {
										navigator.clipboard
											.writeText("Migranium Inc")
											.then(() => {
												customToast(
													"Copied to Clipboard",
													{
														id: "copy-to-clipboard",
														type: "success",
													}
												);
											})
											.catch(() => {
												customToast("Unable to copy", {
													id: "copy-to-clipboard",
													type: "error",
												});
											});
									}}
								/>
							</div>
							<div className="space-y-1.5">
								<Label>URL</Label>
								<InputIcon
									outerClassName="h-9 shadow-[0px_0px_2px_0.5px_rgba(16,24,40,0.05)]"
									icon="mgc_copy_2_fill cursor-pointer"
									position="right"
									value="www.exampleurl.com"
									iconClick={() => {
										navigator.clipboard
											.writeText("www.exampleurl.com")
											.then(() => {
												customToast(
													"Copied to Clipboard",
													{
														id: "copy-to-clipboard",
														type: "success",
													}
												);
											})
											.catch(() => {
												customToast("Unable to copy", {
													id: "copy-to-clipboard",
													type: "error",
												});
											});
									}}
								/>
							</div>
							<div className="space-y-1.5">
								<Label>Lifetime Token</Label>
								<InputIcon
									outerClassName="h-9 shadow-[0px_0px_2px_0.5px_rgba(16,24,40,0.05)]"
									icon="mgc_copy_2_fill cursor-pointer"
									position="right"
									value="-1"
									iconClick={() => {
										navigator.clipboard
											.writeText("-1")
											.then(() => {
												customToast(
													"Copied to Clipboard",
													{
														id: "copy-to-clipboard",
														type: "success",
													}
												);
											})
											.catch(() => {
												customToast("Unable to copy", {
													id: "copy-to-clipboard",
													type: "error",
												});
											});
									}}
								/>
							</div>
						</div>
						<figure className="max-w-[286px] flex-1 self-stretch bg-[#D9D9D9]" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default StepThree;
