import { GetAnalyticsSlice } from "@src/store/slices/analytics/getAnalyticsSlice";
import dayjs from "dayjs";
import { CalendarIcon } from "lucide-react";
import React, { useEffect } from "react";
import { DateRange } from "react-day-picker";
import { LuRotateCcw } from "react-icons/lu";
import { useQueryClient } from "react-query";
import Statistics from "../../components/Dashboard/analytics/Statistics/Statistics";
import Summary from "../../components/Dashboard/analytics/Summary";
import { CustomSelect } from "../../components/form/CustomSelect";
import RequestIsLoading from "../../components/RequestIsLoading";
import { Button } from "../../components/ui/button";
import { Calendar } from "../../components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "../../components/ui/popover";
import useAnalyticsStore, {
	AnalyticsQuery,
} from "../../store/useAnalyticsStore";
import useUserStore from "../../store/useUserStore";
import { cn } from "../../utils/functions";

const Analytics: React.FC = () => {
	const { isLoading, isFetching } = GetAnalyticsSlice();
	const locationsList = useUserStore((s) => s.locationsList);
	const stationsList = useUserStore((s) => s.stationsList);
	const setAnalyticsQuery = useAnalyticsStore((s) => s.setAnalyticsQuery);
	const analyticsQuery = useAnalyticsStore((s) => s.analyticsQuery);
	const queryClient = useQueryClient();

	return (
		<section className="flex flex-col gap-y-4">
			<div className="mt-0.5 flex items-center justify-end space-x-4">
				<CustomSelect
					className="h-9 w-[186px]"
					placeholder="Location"
					label="Location"
					options={locationsList}
					value={analyticsQuery?.location_id}
					onChange={(value) => {
						setAnalyticsQuery({
							location_id: value,
						} as AnalyticsQuery);
					}}
					labelClassName="px-2"
					itemClassName="px-8"
				/>
				{analyticsQuery?.location_id && (
					<CustomSelect
						className="h-9 w-[186px]"
						placeholder="Station"
						label="Station"
						options={stationsList}
						value={analyticsQuery?.station_id}
						onChange={(value) => {
							setAnalyticsQuery({
								station_id: value,
							} as AnalyticsQuery);
						}}
						labelClassName="px-2"
						itemClassName="px-8"
					/>
				)}

				<AnalyticsDatePicker />
				<Button variant="outline" size="icon-sm" className="h-9">
					<LuRotateCcw
						size={18}
						onClick={() =>
							queryClient.invalidateQueries("analytics")
						}
						className={`${isFetching && "spinning"}`}
					/>
				</Button>
				<Button className="h-9 max-w-[115px] space-x-1.5 rounded-md">
					<i className="mgc_download_2_line before-text-white text-[18px]" />
					<p className="text-white">Export Pdf</p>
				</Button>
			</div>
			<div
				className={`mt-6 flex flex-col space-y-6 ${isLoading && "hidden"}`}
			>
				<Summary />
				<Statistics />
			</div>
			<RequestIsLoading isLoading={isLoading} />
		</section>
	);
};

export default Analytics;

const AnalyticsDatePicker: React.FC = () => {
	const user = useUserStore((s) => s.user);
	const [date, setDate] = React.useState<DateRange | undefined>({
		from: new Date(user?.created_at ?? new Date()),
		to: new Date(),
	});

	const setAnalyticsQuery = useAnalyticsStore((s) => s.setAnalyticsQuery);

	useEffect(() => {
		setAnalyticsQuery({
			from_date: dayjs(date?.from ?? new Date()).format("YYYY-MM-DD"),
			before_date: dayjs(date?.to ?? new Date()).format("YYYY-MM-DD"),
		} as AnalyticsQuery);
	}, [date]);

	return (
		<div className={cn("grid max-w-[320px] gap-2")}>
			<Popover>
				<PopoverTrigger asChild>
					<Button
						id="date"
						variant={"outline"}
						className={cn(
							"h-9 w-[300px] justify-start text-left font-normal",
							!date && "text-muted-foreground"
						)}
					>
						<CalendarIcon className="mr-2 h-4 w-4" />
						{date?.from ? (
							date.to ? (
								<>
									{dayjs(date.from).format("MMM DD, YYYY")} -
									{dayjs(date.to).format("MMM DD, YYYY")}
								</>
							) : (
								dayjs(date.from).format("MMM DD, YYYY")
							)
						) : (
							<span>Pick a date</span>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-auto p-0" align="start">
					<Calendar
						initialFocus
						mode="range"
						defaultMonth={date?.from}
						selected={date}
						onSelect={setDate}
						numberOfMonths={2}
					/>
				</PopoverContent>
			</Popover>
		</div>
	);
};
