import { Dispatch, SetStateAction, useRef } from "react";
import Overlay from "../Overlay";
import { Button } from "../ui/button";
import { cn } from "../../utils/functions";
import { IoClose } from "react-icons/io5";

interface ModalWrapperProps {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
	children: React.ReactNode;
	className?: string;
	isPersistent?: boolean;
}

const ModalWrapper = ({
	show,
	setShow,
	className,
	isPersistent,
	children,
}: ModalWrapperProps) => {
	const modalRef = useRef<HTMLDivElement | null>(null);
	return (
		<Overlay
			modalRef={modalRef}
			show={show}
			setShow={setShow}
			isPersistent={isPersistent}
		>
			<div
				ref={modalRef}
				className={cn(
					"relative  overflow-y-auto rounded-md bg-white p-4 ",
					className
				)}
			>
				<Button
					className="absolute right-1 top-1 bg-transparent text-black hover:bg-transparent "
					onClick={() => setShow(false)}
				>
					<IoClose className=" text-black" />
				</Button>
				{children}
			</div>
		</Overlay>
	);
};

export default ModalWrapper;
