import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
} from "axios";
import toast from "react-hot-toast";
import { CONFIGS } from "@src/configs";

export const client: AxiosInstance = axios.create({
	baseURL: `${CONFIGS.URL.API_BASE_URL}`,
	withCredentials: true,
});

export const request = ({
	...options
}: AxiosRequestConfig): Promise<AxiosResponse> => {
	const onSuccess = (response: AxiosResponse) => response;
	const onError = (error: AxiosError) => {
		if (
			(error.response && error.response.status >= 400) ||
			!error.response
		) {
			if (!error.response) {
				toast.error(
					"Kindly check your network connection and try again"
				);
				return Promise.reject(error);
			}
			return Promise.reject(error);
		}
		return Promise.reject(error);
	};

	return client(options).then(onSuccess).catch(onError);
};
