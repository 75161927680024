import dayjs from "dayjs";
import React from "react";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "../../../ui/accordion";
import ReviewRatings from "./ReviewRatings";
import VisitsByLocation from "./VisitsByLocation";
import VisitsByWeekDay from "./VisitsByWeekDay";
import useAnalyticsStore from "../../../../store/useAnalyticsStore";

const Statistics: React.FC = () => {
	const analyticsQuery = useAnalyticsStore((s) => s.analyticsQuery);
	const dateRange =
		dayjs(analyticsQuery?.from_date).format("D MMMM, YYYY") +
		" " +
		" " +
		dayjs(analyticsQuery?.before_date).format("D MMMM, YYYY");

	return (
		<Accordion
			type="single"
			collapsible
			className="flex flex-col space-y-6"
		>
			<AccordionItem
				value="item-1"
				className="w-full rounded-[20px] border-none bg-[#F8F9FB] p-3"
			>
				<AccordionTrigger className="flex py-0 hover:no-underline">
					<div className="flex flex-col items-start space-y-1">
						<h3 className="text-lg font-semibold text-[#111827]">
							Statistics
						</h3>
						<p className="text-sm font-normal text-[#374151]">
							{dateRange}
						</p>
					</div>
				</AccordionTrigger>
				<AccordionContent className="grid grid-cols-[1fr_1fr] grid-rows-2 gap-4 p-0 pt-4">
					<VisitsByLocation />
					{/* <VisitsByStation /> */}
					<ReviewRatings />
				</AccordionContent>
			</AccordionItem>
			<AccordionItem
				value="item-2"
				className="w-full rounded-[20px] border-none bg-[#F8F9FB] p-3"
			>
				<AccordionTrigger className="flex py-0 hover:no-underline">
					<div className="flex flex-col items-start space-y-1">
						<h3 className="text-lg font-semibold text-[#111827]">
							Trends
						</h3>
						<p className="text-sm font-normal text-[#374151]">
							{dateRange}
						</p>
					</div>
				</AccordionTrigger>
				<AccordionContent className="grid grid-cols-[1fr_1fr] grid-rows-2 gap-4 p-0 pt-4">
					<VisitsByWeekDay />
				</AccordionContent>
			</AccordionItem>
		</Accordion>
	);
};

export default Statistics;
