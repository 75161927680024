import React, { useEffect, useState } from "react";
import { CustomSelect } from "@components/form/CustomSelect";
import { Button } from "@components/ui/button";
import FormInput from "@components/form/FormInput";
import { AccordionComboboxSearch } from "@components/common/CustomSearch";
import { useFormContext } from "react-hook-form";
import { getErrorMessage } from "@src/utils/functions";

const options = [
	{ value: "marvin-mckinney", label: "Marvin McKinney" },
	{ value: "esther-howard", label: "Esther Howard" },
	{ value: "esther-tim", label: "Esther Tim" },
	{ value: "esther-troth", label: "Esther Troth" },
	{ value: "esther-bush", label: "Esther Bush" },
];

const roleOptions = [
	{ value: "PARTNER_MANAGER", label: "Partner Manager" },
	{ value: "LOCATION_MANAGER", label: "Location Manager" },
	{ value: "ROOM_MANAGER", label: "Room Manager" },
	{ value: "TEAM_MEMBER", label: "Team Member" },
	{ value: "BUSINESS_ADMIN", label: "Super Admin" },
];

interface Member {
	id: number;
	memberName: string;
	memberRole: string;
	memberEmail: string;
	memberPhone: string;
}

const LocationMember: React.FC = () => {
	const [members, setMembers] = useState<Member[]>([]);
	const [selectedMember, setSelectedMember] = useState<Member | null>(null);
	const [isFormVisible, setIsFormVisible] = useState(false);
	const [formData, setFormData] = useState<Member>({
		id: selectedMember?.id || Date.now(),
		memberName: selectedMember?.memberName || "",
		memberRole: selectedMember?.memberRole || "",
		memberEmail: selectedMember?.memberEmail || "",
		memberPhone: selectedMember?.memberPhone || "",
	});

	useEffect(() => {
		if (selectedMember) {
			setFormData(selectedMember);
		}
	}, [selectedMember]);

	const handleChange = (value: string) => {
		console.log("Selected value:", value);
	};

	const handleAddNewMember = () => {
		setSelectedMember(null);
		setIsFormVisible(true);
	};

	// const handleSaveMember = (member: Member) => {
	// 	setMembers((prev) => {
	// 		const existingMemberIndex = prev.findIndex(
	// 			(m) => m.id === member.id
	// 		);
	// 		if (existingMemberIndex !== -1) {
	// 			const updatedMembers = [...prev];
	// 			updatedMembers[existingMemberIndex] = member;
	// 			return updatedMembers;
	// 		}
	// 		return [...prev, member];
	// 	});
	// 	setIsFormVisible(false);
	// };

	// const handleEditMember = (member: Member) => {
	// 	setSelectedMember(member);
	// 	setIsFormVisible(true);
	// };

	// const handleRemoveMember = (id: number) => {
	// 	setMembers((prev) => prev.filter((member) => member.id !== id));
	// };

	const {
		register,
		watch,
		// setValue,
		// getValues,
		// trigger,
		formState: { errors },
	} = useFormContext();

	return (
		<div>
			<h3 className="text-xl font-medium">Members</h3>

			<div>
				<div>
					<label className="text-sm">Admin Member *</label>
					<div className="relative flex items-center">
						<AccordionComboboxSearch
							options={options}
							placeholder="Type a for search..."
							emptyMessage="No options found."
							onSelect={handleChange}
						/>
					</div>
				</div>
				<Button
					variant={"link"}
					className="p-0"
					onClick={handleAddNewMember}
				>
					<i className="mgc_user_add_line mr-2 text-sm" />
					Add new member
				</Button>
				{isFormVisible && (
					<div className="flex items-end justify-between space-x-1 rounded-lg bg-gray-100 p-3 pr-1">
						<div className="grid flex-1 grid-cols-4 gap-3">
							<div>
								<FormInput
									inputType={"text"}
									label="Member Name  * "
									register={{ ...register("memberName") }}
									className="h-10"
									error={getErrorMessage(
										errors.address?.message
									)}
									minLength={5}
									maxLength={40}
								/>
							</div>
							<div className="flex-1">
								<label className="bt-1.5 mb-1.5 block text-sm font-medium tracking-[-0.1px] text-[#323539]">
									Role *
								</label>
								<CustomSelect
									placeholder="Select"
									label="Role  *"
									className=" h-10"
									options={roleOptions}
									value={watch("memberRole")}
									onChange={() => {
										// setSelectedStations(selectedOption);
									}}
									error={getErrorMessage(
										errors.city?.message
									)}
								/>
							</div>
							<div>
								<FormInput
									inputType={"text"}
									label="Email Address "
									register={{ ...register("name") }}
									className="h-10"
									error={getErrorMessage(
										errors.address?.message
									)}
									minLength={5}
									maxLength={40}
								/>
							</div>
							<div>
								<FormInput
									inputType={"text"}
									label="Phone Number"
									register={{ ...register("name") }}
									className="h-10"
									error={getErrorMessage(
										errors.address?.message
									)}
									minLength={5}
									maxLength={40}
								/>
							</div>
						</div>
						<div className="flex h-[40px] items-center">
							<Button variant="secondary" size="icon-sm">
								<i className="mgc_add_circle_line text-[24px] text-red-500" />
							</Button>
						</div>
					</div>
				)}
			</div>

			<div className="mt-4">
				<h3 className="mb-2 text-xl font-medium">Selected Member</h3>
				<div className="flex items-center justify-between rounded-lg bg-gray-100 p-3">
					<div>
						<h2 className="text-md font-medium">
							Ronald Richards{" "}
							<span className="font-thin">(Super Admin)</span>
						</h2>
						<div className="flex space-x-3 text-sm text-gray-400">
							<p>(303) 555-0105</p>
							<p>tim.jennings@example.com</p>
						</div>
					</div>
					<div className="flex">
						<Button variant="secondary" size="icon-sm">
							<i className="mgc_edit_2_line mr-2 text-[24px]" />
						</Button>
						<Button
							variant="secondary"
							className="text-red-500"
							size="icon-sm"
						>
							<i className="mgc_minus_circle_line mr-2 text-[24px] text-red-500" />
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LocationMember;
