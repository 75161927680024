import { useState } from "react";
import { Switch } from "../../ui/switch";
import { Label } from "../../ui/label";

const AutoApprove = () => {
	const [isAutoApprove, setIsAutoApprove] = useState<boolean>(false);
	return (
		<div className="flex items-center ">
			<p className="font-medium text-[#323539]">Auto Approve</p>
			<Switch
				defaultChecked={isAutoApprove}
				id="queue-on-off"
				onClick={() => setIsAutoApprove(!isAutoApprove)}
				className="scale-[0.8] data-[state=checked]:bg-[#2AA63C] data-[state=unchecked]:bg-gray-300"
			/>
			<Label htmlFor="automatic-approve">
				{isAutoApprove ? (
					<span className="text-base font-bold text-[#2AA63C]">
						ON
					</span>
				) : (
					<span className="text-base font-bold text-[#C1C1C1]">
						OFF
					</span>
				)}
			</Label>
		</div>
	);
};

export default AutoApprove;
