import { LoaderButton } from "@src/components/form/LoaderButton";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogTitle,
	DialogTrigger,
} from "@src/components/ui/dialog";
import ManageWaitlistSlice from "@src/store/slices/waitlist/manageWaitlistSlice";
import { QueueEntry } from "@src/types/waitlist/waitlist";
import React, { useState } from "react";
import { LuX } from "react-icons/lu";
import useCustomToast from "../../CustomToast";
import { Button } from "../../ui/button";
import { StatusOfPatient } from "@src/types/wait-list";

const DeletePatient: React.FC<{
	patientDetails: QueueEntry;
}> = ({ patientDetails }) => {
	const [showDeleteCustomerModal, setShowDeleteCustomerModal] =
		useState(false);

	const customToast = useCustomToast();

	const manageWaitlistMutation = ManageWaitlistSlice(
		() => {
			customToast("Patient Deleted Successfully", {
				id: "manage-patient",
				type: "success",
			});
		},
		() => {
			customToast("Patient could not be deleted", {
				id: "manage-patient",
				type: "error",
			});
		}
	);

	return (
		<Dialog
			open={showDeleteCustomerModal}
			onOpenChange={(newState) => {
				if (!manageWaitlistMutation.isLoading)
					setShowDeleteCustomerModal(newState);
			}}
		>
			<DialogTrigger asChild>
				<Button
					className="size-[30px] rounded-md bg-[#F5F5F5] p-0 hover:bg-[#F5F5F5]"
					onClick={() => setShowDeleteCustomerModal(true)}
				>
					<i className="mgc_delete_2_line text-[14px]" />
				</Button>
			</DialogTrigger>
			<DialogContent className="max-w-[360px] p-4">
				<div className="flex justify-between space-x-2">
					<i className="mgc_user_x_line py-1 text-[20px] before:!text-[#C8322B]" />
					<div className="flex flex-col">
						<DialogTitle className="text-[22px] font-semibold capitalize leading-[30px] -tracking-[1%] text-[#323539]">
							Delete From Waitlist
						</DialogTitle>
						<p className="trakcing-[-0.1px] text-[14px] leading-[20px] text-[#6D748D]">
							Are you sure you want to delete{" "}
							<b className="text-[#323539]">
								{patientDetails.customer.full_name}
							</b>{" "}
							from the waitlist? This action can&apos;t be undone.
						</p>
						<DialogFooter className="mt-6 space-x-1 sm:space-x-1">
							<Button
								variant="ghost"
								className="space-x-5 text-[#323539]"
								onClick={() => {
									if (!manageWaitlistMutation.isLoading)
										setShowDeleteCustomerModal(false);
								}}
								disabled={manageWaitlistMutation.isLoading}
							>
								Cancel
							</Button>
							<LoaderButton
								variant="destructive"
								className="px-5 text-white"
								onClick={() => {
									customToast("Deleting Patient", {
										id: "manage-patient",
										type: "loading",
									});
									manageWaitlistMutation.mutate({
										data: {
											waitlist_id:
												patientDetails.queue_id,
											status: StatusOfPatient.Cancelled,
										},
										queryParams: {
											business_id:
												patientDetails.business_id,
										},
									});
								}}
								loading={manageWaitlistMutation.isLoading}
								disabled={manageWaitlistMutation.isLoading}
								loaderSize={20}
							>
								Delete
							</LoaderButton>
						</DialogFooter>
					</div>
					<button
						disabled={manageWaitlistMutation.isLoading}
						className="flex items-start"
						onClick={(e) => {
							e.preventDefault();
							if (!manageWaitlistMutation.isLoading)
								setShowDeleteCustomerModal(false);
						}}
					>
						<LuX
							color="#858C95"
							className="cursor-pointer"
							width="20px"
							height="20px"
						/>
					</button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default DeletePatient;
