import { Dispatch, SetStateAction, useState } from "react";
import ModalWrapper from "../common/ModalWrapper";
import { Button } from "../ui/button";
import { cn } from "../../utils/functions";
import Select from "react-select";
import General from "../Dashboard/schedule/settings/General";
import LinkedCalendars from "../Dashboard/schedule/settings/LinkedCalendars";

interface ScheduleSettingsProps {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
}

const ScheduleSettings = ({ show, setShow }: ScheduleSettingsProps) => {
	const [activeTab, setActiveTab] = useState<"all" | "specific">("all");
	const [settingTab, setSettingTab] = useState<
		"general" | "operational_hours" | "linked_calendars"
	>("general");
	return (
		<ModalWrapper show={show} setShow={setShow}>
			<div className="flex h-[80vh] max-h-[80vh] w-[65vw] flex-col gap-y-4 bg-white">
				<div className="border-b pb-2">
					<h1 className="text-[22px] font-semibold text-[#323539]">
						Schedule Setttings
					</h1>
				</div>

				{/* For the tab selection  */}
				<section className="pt-4">
					{/* The top tabs  */}
					<div className="mb-4 ml-auto flex w-[70%] cursor-pointer border-b border-b-[#9CA3AF] text-[15px] font-semibold text-[#858C95]">
						<div
							onClick={() => setActiveTab("all")}
							className={cn("flex w-full justify-center py-2", {
								"border-b-2 border-b-primary text-primary":
									activeTab === "all",
							})}
						>
							<p>Default</p>
						</div>
						<div
							onClick={() => setActiveTab("specific")}
							className={cn("flex w-full justify-center py-2", {
								"border-b-2 border-b-primary text-primary":
									activeTab === "specific",
							})}
						>
							<p>Specific Stations</p>
						</div>
					</div>

					{activeTab === "specific" ? (
						<div className="mb-4 flex justify-center rounded-[20px] bg-[#F7F7F7] py-4">
							<div className="mx-auto max-w-[683px] space-y-2">
								<h1 className="text-[#858C95]">
									<span>Select a station</span> from the
									dropdown below to{" "}
									<span className="font-semibold">view</span>
									and{" "}
									<span className="font-semibold">
										edit
									</span>{" "}
									a specific station&apos;s schedule settings
								</h1>
								<Select placeholder="Select a station" />
							</div>
						</div>
					) : null}

					{/* the main content  */}
					<div className="flex gap-8">
						<div className="flex w-full flex-[0.3] flex-col gap-y-2 border-r border-r-[#005893] pr-4 ">
							<Button
								className={cn(
									"h-[58px] justify-start bg-[#F7F7F7] text-[#323539] ",
									{
										"bg-[#0C5392] text-white":
											settingTab === "general",
									}
								)}
								onClick={() => setSettingTab("general")}
							>
								General
							</Button>
							<Button
								className={cn(
									"h-[58px] justify-start bg-[#F7F7F7] text-[#323539]",
									{
										"bg-[#0C5392] text-white":
											settingTab === "linked_calendars",
									}
								)}
								onClick={() =>
									setSettingTab("linked_calendars")
								}
							>
								Linked Calendars
							</Button>
							<Button
								className={cn(
									"h-[58px] justify-start bg-[#F7F7F7] text-[#323539]",
									{
										"bg-[#0C5392] text-white":
											settingTab === "operational_hours",
									}
								)}
							>
								Operational Hours
							</Button>
						</div>
						{/* Settings content  */}
						<div className="flex-[0.7] ">
							{settingTab === "general" ? (
								<General />
							) : settingTab === "linked_calendars" ? (
								<LinkedCalendars />
							) : (
								<p>Operational Hours</p>
							)}
						</div>
					</div>
				</section>

				<div className="mt-auto flex w-full justify-between gap-4 border-t py-4">
					<Button
						className="bg-transparent text-primary hover:bg-transparent"
						variant="outline"
						onClick={() => setShow(false)}
					>
						Advanced Settings
					</Button>
					<div>
						<Button
							variant="ghost"
							className="text-red-400"
							onClick={() => setShow(false)}
						>
							Cancel
						</Button>
						<Button
							className=" text-white"
							variant="default"
							onClick={() => setShow(false)}
						>
							Save
						</Button>
					</div>
				</div>
			</div>
		</ModalWrapper>
	);
};

export default ScheduleSettings;
