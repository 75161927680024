import CustomSelect from "@src/components/common/CustomSelect";
import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { Button } from "@src/components/ui/button";
import { Input } from "@src/components/ui/input";
import { Select } from "@src/components/ui/select";
import { useState } from "react";

interface IntakeFieldListProps {}

const IntakeFieldList: React.FC<IntakeFieldListProps> = () => {
	const [isEdit, setIsEdit] = useState(false);
	return (
		<div className="flex items-center justify-between rounded-lg border p-2 text-sm text-gray-400">
			<div
				className={`flex items-center space-x-2 pr-2 ${!isEdit ? "w-[40%]" : "w-[35%]"}`}
			>
				<CustomCheckbox2
					isChecked
					handleCheckboxChange={() => {}}
					className="checked:border-[#195388] checked:bg-[#195388]"
					id={""}
				/>
				{!isEdit ? (
					<label
						htmlFor="terms"
						className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						Firstname
					</label>
				) : (
					<Input />
				)}
			</div>
			<div className={`${!isEdit ? "w-[10%]" : "w-[13%]"} pr-2`}>
				{!isEdit ? <p>Text</p> : <CustomSelect placeholder="Select" />}
			</div>
			<div className={`${!isEdit ? "w-[10%]" : "w-[12%]"} pr-2`}>
				{!isEdit ? (
					<p>[All Station]</p>
				) : (
					<CustomSelect placeholder="Select" />
				)}
			</div>
			<div className="w-[15%] pr-2">
				{!isEdit ? (
					<p>[Waitlist schedule]</p>
				) : (
					<CustomSelect placeholder="Select" />
				)}
			</div>
			<div className="flex w-[25%] justify-end space-x-2">
				{isEdit && (
					<>
						<div className="flex-1">
							<CustomSelect placeholder="Select" />
						</div>
						<Button
							variant="secondary"
							size="icon-sm"
							onClick={() => setIsEdit(false)}
						>
							<i className="mgc_close_line text-[16px] text-primary"></i>
						</Button>
						<Button
							variant="default"
							size="icon-sm"
							className="text-white"
							// onClick={() => setShowClearQueueModal(true)}
						>
							<i className="mgc_save_2_line text-[16px]"></i>
						</Button>
					</>
				)}
				{!isEdit && (
					<Button
						variant="secondary"
						size="icon-sm"
						onClick={() => setIsEdit(true)}
					>
						<i className="mgc_edit_2_line text-[16px] text-primary"></i>
					</Button>
				)}
				<Button
					variant="secondary"
					size="icon-sm"
					// onClick={() => setShowClearQueueModal(true)}
				>
					<i className="mgc_delete_2_line text-[16px] text-primary"></i>
				</Button>
			</div>
		</div>
	);
};

export default IntakeFieldList;
