import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "@src/components/form/FormInput";
import { Button } from "@src/components/ui/button";
import React, { Dispatch, SetStateAction } from "react";

import { useForm } from "react-hook-form";
import { z } from "zod";

const StepOne: React.FC<{
	step?: number;
	isErrorMessageOpen: boolean;
	errorMessage: string;
	setErrorMessage: Dispatch<SetStateAction<string>>;
	setIsErrorMessageOpen: Dispatch<SetStateAction<boolean>>;
	setStep: Dispatch<SetStateAction<number>>;
}> = ({ setStep }) => {
	const schema = z.object({
		url: z.string().url(),
	});

	const {
		register,
		// handleSubmit,
		// setError,
		formState: { errors },
	} = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
	});

	return (
		<div className="m-1.5 flex flex-1 flex-col space-y-3">
			<h2 className="text-[22px] font-semibold leading-[30px] text-[#09090B]">
				Step 1
			</h2>
			<form className="mt-[29px] flex w-full max-w-[805px] flex-col space-y-4 rounded-xl border border-[#E4E4E7] bg-white p-6 shadow-[0_10px_15px_-3px_rgba(0,0,0,0.1)]">
				<div>
					<h3 className="text-[18px] font-semibold leading-[30px] text-[#09090B]">
						Sign in to your EMR!
					</h3>
					<p className="text-[14px] leading-[20px] text-[#71717A]">
						Go to your EMR website, paste the login link here, and
						then sign into your account!
					</p>
				</div>
				<div className="space-y-1">
					{/* <Label className="text-[14px] font-medium leading-[20px] text-[#09090B]">
          Go to your organization&apos;s Unique EMR
          Link
        </Label> */}
					<FormInput
						inputType={"text"}
						label="Paste Below"
						placeholder="Enter the URL here"
						minLength={5}
						maxLength={2048}
						error={errors.url?.message}
						register={{ ...register("url") }}
						required
					/>
				</div>
				<Button
					className="mt-4 h-9 self-end text-white"
					onClick={(e) => {
						e.preventDefault();
						setStep(2);
					}}
				>
					Connect to your EMR
				</Button>
			</form>
		</div>
	);
};

export default StepOne;
