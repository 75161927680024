import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import BookADemo from "./BookADemo";
import ContactUsModal from "./ContactUsModal/ContactUsModal";
import { CustomButton } from "./form/CustomButton";

const Navbar: React.FC<{
	isWhite?: boolean;
	showBookADemo: boolean;
	setShowBookDemo: Dispatch<SetStateAction<boolean>>;
	showContactUsModal: boolean;
	setshowContactUsModal: Dispatch<SetStateAction<boolean>>;
}> = ({
	isWhite,
	showBookADemo,
	setShowBookDemo,
	showContactUsModal,
	setshowContactUsModal,
}) => {
	const navigate = useNavigate();
	const [isMobileShowing, setIsMobileShowing] = useState(false);

	const [hasScrolled, setHasScrolled] = useState(false);
	const [rootElement, setRootElement] = useState<null | HTMLElement>(null);

	useEffect(() => {
		setRootElement(document.getElementById("root"));
		window.addEventListener("scroll", () => {
			if (window.scrollY > 20) {
				setHasScrolled(true);
			} else {
				setHasScrolled(false);
			}
		});
		return () =>
			window.removeEventListener("scroll", () => {
				setHasScrolled(false);
			});
	}, []);

	return (
		<>
			<nav
				className={`sticky top-0 z-40 flex w-full items-center justify-center ${
					hasScrolled || isWhite
						? "border-b border-b-[#E5E5E7] bg-white"
						: "border-none bg-[#005893]"
				} duration-300 ease-in-out`}
			>
				<div className="relative flex w-full max-w-[1248px] items-center justify-between">
					<div className="flex w-full items-center justify-between px-4 py-4">
						<Link to={"/"}>
							<img
								src="/assets/images/logo-blue.svg"
								alt="Migranium logo"
								className={`h-10 msm:h-[31px] ${
									!(hasScrolled || isWhite) && "hidden"
								}`}
							/>
							<img
								src="/assets/images/logo-white.svg"
								alt="Migranium logo"
								className={`h-10 msm:h-[31px] ${
									(hasScrolled || isWhite) && "hidden"
								}`}
							/>
						</Link>
						<ul className="flex items-center space-x-11">
							<div className="flex space-x-8 mlg:hidden">
								<NavbarItem
									title={"Contact Us"}
									hasScrolled={hasScrolled}
									onClick={() => {
										setshowContactUsModal(true);
									}}
									isWhite={isWhite}
								/>
							</div>
							<div className="flex items-center space-x-4 sm:space-x-2.5">
								<CustomButton
									disabled={false}
									onClick={() => {
										navigate("/sign-in");
									}}
									className={`border py-2.5  ${
										(hasScrolled && !isMobileShowing) ||
										isWhite
											? "border-[#005893] bg-white !text-[#005893] hover:border-[#3EC9BC] hover:!text-[#3EC9BC]"
											: "border-white bg-[#005893] hover:border-[#72F4E8] hover:!text-[#72F4E8]"
									} h-9 max-h-[40px] text-sm  font-medium text-white duration-200 ease-in-out  sm:w-[103px] lg:h-10 mlg:px-3 mlg:py-2`}
								>
									Sign in
								</CustomButton>
								<div
									onClick={() =>
										setIsMobileShowing(!isMobileShowing)
									}
									className="lg:hidden"
								>
									<input
										id="menu__toggle"
										type="checkbox"
										checked={isMobileShowing}
										className="hidden"
										hidden
									/>
									<label className="menu__btn flex h-[18px] w-[18px] items-center justify-center">
										<span
											className={`${
												hasScrolled || isWhite
													? "!bg-[#005893] before:!bg-[#005893] after:!bg-[#005893]"
													: ""
											} duration-200 ease-in-out`}
										></span>
									</label>
								</div>
								<CustomButton
									onClick={() => setShowBookDemo(true)}
									className={`${
										hasScrolled || isWhite
											? "bg-[#005893] text-white hover:text-[#72F4E8]"
											: "bg-white !text-[#005893] hover:border-[#72F4E8] hover:bg-[#72F4E8] hover:text-[#053969]"
									}  flex h-10 max-h-[40px] w-[105px] items-center justify-center py-2.5 text-xs !font-semibold  mlg:hidden`}
									disabled={false}
								>
									Book a Demo
								</CustomButton>
							</div>
						</ul>
					</div>
					<MobileNavbar
						isMobileShowing={isMobileShowing}
						setShow={setIsMobileShowing}
						hasScrolled={hasScrolled}
						rootElement={rootElement}
						setShowBookDemo={setShowBookDemo}
						setshowContactUsModal={setshowContactUsModal}
					/>
				</div>
			</nav>
			<BookADemo
				showBookADemo={showBookADemo}
				setShowBookDemo={setShowBookDemo}
			/>
			<ContactUsModal
				show={showContactUsModal}
				setShow={setshowContactUsModal}
			/>
		</>
	);
};

interface NavbarItemProps {
	title: string;
	link?: string;
	setShow?: Dispatch<SetStateAction<boolean>>;
	hasScrolled: boolean;
	onClick?: () => void;
	isWhite?: boolean;
}

const NavbarItem: React.FC<NavbarItemProps> = ({
	title,
	link,
	hasScrolled,
	onClick,
	isWhite,
}) => {
	const [shouldShowHover, setShowHover] = useState(false);

	return (
		<Link
			to={link || ""}
			onClick={() => {
				if (onClick) onClick();
			}}
			className="group cursor-pointer"
		>
			<li
				className={`relative font-medium duration-200 ease-in-out ${
					shouldShowHover && "text-red-900"
				} ${
					hasScrolled || isWhite
						? "text-[#5f6061] hover:text-[#3EC9BC]"
						: "text-[#BACCDB] hover:text-[#72F4E8]"
				}`}
			>
				{title}
				<hr
					className={`absolute left-1/2 top-full h-0.5 w-3 -translate-x-1/2 border-transparent duration-200 ease-in-out ${
						hasScrolled || isWhite
							? "group-hover:border-[#3EC9BC]"
							: "group-hover:border-[#72F4E8]"
					} `}
				/>
			</li>
		</Link>
	);
};

interface MobileNavbarItemProps {
	title: string;
	link?: string;
	setShow: Dispatch<SetStateAction<boolean>>;
	onClick?: () => void;
	isWhite?: boolean;
}

const MobileNavbarItem: React.FC<MobileNavbarItemProps> = ({
	title,
	link,
	setShow,
	onClick,
}) => {
	return (
		<Link
			to={link || ""}
			onClick={() => {
				if (onClick) onClick();
				setTimeout(() => {
					setShow(false);
				}, 800);
			}}
			className="cursor-pointer"
		>
			<li className="font-medium text-[#323539]">{title}</li>
		</Link>
	);
};

interface MobileNavbarProps {
	isMobileShowing: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
	hasScrolled: boolean;
	rootElement: HTMLElement | null;
	setShowBookDemo: Dispatch<SetStateAction<boolean>>;
	setshowContactUsModal: Dispatch<SetStateAction<boolean>>;
}

const MobileNavbar: React.FC<MobileNavbarProps> = ({
	isMobileShowing,
	hasScrolled,
	setShow,
	setShowBookDemo,
	setshowContactUsModal,
}) => {
	const navigate = useNavigate();
	return (
		<>
			<AnimatePresence>
				{isMobileShowing && (
					<motion.div
						initial={{ top: "-400px" }}
						animate={{ top: "100%" }}
						exit={{ top: "-400px" }}
						className="absolute w-full lg:hidden"
					>
						<ul
							className={`flex flex-col justify-center space-y-7 bg-white px-4 pb-10 pt-4`}
						>
							<MobileNavbarItem
								title={"Features"}
								link={"/#features"}
								setShow={setShow}
							/>
							<MobileNavbarItem
								title={"How it works"}
								link={"/#how-it-works"}
								setShow={setShow}
							/>
							{/* <MobileNavbarItem
								title={"Pricing"}
								link={"/#pricing"}
								hasScrolled={hasScrolled}
							/> */}
							<MobileNavbarItem
								title={"About Us"}
								link={"/about"}
								setShow={setShow}
							/>
							<MobileNavbarItem
								title={"Contact Us"}
								onClick={() => {
									setshowContactUsModal(true);
								}}
								setShow={setShow}
							/>
						</ul>
						<div className="flex items-center justify-between bg-[#F8F9FB] p-4">
							<CustomButton
								onClick={() => setShowBookDemo(true)}
								className={`${
									hasScrolled
										? "border-[#005893] bg-white text-[#053969] hover:border-[#3EC9BC] hover:text-[#3EC9BC]"
										: "bg-[#005893] text-white hover:border-[#72F4E8] hover:bg-[#72F4E8] hover:text-[#053969]"
								} px-3 py-2 text-sm font-medium duration-200 ease-in-out`}
								disabled={false}
							>
								Book a Demo
							</CustomButton>
							<Link
								to={"/sign-up"}
								className="cursor-pointer px-3 py-2 text-[15px] text-sm font-semibold text-[#323539]"
							>
								Sign up
							</Link>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
};

export default Navbar;

export const scrollWithOffset = (el: HTMLElement | null) => {
	if (!el) return;

	const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
	const yOffset =
		-window.innerHeight / 2 + el.getBoundingClientRect().height / 2;
	window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};
