import React, { Dispatch, SetStateAction, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

interface OverlayProps extends React.PropsWithChildren {
	show: boolean;
	setShow: Dispatch<SetStateAction<boolean>>;
	onClose?: () => void;
	modalRef: any;
	isPersistent?: boolean;
}

export const Overlay = ({
	children,
	show,
	setShow,
	onClose,
	modalRef,
	isPersistent,
	...props
}: OverlayProps) => {
	useEffect(() => {
		if (show) {
			document.body.style.overflow = "hidden";
		} else if (!show) {
			document.body.style.overflow = "visible";
		}

		if (!isPersistent) {
			const handleClickOutside = (event: MouseEvent) => {
				if (
					modalRef.current &&
					!modalRef.current.contains(event.target as Node)
				) {
					onClose && onClose();
					setShow(false);
				}
			};

			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}
	}, [show]);

	return (
		<AnimatePresence {...props}>
			{show && (
				<motion.section
					exit={{ opacity: 0 }}
					initial={{ opacity: 0 }}
					animate={{
						opacity: 1,
						transition: { duration: 0.4, ease: "easeInOut" },
					}}
					onClick={onClose}
					className="font-villeray fixed bottom-0 left-0 right-0 top-0 z-[6000] flex w-full items-center justify-center bg-[#000]/40 "
				>
					<motion.div
						exit={{ opacity: 0 }}
						initial={{ opacity: 0, y: 40, scale: 0.98 }}
						animate={{
							opacity: 1,
							y: 0,
							scale: 1,
							transition: { duration: 0.4, ease: "easeInOut" },
						}}
						// onClick={(e) => e.stopPropagation()}
						className={`scrollbar-hide z-[6000] mx-4 flex h-full max-h-[calc(100dvh-16px)] w-full cursor-default items-center justify-center overflow-visible overflow-y-scroll rounded-[10px]`}
						onPointerDown={(e) => e.stopPropagation()}
						onPointerDownCapture={(e) => e.stopPropagation()}
						onPointerUp={(e) => e.stopPropagation()}
						onPointerUpCapture={(e) => e.stopPropagation()}
					>
						{children}
					</motion.div>
				</motion.section>
			)}
		</AnimatePresence>
	);
};

export default Overlay;
