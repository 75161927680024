import { User } from "@src/types/NewUser";
import $http from "../xhr";
import { AnalyticsQuery } from "@src/store/useAnalyticsStore";
import { Analytics } from "@src/types/Analytics";
import {
	LocationArray,
	AddLocationData,
	AddBusinessLocationResponseType,
	LocationResponse,
	StationsArrayProps,
} from "@src/types/Location";
import { AddStationType } from "@src/types/Station";
import {
	ManageWaitlistType,
	QueueData,
	WaitlistGeneralSettings,
} from "@src/types/waitlist/waitlist";
import { AddPatientData } from "@src/components/Dashboard/waitlist/AddPatient/AddPatient";
import { serializeParamsToQueryString } from "@src/utils/functions";

//Auth Start

export const APIVersion1Register = async (data: unknown) =>
	$http.post(`/v1/auth/register`, data).then((res) => res.data);

export const APIVersion1Login = async (data: unknown): Promise<unknown> =>
	$http.post("/sign-in-with-google", data).then((res) => res.data);

export const APIVersion1GoogleLogin = async (data: {
	token: string;
}): Promise<{
	status: boolean;
	message: string;
	user: User;
	token: string;
}> => $http.post("/sign-in-with-google", data).then((res) => res.data);

//Auth End

// Analytics Queries Start

export const APIVersion1Analytics = async (
	business_id?: number,
	params?: AnalyticsQuery
): Promise<{
	status: boolean;
	data: Analytics;
}> =>
	$http
		.get(
			`/business-performance-monitor/summary/of-business/` + business_id,
			{
				params,
			}
		)
		.then((res) => res.data);

// Analytics Queries End

// Locations Queries Start

export const APIVersion1GetLocations = async (): Promise<{
	status: boolean;
	data: AddLocationData;
}> => $http.get("/v2/my-business/locations").then((res) => res.data);

export const APIVersion1GetSingleBusinessLocationDetail = async (
	id: number
): Promise<{
	status: boolean;
	data: LocationResponse;
}> => $http.get(`/my-business/locations/${id}/detail`).then((res) => res.data);

export const APIVersion1GetBusinessLocationStations = async (
	id: number
): Promise<{
	status: boolean;
	data: StationsArrayProps;
}> => $http.get(`/my-location/${id}/stations`).then((res) => res.data);

export const APIVersion1DeleteLocation = async (
	id: number | string
): Promise<{
	status: boolean;
	data: any;
}> =>
	$http.delete(`/my-business/locations/${id}/delete`).then((res) => res.data);

export const APIVersion1AddBusinessLocation = async (
	data: unknown
): Promise<AddBusinessLocationResponseType> =>
	$http.post("/v2/my-business/locations/add", data).then((res) => res.data);

export const APIVersion1AddBusinessWorkingHours = async (
	data: unknown
): Promise<AddBusinessLocationResponseType> =>
	$http.post("/v2/my-business/working-hours", data).then((res) => res.data);

export const APIVersion1UpdateBusinessLocation = async (
	id: number,
	data: unknown
): Promise<AddBusinessLocationResponseType> =>
	$http
		.post(`/my-business/locations/${id}/update`, data)
		.then((res) => res.data);

export const APIVersion1UpdateBusinessLocationStation = async (
	locationId: number,
	stationId: number,
	data: unknown
): Promise<AddBusinessLocationResponseType> =>
	$http
		.post(`/my-location/${locationId}/stations/${stationId}/update`, data)
		.then((res) => res.data);

export const APIVersion1AddBusinessLocationStation = async (
	locationId: number,
	data: AddStationType
): Promise<AddBusinessLocationResponseType> =>
	$http
		.post(`/my-location/${locationId}/stations/add`, data)
		.then((res) => res.data);

export const APIVersion1DeleteStation = async (
	locationId: number,
	stationId: number
): Promise<{
	status: boolean;
	data: any;
}> =>
	$http
		.delete(`/my-location/${locationId}/stations/${stationId}/delete`)
		.then((res) => res.data);
// Waitlist Queries Start

export const APIVersion1GetWaitlist = async (): Promise<{
	status: boolean;
	data: QueueData;
	message: string;
}> => $http.get("/my-queue/queue-order-v2/").then((res) => res.data);

export const APIVersion1AddPatient = async (
	data: AddPatientData,
	queueUrl: string
): Promise<Station> =>
	$http
		.post("/customers/waitlist/join-queue/" + queueUrl, data)
		.then((res) => res.data);

export const APIVersion1ManageWaitlist = async ({
	data,
	queryParams,
}: {
	data: ManageWaitlistType;
	queryParams: {
		business_id: number | string;
		selected_location_ids?: number[];
		selected_station_ids?: number[];
		sorted_by_priority?: boolean;
	};
}): Promise<Station> => {
	return $http
		.post("/change-waitlist-status", data, {
			params: queryParams,
			paramsSerializer: (params) => serializeParamsToQueryString(params),
		})
		.then((res) => res.data);
};

// Waitlist Queries End

// Business Settings

export const APIVersion1UpdateBusinessSettings = async (
	data: WaitlistGeneralSettings,
	business_id?: string | number
): Promise<Station> =>
	$http
		.post(
			"/update-business-level-settings-of-business/" +
				business_id +
				"?update_all_stations=1&update_all_locations=1",
			data
		)
		.then((res) => res.data);

// Business Settings End
