import { IoMdAddCircleOutline } from "react-icons/io";
import { FaTag } from "react-icons/fa6";
import { Button } from "../ui/button";
import { cn } from "../../utils/functions";
import Select from "react-select";

const PatientDetails = () => {
	return (
		<div className="w-full">
			<div className="space-y-4">
				<h1 className="text-xl font-[600] text-[#323539]">
					Linday Wilson
				</h1>
				<div className="flex items-center justify-between">
					<DetailsPlaceholder
						section={1}
						title="Email Address"
						content="courtney.henry@gmail.com"
					/>
					<div className="flex  gap-x-8">
						<DetailsPlaceholder
							section={1}
							title="Phone Number"
							content="+61480013910"
						/>
						<DetailsPlaceholder
							section={1}
							title="Date"
							content="26 Feb 2024"
						/>
					</div>
				</div>
				<div>
					<h4 className="text-[#858C95]">Tags</h4>
					<div className="flex items-center gap-2">
						<div className="text-red flex items-center gap-2 rounded-sm bg-[#F6E2E1] px-2 py-2 text-[10px]  text-[#C8322B]">
							<FaTag />
							<p>High Priority</p>
						</div>
						<Button
							className="gap-2 px-0 py-0 text-[14px]"
							variant="ghost"
						>
							<IoMdAddCircleOutline className="text-lg" />
							Add Tag
						</Button>
					</div>
				</div>
			</div>

			<div className="bprder-t-[#E5E5E7] flex justify-between border-b border-t border-t-[#E5E5E7] py-6">
				<DetailsPlaceholder
					section={2}
					title="Location"
					content="2464 Royal Ln. Mesa, New Jersey 45463"
				/>
				<DetailsPlaceholder
					section={2}
					title="Arrival Time"
					content="04:00 pm"
				/>
				<DetailsPlaceholder
					section={2}
					title="Total Wait Time"
					content="2 hr 30 mins"
				/>
			</div>

			<div className="space-y-4">
				<h1 className="text-xl font-[600] text-[#323539]">Activity</h1>
				<div className="flex items-center justify-between">
					<div className="w-full">
						<Select
							placeholder="Station"
							className="max-w-[272px]  "
							options={[]}
							// onChange={() => {
							// 	console.log("Station");
							// }}
						/>
					</div>
					<div className="flex w-full justify-end gap-x-8">
						<Select
							placeholder="Status"
							className="w-full max-w-[186px]"
							options={[
								{ value: "Pending", label: "Pending" },
								{ value: "Completed", label: "Completed" },
							]}
							// onChange={() => console.log("Status")}
						/>
						<Select
							placeholder="Sort by"
							className=" w-full max-w-[186px]"
							options={[]}
							// onChange={() => console.log("Sort by")}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PatientDetails;

const DetailsPlaceholder = ({
	title,
	content,
	section,
}: {
	title: string;
	content: string;
	section: 1 | 2;
}) => {
	return (
		<div className="space-y-2 font-[400]">
			<h4
				className={cn("text-[#858C95]", {
					"text-[16px]": section == 1,
					"text-[14px]": section == 2,
				})}
			>
				{title}
			</h4>
			<p className={"text-[#323539]"}>{content}</p>
		</div>
	);
};
