import {
	countryCodes,
	countryOptions,
	findCountry,
	findState,
	ProductTypeOptions,
	statesOptions,
} from "@utils/constants";

export const changeCountry = (country: string) => {
	if (country) {
		return statesOptions(country);
	} else {
		return [];
	}
};

export const updateCountryAndState = (
	setValue: any,
	setProvinceOptions: any,
	isFromAddress: boolean,
	stateValue?: string,
	countryValue?: string
) => {
	if (isFromAddress) {
		setValue("country", countryValue);
		setProvinceOptions(changeCountry(countryValue ?? ""));
		// console.log(12, stateValue);
		setValue("state", stateValue);
	} else {
		if (countryValue) {
			// console.log(11);
			setValue("country", countryValue);
			setProvinceOptions(changeCountry(countryValue));
		}
		if (stateValue) {
			setValue("state", stateValue);
			// console.log(22, stateValue);
		}
	}
};

export * from "./DeleteLocationModal";
export * from "./LocationInfoModal";
export * from "./AddStationModal";
export * from "./StationInfoModal";
