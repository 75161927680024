import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { InlineWidget } from "react-calendly";
import { AnimatePresence, motion } from "framer-motion";
import { IoClose } from "react-icons/io5";

const BookADemo: React.FC<{
	showBookADemo: boolean;
	setShowBookDemo: Dispatch<SetStateAction<boolean>>;
}> = ({ showBookADemo, setShowBookDemo }) => {
	const modalRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		if (showBookADemo) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "visible";
		}

		const handleClickOutside = (event: MouseEvent) => {
			if (
				modalRef.current &&
				!modalRef.current.contains(event.target as Node)
			) {
				setShowBookDemo(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [showBookADemo]);
	return (
		<AnimatePresence>
			{showBookADemo && (
				<motion.div
					exit={{ opacity: 0 }}
					initial={{ opacity: 0 }}
					animate={{
						opacity: 1,
						transition: { duration: 0.4, ease: "easeInOut" },
					}}
					className="fixed inset-0 z-50 flex w-full items-center bg-[rgba(0,0,0,0.4)] "
				>
					<motion.div
						exit={{ opacity: 0 }}
						initial={{ opacity: 0, y: 40, scale: 0.98 }}
						animate={{
							opacity: 1,
							y: 0,
							scale: 1,
							transition: { duration: 0.4, ease: "easeInOut" },
						}}
						className="mx-auto flex w-full flex-1 justify-center"
					>
						<div
							className="relative flex h-full w-full max-w-[1000px] flex-col items-center justify-center space-y-10"
							ref={modalRef}
						>
							<div className="w-full self-stretch">
								<InlineWidget url="https://calendly.com/migranium/30min?hide_landing_page_details=1&back=1&month=2024-01" />
							</div>
							<figure
								className="absolute right-[5%] top-0 cursor-pointer rounded-full p-2 duration-300 ease-in-out hover:bg-white hover:bg-opacity-40 mlg:hidden"
								onClick={() => setShowBookDemo(false)}
							>
								<IoClose className="fill-white" size={24} />
							</figure>
							<figure
								className="cursor-pointer rounded-full p-2 duration-300 ease-in-out hover:bg-white hover:bg-opacity-40 lg:hidden"
								onClick={() => setShowBookDemo(false)}
							>
								<IoClose className="fill-white" size={32} />
							</figure>
						</div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default BookADemo;
