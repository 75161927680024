import moment from "moment";
import { cn } from "../../utils/functions";
interface TimeSlotProps {
	selected: boolean;
	onClick: () => void;
	timeslot: {
		start_time: string;
		end_time: string;
		is_active: number;
	};
	className?: string;
}
const TimeSlot = ({
	timeslot,
	selected,
	className,
	onClick,
}: TimeSlotProps) => {
	return (
		<div
			className={cn(
				" w-[144px] cursor-pointer rounded-sm border-[0.5px] border-[#005893] p-1.5 text-[#005893]",
				className,
				{
					"bg-[#005893] text-white": selected,
				}
			)}
			onClick={onClick}
		>
			<p className="whitespace-nowrap font-hoves text-[14px] font-[400]">
				{moment(timeslot.start_time, "HH:mm:ss").format("hh:mm A")} -{" "}
				{moment(timeslot.end_time, "HH:mm:ss").format("hh:mm A")}
			</p>
		</div>
	);
};

export default TimeSlot;
