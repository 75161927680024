import { zodResolver } from "@hookform/resolvers/zod";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";
import {
	useGoogleRegisterUser,
	useRegisterUser,
} from "../../hooks/useAuthData";
import useUserStore from "../../store/useUserStore";
import useCustomToast from "../CustomToast";
import { CustomButton } from "../form/CustomButton";
import FormInput from "../form/FormInput";
import Loader from "../Loader/Loader";
import RequestIsLoading from "../RequestIsLoading";

const schema = z.object({
	name: z.string().min(4, { message: "Name must be at least 4 characters" }),
	password: z
		.string()
		.min(8, { message: "Password must be at least 8 characters" }),
	email: z.string().email({ message: "Invalid email address format" }),
});

type FormFields = z.infer<typeof schema>;

const SignUpCard: React.FC<{
	//
}> = () => {
	const {
		register,
		handleSubmit,
		setError,
		getValues,
		formState: { errors },
	} = useForm<FormFields>({
		resolver: zodResolver(schema),
	});
	const [confirmPassword, setConfirmPassword] = useState("");

	const registerUserMutaion = useRegisterUser(undefined, undefined, setError);
	const googleRegisterUserMutation = useGoogleRegisterUser();

	const reset = useUserStore((s) => s.reset);
	const customToast = useCustomToast();

	const onSubmit: SubmitHandler<FormFields> = async (data) => {
		try {
			registerUserMutaion.mutate(data);
		} catch (error) {
			setError("root", {
				message: "An error occured kindly try again later",
			});
		}
	};

	const responseMessage = (response: CredentialResponse) => {
		googleRegisterUserMutation.mutate({
			token: response.credential ?? "",
		});
	};

	const errorMessage = () => {
		customToast("Google sign up failed", {
			id: "google-signup",
			type: "error",
		});
	};

	useEffect(() => {
		reset();
	}, []);

	return (
		<form
			className="relative z-10 flex w-full max-w-[488px] flex-col space-y-4 rounded-[10px] bg-white pt-6 shadow-[0_20px_25px_-5px_rgba(16,24,40,0.1),_0_8px_10px_-6px_rgba(16,24,40,0.1)]"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="flex flex-col space-y-2 px-4 pb-6 md:px-8">
				<h3 className="text-[22px] font-semibold leading-[30px] tracking-[-0.22px] text-[#323539]">
					Create an account
				</h3>
				<Link
					to={"/sign-in"}
					className="font-normal tracking-[-1%] text-[#858C95]"
				>
					Already have an account?
					<span className="text-[#195388]"> Sign in</span>
				</Link>
			</div>
			<div className="flex flex-col space-y-6 px-4 md:px-8">
				<FormInput
					inputType={"text"}
					label="Full Name"
					minLength={5}
					error={errors.name?.message}
					register={{ ...register("name") }}
					required
				/>
				<FormInput
					inputType={"text"}
					label="Email Address"
					minLength={5}
					maxLength={40}
					error={errors.email?.message}
					register={{ ...register("email") }}
					required
				/>
				<div className="flex flex-col space-y-1.5">
					<FormInput
						inputType={"password"}
						label="Password"
						minLength={5}
						maxLength={40}
						register={{ ...register("password") }}
						error={errors.password?.message ? "" : null}
						required
					/>
					<p
						className={`text-sm tracking-[-0.1px] ${errors.password?.message ? "text-red-500" : "text-[#858C95]"}`}
					>
						Password should be minimum 8 characters
					</p>
				</div>
				<div className="flex flex-col space-y-1.5">
					<FormInput
						inputType={"password"}
						label="Confirm Password"
						minLength={5}
						maxLength={40}
						onChange={(e) => {
							setConfirmPassword(e.target.value);
						}}
						required
					/>
					{confirmPassword.length > 0 &&
						(!(confirmPassword === getValues("password")) ? (
							<p className="text-sm tracking-[-0.1px] text-red-600">
								Password does not match current password
							</p>
						) : (
							// <p className="text-sm tracking-[-0.1px] text-[#48AA75]">
							// 	Password matches
							// </p>
							<></>
						))}
				</div>
			</div>
			<div className="px-4 md:px-8">
				By creating an account, you agree to Migranium&apos;s{" "}
				<Link
					to="/terms"
					className="text-base tracking-[-1%] text-[#195388] underline"
				>
					Terms
				</Link>{" "}
				and{" "}
				<Link
					to="/privacy-policy"
					className="text-base tracking-[-1%] text-[#195388] underline"
				>
					Policies
				</Link>{" "}
			</div>
			<div className="flex items-center justify-between rounded-b-[10px] bg-[#FAFBFC] px-4 pb-6 pt-4 md:px-8 mmd:flex-col mmd:space-y-6">
				<CustomButton
					disabled={registerUserMutaion.isLoading}
					className={
						"h-[46px] w-full bg-[#195388] text-white hover:border-[#72F4E8] hover:bg-[#72F4E8] hover:text-[#053969] md:h-10 md:w-[104px] mmd:text-[15px]"
					}
					type="submit"
				>
					{registerUserMutaion.isLoading ? (
						<div className="">
							<Loader size={24} />
						</div>
					) : (
						"Sign up"
					)}
				</CustomButton>
				<div className="flex flex-col justify-end space-y-2 mmd:w-full mmd:flex-col mmd:space-y-2">
					{/* <p className="text-sm tracking-[-0.1px] text-[#858C95]">
						Or Sign up with
					</p> */}
					<div>
						<GoogleLogin
							onSuccess={responseMessage}
							onError={errorMessage}
						/>
					</div>
				</div>
			</div>
			<RequestIsLoading
				isWhite
				isLoading={googleRegisterUserMutation.isLoading}
			/>
		</form>
	);
};

export default SignUpCard;
