interface OpreatingTimeProps {}

const OpreatingTime: React.FC<OpreatingTimeProps> = () => {
	return (
		<div className="space-y-8">
			<h3 className="text-xl font-semibold">Add Operating Hours</h3>
		</div>
	);
};

export default OpreatingTime;
