import React, { useState } from "react";
import NavBar from "../../components/Navbar";
import ForgotPasswordCard from "../../components/ForgotPasswordCard";

const ForgotPassword: React.FC = () => {
	const [showBookADemo, setShowBookDemo] = useState(false);
	const [showContactUsModal, setshowContactUsModal] = useState(false);

	return (
		<main className="relative flex min-h-screen flex-col">
			<NavBar
				isWhite
				showBookADemo={showBookADemo}
				setShowBookDemo={setShowBookDemo}
				showContactUsModal={showContactUsModal}
				setshowContactUsModal={setshowContactUsModal}
			/>
			<div className="clock-background-image flex h-full w-full flex-1 items-center justify-center px-4">
				<div className="relative flex w-full max-w-[1216px] items-center justify-between gap-12 mmd:flex-col-reverse mmd:py-12">
					<ForgotPasswordCard />
					<div className="z-10 flex flex-col space-y-3 md:space-y-6">
						<h1 className="text-2xl font-bold text-[#323539] md:text-[40px] md:font-semibold md:leading-[32px] md:tracking-[-1.5%]">
							Forgot your password?
						</h1>
						<p className="tracking-[-1%] text-[#858C95] sm:text-lg">
							Reset your password to regain access to streamlined
							scheduling,
							<br className="msm:hidden" /> enhanced customer
							management, and advanced analytics.
						</p>
					</div>
				</div>
			</div>
		</main>
	);
};

export default ForgotPassword;
