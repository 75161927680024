import React from "react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Switch } from "@radix-ui/react-switch";
import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { MdOutlineDragIndicator } from "react-icons/md";
import CustomSwitchAlt from "@src/components/global/CustomSwitchAlt";
import { Input } from "@src/components/ui/input";
import { Slider } from "@src/components/ui/slider";
import FormInput from "@src/components/form/FormInput";

type Item = {
	id: string;
	title: string;
	checked: boolean;
	quantity: number;
	duration: string;
	isRange?: boolean;
};

const initialItems: Item[] = [
	{
		id: "1",
		title: "Waitlist",
		checked: false,
		quantity: 10,
		duration: "Weeks away",
		isRange: false,
	},
	{
		id: "2",
		title: "High Priority",
		checked: false,
		quantity: 10,
		duration: "Weeks away",
		isRange: false,
	},
	{
		id: "3",
		title: "Upcoming Appointments",
		checked: true,
		quantity: 4,
		duration: "Weeks away",
		isRange: true,
	},
];

const SortableItem = ({ id, item }: { id: string; item: Item }) => {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			{...attributes}
			className="mb-2 flex overflow-hidden rounded-lg bg-gray-100"
		>
			<div
				{...listeners}
				className="flex h-auto cursor-grab items-center px-3 hover:bg-[#000]/5"
			>
				<MdOutlineDragIndicator className="text-gray-400" size={"24"} />
			</div>
			<div className="flex flex-1 items-center justify-between space-x-4 p-2">
				<CustomCheckbox2
					isChecked={item.checked}
					handleCheckboxChange={() => {}}
					className="checked:border-[#195388] checked:bg-[#195388]"
					id={item.id}
				/>
				<p className="w-[185px] text-sm">{item.title}</p>
				{item?.isRange && (
					<Input
						className="h-10 w-[60px] text-center"
						maxLength={40}
					/>
				)}

				<div className="flex-1">
					<Slider defaultValue={[33]} max={100} step={1} />
				</div>
				<div>
					<Input
						className="h-10 w-[80px] text-center"
						maxLength={40}
					/>
				</div>
				{item?.isRange && <Input className="w-[100px] text-center" />}
			</div>
		</div>
	);
};

const ScheduleOptimizer = () => {
	const [items, setItems] = React.useState(initialItems);

	const handleDragEnd = (event: any) => {
		const { active, over } = event;

		if (active.id !== over.id) {
			setItems((items) => {
				const oldIndex = items.findIndex(
					(item) => item.id === active.id
				);
				const newIndex = items.findIndex((item) => item.id === over.id);
				return arrayMove(items, oldIndex, newIndex);
			});
		}
	};

	return (
		<div>
			<div className="flex justify-between">
				<div className="">
					<h3 className="text-xl font-semibold">
						Schedule Optimizer
					</h3>
					<p className="text-sm text-gray-400">
						We email/sms your patients who are in line in the
						following categories.
					</p>
				</div>
				<CustomSwitchAlt />
			</div>

			<div className="py-4">
				<h2 className="text-lg">
					Select categories you want notified in order.
				</h2>
				<p className="mb-4">
					You may also select the quantity of people who will be
					notified.
				</p>
				<DndContext
					collisionDetection={closestCenter}
					onDragEnd={handleDragEnd}
				>
					<SortableContext
						items={items}
						strategy={verticalListSortingStrategy}
					>
						{items.map((item) => (
							<SortableItem
								key={item.id}
								id={item.id}
								item={item}
							/>
						))}
					</SortableContext>
				</DndContext>
			</div>
		</div>
	);
};

export default ScheduleOptimizer;
