import React, { useState } from "react";
import EveryOtherTimeOscarIntegration from "./EveryOtherTime/EveryOtherTimeOscarIntegration";
import FirstTimeOscarIntegration from "./FirstTime/FirstTimeOscarIntegration";

const OscarIntegration: React.FC = () => {
	const [firstTime] = useState(true);

	if (firstTime) return <FirstTimeOscarIntegration />;
	if (!firstTime) return <EveryOtherTimeOscarIntegration />;
};

export default OscarIntegration;
