import CustomSelect from "@src/components/common/CustomSelect";
import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import { Button } from "@src/components/ui/button";
import { Card } from "@src/components/ui/card";
import { Input } from "@src/components/ui/input";
import { Select } from "@src/components/ui/select";
import IntakeFieldList from "./IntakeFieldList";

interface IntakeFieldsProps {}

const IntakeFields: React.FC<IntakeFieldsProps> = () => {
	return (
		<div className="space-y-4 p-5">
			<Card className="space-y-2 p-4">
				<div className="flex items-center justify-between">
					<p className="font-medium">
						Add from Existing Custom fields
					</p>
					<p className=" text-primary">Add new custom fields</p>
				</div>
				<CustomSelect placeholder="Select all the applies" />
			</Card>

			<div className="flex items-center justify-between">
				<div className="flex items-center space-x-2">
					<CustomCheckbox2
						isChecked
						handleCheckboxChange={() => {}}
						className="checked:border-[#195388] checked:bg-[#195388]"
						id={""}
					/>
					<label className="font-normal text-gray-400">
						Select all
					</label>
				</div>
				<Button variant="secondary">
					<i className="mgc_delete_2_line text-[16px] text-primary"></i>
				</Button>
			</div>

			<div className="w-full space-y-2">
				{/* Table Header */}
				<div className="flex justify-between rounded-t-lg border-b p-2 text-sm">
					<div className="flex w-[40%] items-center font-semibold">
						<CustomCheckbox2
							isChecked
							handleCheckboxChange={() => {}}
							className="checked:border-[#195388] checked:bg-[#195388]"
							id={""}
						/>
						<p className="ml-2">Field name</p>
					</div>
					<div className="w-[10%] font-semibold">Type</div>
					<div className="w-[10%] font-semibold">For Station</div>
					<div className="w-[15%] font-semibold">List</div>
					<div className="w-[25%] font-semibold">Dependency</div>
				</div>
				<IntakeFieldList />
				<IntakeFieldList />
				<IntakeFieldList />
			</div>
		</div>
	);
};

export default IntakeFields;
