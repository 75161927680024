import CustomSelect from "@src/components/common/CustomSelect";
import CustomCheckbox2 from "@src/components/form/CustomCheckbox2";
import FormInput from "@src/components/form/FormInput";
import CustomSwitchAlt from "@src/components/global/CustomSwitchAlt";
import { Input } from "@src/components/ui/input";
import { Slider } from "@src/components/ui/slider";
import { Switch } from "@src/components/ui/switch";
import Select from "react-select";
// import NotificationSettings from "./ScheduleOptimizer";

interface GeneralProps {}

const General: React.FC<GeneralProps> = () => {
	return (
		<div className="space-y-8">
			<h3 className="text-xl font-semibold">General Schedule</h3>
			<div className="space-y-5">
				<div className="flex items-center justify-between">
					<h3>This station will not use scheduled appointments</h3>
					<CustomCheckbox2
						isChecked
						handleCheckboxChange={() => {}}
						className="checked:border-[#195388] checked:bg-[#195388]"
						id={""}
						textAfter="No Appointments"
					/>
				</div>

				<div className=" space-y-5">
					<div className="flex items-center border-b pb-5">
						<div className="flex-1">
							<p>Schedule Visibility</p>
							<p className="text-sm text-gray-400">
								This enables people to view the availability of
								this station and book appointments
							</p>
						</div>
						<CustomSwitchAlt />
					</div>

					<div className="flex items-center border-b pb-5">
						<div className="flex-1">
							<p>Auto Approve</p>
							<p className="text-sm text-gray-400">
								If there is no conflict, appointments are
								automatically approved
							</p>
						</div>
						<CustomSwitchAlt />
					</div>

					<div className="border-b pb-5">
						<div className="mb-8 flex items-center">
							<div className="flex-1">
								<p>Schedule Block</p>
								<p className="text-sm text-gray-400">
									This allows people to book appointments up
									until the time that is selected below
								</p>
							</div>
							<CustomSwitchAlt />
						</div>
						<div>
							<Slider defaultValue={[33]} max={100} step={1} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default General;
