import { LuRotateCcw, LuSettings, LuTrash2 } from "react-icons/lu";
import { Button } from "../../ui/button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Dispatch, SetStateAction } from "react";
import { Input } from "../../ui/input";
import { PiMagnifyingGlass } from "react-icons/pi";
import { EventItem } from "../../../services/data";
import Select from "react-select";
import { stations } from "../../../services/mockdata/dummyEventData";
import CustomSelect from "../../common/CustomSelect";

interface SchedulerHeaderProps {
	events: EventItem[];
	setEvents: Dispatch<SetStateAction<EventItem[]>>;
	selectedStations: { value: string; label: string }[];
	setSelectedStations: Dispatch<
		SetStateAction<{ value: string; label: string }[]>
	>;
	setShowAppointmentModal: Dispatch<SetStateAction<boolean>>;
	setShowSettingsModal: Dispatch<SetStateAction<boolean>>;
}

const ScheduleHeader = ({
	setShowAppointmentModal,
	setShowSettingsModal,
	selectedStations,
	setSelectedStations,
}: SchedulerHeaderProps) => {
	return (
		<div className="flex items-center justify-between gap-x-4 text-sm">
			<div className="flex items-center gap-3">
				<Select
					className="h-[36px] w-[140px]"
					placeholder="Locations"
					options={[]}
					onChange={() => {}}
				/>
				<CustomSelect
					className="h-[36px]  w-[160px] focus:outline-none "
					placeholder="Stations"
					isMulti
					closeMenuOnSelect={false}
					options={stations.map((station) => ({
						value: station,
						label: station,
					}))}
					value={selectedStations}
					onChange={(selectedOption) => {
						setSelectedStations(selectedOption);
					}}
				/>
			</div>
			<div className="flex items-center gap-3">
				<div className="relative">
					<Input
						className="h-[36px] w-[250px] pl-6  focus:outline-none"
						placeholder="Search by Name"
					/>
					<span className=" absolute left-1 top-[25%] text-lg">
						<PiMagnifyingGlass width={"14px"} height={"14px"} />
					</span>
				</div>
				<Button
					className="flex h-[36px] items-center gap-x-2 text-[14px] font-[600]"
					variant="default"
					onClick={() => setShowAppointmentModal(true)}
				>
					<span className="pr-1 text-lg">
						<IoMdAddCircleOutline color={"#fff"} />
					</span>
					<p>Add Appointment</p>
				</Button>

				<Button
					variant="outline"
					size="icon-sm"
					onClick={() => location.reload()}
				>
					<LuRotateCcw size={18} />
				</Button>

				<Button
					variant="outline"
					size="icon-sm"
					onClick={() => setShowSettingsModal(true)}
				>
					<LuSettings size={18} />
				</Button>
				<Button variant="outline" size="icon-sm">
					<LuTrash2 size={18} className=" text-red-500" />
				</Button>
			</div>
		</div>
	);
};

export default ScheduleHeader;
