import { useEffect, useRef, useState } from "react";

export const useElementWidth = () => {
	const elementRef = useRef<HTMLDivElement>(null);

	const [elementWidth, setElementWidth] = useState<number>(() => {
		// Initialize with the current width if available, or 0
		return elementRef.current?.offsetWidth || 0;
	});

	useEffect(() => {
		const updateWidth = () => {
			if (elementRef.current) {
				setElementWidth(elementRef.current.offsetWidth);
			}
		};

		// Call updateWidth immediately to set the initial width
		updateWidth();

		const handleResize = () => {
			updateWidth();
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return { elementRef, elementWidth };
};
