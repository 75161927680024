import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@src/components/ui/input";
import { Label } from "@src/components/ui/label";
import React, { Dispatch, SetStateAction } from "react";
import { Button } from "@src/components/ui/button";
import { useForm } from "react-hook-form";
import { z } from "zod";

const StepThree: React.FC<{
	step?: number;
	isErrorMessageOpen: boolean;
	errorMessage: string;
	setErrorMessage: Dispatch<SetStateAction<string>>;
	setIsErrorMessageOpen: Dispatch<SetStateAction<boolean>>;
	setStep: Dispatch<SetStateAction<number>>;
}> = () => {
	// const customToast = useCustomToast();

	const schema = z.object({
		client_id: z.string(),
		client_secret: z.string(),
		emr_base_url: z.string(),
	});

	const {
		register,
		// handleSubmit,
		// setError,
		// formState: { errors },
	} = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),
	});

	return (
		<div className="m-1.5 flex flex-1 flex-col space-y-3">
			<h2 className="text-[22px] font-semibold leading-[30px] text-[#09090B]">
				Step 4
			</h2>
			<div className="mt-[29px] flex w-full max-w-[805px] flex-col space-y-8 rounded-xl border border-[#E4E4E7] bg-white p-6 shadow-[0_10px_15px_-3px_rgba(0,0,0,0.1)]">
				<div className="flex flex-col space-y-4">
					<div>
						<h3 className="text-[18px] font-semibold leading-[30px] text-[#09090B]">
							Add links and IDs to connect the system
						</h3>
						<div className="text-[14px] leading-[20px] text-[#71717A]">
							<p>
								Once added, under the &apos;Manage Clients&apos;
								section of your EMR system (screenshot attached
								below), you&apos;ll find Client Keys, Client
								Secret, and a URI.
							</p>
							<br />
							<p>
								Copy the information for Migranium from each
								into the input fields below to merge both
								systems.
							</p>
						</div>
					</div>
					<img
						src="/assets/images/integration/oscar/step-4.png"
						className="shadow-[0_0_10px_0_rgba(0,0,0,0.25)]"
						alt="Sub-step-3"
					/>
				</div>
				<div className="flex flex-col space-y-4">
					<div className="space-y-1">
						<h3 className="text-[18px] font-semibold text-[#09090B]">
							Fill in the information to complete adding a new
							client
						</h3>
						<p className="text-[14px] leading-[20px] text-[#71717A]">
							Copy the information from below and paste them on
							the{" "}
							<span className="font-semibold text-[#09090B]">
								&apos;Add New&apos;
							</span>{" "}
							pop up.
						</p>
					</div>
					<form className="flex flex-col">
						<div className="flex flex-col space-y-4">
							<div className="space-y-1.5">
								<Label>Client ID / Key</Label>
								<Input
									className="h-9 shadow-[0px_0px_2px_0.5px_rgba(16,24,40,0.05)]"
									{...register("client_id")}
								/>
							</div>
							<div className="space-y-1.5">
								<Label>Client Secret</Label>
								<Input
									className="h-9 shadow-[0px_0px_2px_0.5px_rgba(16,24,40,0.05)]"
									{...register("client_secret")}
								/>
							</div>
							<div className="space-y-1.5">
								<Label>EMR Base URL</Label>
								<Input
									className="h-9 shadow-[0px_0px_2px_0.5px_rgba(16,24,40,0.05)]"
									{...register("emr_base_url")}
								/>
							</div>
						</div>
						<Button
							className="mt-4 h-9 self-end text-white"
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							Add
						</Button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default StepThree;
