import React, { useState, useRef, useEffect } from "react";
import { Check, ChevronDown } from "lucide-react";
import { Search } from "lucide-react";
// import { cn } from "@utils/functions";
import useDebounce from "@hooks/useDebounce";

interface Option {
	value: string;
	label: string;
}

interface SearchProps {
	options: Option[];
	placeholder?: string;
	emptyMessage?: string;
	className?: string;
	onSelect?: (value: string) => void;
}

const CustomSearch: React.FC<SearchProps> = ({
	options = [],
	placeholder = "Search...",
	emptyMessage = "No results found.",
	className,
	onSelect,
}) => {
	const [query, setQuery] = useState("");
	const [open, setOpen] = useState(false);
	const [selectedValue, setSelectedValue] = useState("");
	const debouncedQuery = useDebounce(query, 300);
	const searchRef = useRef<HTMLDivElement>(null);

	const filteredOptions =
		debouncedQuery === ""
			? options
			: options.filter((option) =>
					option.label
						.toLowerCase()
						.includes(debouncedQuery.toLowerCase())
				);

	const handleSelect = (value: string, label: string) => {
		setSelectedValue(value);
		setQuery(label);
		setOpen(false);
		if (onSelect) {
			onSelect(value);
		}
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				searchRef.current &&
				!searchRef.current.contains(event.target as Node)
			) {
				setOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div ref={searchRef} className={cn("relative w-full", className)}>
			<div
				className="flex items-center rounded-md border border-gray-300 px-3 py-2 focus-within:ring-2 focus-within:ring-blue-500"
				role="combobox"
				aria-expanded={open}
				aria-haspopup="listbox"
				aria-controls="search-results"
			>
				<Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
				<input
					type="text"
					placeholder={placeholder}
					value={query}
					onChange={(e) => {
						setQuery(e.target.value);
						setOpen(true);
					}}
					onFocus={() => setOpen(true)}
					className="w-full outline-none"
					aria-autocomplete="list"
				/>
			</div>
			{open && (
				<ul
					id="search-results"
					role="listbox"
					className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5"
				>
					{filteredOptions.length === 0 ? (
						<li className="px-4 py-2 text-gray-500">
							{emptyMessage}
						</li>
					) : (
						filteredOptions.map((option) => (
							<li
								key={option.value}
								onClick={() =>
									handleSelect(option.value, option.label)
								}
								className={cn(
									"cursor-pointer px-4 py-2 hover:bg-blue-100",
									selectedValue === option.value &&
										"bg-blue-100"
								)}
								role="option"
								aria-selected={selectedValue === option.value}
							>
								{option.label}
							</li>
						))
					)}
				</ul>
			)}
		</div>
	);
};

export default CustomSearch;

import { Button } from "@components/ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@components/ui/popover";
// import {
// 	Accordion,
// 	AccordionContent,
// 	AccordionItem,
// 	AccordionTrigger,
// } from "@components/ui/accordion";

interface Option {
	value: string;
	label: string;
}

interface SearchProps {
	options: Option[];
	placeholder?: string;
	emptyMessage?: string;
	className?: string;
	onSelect?: (value: string) => void;
}

export function AccordionComboboxSearch({
	options = [],
	placeholder = "Search...",
	emptyMessage = "No results found.",
	// className,
	onSelect,
}: SearchProps) {
	const [open, setOpen] = React.useState(false);
	const [value, setValue] = React.useState("");

	// Ensure options is always an array
	const safeOptions = Array.isArray(options) ? options : [];
	console.log("🚀 ~ options:", options);
	console.log("🚀 ~ safeOptions:", safeOptions);

	return (
		<div className="relative w-full">
			<Popover
				open={open}
				onOpenChange={(open) => {
					console.log("🚀 ~ Popover open state changed:", open);
					setOpen(open);
				}}
			>
				<PopoverTrigger className="w-full" type="button">
					<Button
						type="button"
						variant="outline"
						role="combobox"
						aria-expanded={open}
						className="w-full justify-between"
					>
						{value
							? safeOptions.find(
									(option) => option.value === value
								)?.label
							: "Member"}
						<ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
					</Button>
				</PopoverTrigger>
				<PopoverContent className=" p-0 [&_[data-radix-popper-content-wrapper]]:relative">
					<Command className="max-h-[200px]">
						<CommandInput placeholder={placeholder} />
						<CommandEmpty>{emptyMessage}</CommandEmpty>
						<CommandList>
							<CommandGroup>
								{safeOptions?.map((option) => (
									<CommandItem
										key={option.value}
										onSelect={() => {
											setValue(
												option.value === value
													? ""
													: option.value
											);
											setOpen(false);
											if (onSelect) {
												onSelect(option.value);
											}
										}}
									>
										<div className="flex w-full items-center justify-between">
											{option?.label}
											<Check
												className={cn(
													"ml-2 h-4 w-4",
													value === option?.value
														? "opacity-100"
														: "opacity-0"
												)}
											/>
										</div>
									</CommandItem>
								)) || []}
							</CommandGroup>
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
		</div>
	);
}

import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}
