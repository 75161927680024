import { Slider } from "../../../ui/slider";
import { Switch } from "../../../ui/switch";

const General = () => {
	return (
		<div className="flex flex-col">
			<div>
				<h1 className="text-xl font-medium text-[#323539]">General</h1>
				<p className="text-[14px] text-[#858C95]">
					Location&apos;s Schedule Settings
				</p>
			</div>
			<div className="space-y-4 py-4">
				<div className="flex items-center gap-4">
					<span className="flex items-center gap-1 font-bold">
						{/* Add No later  */}
						<Switch
							className=" scale-[0.70]"
							//   checked={field.value}
							//   onCheckedChange={field.onChange}
							//   disabled

							aria-readonly
						/>
						YES
					</span>
					<p className="">Schedule Visibility</p>
					<p className="text-[14px] font-normal text-[#858C95]">
						Allow patients to schedule appointments for this station
					</p>
				</div>
				<div className="flex items-center gap-2">
					<span className="flex items-center gap-1 font-bold">
						{/* Add off later  */}
						<Switch
							className="scale-[0.70] data-[state=checked]:bg-[#2EBF43]"
							//   checked={field.value}
							//   onCheckedChange={field.onChange}
							//   disabled
							aria-readonly
						/>
						ON
					</span>
					<p className="">Auto Approve</p>
					<p className="text-[14px] font-normal text-[#858C95]">
						Approve appointments automatically
					</p>
				</div>
			</div>
			<div className="border-t py-2">
				<div className="pt-2">
					<h1 className="font-medium">Schedule Optimizer</h1>
					<p className="text-[14px] text-[#858C95]">
						Turn on to blast email patients about sudden openings on
						the schedule.{" "}
					</p>
				</div>
				<div className="flex items-center gap-8 pt-6">
					<span className="flex items-center gap-2 font-bold">
						{/* Add off later  */}
						<Switch
							className="scale-[0.70]"
							//   checked={field.value}
							//   onCheckedChange={field.onChange}
							//   disabled
							aria-readonly
						/>
						ON
					</span>
					<p className=" whitespace-nowrap">Email Appointments</p>
					<Slider
						defaultValue={[5]}
						max={10}
						step={1}
						className="h-[4px] w-[140px]"
					/>
				</div>
			</div>
		</div>
	);
};

export default General;
