import React, { useRef } from "react";
import { LuX } from "react-icons/lu";
import { useNavigate } from "react-router";
import { ModalProps } from "../../types";
import Overlay from "../Overlay";
import { Button } from "../ui/button";

const DefaultOperatingHoursModal: React.FC<ModalProps> = ({
	show,
	setShow,
}) => {
	const modalRef = useRef(null);
	const navigate = useNavigate();

	return (
		<Overlay show={show} setShow={setShow} modalRef={modalRef} isPersistent>
			<div
				className="relative z-50 w-full max-w-[397px] rounded-[10px] border border-[#E5E5E7] bg-white p-6 text-main-1"
				ref={modalRef}
			>
				<div className="flex flex-col justify-between space-y-8">
					<div className="flex justify-between">
						<h1 className="text-left text-[22px] font-semibold leading-[30px] -tracking-[1%] text-main-1">
							Are you sure you want to exit?
						</h1>
						<LuX
							color="#858C95"
							size={20}
							className="cursor-pointer self-center"
							onClick={() => {
								setShow(false);
							}}
						/>
					</div>
					<p className="leading-[20px] -tracking-[0.1px] text-[#323539]">
						Once you exit, your onboarding information might be
						lost. Continue to next steps to save your progress.
					</p>

					<div className="mt-6 flex space-x-1 self-end">
						<Button
							variant="ghost"
							className="h-[46px] w-[130px]"
							onClick={(e) => {
								e.preventDefault();
								navigate("/sign-in");
							}}
						>
							Yes
						</Button>
						<Button
							className="h-[46px] w-[130px]"
							onClick={(e) => {
								e.preventDefault();
								setShow(false);
							}}
						>
							Cancel
						</Button>
					</div>
				</div>
			</div>
		</Overlay>
	);
};

export default DefaultOperatingHoursModal;
