export const Appointment_Time = [
	{
		start_time: "09:00:00",
		end_time: "09:15:00",
		is_active: 1,
	},
	{
		start_time: "09:15:00",
		end_time: "09:30:00",
		is_active: 1,
	},
	{
		start_time: "09:30:00",
		end_time: "09:45:00",
		is_active: 1,
	},
	{
		start_time: "09:45:00",
		end_time: "10:00:00",
		is_active: 1,
	},
	{
		start_time: "10:00:00",
		end_time: "10:15:00",
		is_active: 1,
	},
	{
		start_time: "10:15:00",
		end_time: "10:30:00",
		is_active: 1,
	},
	{
		start_time: "10:30:00",
		end_time: "10:45:00",
		is_active: 1,
	},
	{
		start_time: "10:45:00",
		end_time: "11:00:00",
		is_active: 1,
	},
	{
		start_time: "11:00:00",
		end_time: "11:15:00",
		is_active: 1,
	},
	{
		start_time: "11:15:00",
		end_time: "11:30:00",
		is_active: 1,
	},
	{
		start_time: "11:30:00",
		end_time: "11:45:00",
		is_active: 1,
	},
	{
		start_time: "11:45:00",
		end_time: "12:00:00",
		is_active: 1,
	},
	{
		start_time: "12:00:00",
		end_time: "12:15:00",
		is_active: 1,
	},
	{
		start_time: "12:15:00",
		end_time: "12:30:00",
		is_active: 1,
	},
	{
		start_time: "12:30:00",
		end_time: "12:45:00",
		is_active: 1,
	},
	{
		start_time: "12:45:00",
		end_time: "13:00:00",
		is_active: 1,
	},
	{
		start_time: "13:00:00",
		end_time: "13:15:00",
		is_active: 1,
	},
	{
		start_time: "13:15:00",
		end_time: "13:30:00",
		is_active: 1,
	},
	{
		start_time: "13:30:00",
		end_time: "13:45:00",
		is_active: 1,
	},
	{
		start_time: "13:45:00",
		end_time: "14:00:00",
		is_active: 1,
	},
	{
		start_time: "14:00:00",
		end_time: "14:15:00",
		is_active: 1,
	},
	{
		start_time: "14:15:00",
		end_time: "14:30:00",
		is_active: 1,
	},
	{
		start_time: "14:30:00",
		end_time: "14:45:00",
		is_active: 1,
	},
	{
		start_time: "14:45:00",
		end_time: "15:00:00",
		is_active: 1,
	},
	{
		start_time: "15:00:00",
		end_time: "15:15:00",
		is_active: 1,
	},
	{
		start_time: "15:15:00",
		end_time: "15:30:00",
		is_active: 1,
	},
	{
		start_time: "15:30:00",
		end_time: "15:45:00",
		is_active: 1,
	},
	{
		start_time: "15:45:00",
		end_time: "16:00:00",
		is_active: 1,
	},
];
