import React from "react";
import { PatientStatus, StatusCardProps } from "../../../types/wait-list";

const StatusCard: React.FC<StatusCardProps> = ({
	status,
	children,
	statusText,
}) => {
	let title: PatientStatus = "" as PatientStatus;
	if (status === PatientStatus.Pending) {
		title = PatientStatus.Pending;
	}
	if (status === PatientStatus.Upcoming) {
		title = PatientStatus.Upcoming;
	}
	if (status === PatientStatus.NextInLine) {
		title = PatientStatus.NextInLine;
	}
	if (status === PatientStatus.Serving) {
		title = PatientStatus.Serving;
	}
	if (status === PatientStatus.Schedule) {
		title = PatientStatus.Schedule;
	}
	return (
		<div
			className={`
        ${
			(title === PatientStatus.Pending && "bg-status-1") ||
			(title === PatientStatus.Upcoming && "bg-status-2") ||
			(title === PatientStatus.NextInLine && "bg-status-3") ||
			(title === PatientStatus.Serving && "bg-status-4") ||
			(title === PatientStatus.Schedule && "bg-[#822D7F]")
		} 
    flex items-center justify-between rounded-t-lg px-3 py-3 text-white`}
		>
			<div>
				<p className="text-xl font-medium leading-7 tracking-[-1%]">
					{title}
				</p>
				<p className="text-sm font-normal leading-4">{statusText}</p>
			</div>
			<div className="flex items-center gap-x-1">{children}</div>
		</div>
	);
};

export default StatusCard;
