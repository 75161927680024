import {
	closestCorners,
	DndContext,
	DragOverlay,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import { TooltipProvider } from "@src/components/ui/tooltip";
import useWaitListStore from "@src/store/useWaitListStore";
import { PatientStatus } from "@src/types/wait-list";
import { QueueEntry } from "@src/types/waitlist/waitlist";
import React, { useState } from "react";
import WaitlistColumnCard from "./WaitlistColumnCard";
import WaitlistColumn from "@src/components/Dashboard/waitlist/WaitlistColumn";

const WaitlistColumnContainer: React.FC<{ statuses: PatientStatus[] }> = ({
	statuses,
}) => {
	const patientsObject = useWaitListStore((s) => s.patientsObject);
	const setPatientsObject = useWaitListStore((s) => s.setPatientsObject);

	const [activeId, setActiveId] = useState();
	const [selectedPatient, setSelectedPatient] = useState<
		QueueEntry | undefined
	>();

	// Find the column that contains a specific item
	const findColumn = (unique) => {
		if (!unique) return null;
		for (const column in patientsObject) {
			if (patientsObject[column].some((card) => card.id === unique)) {
				return column;
			}
		}
		return null;
	};

	// Handle the start of a drag action
	const onDragStart = (event) => {
		const { active } = event;

		const activeId = active.id;

		// Find the selected patient from patientsObject
		const selectedPatient = Object.keys(patientsObject).reduce(
			(acc, key) => {
				const patient = patientsObject[key].find(
					(item) => item.id === activeId
				);
				return patient ? patient : acc;
			},
			undefined
		);

		// Set the selected patient and active ID
		setSelectedPatient(selectedPatient);
		setActiveId(activeId);
	};

	// Handle the drag over action
	const onDragOver = (event) => {
		const { active, over, delta } = event;
		const activeId = active.id;
		const overId = over ? over.id : null;
		const activeColumn = findColumn(activeId);
		const overColumn = findColumn(overId);

		// If activeColumn or overColumn is not found, or they are the same, do nothing
		if (
			!activeColumn ||
			!overColumn ||
			patientsObject[activeColumn] === patientsObject[overColumn]
		) {
			return;
		}

		const activeItems = patientsObject[activeColumn];
		const overItems = patientsObject[overColumn];

		const activeIndex = activeItems.findIndex((i) => i.id === activeId);
		const overIndex = overItems.findIndex((i) => i.id === overId);

		const newIndex = () => {
			const putOnBelowLastItem =
				overIndex === overItems.length - 1 && delta.y > 0;
			const modifier = putOnBelowLastItem ? 1 : 0;
			return overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
		};

		// Update the state with the new order
		setPatientsObject({
			...patientsObject,
			[activeColumn]: activeItems.filter((i) => i.id !== activeId),
			[overColumn]: [
				...overItems.slice(0, newIndex()),
				activeItems[activeIndex],
				...overItems.slice(newIndex()),
			],
		});
	};

	// Handle the end of a drag action
	const onDragEnd = (event) => {
		const { active, over } = event;
		if (!over) return;

		const activeId = active.id;
		const overId = over.id;

		// Find the columns the items belong to
		const activeColumn = Object.keys(patientsObject).find((column) =>
			patientsObject[column].some((item) => item.id === activeId)
		);

		const overColumn = Object.keys(patientsObject).find(
			(column) =>
				patientsObject[column].some((item) => item.id === overId) ||
				column === overId
		);

		// If activeColumn or overColumn is not found, do nothing
		if (!activeColumn || !overColumn) return;

		// If the item was moved to a different column
		if (activeColumn !== overColumn) {
			const activeItems = patientsObject[activeColumn];
			const overItems = patientsObject[overColumn];
			const activeItem = activeItems.find((item) => item.id === activeId);

			// Update the state with the new order
			setPatientsObject({
				...patientsObject,
				[activeColumn]: activeItems.filter(
					(item) => item.id !== activeId
				),
				[overColumn]: [...overItems, activeItem],
			});
		} else {
			// If the item was moved within the same column
			const columnItems = patientsObject[activeColumn];
			const oldIndex = columnItems.findIndex(
				(item) => item.id === activeId
			);
			const newIndex = columnItems.findIndex(
				(item) => item.id === overId
			);

			// Update the state with the new order
			setPatientsObject({
				...patientsObject,
				[activeColumn]: arrayMove(columnItems, oldIndex, newIndex),
			});
		}
		setActiveId(undefined);
	};

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	return (
		<div className={`mb-2 mt-4 flex flex-1 gap-4 overflow-x-scroll`}>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCorners}
				onDragEnd={onDragEnd}
				onDragOver={onDragOver}
				onDragStart={onDragStart}
				// onDragMove={onDragMove}
			>
				{statuses.map((status, index) => (
					<WaitlistColumn
						key={index}
						status={status as PatientStatus}
						statuses={statuses}
						activeId={activeId}
						setActiveId={setActiveId}
					/>
				))}
				<DragOverlay>
					<TooltipProvider>
						{activeId && selectedPatient ? (
							<WaitlistColumnCard
								noOfPatients={0}
								status={
									findColumn(
										selectedPatient.id
									) as PatientStatus
								}
								index={0}
								patient={selectedPatient}
							/>
						) : null}
					</TooltipProvider>
				</DragOverlay>
			</DndContext>
		</div>
	);
};

export default WaitlistColumnContainer;
