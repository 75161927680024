import Modal from "@src/components/ui-extended/Modal";
import { Button } from "@src/components/ui/button";
import { DialogFooter, DialogTitle } from "@src/components/ui/dialog";
import { Label } from "@src/components/ui/label";
import { Textarea } from "@src/components/ui/textarea";
import React, { Dispatch, SetStateAction } from "react";
import { LuX } from "react-icons/lu";

const ErrorMessageDialog: React.FC<{
	isOpen: boolean;
	errorMessage: string;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	setErrorMessage: Dispatch<SetStateAction<string>>;
}> = ({ isOpen, setIsOpen, errorMessage }) => {
	return (
		<Modal
			setIsOpen={setIsOpen}
			isOpen={isOpen}
			className="max-w-[1015px] gap-8 p-6"
			header={
				<div className="flex flex-col space-y-2">
					<div className="flex justify-between">
						<DialogTitle className="text-[22px] font-medium capitalize leading-[30px] -tracking-[1%] text-main-1">
							Error when integrating with Oscar
						</DialogTitle>
						<LuX
							onClick={() => {
								setIsOpen(false);
							}}
							color="#858C95"
							className="cursor-pointer"
							width="20px"
							height="20px"
						/>
					</div>
					<p className="leading-[24px] tracking-[-1%] text-[#323539]">
						There was an error integrating....
					</p>
				</div>
			}
		>
			<div className="flex flex-1 flex-col space-y-1.5">
				<Label>Error Message</Label>
				<Textarea
					className="min-h-[234px] flex-1 !cursor-default resize-none"
					value={errorMessage}
					disabled
				/>
			</div>
			<DialogFooter className="flex justify-between sm:justify-between">
				<Button
					variant="ghost"
					className="text-[#323539]"
					type="button"
					onClick={() => {
						setIsOpen(false);
					}}
				>
					Cancel
				</Button>
				<div className="space-x-2.5">
					<Button
						variant="outline"
						className="border-primary text-primary"
					>
						Contact Migranium Support
					</Button>
					<Button className="text-white">Retry</Button>
				</div>
			</DialogFooter>
		</Modal>
	);
};

export default ErrorMessageDialog;
