import { useState } from "react";
import moment from "moment-timezone";
import { zodResolver } from "@hookform/resolvers/zod";
import LocationInfo from "../components/LocationInfo";
import Stepper from "@components/Stepper";
import ScheduleForm from "../components/ScheduleForm";
import LocationMember from "../components/LocationMember";
import { Button } from "@components/ui/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import {
	AddLocationDataV2,
	AddLocationSchemaV2,
	// WorkingHoursProps,
	WorkingHoursSchema,
} from "@src/types/Location";
import { defaultTimeSlots, defaultTimeSlotsV2 } from "@utils/constants";
import { isTimeOverlapping } from "@utils/functions";
import {
	AddBusinessLocationSlice,
	UpdateLocationOperatingHoursSlice,
	GetLocationDetailsSlice,
	// UpdateBusinessLocationSlice,
} from "@src/store/slices/locations/locationSlice";
// import useLocationsStore from "@src/store/useLocationsStore";
import RequestIsLoading from "@components/RequestIsLoading";

export default function LocationTabList() {
	const [addLocationResponse, setAddLocationResponse] = useState<any>(null);

	const [currentStep, setCurrentStep] = useState<number>(0);
	console.log("🚀 ~ LocationTabList ~ currentStep:", currentStep);
	const [slots, setSlots] = useState<any[]>(defaultTimeSlots);
	const [slotsV2, setSlotsV2] = useState<any>(defaultTimeSlotsV2);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const locationId = searchParams.get("locationId") || "";
	const isEditMode = Boolean(locationId);
	const stepDetails: string[] = [
		"Location Information",
		"Operating Hours",
		"Members",
	];
	// const addLocationInfo = useLocationsStore((s) => s.addLocationInfo);

	const { data, isLoading } = isEditMode
		? GetLocationDetailsSlice(Number(parseInt(locationId)))
		: { data: null, isLoading: false };
	console.log("🚀 ~ LocationTabList ~ data:", data);

	const addBusinessLocationMutation = AddBusinessLocationSlice(
		(data) => {
			setAddLocationResponse(data.location);
			setCurrentStep(currentStep + 1);
		},
		(error) => console.log("🚀 ~ LocationTabList ~ error:", error)
		// slots
	);

	const updateBusinessWorkingHoursMutation =
		UpdateLocationOperatingHoursSlice(
			() => {
				setCurrentStep(currentStep + 1);
			},
			(error) => console.log("🚀 ~ LocationTabList ~ error:", error)
			// slots
		);

	const methods = useForm<AddLocationDataV2>({
		resolver: zodResolver(
			currentStep === 0
				? AddLocationSchemaV2
				: currentStep === 1
					? WorkingHoursSchema
					: WorkingHoursSchema
		),
		defaultValues: {
			name: "",
			state: "",
			address: "",
			city: "",
			country: "",
			approximate_waiting_time: "15",
			schedule_block_in_min: 15,
			time_zone: moment.tz.guess(),
			day_time_slots: slots,

			child_update_strategy: "specific", // "specific|ignore|default|non_default|override",
			child_ids: [],
			waitlist_schedule_option: "waitlist", // "all|schedule|waitlist"
			data: slotsV2,
			is_waitlist: true,
			is_schedule: false,
		},
	});

	const {
		handleSubmit,
		formState: { isValid },
	} = methods;

	const handleAddLocationForm: SubmitHandler<AddLocationDataV2> = async (
		data
	) => {
		if (isValid && currentStep === 0) {
			// setCurrentStep(currentStep + 1);
			if (data.approximate_waiting_time.length <= 0)
				data.approximate_waiting_time = "15";

			const hours = Math.floor(+data.approximate_waiting_time / 60);
			const minutes = +data.approximate_waiting_time % 60;

			const newFormData = new FormData();
			newFormData.append("name", data.name);
			newFormData.append("state", data.state);
			newFormData.append("address", data.address);
			newFormData.append("city", data.city);
			newFormData.append("country", data.country);
			newFormData.append(
				"approximate_waiting_time",
				`${String(hours).padStart(2, "0")}:${String(
					data.approximate_waiting_time ? minutes : 30
				).padStart(2, "0")}:00`
			);
			newFormData.append("time_zone", data.time_zone);
			newFormData.append(
				"schedule_block_in_min",
				(data.schedule_block_in_min || 15).toString()
			);
			newFormData.append("auto_clearing_minutes", (15).toString());
			newFormData.append("is_auto_clearing_on", (1).toString());
			// if (data.image) newFormData.append("image", data.image);
			addBusinessLocationMutation.mutate(newFormData);
			return;
		} else if (isValid && currentStep === 1) {
			if (isTimeOverlapping(slots)) return;

			const payload = {
				child_update_strategy: "specific",
				child_ids: [addLocationResponse?.id || 7],
				waitlist_schedule_option:
					data.is_schedule && data.is_waitlist
						? "all"
						: data.is_schedule
							? "schedule"
							: "waitlist",
				data: data.data,
			};
			updateBusinessWorkingHoursMutation.mutate(payload);

			return;
		} else if (isValid && currentStep === 2) {
			// setCurrentStep(currentStep + 1);
			return;
		}
	};

	const handleCancel = () => {
		navigate("/dashboard/locations");
	};

	const RenderStepContent = (activeStep: number) => {
		switch (activeStep) {
			case 0:
				return <LocationInfo />;
			case 1:
				return <ScheduleForm />;
			case 2:
				return <LocationMember />;
			default:
				return "";
		}
	};

	return (
		<div className="flex-1">
			<FormProvider {...methods}>
				<RequestIsLoading
					isLoading={
						addBusinessLocationMutation.isLoading ||
						updateBusinessWorkingHoursMutation.isLoading ||
						isLoading
					}
				/>
				<form
					encType="multipart/form-data"
					onSubmit={handleSubmit(handleAddLocationForm)}
				>
					<div className="mx-auto w-full max-w-[1000px] flex-1 space-y-6">
						<Stepper step={currentStep} steplist={stepDetails} />
						{RenderStepContent(currentStep)}
					</div>
					<div className="mx-auto flex w-full max-w-[1000px] justify-end gap-2 py-4">
						<Button
							type="reset"
							onClick={handleCancel}
							variant={"ghost"}
						>
							{currentStep === 2 ? "Skip for now" : "Cancel"}
						</Button>
						<Button type="submit" className="px-9">
							{currentStep === 0
								? "Next"
								: currentStep === 1
									? "Save & Continue"
									: "Save"}
						</Button>
					</div>
				</form>
			</FormProvider>
		</div>
	);
}
