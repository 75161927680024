import { create } from "zustand";
import { persist } from "zustand/middleware";
import { LocationArray } from "../types/Location";

interface LocationsStore {
	locations: LocationArray;
	addLocationInfo: AddLocationInfoType | null;
	setLocations: (locations: LocationArray) => void;
	setAddLocationInfo: (addLocationInfo: AddLocationInfoType) => void;
	reset: () => void;
}

interface AddLocationInfoType {
	id: number;
}

const initialState = {
	locations: [],
	addLocationInfo: null,
};

const useLocationsStore = create<
	LocationsStore,
	[["zustand/persist", LocationsStore]]
>(
	persist(
		(set) => ({
			...initialState,
			setLocations: (locations: LocationArray) => {
				set(() => ({ locations }));
			},
			setAddLocationInfo: (addLocationInfo: AddLocationInfoType) => {
				set(() => ({ addLocationInfo }));
			},
			reset: () => set(initialState),
		}),
		{
			name: "locations-storage",
			getStorage: () => localStorage,
		}
	)
);

export default useLocationsStore;
